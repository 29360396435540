import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as inboxDataActions from "./actions";
import { useParams } from "react-router-dom";
import { Grid, Container, Typography, Button } from "@material-ui/core";
import { useStyles } from "../../constants/styles";
import Table from "../../components/Table";
import TableHeader from "../../components/TableHeader";
import { formatResult } from "../../utils/formatResult";
import SimpleCard from "../../components/SimpleCard";
import { dateISO } from "../../utils/dateISO";
import { sortingAscending } from "../../utils/sortingAscending";
import useString from "../../i18n/hooks/useString";

const Data = () => {
  const classes = useStyles();
  const { alertId } = useParams();
  const dispatch = useDispatch();
  const { current: actions } = useRef(
    bindActionCreators({ ...inboxDataActions }, dispatch)
  );
  const inboxData = useSelector(({ inboxDataReducer }) => inboxDataReducer);
  const [selected, setSelected] = useState([]);
  const getString = useString();

  useEffect(() => {
    actions.getInboxScans(alertId);
  }, [actions, alertId]);

  useEffect(() => {
    if (inboxData.scans.length) setSelected([inboxData.scans[0].id]);
    else setSelected([]);
  }, [actions, inboxData.scans]);

  useEffect(() => {
    if (selected[0]) actions.getInboxScanDetails(alertId, selected[0]);
  }, [actions, alertId, selected]);

  const handleSelect = (newSelected) =>
    setSelected(newSelected.filter((each) => each !== selected[0]));

  useEffect(() => {
    if (inboxData.csvFile) {
      var atag = document.createElement("a");
      var file = new Blob([inboxData.csvFile], { type: "text/csv" });
      atag.href = URL.createObjectURL(file);
      atag.download = "download.csv";
      atag.click();
    }
  }, [inboxData.csvFile]);

  const data = formatResult(
    sortingAscending(
      inboxData.scanDetails.data,
      inboxData.sortBy,
      inboxData.sortAscending
    ),
    inboxData.scanDetails.measures,
    inboxData.scanDetails.dimensions[0]
  );

  return (
    <Container className={classes.container}>
      <Typography style={{ marginTop: "2em" }}>
        <span style={{ fontWeight: "bold" }}>
          {getString("InboxData.Span.LastTen")}{" "}
        </span>
        {getString("InboxData.Typography.ClickRow")}
      </Typography>
      <TableHeader />
      <Grid className={classes.tableWrapper}>
        <Table
          {...inboxData.mainTable}
          data={inboxData.scans}
          actions={{ ...actions, setSelected: handleSelect }}
          selectedIds={selected}
        />
      </Grid>
      <hr className={classes.aboutPageLine} />

      <Grid>
        <SimpleCard
          fields={inboxData.simpleCard.fields}
          title={`${getString("InboxData.SimpleCard.ScanDetails")} ${dateISO(
            inboxData.scanDetails.timestamp,
            true
          )}`}
          data={inboxData.scanDetails}
          outline={false}
          baseLink={inboxData.scanDetails.baseLink}
        />
      </Grid>

      <Typography style={{ marginTop: "2em", fontWeight: "bold" }}>
        {getString("InboxData.Typography.Filters")}
      </Typography>
      <TableHeader />
      <Grid className={classes.tableWrapper}>
        <Table
          tid="table_filters"
          actions={{}}
          {...inboxData.filterTable}
          data={inboxData.scanDetails.filters}
          borderless
        />
      </Grid>

      <Grid
        style={{
          marginTop: "2em",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography>
          <span style={{ fontWeight: "bold" }}>
            {getString("InboxData.Typography.ReportedData")}{" "}
          </span>
          ({getString("InboxData.Typography.ReportedSubtitle")} {data?.length}{" "}
          {getString("InboxData.Typography.ReportedSubtitleCont")}{" "}
          {inboxData.scanDetails.countPositive})
        </Typography>

        {inboxData.scans && (
          <Button
            variant="text"
            onClick={() =>
              actions.getCsvFile(alertId, inboxData.scanDetails.scanId)
            }
            target="_blank"
            rel="noopener noreferrer"
            style={{
              marginRight: "2em",
              textDecoration: "underline",
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            {getString("InboxData.Button.ExportData")}
          </Button>
        )}
      </Grid>
      <TableHeader />
      <Grid className={classes.tableWrapper} style={{ marginBottom: "5em" }}>
        <Table
          {...inboxData.reportedDataTable}
          data={data}
          actions={{ actions }}
        />
      </Grid>
    </Container>
  );
};

export default Data;
