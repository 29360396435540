import ActionTypes from "../../constants/actionTypes";

export const blockNavigation = () => {
  return async dispatch => {
    dispatch({
      type: ActionTypes.NAVIGATION_BLOCK,
    });
    return Promise.resolve();
  }
};

export const unblockNavigation = () => {
  return async dispatch => {
    dispatch({
      type: ActionTypes.NAVIGATION_UNBLOCK,
    });
    return Promise.resolve();
  }
};
