import React, { useState, useEffect, useRef } from "react";
import { bindActionCreators } from "redux";
import { useSelector, useDispatch } from "react-redux";
import { Container, Grid } from "@material-ui/core";
import * as configActions from "./actions";
import Form from "../../components/Form";
import BottomBar from "../../components/BottomBar";
import Modal from "../../components/Modal";
import BreadcrumbsHeader from '../../components/BreadcrumbsHeader';
import { useStyles } from "../../constants/styles";
import { push } from "connected-react-router";
import paths from "../../constants/paths";

const Config = () => {
  const config = useSelector(({ configReducer }) => configReducer);
  const login = useSelector(({ loginReducer }) => loginReducer);
  const dispatch = useDispatch();
  const { current: actions } = useRef(bindActionCreators({...configActions}, dispatch));

  const [modalVisible, setModalVisible] = useState(false);
  const classes = useStyles()
  
  useEffect(() => {
    if(login.isAdmin) actions.getConfig()
    else dispatch(push(paths.alerts))
  }, []);

  const handleSave = () => actions.editPingSettings(config);
  const handleCancel = () => setModalVisible(true);
  const handleSelect = field => value => actions.addValueToConfig(field, value.target.value);

  return (
    <>
      <Container className={classes.formContainer}>
        <Grid>
          <BreadcrumbsHeader crumbs={config.crumbs} />
        </Grid>
        <Grid className={classes.configForm}>
          <Form {...config.form} 
          reducerState={config}
          handleSelect={handleSelect}
          />
        </Grid>
      </Container>
      <BottomBar actions={{ handleSave, handleCancel }} {...config.bottomBar} reducerState={config} />
      <Modal
        actions={actions}
        {...config.modal}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
      />
    </>
  );
};

export default Config;
