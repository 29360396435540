import React, { useState, useEffect } from "react";
import { makeStyles, Button, Grid, Paper, Typography } from "@material-ui/core";
import DateTimePicker from "../../DateTimePicker";
import Radio from "../../Radio";
import Selector from "../../Selector";
import Table from "../../Table";
import useString from "../../../i18n/hooks/useString";
import TextField from "../../TextField";
import NavTitle from "../../NavTitle";

const useStyles = makeStyles((theme) => ({
  scanInputs: {
    display: 'flex',
    marginTop: theme.spacing(1),
    '& > *': {
      margin: 0,
      marginRight: theme.spacing(1)
    }
  },
  timeInputs: {
    display: 'flex',
    '& > *': {
      width: 'auto',
      margin: 0,
      marginRight: theme.spacing(1)
    }
  },
  scheduledTimeInputs: {
    margin: theme.spacing(2, 0),
    display: 'flex',
    '& > *': {
      margin: 0,
      marginRight: theme.spacing(1)
    },
  },
  spacedContent: {
    '& > *': {
      marginTop: theme.spacing(1),
    },
  }
}));

const Schedule = props => {
  const classes = useStyles();
  const getString = useString();

  const { actions, alertsDetails, type } = props;
  const [time, setTime] = useState("09:00");
  const [serverTime, setServerTime] = useState("")

  useEffect(() => {
    const inter = setInterval(() => {
      const serverTimestamp = new Date().getTime() - (alertsDetails.timezoneOffset * 60000)
      const formatted = new Date(serverTimestamp).toISOString().slice(0, -5).replace('T', ' ')
      setServerTime(formatted)
    },1000)
    return () => clearInterval(inter)
  },[])
  
  const handleSelect = field => value => {
    switch (field) {
      case "timeZone":
        actions.addValueToAlert(field, value ? value.value || "" : "");
        break;
      case "dayOfWeek":
        actions.addValueToAlert(field, value.map(each => each.value) || []);
        break;
      case "dayOfMonth":
        actions.addValueToAlert(field, value || []);
        break;
      case "intervalMinutes":
      case "intervalHours":
      case "minHour":
      case "maxHour":
      case "since":
      case "until":
        actions.addValueToAlert(field, value.target.value);
        break;
      case "intervalTime":
      case "onSchedule":
        actions.addValueToAlert(field, value === "true");
        break;
      default:
        break;
    }
  };

  const handleAddTimes = () => {
    actions.addScheduleTime(time);
    setTime("");
  };

  const deleteScheduleTime = value => actions.deleteScheduleTime(value.index);

  const scheduleSectionDescription = type === "broadcast" 
    ? getString("AlertsDetails.Schedule.Broadcast.Description")
    : getString("AlertsDetails.Schedule.Description");

  return (
    <Grid container tid="grid_parent" justify="space-evenly" direction="column" spacing={2}>
      <Grid item xs>
        <NavTitle
          title={getString("AlertsDetails.Label.Schedule")}
          description={scheduleSectionDescription}
        />
      </Grid>
      <Grid item tid="grid_schedule_reload_radio" xs style={{ flexBasis: 'auto' }}>
        <Paper tid="paper_how_to_trigger" elevation={0}>
          {getString("Schedule.Label.HowToTrigger")}
        </Paper>
        <Radio
          tid="radio_schedule_reload"
          id="onSchedule"
          value={JSON.stringify(alertsDetails.onSchedule)}
          type="radio"
          options={[
            {
              name: type === "data"
                ? getString("Schedule.Label.OnReload")
                : getString("Schedule.Label.TriggerNow"),
              value: "false"
            },
            {
              name: getString("Schedule.Label.OnSchedule"),
              value: "true"
            }
          ]}
          onChange={handleSelect("onSchedule")}
        />
      </Grid>
      {alertsDetails.onSchedule ? (
        <Grid container item tid="grid_body" spacing={2} direction="column">
          <Grid item tid="grid_schedule_server_time" xs style={{ flexBasis: 'auto' }}>
            <Typography variant="body2" tid="typography_server-time">
              {getString("Schedule.Label.ServerTime")} {serverTime}
            </Typography>
          </Grid>
          <Grid item tid="grid_selector_date_time" xs style={{ width: '650px', flexBasis: 'auto'}}>
            <div className={classes.spacedContent}>
              <Selector
                disabled={!!alertsDetails.dayOfMonth.length}
                tid="selector_days_of_the_week"
                {...alertsDetails.selectors.dayOfWeek}
                label={"Schedule.Label.DaysOfTheWeek"}
                id="dayOfWeek"
                value={alertsDetails.dayOfWeek}
                onSelect={handleSelect("dayOfWeek")}
              />
              <Selector
                disabled={!!alertsDetails.dayOfWeek.length}
                tid="selector_days_of_the_month"
                {...alertsDetails.selectors.dayOfMonth}
                label={"Schedule.Label.DaysOfTheMonth"}
                id="dayOfMonth"
                value={alertsDetails.dayOfMonth}
                onSelect={handleSelect("dayOfMonth")}
              />
            </div>
            <div className={classes.scanInputs}>
              <DateTimePicker
                tid="date_time_picker_scan_since"
                id="since"
                value={alertsDetails.since}
                onChange={handleSelect("since")}
                label={"Schedule.Label.ScanSince"}

              />
              <DateTimePicker
                tid="date_time_picker_scan_until"
                id="until"
                value={alertsDetails.until}
                onChange={handleSelect("until")}
                label={"Schedule.Label.ScanUntil"}
              />
            </div>
          </Grid>
          <Grid
            container
            item
            tid="grid_interval_time_parent"
            xs
            spacing={1}
            direction="column"
            justify="space-around"
            alignItems="stretch"
            className={classes.spacedContent}
            style={{ width: '650px', flexBasis: 'auto' }}
          >
            <Grid item tid="grid_radio_intervals_times" xs style={{ flexBasis: 'auto' }}>
              <NavTitle tid="paper_schedule_alert_by" title={getString("Schedule.Label.ScheduleAlertBy")} />
              <Radio
                tid="radio_interval_time"
                id="intervalTime"
                value={JSON.stringify(alertsDetails.intervalTime)}
                type="radio"
                options={[
                  {
                    name: "Schedule.Label.Intervals",
                    value: "false"
                  },
                  {
                    name: "Schedule.Label.Times",
                    value: "true"
                  }
                ]}
                onChange={handleSelect("intervalTime")}
              />
            </Grid>
            {!alertsDetails.intervalTime ? (
              <Grid item tid="grid_interval_parent" xs={10} style={{ flexBasis: 'auto' }}>
                <Paper tid="paper_between_times" elevation={0}>
                  {getString("Schedule.Label.BetweenTimes")}
                </Paper>
                <div className={classes.scheduledTimeInputs}>
                  <TextField
                    tid="text_field_start_time"
                    fullWidth={true}
                    id="minHour"
                    onChange={handleSelect("minHour")}
                    value={alertsDetails.minHour}
                    label={"Schedule.Label.StartTime"}
                    type="time"
                    inputProps={{
                      step: 300
                    }}
                  />
                  <TextField
                    tid="text_field_end_time"
                    fullWidth={true}
                    id="maxHour"
                    onChange={handleSelect("maxHour")}
                    value={alertsDetails.maxHour}
                    label={"Schedule.Label.EndTime"}
                    type="time"
                    inputProps={{
                      step: 300
                    }}
                  />
                </div>
                <Grid item tid="grid_hours_minutes_parent" xs>
                  <Paper tid="paper_every" elevation={0}>
                    {getString("Schedule.Label.Every")}
                  </Paper>
                  <div className={classes.scheduledTimeInputs}>
                    <TextField
                      tid="text_field_hours"
                      name="intervalHours"
                      label={"Schedule.Label.Hours"}
                      type="number"
                      id="intervalHours"
                      onChange={handleSelect("intervalHours")}
                      value={alertsDetails.intervalHours}
                      inputProps={{ min: 0 }}
                    />
                    <TextField
                      tid="text_field_minutes"
                      name="intervalMinutes"
                      label={"Schedule.Label.Minutes"}
                      type="number"
                      id="intervalMinutes"
                      onChange={handleSelect("intervalMinutes")}
                      value={alertsDetails.intervalMinutes}
                      inputProps={{ min: 0, max: 59 }}
                    />
                  </div>
                </Grid>
              </Grid>
            ) : (
                <Grid item tid="grid_time" xs>
                  <div className={classes.timeInputs}>
                    <TextField
                      tid="text_field_time_to_scan"
                      label={"Schedule.Label.TimeToScan"}
                      value={time}
                      type="time"
                      onChange={e => setTime(e.target.value)}
                    />
                    <Button
                      tid="button_add_scan_time"
                      style={{ marginTop: ' 20px' }}
                      variant="outlined"
                      onClick={handleAddTimes}
                      disabled={!time || alertsDetails.times.includes(time)}
                    >
                      {getString("Schedule.Label.AddScanTime")}
                    </Button>
                  </div>
                  <Table
                    tid="table_times"
                    actions={{ deleteScheduleTime }}
                    data={alertsDetails.times.map(value => ({ value }))}
                    {...alertsDetails.tables.times}
                  />
                </Grid>
              )}
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default Schedule;
