import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as sourcesDetailsActions from "./actions";
import { Container, Grid } from "@material-ui/core";
import Uploader from "../../components/Uploader";
import Form from "../../components/Form";
import BottomBar from "../../components/BottomBar";
import Modal from "../../components/Modal";
import Breadcrumbs from '../../components/BreadcrumbsHeader';
import { useStyles } from "../../constants/styles";
import useNavBlocker from "../../components/NavigationBlocker/hooks/useNavBlocker";
import { push } from "connected-react-router";
import paths from "../../constants/paths";

const SourcesDetails = () => {
  const { unblockNavigation, blockNavigation } = useNavBlocker();
  useEffect(() => {
    blockNavigation();
  }, []);

  const dispatch = useDispatch();
  const { current: actions } = useRef(bindActionCreators({...sourcesDetailsActions}, dispatch));
  const sourcesDetails = useSelector(({ sourcesDetailsReducer }) => sourcesDetailsReducer);
  const login = useSelector(({ loginReducer }) => loginReducer);
  const [modalVisible, setModalVisible] = useState(false);
  const classes = useStyles()
  const { id } = useParams();

  useEffect(() => {
    if(login.isAdmin) {
      if (id) actions.getSource(id)
      else dispatch(push(paths.sources))
    }
    else dispatch(push(paths.alerts))
  }, []);

  const handleSave = async () => {
      await unblockNavigation();
      actions.editSource(id, sourcesDetails);
  }
  const handleTest = () => actions.testSource(sourcesDetails);
  const handleCancel = () => actions.cancelEditSource();

  const handleSelect = field => value => {
    
    switch (field) {
      case "ssoEnabled":
        actions.addValueToSourcesDetails(field, value === "true");
        break;
      default:
        actions.addValueToSourcesDetails(field, value.target.value);
        break;
    }
  };

  return (
    <>
      <div style={{overflowY: 'auto', flex: '1 1 auto'}}>
        <Container className={classes.formContainer}>
          <Grid>
            <Breadcrumbs crumbs={sourcesDetails.crumbs} />
          </Grid>
          <Grid container className={classes.formAndUpload}>
            <Grid item xs={12} md={6}>
              <Form {...sourcesDetails.form}
              reducerState={sourcesDetails}
              handleSelect={handleSelect}
            />
            </Grid>
            <Grid item xs={12} md={6} className={classes.fileUpload}>
              <Uploader
                actions={actions}
                {...sourcesDetails.uploader}
                inserted={sourcesDetails.inserted}
              />
            </Grid>
          </Grid>
        </Container>
        <Modal
          actions={actions}
          {...sourcesDetails.modal}
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
        />
      </div>
      <BottomBar
        actions={{ handleSave, handleCancel, handleTest }}
        {...sourcesDetails.bottomBar}
        reducerState={sourcesDetails}
      />
    </>
  );
};

export default SourcesDetails;
