export const historyFields = [{
    label: "InboxSystem.Label.TriggeredDateAndTime",
    style: {
        display:'none'
    },
    key: 'triggered',
    formula: 'date'
},
{
    label: "InboxSystem.Label.Status",
    style: {
        textStyle: 'bold'
    },
    key: 'status',
    formula: 'status'
}]

export const fields = [{
    label: "InboxSystem.Label.AlertName",
    style: {
        display:'none'
    },
    key: 'alertName',
    formula: null
},
{
    label: "InboxSystem.Label.ApplicationName",
    style: {
        textStyle: 'bold'
    },
    key: 'applicationName',
    formula: null
},
{
    label: "InboxSystem.Label.TaskName",
    style: {},
    key: 'testName',
    formula: 'uppercase'
},
{
    label: "InboxSystem.Label.TaskStatus",
    style: {
        color: 'red'
    },
    key: 'taskStatus',
    formula: 'toDate'
},
{
    label: "InboxSystem.Label.Duration",
    style: {},
    key: 'duration',
    formula: null
},
{
    label: "InboxSystem.Label.isActive",
    style: {},
    key: 'isActive',
    formula: null
},
{
    label: "InboxSystem.Label.AlertRules",
    style: {},
    key: 'rule',
    formula: null
},
]

export const tables = {
    options: {
        select: false,
        header: true
    },
    style: {
        header: {
            fontWeight: 'bold'
        }
    },
    fields: [
        {
            label: "InboxSystem.Label.Status",
            type: '',
            id: 'status',
            style: { flex: 3 },
            getValue: row => row.status,
            onClick: null
        },
        {
            label: "InboxSystem.Label.Triggered",
            type: '',
            id: 'triggered',
            style: { flex: 3 },
            getValue: row => row.triggered,
            onClick: null
        },
    ]
}

export const simpleCard = {
    labels: [
        {
            label: "InboxSystem.Label.AlertName",
            key: 'alertName',
        },
        {
            label: "InboxSystem.Label.ApplicationName",
            key: 'appName',
        },
        {
            label: "InboxSystem.Label.TaskName",
            key: 'taskName',
        },
        {
            label: "InboxSystem.Label.TaskStatus",
            key: 'taskStatus',
        },
        {
            label: "InboxSystem.Label.Duration",
            key: 'duration',
        },
        {
            label: "InboxSystem.Label.isActive",
            key: 'isActive',
        },
        {
            label: "InboxSystem.Label.AlertRules",
            key: 'rule',
        },
    ],
}
