import ActionTypes from "../../constants/actionTypes";
import API from "../../lib/api";
import { toUserPrivileges } from "./dataHandler";

export const getPermissions = ({sort, reverse} = {}) => {
  return async dispatch => {
    try {
      const result = await API.getUserPermissions({sort, reverse});
      const data = await toUserPrivileges(result);
      dispatch({
        type: ActionTypes.GET_USER_PRIVILEGES,
        payload: {
          data
        }
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: { snackMessage: typeof error === "string" ? error : JSON.stringify(error) }
      });
    }
  };
};
