export const toSourcesDetails = input => {
    return {
        name: input.name,
        type: input.type,
        hostname: input.config.hostname,
        httpsPort: input.config.httpsPort,
        qpsPort: input.config.qpsPort,
        qrsPort: input.config.qrsPort,
        userDirectory: input.config.userDirectory,
        userId: input.config.userId,
        filter: input.config.filter,
        virtualProxy: input.config.virtualProxy,
        sessionCookieName: input.config.sessionCookieName,
        url: input.config.url,
        proxyUrl: input.config.proxyUrl,
        ssoEnabled: input.config.ssoEnabled ? input.config.ssoEnabled : false,
        authenticationURI: input.config.authenticationURI ? input.config.authenticationURI : null
    }
}
export const fromSourceForm = values => {
   let object = {
        name: values.name,
        type: 'qlik',
        config: {
            hostname: values.hostname,
            url: values.url,
            httpsPort: values.httpsPort,
            qpsPort: values.qpsPort,
            qrsPort: values.qrsPort,
            userDirectory: values.userDirectory,
            userId: values.userId,
            filter: values.filter,
            virtualProxy: values.virtualProxy,
            sessionCookieName: values.sessionCookieName,
            proxyUrl: values.proxyUrl,
            ssoEnabled: values.ssoEnabled ? values.ssoEnabled : false,
            authenticationURI: values.authenticationURI ? values.authenticationURI : null
        }
    }
    if(values.inserted.client && values.inserted.client_key) {
        object.certs = {
            client: values.inserted.client,
            client_key: values.inserted.client_key
        }
    }
    return object;
};
