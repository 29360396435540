import React from 'react';
import { Button, Tooltip } from '@material-ui/core';
import useString from '../../i18n/hooks/useString';

const WrappedButton = ({ children, title, tooltipProps, ...rest }) => {
    const getString = useString();
    const renderButton = () => (
      <Button tid="button" {...rest}>
        {children}
      </Button>
    );
    return(
      title ? (
        <Tooltip title={getString(title)} {...tooltipProps} tid="WrappedButton__Tooltip">
           { renderButton() }
        </Tooltip>
      ) : renderButton())
}

export default WrappedButton;
