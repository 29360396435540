import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import useString from "../../i18n/hooks/useString";
import * as inboxActions from "./actions";
import { useParams } from "react-router-dom";
import { Grid, Container, Typography } from "@material-ui/core";
import SimpleCard from "../../components/SimpleCard";
import Table from "../../components/Table";
import TableHeader from "../../components/TableHeader";
import { useStyles } from "../../constants/styles";

const System = () => {
  const { alertId, taskId } = useParams();
  const dispatch = useDispatch();
  const getString = useString();
  const { current: actions } = useRef(
    bindActionCreators({ ...inboxActions }, dispatch)
  );
  const inboxSystem = useSelector(
    ({ inboxSystemReducer }) => inboxSystemReducer
  );

  useEffect(() => {
    actions.getHistorySystemDetail(alertId, taskId, getString);
  }, [actions, alertId, taskId]);

  const classes = useStyles();
  return (
    <Container className={classes.inboxSystemContainer}>
      <TableHeader />
      <Grid className={classes.tableWrapper}>
        <SimpleCard
          fields={inboxSystem.simpleCard.labels}
          data={inboxSystem}
          title={getString("InboxSystem.Label.Details")}
          outline={true}
        />
      </Grid>

      <Typography className={classes.triggeredHistoryTitle}>
        {getString("InboxSystem.Label.TriggeredHistory")}
      </Typography>
      <TableHeader />
      <Grid className={classes.tableWrapper}>
        <Table
          {...inboxSystem.tables}
          data={inboxSystem.history}
          actions={{ actions }}
        />
      </Grid>
    </Container>
  );
};

export default System;
