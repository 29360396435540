export const toUserManagement = (input) => {
    if(!Array.isArray(input)) input = [input]
    return input.map(each => {
        return {
            id: each._id,
            firstName: each.firstName,
            lastName: each.lastName,
            name: each.name,
            role: each.admin ? "Users.DataHandler.Admin" : "Users.DataHandler.User",
            domain: each.domain,
            email: each.channels?.email?.connection,
            isActive: each.isActive,
            license: (each.license || "").toUpperCase()
        }
    })
};
