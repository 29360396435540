import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  Button,
  Menu,
  MenuItem,
  Switch,
  TableSortLabel,
  Tooltip,
  Typography,
} from "@material-ui/core";
import MoreIcon from "@qlik-trial/sprout/icons/More";
import { NavLink } from "react-router-dom";
import useString from "../../i18n/hooks/useString";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    border: ({ borderless }) => borderless && "none",
    "& *": {
      border: ({ borderless }) => borderless && "none",
    },
  },
  actionButton: {
    margin: "1px 4px",
  },
  iconButton: {
    minWidth: 0,
    maxWidth: "24px",
  },
}));

const sortOrder = {};

const EnhancedTableHead = ({
  tableProps,
  onSelectAllClick,
  numSelected,
  rowCount,
}) => {
  const { actions, options, menu = [], fields = [], style = {} } = tableProps;
  const getString = useString();

  const sort = (fieldId, event) => {
    event.stopPropagation();
    const order = sortOrder?.[fieldId] === "asc" ? "desc" : "asc";
    if (actions.sortField) {
      actions.sortField(fieldId, order);
      sortOrder[fieldId] = order;
    }
  };
  return (
    <TableHead tid="table_head">
      <TableRow tid="table_row_head" style={style}>
        {options.onSelect && !options.hideSelections ? (
          <TableCell
            tid="table_cell_head_checkbox"
            padding="checkbox"
            align="center"
          >
            <Checkbox
              tid="checkbox_table_head"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all desserts" }}
            />
          </TableCell>
        ) : null}
        {fields.map((field, i) => (
          <TableCell
            tid={`table_cell_label_${i}`}
            key={`table_cell_label_${i}`}
          >
            {field.sortBy && actions.sortField ? (
              <TableSortLabel
                tid={`table_sort_label_${i}`}
                direction={
                  sortOrder[field.sortBy] ? sortOrder[field.sortBy] : "desc"
                }
                onClick={(event) => sort(field.sortBy, event)}
                style={{ ...style.header, ...field.style }}
              >
                {getString(field.label)}
              </TableSortLabel>
            ) : (
              <div
                tid={`div_label_${i}`}
                style={{ ...style.header, ...field.style }}
              >
                {getString(field.label)}
              </div>
            )}
          </TableCell>
        ))}
        {menu.length ? <TableCell /> : null}
      </TableRow>
    </TableHead>
  );
};

const Cell = ({ data, actions, field, ...rest }) => {
  const classes = useStyles();
  const getString = useString();
  let content = null;
  const {
    type,
    cellStyle,
    style,
    onClick,
    getValue,
    getLink,
    Icon,
    nav,
    label,
    key,
  } = field;
  const value = getValue ? getValue(data) : "";
  const link = getLink ? getLink(data) : "";
  const linkInactive = !data.activeLink && typeof data.activeLink === "boolean";
  const onClickHandler = () => {
    if (onClick) {
      if (actions[onClick]) actions[onClick](data);
      else onClick(data);
    }
  };
  switch (type) {
    case "nav":
    case "navIcon":
      content = getLink ? (
        <a target="_blank" rel="noopener noreferrer" href={link}>
          {value || getString(label)}
        </a>
      ) : (
        <NavLink
          style={linkInactive ? { pointerEvents: "none", textDecoration: "none" } : null}
          tid="nav_link_nav_icon"
          to={nav(data)}
        >
          <Button disabled={linkInactive ? true : false} tid={`button_nav_icon_${rest.tid || 0}`}>
            {value || getString(label)}
          </Button>
        </NavLink>
      );
      break;
    case "switch":
      content = (
        <div tid="div_switch" onClick={(event) => event.stopPropagation()}>
          <Switch
            tid={`switch_switch_${rest.tidIndex || ''}`}
            color="primary"
            onClick={onClickHandler}
            checked={getValue ? getValue(data) || false : false}
          />
        </div>
      );
      break;
    case "button":
      content = (
        <Button
          tid="button_button"
          size="small"
          variant="outlined"
          className={classes.actionButton}
          onClick={onClickHandler}
        >
          {value || getString(label)}
        </Button>
      );
      break;
    case "icon":
      content = (
        <IconButton tid="icon_button" onClick={onClickHandler} style={style}>
          <Icon />
        </IconButton>
      );
      break;
    default:
      const valueString = getString(value)
      const short =
        value && valueString.length > 30 ? valueString.slice(0, 30) + "..." : "";
      content = (
        <Tooltip
          tid="tooltip_wrapper"
          title={short ? valueString : ""}
          aria-label={short ? valueString : ""}
        >
          <div style={style}>{short || valueString}</div>
        </Tooltip>
      );
      break;
  }
  return (
    <TableCell tid="table_cell_content" key={key} style={cellStyle}>
      {content}
    </TableCell>
  );
};

const MyTable = (props) => {
  const {
    actions = {},
    data = [],
    fields,
    menu = [],
    options = {},
    selectedIds = [],
    style = {},
    placeholder,
    borderless,
  } = props;

  const classes = useStyles({ borderless });
  const getString = useString();
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuAction = (action) => (id) => {
    if (actions[action]) actions[action](id);
    handleClose();
  };

  const clickHandler = (id) => {
    if (options.onSelect) {
      const index = selectedIds.indexOf(id);
      const newSelected =
        index > -1
          ? [...selectedIds.slice(0, index), ...selectedIds.slice(index + 1)]
          : [...selectedIds, id];
      if (actions[options.onSelect]) actions[options.onSelect](newSelected);
      else options.onSelect(newSelected);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.id);
      if (actions[options.onSelect]) actions[options.onSelect](newSelecteds);
      else options.onSelect(newSelecteds);
    } else {
      if (actions[options.onSelect]) actions[options.onSelect]([]);
      else options.onSelect([]);
    }
  };

  const isSelected = (id) => selectedIds.indexOf(id) > -1;
  const empty = !fields?.length > 0 || !data?.length > 0;

  return placeholder && empty ? (
    <Typography paragraph={true} variant="body2" color="textSecondary">
      {placeholder}
    </Typography>
  ) : (
    <Table
      tid={`${props.tid || "table_main"}`}
      size={options.large ? "medium" : "small"}
      style={style}
      className={classes.table}
      stickyHeader
    >
      {options.header ? (
        <EnhancedTableHead
          tid="enhanced_table_head"
          tableProps={props}
          numSelected={selectedIds.length}
          onSelectAllClick={handleSelectAllClick}
          rowCount={data.length}
        />
      ) : null}
      <TableBody tid={`table_body_${props.tid || ''}`}>
        {data.map((row, index) => {
          const isItemSelected = isSelected(row?.id);
          const labelId = `enhanced-table-checkbox-${index}`;
          return (
            <TableRow
              tid={`${props.tid || ''}_table_row_${index}`}
              hover
              role="checkbox"
              aria-checked={isItemSelected}
              tabIndex={-1}
              key={`table_row_${index}`}
              selected={isItemSelected}
              onClick={() => clickHandler(row.id)}
            >
              {options.onSelect && !options.hideSelections ? (
                <TableCell
                  tid={`table_cell_data_${index}`}
                  padding="checkbox"
                  align="center"
                >
                  <Checkbox
                    tid={`checkbox_table_${index}`}
                    checked={isItemSelected}
                    inputProps={{ "aria-labelledby": labelId }}
                    onClick={() => clickHandler(row.id)}
                  />
                </TableCell>
              ) : null}
              {fields.map((field, i) => (
                <Cell
                  tid={`cell_id_${index}_${i}`}
                  key={`cell_id_${index}_${i}`}
                  data={{ index, ...row }}
                  actions={actions}
                  field={field}
                  tidIndex={`${index}`}
                />
              ))}
              {menu.length ? (
                <TableCell
                  tid={`table_cell_menu_${index}`}
                  padding="none"
                  onClick={(event) => event.stopPropagation()}
                >
                  <Button
                    size="small"
                    variant="outlined"
                    tid={`menu_button_${index}`}
                    name={row.id}
                    className={`${classes.actionButton} ${classes.iconButton}`}
                    onClick={(event) => openMenu(event)}
                  >
                    <MoreIcon size="small" />
                  </Button>
                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={!!(anchorEl && anchorEl.name === row.id)}
                    onClose={handleClose}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    {menu.map((each, itemIndex) => (
                      <MenuItem
                        tid={`table_cell_menu_${index}_item_${each.action || itemIndex}`}
                        key={`table_cell_menu_${index}_item_${itemIndex}`}
                        onClick={() => handleMenuAction(each.action)(row.id)}
                      >
                        {getString(each.label)}
                      </MenuItem>
                    ))}
                  </Menu>
                </TableCell>
              ) : null}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default MyTable;
