import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import useString from "../../i18n/hooks/useString";
import { COLORS } from '../../utils/sproutOverrides';

const useStyles = makeStyles((theme) => ({
  title: {
    width: ({ underlineWidth }) => underlineWidth || '224px',
    borderBottom: ({ noUnderline }) => !noUnderline ? `1px solid ${COLORS.GREYSCALE_85}` : 'none',
    paddingBottom: ({ noUnderline }) => !noUnderline ?  theme.spacing() : '0px',
  },
  secondaryLabel: {
    color: COLORS.TEXT_SECONDARY,
  }
}));


const NavTitle = ({title, subtitle, style = {}, description, underlineWidth, noUnderline, className, ...rest}) => {
  const classes = useStyles({ underlineWidth, noUnderline });
  const getString = useString();

  return (
    <>
      <Typography 
        tid="typography_subtitle"
        paragraph={!description}
        gutterBottom={!!description}
        variant="body1"
        className={className ? `${classes.title} ${className}` : classes.title}
        style={style}
        {...rest}
      >
        {getString(title)} <span tid="span_subtitle" className={classes.secondaryLabel}>{getString(subtitle)}</span>
      </Typography>
      {description &&
        <Typography
          tid="typography_description"
          variant="body2"
          color="textSecondary"
          style={style}
          paragraph
        >
          {getString(description)}
        </Typography>
      }
    </>
  )
}
export default NavTitle;



