import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { bindActionCreators } from "redux";
import * as loginActions from "./actions";
import useString from "../../i18n/hooks/useString";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Link,
  FormControl,
  FormLabel
} from "@material-ui/core";
import { push } from "connected-react-router";
import paths from "../../constants/paths";
import { Typography } from "@material-ui/core";
import TextField from "../../components/TextField";
import CircularIndeterminate from "../../components/Spinner";

const useStyles = makeStyles(theme => ({
  backdrop: {
    alignContent: 'center',
    backgroundColor: '#F7F7F7',
    height: '100%',
    width: '100%',
  },
  loginCard: {
    display: 'flex',
    flexFlow: 'column',
    backgroundColor: theme.palette.background.paper,
    boxSizing: 'border-box',
    boxShadow: '0 1px 2px 0 rgba(40,40,40,0.16)',
    borderRadius: '3px',
    alignContent: 'center',
    height: 'auto',
    width: '450px',
    maxWidth: '450px',
    padding: theme.spacing(6, 8),
    margin: `${theme.spacing(14)}px auto`,
    [theme.breakpoints.down('xs')]: {
      width: `calc(100% - ${theme.spacing()}px)`,
      padding: theme.spacing(4, 5),
    },
  },
  cardHeader: {
    marginBottom: theme.spacing(3),
    color: theme.palette.text.primary,
  },
  inputWrapper: {
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(2),
    }
  },
  loginButton: {
    width: '100%',
    marginTop: theme.spacing(4),
  },
  link: {
    cursor: 'pointer',
  },
  linkContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(1, 0),
  },
  title: {
    lineHeight: '36px',
    paddingBottom: theme.spacing()
  },
  subtitle: {
    color: theme.palette.text.secondary,
  },
  fullWidthControl: {
    width: '100%',
  },
  label: {
    marginBottom: theme.spacing(),
    fontSize: theme.typography.fontSize,
  }
}))


const Login = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const login = useSelector(({ loginReducer }) => loginReducer);
  const { current: actions } = useRef(bindActionCreators({ ...loginActions }, dispatch));
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [fieldValue, setFieldValue] = useState({ username: '', password: '' });

  const getString = useString();

  const handleConfirm = () => {
    if (showForgotPassword) {
      actions.sendForgotPassword({
        userName: fieldValue.username
      });
      return;
    }
    actions.onLogin({
      userName: fieldValue.username,
      password: fieldValue.password,
    });
  }

  useEffect(() => {
    actions.registrationStatus()
  }, [])

  useEffect(() => {
    if (login.token && login.userId) dispatch(push(paths.alerts))
  }, [])

  const handleValue = field => value => setFieldValue({ ...fieldValue, [field]: value })

  const handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      handleConfirm();
    }
  };

  useEffect(() => {
    if (login.ssoEnabled) handleSSOLogin()
  }, [login.ssoEnabled])

  const handleSSOLogin = (arg = "") => {
    if (sessionStorage.session !== "FORCE_LOGOUT" || arg === "FORCE") {
      delete sessionStorage.session
      window.location.href = login.authenticationURI
    }
  };

  const basicAuthFlow = <>
    <div className={classes.inputWrapper}>
      <FormControl className={classes.fullWidthControl} >
        <FormLabel className={classes.label}> {getString("Login.Label.UserID")} </FormLabel>
        <TextField
          tid="text_field_username_login"
          fullWidth={true}
          id="loginUsername"
          onChange={(event) => handleValue("username")(event.target.value)}
          value={fieldValue.username}
          onKeyUp={handleKeyUp}
          autoFocus
        />
      </FormControl>
      {!showForgotPassword &&
        <FormControl className={classes.fullWidthControl} >
          <FormLabel className={classes.label}> {getString("Login.Label.Password")} </FormLabel>
          <TextField
            tid="text_field_login_password"
            fullWidth={true}
            id="loginPassword"
            onChange={(event) => handleValue("password")(event.target.value)}
            value={fieldValue.password}
            type="password"
            onKeyUp={handleKeyUp}
          />
        </FormControl>
      }
    </div>
    <div className={classes.linkContainer}>
      <Link
        variant="body2"
        className={classes.link}
        onClick={() => setShowForgotPassword(!showForgotPassword)}
      >
        {showForgotPassword
          ? getString("Login.Link.BackToLogin")
          : getString("Login.Link.ForgotPassword")
        }
      </Link>
    </div>
    <Button
      variant="contained"
      color="primary"
      type="submit"
      className={classes.loginButton}
      onClick={handleConfirm}
    >
      {showForgotPassword
        ? getString("Login.Link.RecoverPassword")
        : getString("Login.Link.Login")
      }
    </Button>
  </>

  const isSSOFlagEnabled = <>
    <div className={classes.loginCard}>
      <div className={classes.cardHeader}>
        <Typography variant="h3" className={classes.title}>
          {getString('Login.Card.Title')}
        </Typography>
        <Typography variant="body1" className={classes.subtitle}>
          {getString('Login.Card.Subtitle')}
        </Typography>
      </div>
      {login.ssoEnabled ?
        <Button
          variant="contained"
          color="primary"
          type="submit"
          className={classes.loginButton}
          onClick={()=>handleSSOLogin("FORCE")}
        >
          Login with Qlik Sense
        </Button> : basicAuthFlow
      }
    </div>
  </>

  return (
    <div className={classes.backdrop}>
      {
        login.loading
          ? <CircularIndeterminate loading={true} />
          : isSSOFlagEnabled
      }
    </div >
  );
};

export default Login;