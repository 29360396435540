import {dateISO} from "../../utils/dateISO";
import {timeConverter} from '@qlik-trial/qaw-common'

const alertRule = (rule, getString) => {
    if (!rule) return getString("InboxSystem.DataHandler.TaskDef.None")
    const { operator, def } = rule
    switch (operator) {
        case '$all':
            return getString("InboxSystem.DataHandler.TaskDef.All")
        case '$sw':
            return getString("InboxSystem.DataHandler.TaskDef.StartsWith", [def])
        case '$ew':
            return getString('InboxSystem.DataHandler.TaskDef.EndsWith', [def])
        case '$in':
            return getString("InboxSystem.DataHandler.TaskDef.Includes", [def])
        default:
            return getString("InboxSystem.DataHandler.TaskDef.None")
    }
}

export const toInboxSystem = (input, getString) => {
      return  { 
        alertName: input.alertName,
        appName: input.appName,
        taskName: input.taskName,
        taskStatus: input.status === 7 ? "InboxSystem.DataHandler.Success" : "InboxSystem.DataHandler.NotYet",
        duration: timeConverter(input.duration),
        isActive: input.inProgress ? 'true' : 'false',
        rule: alertRule(input.rule, getString),
        history: input.history.map(each => {
            return {
                status: each.status, 
                triggered: dateISO(each.triggered, true),
            }
        })
    }
};
