import ActionTypes from "../../constants/actionTypes";
import * as definitions from "./definitions";
import { shouldCleanup } from '../../utils/navigationCleanup';

const initialState = {
  ...definitions,
  name: "",
  type: "email",
  connectionHost: "smtp.gmail.com",
  connectionPort: 587,
  connectionTest: "",
  connectionAuthUser: "",
  connectionAuthPass: "",
  connectionAuthSecurity: "none",
  authenticationMethod: "basic",
  connectionFrom: "",
  connectionCC: "",
  connectionBCC: "",
  tested: false,
  saveIsDisabled: false
};

export default function editChannelReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.EDIT_DELIVERY_CHANNEL:
      return {
        ...state,
        result: action.payload.result,
        ...action.payload.data
      };
    case ActionTypes.GET_CHANNELS_DETAILS:
      return {
        ...initialState,
        ...action.payload
      };
    case ActionTypes.TEST_CONNECTION:
      return {
        ...state,
        ...action.payload.data,
        tested: action.payload.result
      };
    case ActionTypes.SAVE_BUTTON_DISABLED:
      return {
        ...state,
        saveIsDisabled: action.payload.saveIsDisabled
      };
    case ActionTypes.CANCEL_EDIT_CHANNELS:
      return state
    case ActionTypes.ADD_VALUE_TO_CHANNELS_DETAILS:
      return {
        ...state,
        [action.payload.field]: action.payload.value
      };
    case ActionTypes.LOCATION_CHANGE:
        return shouldCleanup(action.payload) ? {...initialState} : state;
    default:
      return state;
  }
}
