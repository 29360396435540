import React, { useState } from "react";
import {
  makeStyles,
  fade,
  Button,
  Checkbox,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControl,
  Grid,
  Typography
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import Selector from "../../components/Selector";
import TextField from "../../components/TextField";
import useString from "../../i18n/hooks/useString";

const useStyles = makeStyles((theme) => ({
  selectInput: {
    maxWidth: '200px',
  },
  deleteButton: {
    margin: theme.spacing(1, 2, 2, 2),
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.light,
    },
    '&:active': {
      backgroundColor: theme.palette.error.dark,
    },
    '&.sprout-focus-visible': {
      borderColor: theme.palette.error.dark,
      boxShadow: `0 0 0 3px ${fade(theme.palette.error.light, 0.5)}`,
    },
  },
  conditionPanelsButton: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: '10px'
  },
  panelOpen: {
    padding: theme.spacing(2),
  },
  letterTitle: {
    marginRight: '10px',
  },
  measureBoxPercentage: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  panelBox: {
    maxWidth: '500px',
    margin: '10px 0px',
  },
  spacedContent: {
    '& > *': {
      marginTop: theme.spacing(1)
    },
  },
  selectorGrid: {
    paddingTop: '0px',
  }
}));

const ConditionPanels = props => {
  const { actions, alertsDetails } = props;
  const getString = useString();
  const { conditions } = alertsDetails;
 
  const columns = [alertsDetails.dimension, ...alertsDetails.measures]
    .filter(e => e)
    .map((each, index) => ({ index, ...each }));

  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const numberToLetter = index =>
    (10 + parseInt(index)).toString(20).toUpperCase();

  const handleSelect = (i, field) => value => {
    switch (field) {
      case "index":
      case "compare":
        actions.addValueToCondition(i, field, value ? value.index : "");
        break;
      case "operator":
      case "type":
      case "aggr":
        actions.addValueToCondition(i, field, value ? value.value || "" : "");
        break;
      case "limit":
        actions.addValueToCondition(i, field, value || []);
        break;
      case "offsetIsPercent":
        actions.addValueToCondition(i, field, value.target.checked);
        break;
      case "offset":
      case "factor":
      case "value":
        actions.addValueToCondition(i, field, value.target.value);
        break;
      default:
        break;
    }
  };

  const conditionTypeOptions = index =>
    alertsDetails.selectors.conditionType.options.filter(each => {
      switch (each.value) {
        case "measure":
          return columns.length > 1;
        case "set":
          return (
            Boolean(alertsDetails.dimension) &&
            Boolean(alertsDetails.conditions[index].index)
          );
        case "history":
          return !(
            !alertsDetails.conditions[index].index && alertsDetails.dimension
          );
        default:
          return true;
      }
    });

  const operatorOptions = index => {
    const array = [...alertsDetails.selectors.operator.options];
    if (!alertsDetails.conditions[index].index && alertsDetails.dimension)
      return array.filter(each => each.text);
    else return array;
  };

  const getTitle = condition => {
    try {
      let result = `${columns[condition.index].label ||
        columns[condition.index].def} ${
        getString(alertsDetails.operators.find(each => each.value === condition.operator)
          .name)
        } `;
      switch (condition.type) {
        case "history":
          if (condition.limit > 1)
            result += `last ${condition.limit} scans (${condition.aggr})`;
          else result += `last scanned value`;
          break;
        case "set":
          if (condition.aggr === "std")
            result += `standard deviation (${(condition.factor > 0 ? "+" : "") +
              condition.factor})`;
          else result += ` ${condition.factor || ""} ${condition.aggr} `;
          break;
        case "measure":
          result += columns[condition.compare]
            ? columns[condition.compare].label || columns[condition.compare].def
            : " ... ";
          break;
        case "manual":
          result += ` ${condition.value || 0}`;
          break;
        default:
          break;
      }
      if (condition.type !== "manual" && condition.offset)
        result += ` ${condition.offset > 0 ? "+" : ""}${condition.offset ||
          ""}${condition.offsetIsPercent ? " %" : ""}`;
      return result;
    } catch (e) {
      return "...";
    }
  };

  const offset = index => (
    <div tid="div_offset">
      <TextField
        tid="offset_text_field"
        fullWidth={true}
        id={index + "offset"}
        label={"Condition.Label.Offset"}
        type="number"
        value={conditions[index].offset || ""}
        onChange={handleSelect(index, "offset")}
        className={classes.selectInput}
      />
      <div tid="div_is_percent" className={classes.measureBoxPercentage}>
        <Checkbox
          tid={index + "checkbox_is_percent"}
          id={index + "offsetIsPercent"}
          checked={conditions[index].offsetIsPercent || false}
          onChange={handleSelect(index, "offsetIsPercent")}
          style={{paddingLeft: 0}}
        />
        <Typography tid="typography_is_percent" variant="body2">{getString("Condition.Label.IsPercent")}</Typography>
      </div>
    </div>
  );

  const getAggregationFields = (condition, index) => {
    switch (condition.aggr) {
      case "avg":
      case "min":
      case "max":
        return offset(index);
      case "percentile":
        return (
          <TextField
            className={classes.selectInput}
            tid="text_field_percentile"
            id={`${index}.factor`}
            label={("Condition.Label.Percentile")}
            type="number"
            value={conditions[index].factor || ""}
            onChange={handleSelect(index, "factor")}
          />
        );
      case "quartile":
        return (
          <TextField
            className={classes.selectInput}
            tid="text_field_quartile"
            id={`${index}.factor`}
            label={("Condition.Label.Quartile")}
            type="number"
            value={conditions[index].factor || ""}
            onChange={handleSelect(index, "factor")}
          />
        );
      case "std":
        return (
          <TextField
            className={classes.selectInput}
            tid="text_field_deviation"
            id={`${index}.factor`}
            label={("Condition.Label.StandardDeviation")}
            type="number"
            value={conditions[index].factor || ""}
            onChange={handleSelect(index, "factor")}
          />
        );
      default:
        return "";
    }
  };

  const getTypeFields = (condition, index) => {
    switch (condition.type) {
      case "manual":
        return (
          <TextField
            className={classes.selectInput}
            tid="text_field_value"
            name={`${index}.value`}
            id={`${index}.value`}
            label={("Condition.Label.Value")}
            value={conditions[index].value || ""}
            onChange={handleSelect(index, "value")}
          />
        );
      case "measure":
        return (
          <>
            <Selector
              tid="selector_compare"
              className={classes.selectInput}
              {...alertsDetails.selectors.column}
              id={`${index}compare`}
              label={("Condition.Label.CompareWith")}
              value={conditions[index].compare}
              onSelect={handleSelect(index, "compare")}
              options={columns}
              getOptionDisabled={option => option.index === condition.index}
            />
            {offset(index)}
          </>
        );
      case "history":
        return (
          <>
            <Selector
              tid="selector_history_limit"
              className={classes.selectInput}
              {...alertsDetails.selectors.scans}
              id={`${index}limit`}
              value={conditions[index].limit}
              onSelect={handleSelect(index, "limit")}
            />
            {condition.limit > 1 ? (
              <Selector
                className={classes.selectInput}
                tid="selector_history_aggr"
                {...alertsDetails.selectors.aggregation}
                id={`${index}aggr`}
                value={conditions[index].aggr}
                onSelect={handleSelect(index, "aggr")}
                options={[
                  { name: "Condition.Label.Average", value: "avg" },
                  { name: "Condition.Label.Min", value: "min" },
                  { name: "Condition.Label.Max", value: "max" },
                ]}
              />
            ) : null}
            {offset(index)}
          </>
        );
      case "set":
        return (
          <>
            <Selector
              tid="selector_set_aggr"
              className={classes.selectInput}
              {...alertsDetails.selectors.aggregation}
              id={`${index}aggr`}
              value={conditions[index].aggr}
              onSelect={handleSelect(index, "aggr")}
              options={[
                { name: "Condition.Label.Average", value: "avg" },
                { name: "Condition.Label.Min", value: "min" },
                { name: "Condition.Label.Max", value: "max" },
                { name: "Condition.Label.Percentile", value: "percentile" },
                { name: "Condition.Label.Quartile", value: "quartile" },
                { name: "Condition.Label.StandardDeviation", value: "std" },
              ]}
            />
            {getAggregationFields(condition, index)}
          </>
        );
      default:
        return "";
    }
  };

  return (
    <FormControl tid="form_control_parent" variant="outlined" style={{ display: 'block'}} fullWidth >
      {conditions.map((condition, index) => (
        <div tid={`div_parent_${index}`} key={index} className={classes.panelBox}>
          <ExpansionPanel
            tid={`expansion_panel_${index}`}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
          >
            <ExpansionPanelSummary
              tid={`expansion_panel_summary_${index}`}
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div style={{flexDirection: 'column'}}>
                <Typography tid={`typography_index_${index}`} variant="h5" className={classes.letterTitle}>
                  {getString("ConditionsPanels.Condition", [numberToLetter(index)])}
                </Typography>
                <Typography tid={`typography_title_${index}`} variant="body2">
                  {getTitle(condition)}
                </Typography>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails tid={`expansion_panel_details_${index}`} className={`${classes.selectorGrid} ${classes.panelOpen}`}>
              <Grid container tid={`grid_selectors_${index}`} spacing={4} direction="row" justify="space-around" alignItems="stretch">
                <Grid item className={`${classes.selectorGrid} ${classes.spacedContent}`} tid={`grid_column_operator_${index}`} xs>
                  <Selector
                    className={classes.selectInput}
                    tid={`selector_index_${index}`}
                    {...alertsDetails.selectors.column}
                    id={`${index}index`}
                    value={conditions[index].index}
                    onSelect={handleSelect(index, "index")}
                    options={columns}
                  />
                  <Selector
                    className={classes.selectInput}
                    tid={`selector_operator_${index}`}
                    {...alertsDetails.selectors.operator}
                    options={operatorOptions(index)}
                    id={`${index}operator`}
                    value={conditions[index].operator}
                    onSelect={handleSelect(index, "operator")}
                  />
                </Grid>
                <Grid item className={`${classes.selectorGrid} ${classes.spacedContent}`} tid={`grid_type_${index}`} xs>
                  <Selector
                    className={classes.selectInput}
                    tid={`selector_type_${index}`}
                    {...alertsDetails.selectors.conditionType}
                    options={conditionTypeOptions(index)}
                    id={`${index}type`}
                    value={conditions[index].type}
                    onSelect={handleSelect(index, "type")}
                  />
                  {getTypeFields(condition, index)}
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
            <Button tid={`button_delete_${index}`} className={classes.deleteButton} variant="outlined" onClick={() => actions.deleteCondition(index)}>
              {getString("General.Delete")}
            </Button>
          </ExpansionPanel>
        </div>
      ))}
      <Grid tid="grid_create_condition" className={classes.conditionPanelsButton} container>
        <Button
          tid="button_create_condition"
          variant="outlined"
          onClick={actions.addCondition}
          disabled={!columns.length || conditions.length >= 10}
        >
          {getString("Condition.Label.CreateNew")}
        </Button>
      </Grid>
    </FormControl>
  );
};

export default ConditionPanels;
