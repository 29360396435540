import ActionTypes from "../../constants/actionTypes";
import * as definitions from "./definitions";
import { shouldCleanup } from '../../utils/navigationCleanup';

const initialState = {
  ...definitions,
  history: [],
};

export default function inboxSystemReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_HISTORY_SYSTEM_DETAIL:
      return {
        ...state,
        ...action.payload
      };
    case ActionTypes.LOCATION_CHANGE:
        return shouldCleanup(action.payload) ? {...initialState} : state;
    default:
      return state;
  }
}
