import paths from "../../constants/paths";

export const crumbs = [
  {label: "Breadcrumbs.Admin"},
  {label: "Breadcrumbs.Management"},
  {label: "Breadcrumbs.Privileges", link: paths.usersPrivileges},
  {label: "Breadcrumbs.Details"}
]

export const table = {
  options: {
    header: true,
    select: true,
    onSelect: "setSelected"
  },
  style: {
    header: {
      fontWeight: "bold"
    }
  },
  fields: [
    {
      label: "Assigner.Label.FullName",
      type: "",
      id: "name",
      style: { flex: 2 },
      getValue: row => row.name,
      onClick: null
    }
  ]
};

export const bottomBar = {
  options: {},
  buttons: {
    right: [
      {
        key: "cancel",
        label: "General.Cancel",
        onClick: 'handleCancel',
        display: () => true
      },
      {
        key: "save",
        label: "General.Save",
        onClick: "handleSave",
        color: "primary",
        variant: "contained",
        disabled: "isSaveDisabled",
        display: () => true
      },
    ]
  }
};

export const modal = {
  options: {
    title: "General.Cancel"
  },
  content: "UsersPrivilegesDetails.Modal.CancelContent",
  buttons: [
    {
      key: "notLeave",
      label: "General.Cancel",
      onClick: null,
      variant: 'outlined',
    },
    {
      key: "leave",
      label: "General.Confirm",
      onClick: 'cancelEditPrivileges',
      color: "primary"
    }
  ]
};

export const modalAssignAll = {
  options: {
    title: "UsersPrivilegesDetails.Modal.AssignAll.Title"
  },
  content: "UsersPrivilegesDetails.Modal.AssignAll.Content",
  buttons: [
    {
      key: "notLeave",
      label: "General.Cancel",
      onClick: null,
      variant: 'outlined',
    },
    {
      key: "leave",
      label: "General.Confirm",
      onClick: 'fullAssignation',
      color: "primary"
    }
  ]
};

export const modalUnassignAll = {
  options: {
    title: "UsersPrivilegesDetails.Modal.UnAssignAll.Title"
  },
  content: "UsersPrivilegesDetails.Modal.UnAssignAll.Content",
  buttons: [
    {
      key: "notLeave",
      label: "General.Cancel",
      onClick: null,
      variant: 'outlined',
    },
    {
      key: "leave",
      label: "General.Confirm",
      onClick: 'fullAssignation',
      color: "primary"
    }
  ]
};
