import React, { useEffect, useRef } from "react";
import { bindActionCreators } from "redux";
import { useSelector, useDispatch } from "react-redux";
import * as UserPrivilegesActions from "./actions";
import { Container, Grid } from "@material-ui/core";
import Table from "../../components/Table";
import BreadcrumbsHeader from "../../components/BreadcrumbsHeader";
import { makeStyles } from "@material-ui/core/styles";
import TableHeader from "../../components/TableHeader";
import { push } from "connected-react-router";
import paths from "../../constants/paths";

const useStyles = makeStyles((theme) => ({
  container: {
    overflowY: "hidden",
    flex: "1 1 auto",
  },
  tableWrapper: {
    overflowX: "hidden",
    overflowY: "auto",
  },
  contentArea: {
    marginTop: theme.spacing(4),
    maxHeight: "100%",
    flexWrap: "nowrap",
  },
  searchActionBar: {
    flex: "1 1 auto",
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const UserPrivileges = () => {
  const classes = useStyles();
  const usersPrivileges = useSelector(
    ({ usersPrivilegesReducer }) => usersPrivilegesReducer
  );
  const login = useSelector(({ loginReducer }) => loginReducer);
  const dispatch = useDispatch();
  const { current: actions } = useRef(
    bindActionCreators({ ...UserPrivilegesActions }, dispatch)
  );

  useEffect(() => {
    if(login.isAdmin) actions.getPermissions()
    else dispatch(push(paths.alerts))
  }, []);

  const sortField = (fieldName, order) => {
    actions.getPermissions({
      sort: fieldName,
      reverse: order === "asc",
    });
  };

  return (
    <Container className={classes.container}>
      <Grid container className={classes.contentArea} direction="column">
        <Grid container>
          <BreadcrumbsHeader crumbs={usersPrivileges.crumbs} />
        </Grid>
      </Grid>
      <TableHeader />
      <Grid item className={classes.tableWrapper}>
        <Table
          actions={{ ...actions, sortField }}
          data={Object.values(usersPrivileges.data)}
          {...usersPrivileges.table}
        />
      </Grid>
    </Container>
  );
};

export default UserPrivileges;
