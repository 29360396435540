import React from 'react'
import { useSelector } from "react-redux";
import Form from "../../Form";
import { Grid } from "@material-ui/core";
import { useStyles } from '../../../constants/styles'
import Uploader from "../../Uploader";

const QlikStep = props => {
    const { actions, register, handleSelect } = props;
    const sourcesDetails = useSelector(({ sourcesDetailsReducer }) => sourcesDetailsReducer);

    const classes = useStyles()
    return (
        <Grid container tid="grid_parent" className={classes.formAndUpload}>
        <Grid item tid="grid_form" xs={12} md={6}>
          <Form {...sourcesDetails.form} 
              tid="form"
              reducerState={register}
              handleSelect={handleSelect}
           />
        </Grid>
        <Grid item tid="grid_uploader" xs={12} md={6} className={classes.fileUpload}>
          <Uploader
            tid="uploader"
            actions={actions}
            {...register.uploaderCerts}
            inserted={register.insertedCert}
          />
        </Grid>
      </Grid>
    )
}

export default QlikStep