import actionTypes from "../../constants/actionTypes";
import * as definitions from "./definitions";
import { shouldCleanup } from '../../utils/navigationCleanup';

const initialState = {
  ...definitions,

  activeStep: 0,

  hostname: "localhost",
  scanInterval: 1,
  httpPort: 4551,
  httpsPort: 443,
  sessionLimit: 30,
  workerSlaves: 10,

  channelName: "Email",
  connectionHost: "smtp.gmail.com",
  connectionPort: 587,
  connectionAuthUser: "",
  connectionAuthPass: "",
  connectionAuthSecurity: "TLS",
  authenticationMethod: "basic",
  connectionFrom: "",
  connectionCC: "",
  connectionBCC: "",

  sourceName: "Qlik",
  type: "qlik",
  qpsPort: "4243",
  qrsPort: "4242",
  userDirectory: "",
  userId: "",
  filter: "",
  virtualProxy: "",
  sessionCookieName: "",
  url: "localhost",
  proxyUrl: '',
  insertedCert: {},
  tested: false
};

export default function registerReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.REGISTER_INITIAL_STEP:
      return {
        ...state,
        activeStep: action.payload,
        httpsPort: action.payload === 1 ? 4552 : 443
      }
    case actionTypes.REGISTER_CONFIG:
      return {
        ...state
      };
    case actionTypes.REGISTER_CHANNEL:
      return {
        ...state
      };
    case actionTypes.REGISTER_SOURCE_TEST:
      return {
        ...state,
        tested: true
      };
    case actionTypes.REGISTER_SOURCE:
      return {
        ...state,
        insertedCert: {},
        hostname: "localhost",
        httpsPort: 4552
      };
    case actionTypes.REGISTER_UPLOAD_SOURCE_CERTS:
      return {
        ...state,
        insertedCert: {
          ...state.insertedCert,
          [action.payload.key]: action.payload.content
        }
      };
    case actionTypes.FIND_REGISTRATION_USER:
      return {
        ...state,
        findUser: action.payload
      };
    case actionTypes.REGISTER_ADMIN:
      return {
        ...state
      };
    case actionTypes.ADD_VALUE_TO_REGISTER:
      return {
        ...state,
        [action.payload.field]: action.payload.value
      };
    case actionTypes.REGISTER_COMPLETED_STEP:
      return {
        ...state,
        activeStep: action.payload + 1
      }
    case actionTypes.REGISTER_BACKWARD_STEP_SOURCE:
      return {
        ...state,
        tested: false
      }
    case actionTypes.REGISTER_BACKWARD_STEP:
      return {
        ...state,
        activeStep: action.payload - 1
      }
    case actionTypes.REGISTER_CLEAR:
      return {
        ...initialState
      }
    case actionTypes.LOCATION_CHANGE:
        return shouldCleanup(action.payload) ? {...initialState} : state;
    default:
      return state;
  }
}
