import { createHashHistory } from "history";
import { applyMiddleware, createStore, combineReducers } from "redux";
import { routerMiddleware, connectRouter } from "connected-react-router";
import ActionTypes from "./constants/actionTypes";
import reducers from "./constants/reducers";
import thunk from "redux-thunk";
import paths from "./constants/paths";
import { push } from "connected-react-router";

export const history = createHashHistory();
let store = null;

const appReducer = combineReducers({
  router: connectRouter(history),
  ...reducers
});

const preReducer = (state, action) => {
  if (action.type === ActionTypes.LOGOUT) {
    try {
      return {
        ...appReducer(undefined, action),
        translationsReducer: state.translationsReducer,
        router: {
          ...state.router,
          location: {
            ...state.router.location,
            pathname: paths.login
          }
        }
      }
    } finally {
      setTimeout(() => store.dispatch(push(paths.login)), 0);
    }

  }
  
  return appReducer(state, action);
};

export default function configureStore(preloadedState) {
  store = createStore(
    preReducer,
    preloadedState,
    applyMiddleware(routerMiddleware(history), thunk)
  );

  return store;
}
