export const thirdPartySoftware = [
 { name: "Axios", url: "https://github.com/axios/axios" },
 { name: "Bcrypt", url: "https://www.npmjs.com/package/bcrypt" },
 { name: "Body-parser", url: "https://github.com/expressjs/body-parser" },
 { name: "Cookie-parser", url: "https://github.com/expressjs/cookie-parser" },
 { name: "Core-js", url: "https://www.npmjs.com/package/core-js" },
 { name: "Cors", url: "https://github.com/expressjs/cors" },
 { name: "Crypto-js", url: "https://github.com/brix/crypto-js" },
 { name: "Enigma", url: "https://www.npmjs.com/package/enigma-js" },
 { name: "Expo", url: "https://expo.io/" },
 { name: "Expo Bar Code Scanner", url: "https://docs.expo.io/versions/latest/sdk/bar-code-scanner/" },
 { name: "Expo Constants", url: "https://docs.expo.io/versions/latest/sdk/constants/" },
 { name: "Expo-server-sdk", url: "https://github.com/expo/expo-server-sdk-node" },
 { name: "Express", url: "https://github.com/expressjs/express" },
 { name: "Express Rate Limit", url: "https://www.npmjs.com/package/express-rate-limit" },
 { name: "Firebase", url: "https://github.com/firebase" },
 { name: "Helmet JS", url: "https://helmetjs.github.io/" },
 { name: "History", url: "https://www.npmjs.com/package/react-history" },
 { name: "Lodash", url: "https://github.com/lodash/lodash" },
 { name: "Material-UI/core", url: "https://material-ui.com/getting-started/installation/" },
 { name: "Material-UI/icons", url: "https://material-ui.com/getting-started/installation/" },
 { name: "Material-UI/lab", url: "https://www.npmjs.com/package/@material-ui/lab" },
 { name: "Material-UI/styles", url: "https://www.npmjs.com/package/@material-ui/styles" }, 
 { name: "MongoDB", url: "https://docs.mongodb.com/manual/installation/" },
 { name: "Mustache", url: "https://github.com/mustache/mustache" },
 { name: "Native Base", url: "https://nativebase.io/" },
 { name: "Nodemailer", url: "https://github.com/nodemailer/nodemailer" },
 { name: "QR Code React", url: "https://github.com/zpao/qrcode.react" },
 { name: "React", url: "https://reactjs.org/docs/create-a-new-react-app.html" },
 { name: "React DOM", url: "https://github.com/facebook/react/tree/master/packages/react-dom" },
 { name: "React Native", url: "https://github.com/facebook/react-native" },
 { name: "React Native Community Viewpager", url: "https://www.npmjs.com/package/@react-native-community/viewpager" },
 { name: "React Native Gesture Handler", url: "https://www.npmjs.com/package/react-native-gesture-handler" },
 { name: "React Native Modal", url: "https://www.npmjs.com/package/react-native-modal" },
 { name: "React Native Paper", url: "https://reactnativepaper.com/" },
 { name: "React Native PDF Reader", url: "https://www.npmjs.com/package/rn-pdf-reader-js" },
 { name: "React Native Plotly", url: "https://www.npmjs.com/package/react-native-plotly" },
 { name: "React Native Vector Icons", url: "https://www.npmjs.com/package/react-native-vector-icons" },
 { name: "React Native Web", url: "https://www.npmjs.com/package/react-native-web" },
 { name: "React Native Webview", url: "https://www.npmjs.com/package/react-native-webview" },
 { name: "React Navigation", url: "https://reactnavigation.org/docs/4.x/getting-started/" },
 { name: "React Navigation Web", url: "https://www.npmjs.com/package/@react-navigation/web" },
 { name: "React-Redux", url: "https://redux.js.org/" },
 { name: "React Router DOM", url: "https://www.npmjs.com/package/react-router-dom" },
 { name: "Redis", url: "https://redis.io/" },
 { name: "Redux", url: "https://redux.js.org/" },
 { name: "Redux Persist", url: "https://www.npmjs.com/package/redux-persist" },
 { name: "Redux Thunk", url: "https://github.com/reduxjs/redux-thunk" },
 { name: "Request", url: "https://github.com/jamesplease/react-request" },
 { name: "UUID", url: "https://www.npmjs.com/package/uuid" },
 { name: "WS", url: "https://www.npmjs.com/package/ws" },
];
