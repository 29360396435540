export const dateISO = (input, addTime, timeSeparator = ' ', timezoneOffset) => {
    try {
        if(!input) return ''
        const dateInput = new Date(input)
        const offset = Number.isInteger(timezoneOffset) ? timezoneOffset : dateInput.getTimezoneOffset()
        const timestamp = dateInput.getTime() - (offset * 60000)
        const value = new Date(timestamp).toISOString().slice(0, -5).split('T')
        if(addTime) return value.join(timeSeparator)
        else return value[0] 
    } catch(e) {
        return ''
    }
}
