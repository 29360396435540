import ActionTypes from "../../constants/actionTypes";
import API from "../../lib/api";
import { toUsersDetails, fromForm } from "./dataHandler";
import { push } from 'connected-react-router';
import paths from '../../constants/paths';


export const getUserDetails = id => {
  return async dispatch => {
    try {
      const result = await API.getOneUser(id);
      const data = await toUsersDetails(result);
      dispatch({
        type: ActionTypes.GET_USER_DETAILS,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: { snackMessage: typeof error === "string" ? error : JSON.stringify(error) }
      });
    }
  };
};

export const editUsersDetails = (id, input) => {
  return async dispatch => {
    try {
      dispatch({
        type: ActionTypes.SAVE_BUTTON_DISABLED,
        payload: { saveIsDisabled: true },
      })
      const data = fromForm(input);
      const result = await API.updateUser(id, data)
      dispatch({
        type: ActionTypes.EDIT_USERS_DETAILS,
        payload: {
          data,
          result
        }
      })
      dispatch({
        type: ActionTypes.SNACK_SUCCESS,
        payload: { snackMessage: "Snackbar.UsersDetails.editUsersDetails" }
      });
      dispatch(push(paths.users))
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: { snackMessage: typeof error === "string" ? error : JSON.stringify(error) }
      });
    } finally {
      dispatch({
        type: ActionTypes.SAVE_BUTTON_DISABLED,
        payload: { saveIsDisabled: false },
      })
    }
  };
};

export const addValueToUsersDetails = (field, value) => {
  return dispatch => {
    dispatch({
      type: ActionTypes.ADD_VALUE_TO_USERS_DETAILS,
      payload: { field, value }
    });
  };
};

export const cancelEditUser = () => {
  return dispatch => {
    dispatch({
      type: ActionTypes.CANCEL_EDIT_USER
    })
    dispatch(push(paths.users));
  }
};
