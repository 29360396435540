import ActionTypes from "../../constants/actionTypes";
import { shouldCleanup } from "../../utils/navigationCleanup";

const initialState = {
  showSnackBar: false,
  snackMessage: "",
  snackType: "success",
  loading: false,
  replacementArray: [],
};

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SNACK_ERROR:
      return {
        ...state,
        snackType: "error",
        showSnackBar: true,
        snackMessage: action.payload.snackMessage,
        replacementArray: action.payload.replacementArray,
      };
    case ActionTypes.SNACK_WARNING:
      return {
        ...state,
        snackType: "warning",
        showSnackBar: true,
        snackMessage: action.payload.snackMessage,
        replacementArray: action.payload.replacementArray,
      };
    case ActionTypes.SNACK_SUCCESS:
      return {
        ...state,
        snackType: "success",
        showSnackBar: true,
        snackMessage: action.payload.snackMessage,
        replacementArray: action.payload.replacementArray,
      };
    case ActionTypes.CLOSE_SNACK_BAR:
      return {
        ...state,
        showSnackBar: false,
        snackMessage: "",
      };
    case ActionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
}
