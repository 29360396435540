export const toChannels = (input) => {
    if(!Array.isArray(input)) input = [input]
    return input.map(each => {
        return {
            id: each._id,
            name: each.name,
            type: each.type,
            address: each.connection.host,
            portNo: each.connection.port,
            securityLayer: each.connection.auth.security,
            email: each.connection.auth.user,
            trigger: each.isActive
        }
    })
};
