const paths = {
    app: '/',
    alerts: '/',
    alertsDetails: '/alerts/:type/:id?',
    getAlertsDetails: (type, id) => `/alerts/${type || 'data'}/${id || ''}`,
    changePassword: '/change-password/:token?',
    getChangePassword: (token) => `/change-password/${token || ''}`,
    channels: '/channels',
    channelsDetails: '/channels/details/:id?',
    getChannelsDetails: (id) => `/channels/details/${id || ''}`,
    config: '/config',
    devices: '/devices',
    inbox: '/inbox',
    inboxSystem: '/inbox/system/:alertId/:taskId',
    inboxData: '/inbox/data/:alertId',
    getInboxData: (link) => `/inbox/data/${link}`,
    getInboxSystem: (link) => `/inbox/system/${link}`,
    login: '/login',
    register: '/register',
    pageNotFound : '/pagenotfound',
    sources: '/sources',
    sourcesDetails: '/sources/details/:id?',
    getSourcesDetails: (id) => `/sources/details/${id || ''}`,
    suggestions: '/suggestions',
    unsubscribe: '/unsubscribe',
    users: '/users',
    loginQXSession: '/loginQXSession',
    usersDetails: '/users/details/:id?',
    getUsersDetails: (id) => `/users/details/${id || ''}`,
    usersGroups: '/user-groups',
    usersGroupsDetails: '/user-groups/details/:id?',
    getUsersGroupsDetails: (id) => `/user-groups/details/${id || ''}`,
    usersPrivileges: '/user-privileges',  
    usersPrivilegesDetails: '/user-privileges/details/:id',
    getUsersPrivilegesDetails: (id) => `/user-privileges/details/${id || ''}`
}

module.exports = paths;