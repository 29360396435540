import React from 'react';
import { makeStyles, Breadcrumbs, Typography } from '@material-ui/core';
import { NavLink } from "react-router-dom";
import useString from "../../i18n/hooks/useString";


const useStyles = makeStyles(theme => ({
  crumbs: {
    margin: theme.spacing(),
  },
}))

const MyBreadCrumbs = ({ crumbs }) => {
  const getString = useString();
  const classes = useStyles();

  return (
    <Breadcrumbs tid="breadcrumbs" className={classes.crumbs}>
      {crumbs.map((crumb, i) => (
        crumb.link ?
          <NavLink tid={`navlink_${i}`} key={i} to={crumb.link}><Typography tid={`typography_ternary_if_${i}`} >{getString(crumb.label)}</Typography></NavLink>
          : <Typography tid={`typography_ternary_else_${i}`} key={i} >{getString(crumb.label)}</Typography>
      ))}
    </Breadcrumbs>
  )
}

export default MyBreadCrumbs;
