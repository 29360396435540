import ActionTypes from "../../constants/actionTypes";
import API from "../../lib/api";
import { push } from "connected-react-router";
import paths from "../../constants/paths";

export const registrationStatus = () => {
  return async dispatch => {
    try {
      dispatch({
        type: ActionTypes.REGISTER_STATUS_LOADING,
        payload: true
      })
      const isRegistered = await API.getRegistrationStatus()
      if (!isRegistered.success) dispatch(push(paths.register));
      dispatch({
        type: ActionTypes.REGISTER_REQUIRED,
        payload: isRegistered
      })
      dispatch({
        type: ActionTypes.REGISTER_STATUS_LOADING,
        payload: false
      })
    } catch (error) {
      console.log(error)
    }
  }
}

export const onLogin = values => {
  return async dispatch => {
    try {
      if (!values.userName || !values.password) throw 'User or password missing'
      let loginBody = {
        user: values.userName,
        password: values.password
      };
      const logged = await API.login(loginBody);
      if (logged.token) {
        dispatch({
          type: ActionTypes.IS_LOGGED,
          payload: logged
        });
        localStorage.setItem('userId', logged.userId)
        localStorage.setItem('token', logged.token)
        delete sessionStorage.session
        dispatch(push(paths.alerts));
      }
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: { snackMessage: typeof error === "string" ? error : JSON.stringify(error) }
      });
    }
  };
};

export const sendForgotPassword = values => {
  return async dispatch => {
    try {
      let forgotBody = { user: values.userName };
      await API.forgotPassword(forgotBody);
      dispatch({
        type: ActionTypes.SNACK_SUCCESS,
        payload: {
          snackMessage: "Snackbar.Login.sentEmailToGeneratePassword"
        }
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: { snackMessage: typeof error === "string" ? error : JSON.stringify(error) }
      });
    }
  };
};

export const isLogged = () => {
  return async dispatch => {
    try {
      const logged = await API.isLogged();
      const isRegistered = await API.getRegistrationStatus()
      if (!logged.userId || !logged.token) throw 'not logged'
      localStorage.setItem('userId', logged.userId)
      localStorage.setItem('token', logged.token)
      const base64Payload = logged.token.split('.')[1]
      const jwtPayload = Buffer.from(base64Payload, 'base64').toString()
      const userInfo = JSON.parse(jwtPayload)
      dispatch({
        type: ActionTypes.IS_LOGGED,
        payload: { ...logged, ...isRegistered, ...userInfo }
      });
    } catch (e) {
      localStorage.clear();
      dispatch({
        type: ActionTypes.NAVIGATION_UNBLOCK
      });
      dispatch({
        type: ActionTypes.LOGOUT
      });
    }
  };
};

export const logout = () => {
  return async dispatch => {
    await API.logout();
    localStorage.clear();
    sessionStorage.session = 'FORCE_LOGOUT'
    dispatch({
      type: ActionTypes.LOGOUT
    })
  }
};

export const addValueToLogin = (field, value) => ({
  type: ActionTypes.ADD_VALUE_TO_LOGIN,
  payload: { field, value }
});
