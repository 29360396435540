import ActionTypes from "../../constants/actionTypes";
import * as definitions from "./definitions";
import { shouldCleanup } from '../../utils/navigationCleanup';

const initialState = {
  ...definitions,
  data: {},
  dataIds: [],
  system: {},
  systemIds: [],
  tab: "data",
  showMoreHidden: false
};

export default function inboxReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_INBOX:
      return {
        ...state,
        [action.payload.tab]: {
          ...state[action.payload.tab],
          ...action.payload.data.reduce((prev, cur) => {
            prev[cur.link || cur.id] = cur;
            return prev;
          }, {})
        },
        [action.payload.tab + "Ids"]: [
          ...new Set([
            ...state[action.payload.tab + "Ids"],
            ...action.payload.data.map(each => each.link || each.id)
          ])
        ]
      }
    case ActionTypes.RESTART_INBOX:
      return {
        ...state,
        [action.payload.tab]: action.payload.data.reduce((prev, cur) => {
          prev[ cur.link || cur.id] = cur;
          return prev;
        }, {}),
        [action.payload.tab + "Ids"]: action.payload.data.map(each => each.link || each.id)
      };
    case ActionTypes.SWITCH_INBOX_TABS:
      return {
        ...state,
        tab: action.payload,
        selectedIds: [],
        showMoreHidden: false
      };
    case ActionTypes.SHOW_MORE_INBOX:
      return {
        ...state,
        showMoreHidden: action.payload
      }
    case ActionTypes.LOCATION_CHANGE:
      return shouldCleanup(action.payload) 
        ? { ...initialState, tab: state.tab } 
        : state;
    default:
      return state;
  }
}
