/* eslint-disable no-throw-literal */
import { dateISO } from "../../utils/dateISO";

export const prepareAlert = {
  data: input => {
    if(!input.name) throw "AlertsDetails.DataHandler.AlertName"
    if(!input.sheet) throw "AlertsDetails.DataHandler.Sheet"
    if(!input.conditionLogic.length) throw "AlertsDetails.DataHandler.ConditionRule"
    if(!input.conditions.length) throw "AlertsDetails.DataHandler.Condition"
    if(!input.channels.length) throw "AlertsDetails.DataHandler.Channel"
    return {
    name: input.name,
    description: input.description,
    onReload: !input.onSchedule,
    digest: input.digest || false,
    source: 'qlik',
    query: buildQuery(input),
    condition: prepareConditions(input).condition,
    impersonate:JSON.parse(input.managedAlert),
    recipients: input.recipients,
    groups: input.groups,
    channels: input.channels,
    schedule: buildSchedule(input),
    notification: {
      custom: input.notificationCustom,
      frequency: input.frequency,
      type: "data",
      message: {
        subject: input.subject,
        body: input.body
      }
    },
    unsubscribed: input.unsubscribed
  }},
  broadcast: input => {
    if(!input.name) throw "AlertsDetails.DataHandler.AlertName"
    if(!input.channels.length) throw "AlertsDetails.DataHandler.Channel"
    if(!input.subject) throw "AlertsDetails.DataHandler.NotificationSubject"
    if(!input.body) throw "AlertsDetails.DataHandler.NotificaitonBody"
    return {
    name: input.name,
    description: input.description,
    recipients: input.recipients,
    groups: input.groups,
    channels: input.channels,
    schedule: buildSchedule(input),
    onReload: !input.onSchedule,
    notification: {
      type: "broadcast",
      message: {
        subject: input.subject,
        body: input.body
      }
    },
    unsubscribed: input.unsubscribed
  }},
  system: input => {
    if(!input.name) throw "AlertsDetails.DataHandler.AlertName"
    if(!input.taskIds.length && !input.systemTaskSelection ) throw "AlertsDetails.DataHandler.Task"
    if(input.systemInProgress && input.systemInProgressFor < 30) throw "AlertsDetails.DataHandler.ProgressValue"
    if(!input.systemInProgress 
      && !input.systemAborted 
      && !input.systemFailed 
      && !input.systemSuccess 
      && !input.systemSystemAborted) throw "AlertsDetails.DataHandler.EventType"
    if(!input.channels.length) throw "AlertsDetails.DataHandler.Channel"
    return {
    name: input.name,
    description: input.description,
    digest: input.digest || false,
    system: {
      taskIds: input.systemTaskSelection ? [] : input.taskIds,
      success: input.systemSuccess,
      failed: input.systemFailed,
      aborted: input.systemAborted,
      systemAborted: input.systemSystemAborted,
      inProgress: input.systemInProgress,
      inProgressFor: input.systemInProgressFor * 1000,
      selectByRules: input.systemTaskSelection,
      taskDef: input.systemTaskSelection ? {
        operator: input.systemTaskDefOp,
        def: input.systemTaskDef
      } : null
    },
    recipients: input.recipients,
    groups: input.groups,
    channels: input.channels,
    notification: {
      frequency: input.frequency,
      type: "system"
    },
    unsubscribed: input.unsubscribed
  }}
};

export const filter = (app, defValue) => {
  return {
    table: false,
    app: app,
    dimensions: [
      {
        def: defValue
      }
    ]
  };
};

export const groupsHandler = value => {
  if (!Array.isArray(value)) value = [value];
  return value.map(each => {
    return {
      id: each._id,
      name: each.name,
      count: each.count
    };
  });
};

export const usersHandler = (input) => {
  if(!Array.isArray(input)) input = [input]
    return input.map(each => {
        return {
            id: each._id,
            name: each.name,
            firstName: each.firstName || '',
            lastName: each.lastName || '',
            domain: each.domain
        }
    })
};

export const previewHandler = (recipients, groups) => {
  return {
    groups: groups,
    users: recipients
  };
};

export const previewResponse = (input, unsubscribed) => {
  return input.map(each => {
    return {
      id: each._id,
      name: each.name,
      fullName: `${each.firstName}${each.lastName ? ' ' + each.lastName : ''}`,
      domain: each.domain,
      unsubscribed: unsubscribed.includes(each._id)
    };
  }).sort((a,b) => a.fullName.localeCompare(b.fullName))
};

const advancedDistribution = input => {
  if (input.managedAlert) return true;
  if (input.groups.length) return true;
  if (input.recipients.length > 1) return true;
  if (
    input.recipients.filter(each => each !== localStorage.getItem("userId"))
      .length
  )
    return true;
  return false;
};

export const transformAlert = {
  data: (input, timezoneOffset) => {
    const conditionLogic = Array.isArray(input.condition.logic)
      ? input.condition.logic
      : [input.condition.logic];
      const format = {
        "0.00a": 'Auto',
        "0,0": "1,000",
        "0,0.0": "1,000.0",
        "0,0.00": "1,000.12",
        "0%": "12%",
        "0.0%": "12.3%",
        "0.00%": "12.34%"
      }
    return {
      name: input.name,
      description: input.description || '',
      digest: input.digest,
      app: input.query.app,
      sheet: input.query.sheet,
      dimension: input.query.dimensions[0],
      measures: input.query.measures.map(each => ({
        ...each,
        format: (each.format ? format[each.format] : format[input.query.format]) || 'Auto'
      })),
      filters: input.query.filters || [],
      bookmark: input.query.bookmark || '',
      sortBy: input.query.sort ? input.query.sort.by : "",
      sortAscending: input.query.sort ? input.query.sort.ascending : false,
      conditionLogic: conditionLogic.map(each =>
        each.replace(/\d+/g, match =>
          (10 + parseInt(match)).toString(20).toUpperCase()
        )
      ),
      conditions: input.condition.list,
      managedAlert: input.impersonate,
      recipients: input.recipients,
      groups: input.groups,
      channels: input.channels.map(each => each !== 'mobile' ? "email" : "mobile"),
      frequency: input.notification.frequency,
      onReload: input.onReload,
      onSchedule: input.schedule?.isActive,
      intervalTime: input.schedule?.type === "time",
      minHour: input.schedule?.minHour ? input.schedule.minHour.join(":") || "" : "",
      maxHour: input.schedule?.maxHour ? input.schedule.maxHour.join(":") || "" : "",
      intervalHours:
        input.schedule?.interval
          ? Math.floor(input.schedule.interval / 1000 / 60 / 60)
          : 0,
      intervalMinutes:
        input.schedule?.interval
          ? Math.round(input.schedule.interval / 1000 / 60) % 60
          : 10,
      times:
        input.schedule?.time
          ? input.schedule.time.map(each => each.join(":"))
          : [],
      since: dateISO(input.schedule?.start, true, 'T', timezoneOffset),
      until:
        input.schedule?.end
          ? dateISO(input.schedule.end, true, 'T', timezoneOffset)
          : "",
      dayOfWeek: input.schedule ? input.schedule.dayOfWeek || [] : [],
      dayOfMonth: input.schedule ? input.schedule.dayOfMonth || [] : [],
      notificationCustom: input.notification.custom,
      subject: input.notification.message ? input.notification.message.subject : '',
      body: input.notification.message ? input.notification.message.body : '',
      unsubscribed: input.unsubscribed || [],
      advancedDistribution: advancedDistribution(input)
    };
  },
  broadcast: (input, timezoneOffset) => {
    return {
      name: input.name,
      description: input.description,
      recipients: input.recipients,
      groups: input.groups,
      channels: input.channels.map(each => each !== 'mobile' ? "email" : "mobile"),
      onSchedule: input.schedule && input.schedule.isActive,
      intervalTime: input.schedule && input.schedule.type === "time",
      minHour: input.schedule ? input.schedule.minHour.join(":") || "" : "",
      maxHour: input.schedule ? input.schedule.maxHour.join(":") || "" : "",
      intervalHours:
        input.schedule && input.schedule.interval
          ? Math.floor(input.schedule.interval / 1000 / 60 / 60)
          : 0,
      intervalMinutes:
        input.schedule && input.schedule.interval
          ? Math.round(input.schedule.interval / 1000 / 60) % 60
          : 10,
      times:
        input.schedule && input.schedule.time
          ? input.schedule.time.map(each => each.join(":"))
          : [],
      since: dateISO(input.schedule?.start, true, 'T', timezoneOffset),
      until:
        input.schedule?.end
          ? dateISO(input.schedule.end, true, 'T', timezoneOffset)
          : "",
      dayOfWeek: input.schedule ? input.schedule.dayOfWeek || [] : [],
      dayOfMonth: input.schedule ? input.schedule.dayOfMonth || [] : [],
      notificationCustom: input.notification.custom,
      subject: input.notification.message.subject,
      body: input.notification.message.body,
      unsubscribed: input.unsubscribed || [],
      advancedDistribution: advancedDistribution(input)
    };
  },
  system: input => {
    return {
      name: input.name,
      description: input.description,
      digest: input.digest,
      taskIds: input.system.taskIds,
      systemSuccess: input.system.success,
      systemFailed: input.system.failed,
      systemAborted: input.system.aborted,
      systemSystemAborted: input.system.systemAborted,
      systemInProgress: input.system.inProgress,
      systemInProgressFor: input.system.inProgressFor / 1000 || 180,
      systemTaskSelection: !!input.system.taskDef,
      systemTaskDef: input.system.taskDef ? input.system.taskDef.def : '',
      systemTaskDefOp: input.system.taskDef ? input.system.taskDef.operator : '$all',
      recipients: input.recipients,
      groups: input.groups,
      channels: input.channels.map(each => each !== 'mobile' ? "email" : "mobile"),
      frequency: input.notification.frequency,
      unsubscribed: input.unsubscribed || [],
      advancedDistribution: advancedDistribution(input)
    };
  }
};

export const buildQuery = input => {
  if(!input.app) throw "AlertsDetails.DataHandler.Application"
  if(!input.measures.length && !input.dimension) throw "AlertsDetails.DataHandler.Column"
  const format = {
    'Auto': "0.00a",
    "1,000": "0,0",
    "1,000.0": "0,0.0",
    "1,000.12": "0,0.00",
    "12%": "0%",
    "12.3%": "0.0%",
    "12.34%": "0.00%"
  }
  return {
    table: true,
    dimensions: input.dimension ? [input.dimension] : [],
    measures: input.measures.map(each => ({...each,
      format: format[each.format]})),
    filters: input.filters,
    bookmark: input.bookmark || null,
    app: input.app,
    sheet: input.sheet,
    sort: {
      by: input.dimension ? input.sortBy : 0,
      ascending: input.sortAscending
    }
  };
};

export const buildSchedule = input => {
  const localOffset = new Date().getTimezoneOffset()
  const offset = (input.timezoneOffset - localOffset) * 60000
  return {
    isActive: input.onSchedule || false,
    type: input.intervalTime ? "time" : "interval",
    start: input.since ? Date.parse(input.since) + offset : null,
    end: input.until ? Date.parse(input.until) + offset : null,
    interval: input.intervalTime
      ? null
      : (input.intervalHours * 60 + input.intervalMinutes) * 60000,
    time: input.intervalTime ? input.times.map(each => each.split(":")) : [],
    minHour: input.minHour ? input.minHour.split(":") : null,
    maxHour: input.maxHour ? input.maxHour.split(":") : null,
    dayOfWeek: input.dayOfWeek,
    dayOfMonth: input.dayOfMonth
  };
};

const validateCondition = input => {
  const { index, type, operator, value, aggr, compare, factor, limit } = input
  if (!(parseInt(index) > -1) || !type || !operator ) throw "AlertsDetails.DataHandler.SomeConditions"
  switch (type){
    case 'manual':
      if (value === undefined) throw "AlertsDetails.DataHandler.SomeConditions"
      break;
    case 'measure':
      if (!(parseInt(compare) > -1)) throw "AlertsDetails.DataHandler.SomeConditions"
      break;
    case 'history':
      if (limit === undefined) input.limit = 1
      if (limit > 1 && !['avg', 'max', 'min'].includes(aggr)) throw "AlertsDetails.DataHandler.SomeConditions"
      break;
    case 'set':
      if (!aggr) throw "AlertsDetails.DataHandler.SomeConditions"
      if (['percentile', 'quartile'].includes(aggr) && !factor) throw "AlertsDetails.DataHandler.SomeConditions"
      if (aggr === 'std' && factor === undefined) input.factor = 1
      break;
    default:
      throw "AlertsDetails.DataHandler.SomeConditions"
  }
  return input
}

export const prepareConditions = input => {
  if(!input.conditionLogic.length) throw "AlertsDetails.DataHandler.ConditionRule"
  if(!input.conditions.length) throw "AlertsDetails.DataHandler.Condition"
  const list = input.conditions.map(validateCondition)
  return {
    data: input.resultData,
    condition: {
      logic: input.conditionLogic.map(each =>
        each.replace(/[A-J]+/g, match => parseInt(match, 20) - 10)
      ),
      list
    }
  };
};

export const cleanRegexLogic = input => input.replace(/(^\s*(and|or))|((and|or)\s*$)|((and|or)(?=\s*\)))|((and|or)(?=\s+(and|or)))|(!(?!\s*\d{1}))/gi, '')
  .replace(/(\(\s*(and|or))/gi, (matches) => matches.replace(/(and|or)/, ''))

export const prepareConditionsNewAlert = input => {
  if(!input.conditionLogic.length) throw "AlertsDetails.DataHandler.ConditionRule"
  if(!input.conditions.length) throw "AlertsDetails.DataHandler.Condition"
  const seemValid = input.conditions.every(each => parseInt(each.index) > -1 && each.type && each.operator)
  if(!seemValid) throw "AlertsDetails.DataHandler.SomeConditions"
  const historyIndexes = input.conditions.map((each, i) => each.type === 'history' ? i : null).filter(e => parseInt(e) > -1)
  const matchLogic = new RegExp(`(${historyIndexes.join('|')})`,'g')
  const logic = input.conditionLogic.map(each => {
    let eachLogic = each.replace(/[A-J]+/g, match => parseInt(match, 20) - 10)
    if(historyIndexes.length) {
       const value = eachLogic.replace(matchLogic, '')
       const clean = cleanRegexLogic(value)
       if (clean.match(/\w+/g)) return clean
       else return null
    }
    else return eachLogic
  }).filter(e => e)
  return {
    warning: !!historyIndexes.length,
    send: !!logic.length,
    body: {
      data: input.resultData,
      condition: {
        logic,
        list: input.conditions
      }
    }
  };
};

export const tasksHandler = input => {
  return input.map(each => ({
    id: each.taskId,
    name: each.taskName,
    type: each.reload ? "AlertsDetails.DataHandler.AppReload" :  "AlertsDetails.DataHandler.Other",
    last: each.success
      ? "AlertsDetails.DataHandler.Success"
      : each.failed
      ? "AlertsDetails.DataHandler.Failed"
      : each.aborted
      ? "AlertsDetails.DataHandler.Aborted"
      : each.systemAborted
      ? "AlertsDetails.DataHandler.SystemAborted"
      : each.inProgress
      ? "AlertsDetails.DataHandler.InProgress"
      : each.neverStarted
      ? "AlertsDetails.DataHandler.NeverStarted"
      : "AlertsDetails.DataHandler.Unknown",
    duration: Math.round(each.duration / 1000),
    applicationName: each.appName ? each.appName : '',
    lastReload: dateISO(each.stopTime || each.startTime, true)
  }));
};

export const handleObjects = input => {
  return {
    bookmarks: input.bookmarks.sort((a,b) => a.name.localeCompare(b.name)),
    dimensions: input.dimensions.sort((a,b) => a.label.localeCompare(b.label)),
    fields: input.fields.sort((a,b) => a.def.localeCompare(b.def)),
    measures: input.measures.sort((a,b) => a.label.localeCompare(b.label)),
    sheets: input.sheets.map(each => {
      each.status = each.approved ? "AlertsDetails.DataHandler.Public" : each.published ? "AlertsDetails.DataHandler.Shared" : "AlertsDetails.DataHandler.MySheets"
      each.statusIndex = each.approved ? 0 : each.published ? 1 : 2
      return each
    }).sort((a,b) => {
      if (a.statusIndex !== b.statusIndex) return a.statusIndex - b.statusIndex
      else return a.name.localeCompare(b.name)
    })
  }
}
