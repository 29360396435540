export const sortingAscending = (resultData, sortBy, sortAscending) => {
    if (resultData.some(row => row.some(cell => cell.qText))) return resultData
    return resultData.sort((a, b) => {
        const first = parseFloat(a[sortBy])
        const second = parseFloat(b[sortBy])
        if (a[sortBy] === '-' ) return 1
        if (b[sortBy] === '-' ) return -1
        if((first || first === 0 ) && (second || second === 0 )) {
            if (sortAscending) {
                return first - second
            } else {
                return second - first
            }
        } else {
            if (sortAscending) {
                return (a[sortBy] || '').localeCompare(b[sortBy]);
            } else {
                return (b[sortBy] || '').localeCompare(a[sortBy]);
            };
        };
    });
};