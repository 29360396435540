import React from "react";
import Uploader from "../../Uploader";
import {
  makeStyles,
  Grid,
  Switch,
  TextareaAutosize,
  Typography,
} from "@material-ui/core";
import useString from "../../../i18n/hooks/useString";
import TextField from "../../TextField";
import NavTitle from "../../NavTitle";
import Selector from "../../Selector";

const useStyles = makeStyles((theme) => ({
  inputWrapper: {
    maxWidth: '500px',
    margin: theme.spacing(1, 0),
    '& > *': {
      marginBottom: theme.spacing(1),
    }
  },
  sectionWrapper: {
    marginBottom: theme.spacing(3),
    '& > *': {
      marginBottom: theme.spacing(1),
    }
  },
  notificationSubjectInput: {
    maxWidth: '500px',
    marginBottom: theme.spacing(2),
  },
  sectionSpacing: {
    marginTop: theme.spacing(1)
  },
}));

const Notification = props => {
  const { actions, alertsDetails, type } = props;
  const classes = useStyles();
  const getString = useString();

  const handleSelect = field => value => {
    switch (field) {
      case "sheet":
        actions.addValueToAlert(field, value ? value.id : "");
        break;
      case "notificationCustom":
        actions.addValueToAlert(field, value.target.checked);
        break;
      case "subject":
      case "body":
        actions.addValueToAlert(field, value.target.value);
        break;
      default:
        break;
    }
  };

  const handleInputChange = field => value => {
    actions.addValueToAlert(field, value);
  };

  return (
    <Grid
      tid="grid_container_parent"
      container
      direction="column"
      justify="flex-start"
      alignItems="stretch"
    >
      {type === "data" && (
        <div className={classes.sectionWrapper}>
          <NavTitle
            title={getString("AlertsDetails.Notification.Sheet.Title")}
            description={getString("AlertsDetails.Notification.Sheet.Description")}
            underlineWidth={'250px'}
          />
          <div className={classes.inputWrapper}>
            <Selector
              tid="selector_sheet"
              {...alertsDetails.selectors.sheet}
              label="DataTable.Label.Sheet"
              id="sheet"
              value={alertsDetails.sheet}
              disabled={!alertsDetails.app}
              onSelect={handleSelect("sheet")}
              options={alertsDetails.options.sheets}
            />
          </div>
        </div>
      )}
      {type === "broadcast" && (
        <div className={classes.sectionWrapper}>
          <NavTitle tid="nav_title_data_source" title={getString("AlertsDetails.Label.General")} />
          <div className={classes.inputWrapper}>
            <TextField
              tid="text-field_alert_name"
              fullWidth={true}
              id="name"
              onChange={(event) => handleInputChange("name")(event.target.value)}
              value={alertsDetails.name}
              label={ getString("AlertsDetails.Label.AlertTitle") }
            />
            <TextField
              tid="text_field_alert_description"
              fullWidth={true}
              id="description"
              onChange={(event) => handleInputChange("description")(event.target.value)}
              value={alertsDetails.description}
              label={getString("AlertsDetails.Label.AlertDescription") }
              labelDetails={getString("AlertsDetails.Label.Optional")}
            />
          </div>
        </div>
      )}
      <NavTitle
        title={getString("AlertsDetails.Notification.Custom.Title")}
        description={getString("AlertsDetails.Notification.Custom.Description")}
      />
      {type === "data" && (
        <Grid item container tid="grid_switch_custom_notification" className={classes.sectionWrapper} alignItems="center" spacing={1}>
          <Switch
            tid="switch_custom_notification"
            id="notificationCustom"
            checked={alertsDetails.notificationCustom || false}
            onClick={handleSelect("notificationCustom")}
            color="primary"
          />
          <Typography variant="body1">
            {getString("Notification.Label.CustomizeNotification")}
          </Typography>
        </Grid>
      )}
      {alertsDetails.notificationCustom || type === "broadcast" ? (
        <Grid item tid="grid_notification_subject">
          <TextField
            className={classes.notificationSubjectInput}
            tid="text_field_notification_subject"
            label={("Notification.Label.NotificationSubject")}
            id={"subject"}
            onChange={handleSelect("subject")}
            value={alertsDetails.subject}
          />
        </Grid>
      ) : null}
      {alertsDetails.notificationCustom && type !== "broadcast" && (
        <Grid item tid="grid_uploader">
          <Uploader
            tid="uploader"
            actions={actions}
            {...alertsDetails.uploader}
            inserted={alertsDetails.inserted}
          />
        </Grid>
      )}
      {alertsDetails.notificationCustom || type === "broadcast" ? (
        <Grid item tid="grid_textarea_auto_size" md>
          <TextareaAutosize
            tid="textarea_auto_size"
            style={{ width: '100%' }}
            rows={20}
            id="body"
            onChange={handleSelect("body")}
            value={alertsDetails.body}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

export default Notification;
