import actionTypes from "../../constants/actionTypes";

const initialState = {
  login: false,
  token: "",
  userId: "",
  userName: "",
  isAdmin: false,
  permissions: [],
  loading: false,
  service: true,
  expired: false,
  expiresOn: null,
  ssoEnabled: false,
  authenticationURI: ""
};

export default function loginReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.IS_LOGGED:
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case actionTypes.LOGOUT:
      return {
        ...initialState
      }
    case actionTypes.ADD_VALUE_TO_FORGOT_PASSWORD:
      return {
        ...state,
        [action.payload.field]: action.payload.value
      };
    case actionTypes.ADD_VALUE_TO_LOGIN:
      return {
        ...state,
        [action.payload.field]: action.payload.value
      };
      case actionTypes.REGISTER_REQUIRED:
      return {
        ...state,
        ...action.payload
      }
    case actionTypes.UPDATE_LOGIN_PENDING:
      return {
        ...state,
        pending: [action.payload, ...state.pending]
      };
    case actionTypes.REGISTER_STATUS_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
};
