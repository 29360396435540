import React, { useState, useEffect } from "react";
import { makeStyles, Grid } from "@material-ui/core";
import Button from "../../Button";
import NavTitle from "../../NavTitle";
import Table from "../../Table";
import Selector from "../../Selector";
import useString from "../../../i18n/hooks/useString";
import { formatResult } from "../../../utils/formatResult";
import TextField from "../../TextField";
import TableHeader from "../../TableHeader";
import actionTypes from "../../../constants/actionTypes";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  gridBody: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    "& .MuiInputBase-fullWidth": {
      maxWidth: "500px",
    },
  },
  addedTableWrapper: {
    maxWidth: "500px",
  },
  customMeasureOptions: {
    "& > *": {
      marginTop: theme.spacing(),
      paddingLeft: theme.spacing(),
      boxSizing: "border-box",
    },
  },
  customFilterOptions: {
    "& > *": {
      marginTop: theme.spacing(),
      paddingLeft: theme.spacing(),
      boxSizing: "border-box",
    },
  },
  spacedContent: {
    "& > *": {
      marginTop: theme.spacing(1),
    },
  },
  centeredAction: {
    display: "flex",
    width: "100%",
    maxWidth: "500px",
    justifyContent: "center",
    margin: theme.spacing(2, 0, 3),
  },
  filtersTitle: {
    marginTop: theme.spacing(3),
  },
}));

const DataTable = (props) => {
  const { actions, alertsDetails } = props;
  const classes = useStyles();
  const getString = useString();
  const [customMeasure, setCustomMeasure] = useState(false);
  const [customFilter, setCustomFilter] = useState(false);
  const [expression, setExpression] = useState({ def: "", label: "" });

  const columns = [alertsDetails.dimension, ...alertsDetails.measures]
    .filter((e) => e)
    .map((each, index) => ({ index, ...each }));

  const handleSelect = (field) => (value) => {
    switch (field) {
      case "app":
      case "sheet":
      case "bookmark":
      case "filterSelection":
        actions.addValueToAlert(field, value?.id || "");
        break;
      case "dimension":
      case "measure":
      case "field":
      case "fieldValues":
      case "name":
      case "description":
        actions.addValueToAlert(field, value);
        break;
      case "format":
        actions.addValueToAlert(field, value?.value || "");
        break;
      case "sortAscending":
        actions.addValueToAlert(field, value?.value || false);
        break;
      case "sortBy":
        actions.addValueToAlert(field, value?.index || 0);
        break;
      default:
        break;
    }
  };

  const handleRefresh = () => {
    if (qsFilter) return
    setPage(0);
    actions.refreshCache(alertsDetails.app);
  }

  const handleMeasureSelect = (option) => {
    setCustomMeasure(option?.group === "Custom");
    handleSelect("measure")(option);
  };

  const handleFilterSelect = (option) => {
    if (option?.group === "Custom") {
      setCustomFilter(true);
      handleSelect("bookmark")(null);
    } else {
      actions.deleteAllFilters();
      setCustomFilter(false);
      handleSelect("bookmark")(option);
    }
    handleSelect("filterSelection")(option);
  };

  const isAddMeasureEnabled =
    (customMeasure && expression.def && expression.label) ||
    (!customMeasure && alertsDetails.measure);

  const dispatch = useDispatch();

  const handleAddMeasure = () => {
    if (customMeasure) {
      if (expression.def && expression.label) actions.addMeasure(expression);
      setExpression({ def: "", label: "" });
      setCustomMeasure(false);
    } else actions.addMeasure();
  };
  const handleAddFilter = () => actions.addFilter();
  const deleteFilter = (value) => actions.deleteFilter(value.index);
  const deleteMeasure = (value) => actions.deleteMeasure(value.index);
  const previewTableData = formatResult(
    alertsDetails.queryData.slice(0, 10),
    alertsDetails.measures,
    alertsDetails.dimension
  );

  //Lazy loading for Apps
  const [page, setPage] = useState(0);
  const [qsFilter, setQSFilter] = useState('')


  const handleScrollForApps = (event) => {
    const listboxNode = event.currentTarget;
    const x = listboxNode.scrollTop + listboxNode.clientHeight;
    if (listboxNode.scrollHeight - x <= 1) {
      const nextPage = page + 1;
      setPage(nextPage);
      actions.getAppSourcesLazyLoading(nextPage, 200, qsFilter);
    }
  };

  useEffect(() => {
    actions.getAppSourcesLazyLoading(0, 200, qsFilter);
  }, [qsFilter, actions])

  const inputFilter = (event, value) => {
    if (event === null) return
    if (value === qsFilter) return
    setQSFilter(value);
    dispatch({
      type: actionTypes.GET_APP_SOURCE_LazyLoading_EmptyApps,
    });
    const nextPage = 0;
    setPage(nextPage);
  };

  useEffect(() => {
    if (alertsDetails.app) actions.getAppSource(alertsDetails.app)
  }, [alertsDetails.app])

  return (
    <Grid container tid="grid_parent" direction="column" spacing={3}>
      <Grid
        container
        item
        className={classes.gridBody}
        tid="grid_body"
        justify="space-between"
        alignItems="stretch"
        spacing={8}
      >
        <Grid tid="grid_data_source" item xs className={classes.spacedContent}>
          <NavTitle
            tid="nav_title_data_source"
            title={getString("AlertsDetails.Label.General")}
          />
          <div style={{ display: "flex" }}>
            <TextField
              tid="text-field_alert_name"
              fullWidth={true}
              id="name"
              onChange={(event) => handleSelect("name")(event.target.value)}
              value={alertsDetails.name}
              label={getString("AlertsDetails.Label.AlertTitle")}
            />
          </div>
          <TextField
            tid="text_field_alert_description"
            fullWidth={true}
            id="description"
            onChange={(event) =>
              handleSelect("description")(event.target.value)
            }
            value={alertsDetails.description}
            label={getString("AlertsDetails.Label.AlertDescription")}
            labelDetails={getString("AlertsDetails.Label.Optional")}
          />
          <Selector
            tid="selector_application"
            style={{ flexGrow: 12 }}
            {...alertsDetails.selectors.app}
            label="DataTable.Label.Source"
            id="app"
            value={alertsDetails.app}
            onSelect={handleSelect("app")}
            options={alertsDetails.options.apps}
            inputfilter={inputFilter}
            ListboxProps={{
              onScroll: handleScrollForApps,
            }}
          />
          <div className={classes.centeredAction}>
            <Button
              tid="button_refresh_cache"
              onClick={handleRefresh}
              variant="outlined"
              title="DataTable.Label.RefreshTooltip"
            >
              {getString("DataTable.Label.RefreshCache")}
            </Button>
          </div>
        </Grid>
        <Grid item xs tid="grid_measure" className={classes.spacedContent}>
          <NavTitle
            tid="nav_title_measure"
            title={getString("DataTable.Label.Measures")}
          />
          <Selector
            {...alertsDetails.selectors.measure}
            label={"DataTable.Label.Measure"}
            id={"measure"}
            tid="selector_measure"
            groupBy={(option) =>
              option.group || getString("DataTable.Group.MasterMeasures")
            }
            value={alertsDetails.measure}
            disabled={!alertsDetails.app}
            getOptionDisabled={(option) =>
              alertsDetails.measures.map((each) => each.id).includes(option.id)
            }
            onSelect={handleMeasureSelect}
            options={[
              {
                label: getString("DataTable.Label.CustomMeasure"),
                id: "Custom",
                group: "Custom",
              },
              ...alertsDetails.options.measures,
            ]}
          />
          {customMeasure && (
            <Grid
              tid="grid_custom_measure"
              className={classes.customMeasureOptions}
            >
              <TextField
                tid="text_field_custom_expression"
                id="customExpression"
                fullWidth={true}
                label="DataTable.Label.CustomExpression"
                value={expression.def}
                onChange={(e) =>
                  setExpression({ ...expression, def: e.target.value })
                }
              />
              <TextField
                tid="text_field_custom_label"
                id="customLabel"
                fullWidth={true}
                label="DataTable.Label.CustomLabel"
                value={expression.label}
                onChange={(e) =>
                  setExpression({ ...expression, label: e.target.value })
                }
              />
            </Grid>
          )}
          <Selector
            tid="selector_format"
            {...alertsDetails.selectors.format}
            label="DataTable.Label.Format"
            id="format"
            value={alertsDetails.format}
            onSelect={handleSelect("format")}
            disabled={!alertsDetails.app || !alertsDetails.measure}
          />
          <div className={classes.centeredAction}>
            <Button
              tid="button_add_measure"
              onClick={handleAddMeasure}
              variant="contained"
              color="primary"
              disabled={!isAddMeasureEnabled}
            >
              {getString("DataTable.Label.AddMeasure")}
            </Button>
          </div>
          <NavTitle title={getString("DataTable.Label.AddedMeasures")} />
          <div className={classes.addedTableWrapper}>
            <Table
              tid="table_measures"
              actions={{ deleteMeasure }}
              data={alertsDetails.measures}
              placeholder={getString("DataTable.Label.AddedMeasures.NoneAdded")}
              {...alertsDetails.tables.measures}
              borderless
            />
          </div>
        </Grid>
        <Grid
          item
          tid="grid_drill_to_dimension"
          xs
          className={classes.spacedContent}
        >
          <NavTitle
            tid="nav_title_drill_to_dimension"
            title={getString("DataTable.Label.DrillToDimension")}
            subtitle={getString("AlertsDetails.Label.Optional")}
            description={getString(
              "DataTable.Label.DrillToDimension.Description"
            )}
          />
          <Selector
            tid="selector_dimension"
            {...alertsDetails.selectors.dimension}
            label="DataTable.Label.Dimension"
            id="dimension"
            value={alertsDetails.dimension}
            onSelect={handleSelect("dimension")}
            disabled={!alertsDetails.app}
            options={[
              ...alertsDetails.options.dimensions.map((each) => ({
                ...each,
                type: "Dimensions",
              })),
              ...alertsDetails.options.fields.map((each) => ({
                ...each,
                type: "Fields",
              })),
            ]}
          />
          <NavTitle
            tid="nav_title_filters"
            title={getString("DataTable.Label.Filters")}
            subtitle={getString("AlertsDetails.Label.Optional")}
            description={getString("DataTable.Label.Filters.Description")}
            className={classes.filtersTitle}
          />
          <Selector
            {...alertsDetails.selectors.bookmark}
            label={getString("DataTable.Label.Filter")}
            id={"filter"}
            tid="selector_filter"
            disabled={!alertsDetails.app}
            groupBy={(option) => option.group || "Bookmarks"}
            value={alertsDetails.bookmark || alertsDetails.filterSelection}
            onSelect={handleFilterSelect}
            options={[
              { name: "Custom Filter", id: "Custom", group: "Custom" },
              ...alertsDetails.options.bookmarks,
            ]}
          />
          {customFilter && (
            <>
              <div
                tid="div_filter_field_values"
                className={classes.customFilterOptions}
              >
                <Selector
                  tid="selector_filter_field"
                  {...alertsDetails.selectors.field}
                  label={getString("DataTable.Label.FilterField")}
                  id={"field"}
                  value={alertsDetails.field}
                  getOptionDisabled={(option) =>
                    alertsDetails.filters
                      .map((each) => each.field)
                      .includes(option.def)
                  }
                  onSelect={handleSelect("field")}
                  options={alertsDetails.options.fields}
                />
                <Selector
                  tid="selector_field_values"
                  {...alertsDetails.selectors.fieldValues}
                  label={getString("DataTable.Label.Values")}
                  id={"fieldValues"}
                  value={alertsDetails.fieldValues}
                  onSelect={handleSelect("fieldValues")}
                  options={alertsDetails.options.fieldValues}
                />
              </div>
              <div className={classes.centeredAction}>
                <Button
                  tid="button_add_filter"
                  onClick={handleAddFilter}
                  variant="contained"
                  color="primary"
                  disabled={
                    !alertsDetails.field ||
                    !alertsDetails.field.def ||
                    !alertsDetails.fieldValues.length
                  }
                >
                  {getString("DataTable.Label.AddFilter")}
                </Button>
              </div>
            </>
          )}
          {alertsDetails.filters.length > 0 && (
            <>
              <NavTitle title={getString("DataTable.Label.AddedFilters")} />
              <div className={classes.addedTableWrapper}>
                <Table
                  tid="table_filters"
                  actions={{ deleteFilter }}
                  data={alertsDetails.filters}
                  {...alertsDetails.tables.filters}
                  borderless
                />
              </div>
            </>
          )}
        </Grid>
      </Grid>
      <Grid item tid="grid_query_table">
        <NavTitle
          title={getString("DataTable.Label.Preview")}
          subtitle={getString("AlertsDetails.Label.Preview.FirstTenRows")}
          description={
            previewTableData?.[0]?.length === 0 &&
            getString("DataTable.Label.Preview.Description")
          }
        />
        {previewTableData?.[0]?.length > 0 && (
          <>
            <TableHeader
              leftToolbar={
                alertsDetails.dimension ? (
                  <>
                    <Selector
                      tid="selector_sort_by"
                      {...alertsDetails.selectors.column}
                      id={"sortBy"}
                      value={alertsDetails.sortBy}
                      label={"DataTable.Label.SortBy"}
                      onSelect={handleSelect("sortBy")}
                      options={columns}
                      style={{ width: "200px" }}
                    />
                    <Selector
                      {...alertsDetails.selectors.sortAscending}
                      id={"sortAscending"}
                      value={alertsDetails.sortAscending}
                      label={"DataTable.Label.SortAscending"}
                      onSelect={handleSelect("sortAscending")}
                      style={{ width: "200px" }}
                    />
                  </>
                ) : null
              }
            />
            <Table
              tid="table_query_table"
              data={previewTableData}
              {...alertsDetails.queryTable}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
};
export default DataTable;
