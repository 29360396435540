import paths from "../../constants/paths";

export const crumbs = [
  { label: "Breadcrumbs.Admin" },
  { label: "Breadcrumbs.Channels", link: paths.channels },
  { label: "Breadcrumbs.Details" }
]

export const form = {
  options: {},
  fields: [
    {
      id: "connectionHost",
      name: "connectionHost",
      label: "ChannelsDetails.Label.Address"
    },
    {
      id: "connectionPort",
      name: "connectionPort",
      label: "ChannelsDetails.Label.PortNo"
    },
    {
      id: "connectionAuthSecurity",
      name: "connectionAuthSecurity",
      type: 'radio',
      options: [{ name: "ChannelsDetails.Fields.Options.SSL", value: "SSL" }, { name: "ChannelsDetails.Fields.Options.TLS", value: "TLS" }, { name: "ChannelsDetails.Fields.Options.None", value: "none" },]

    },
    {
      id: "authenticationMethod",
      name: "authenticationMethod",
      type: 'radio',
      value: 'basic',
      options: [{ name: "ChannelsDetails.AuthMethod.Basic", value: "basic" }, { name: "ChannelsDetails.AuthMethod.Anonymous", value: "anonymous" },]
    },
    {
      id: "connectionAuthUser",
      name: "connectionAuthUser",
      label: "ChannelsDetails.Label.UserName"
    },
    {
      id: "connectionAuthPass",
      name: "connectionAuthPass",
      label: "ChannelsDetails.Label.Password",
      type: 'password',
      hidden: true
    },
    {
      id: "connectionFrom",
      name: "connectionFrom",
      label: "ChannelsDetails.Label.DefaultEmail",
      helperText: "ChannelsDetails.HelperText.DefaultEmail"
    },
    {
      id: "connectionTest",
      name: "connectionTest",
      label: "ChannelsDetails.Label.TestEmail",
      helperText: "ChannelsDetails.HelperText.TestEmail"
    }
  ]
};

export const bottomBar = {
  options: {},
  buttons: {
    right: [
      {
        key: "cancel",
        type: "button",
        label: "General.Cancel",
        onClick: "handleCancel",
        display: () => true
      },
      {
        key: "test",
        type: "button",
        label: "General.Test",
        onClick: "handleTest",
        display: () => true
      },
      {
        key: "save",
        type: "button",
        label: "General.Save",
        onClick: "handleSave",
        variant: 'contained',
        color: 'primary',
        disabled: (s, l, state) => !state.tested || state.saveIsDisabled,
        display: () => true
      },
    ]
  }
};

export const modal = {
  options: {
    title: "General.Cancel"
  },
  content: "NavigationBlocker.Warning.Content",
  buttons: [
    {
      key: "notLeave",
      label: "General.Cancel",
      onClick: null,
      variant: 'outlined',
    },
    {
      key: "leave",
      label: "General.Confirm",
      onClick: 'cancelEditChannels',
      color: "primary"
    }
  ]
}
