import React from "react";
import { MenuItem, Menu } from "@material-ui/core";
import UserManagementMenu from "./UserManagementMenu";
import paths from "../../constants/paths";
import { NavLink } from "react-router-dom";
import { useStyles } from "../../constants/styles";
import { withRouter } from "react-router";
import useString from "../../i18n/hooks/useString";

const AdminDropdown = ({ anchorEl, onClose }) => {
  const classes = useStyles();
  const getString = useString();

  return (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      <MenuItem tid="menu_item_user_management">
        <UserManagementMenu
          classes={classes}
          handleCloseAdmin={onClose}
        />
      </MenuItem>
      <MenuItem
        tid="menu_item_sources"
        onClick={onClose}
        component={NavLink}
        to={paths.sources}
      >
        <p>{getString("Admin.MenuItem.Sources")}</p>
      </MenuItem>
      <MenuItem
        tid="menu_item_channels"
        onClick={onClose}
        component={NavLink}
        to={paths.channels}
      >
        <p>{getString("Admin.MenuItem.Channels")}</p>
      </MenuItem>
      <MenuItem
        tid="menu_item_config"
        onClick={onClose}
        component={NavLink}
        to={paths.config}
      >
        <p>{getString("Admin.MenuItem.Config")}</p>
      </MenuItem>
    </Menu>
  );
};

export default withRouter(AdminDropdown);
