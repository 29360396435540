export const tables = {
    preview: {
      options: {
        header: true,
      },
      fields: [
        { id: "fullName", label: "RecipientsGroup.Label.FullName", getValue: (row) => row.fullName },
        { id: "name", label: "RecipientsGroup.Label.User", getValue: (row) => row.name },
        { id: "domain", label: "RecipientsGroup.Label.Domain", getValue: (row) => row.domain },
        {
          id: "unsubscribed",
          label: "RecipientsGroup.Label.Unsubscribed",
          type: "switch",
          getValue: (row) => row.unsubscribed || false,
          onClick: "handleUnsubscribed",
        },
      ],
    },
  };

  export const groupFields = [
    {
      id: "name",
      label: "RecipientsGroup.Label.Name",
      getValue: (row) => row.name,
    },
    // {
    //   id: "length",
    //   label: "RecipientsGroup.Label.Recipients",
    //   getValue: (row) => row.count,
    // },
  ]