

import ActionTypes from "../../constants/actionTypes";
import API from "../../lib/api";
import { push } from "connected-react-router";
import paths from "../../constants/paths";

export const loginSSO = (values, type = "data", id) => {
    return async dispatch => {
      try {
        if(!values) throw 'uniqueId missing'
        const logged = await API.loginSSO(values);
        if (logged.token) {
          dispatch({
            type: ActionTypes.IS_LOGGED,
            payload: logged
          });
          localStorage.setItem('userId', logged.userId)
          localStorage.setItem('token', logged.token)
          if (type && id) {
            dispatch(push(paths.getAlertsDetails(type, id)))
          } else {
            dispatch(push(paths.alerts));
          }
        } else {
          throw 'login failed';
        }
      } catch (error) {
        dispatch({
          type: ActionTypes.SNACK_ERROR,
          payload: { snackMessage: typeof error === "string" ? error : JSON.stringify(error) }
        });
        return false;
      }
    };
  };
