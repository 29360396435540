export const toUsersDetails = (input) => {
    return {
        name: input.name,
        firstName: input.firstName,
        lastName: input.lastName,
        domain: input.domain,
        email: input.channels && input.channels.email ? input.channels.email.connection : null,
        admin: input.admin,
        isPasswordSet: input.isPasswordSet ? 'Yes':'No'
    }
}

export const fromForm = values => {
    return {
        admin: values.admin,
        channels: {
            email: {
                connection: values.email
            }
        }
    }
}
