import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        marginBottom: '5em',
        marginRight: '2em',
        zIndex: 2000,
    },
}));

const SpinnerAdornment = () => (
    <CircularProgress
        tid="circular_progress"
        size={80}
        value={80}
    />
)

const CircularIndeterminate = ({ loading }) => {
    const classes = useStyles();
    return (
        <>
            {loading
                ? <div className={classes.root} >
                    <SpinnerAdornment tid="spinner_adornment" />
                </div>
                : null
            }
        </>
    );
}

export default CircularIndeterminate;
