import ActionTypes from "../../constants/actionTypes";
import { push } from "connected-react-router";
import paths from "../../constants/paths";
import API from "../../lib/api";
import { isValidHttpUrl } from "../../utils/validateURL";
import { fromSourceForm, toSourcesDetails } from "./dataHandler";

export const getSource = (id) => {
  return async (dispatch) => {
    try {
      const result = await API.getOneSource(id);
      const data = await toSourcesDetails(result);
      dispatch({
        type: ActionTypes.GET_SOURCE,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: {
          snackMessage:
            typeof error === "string" ? error : JSON.stringify(error),
        },
      });
    }
  };
};

const verifySsoUrl = (url) =>{
  return new Promise((resolve,reject)=>{
   let popupWindow = window.open(url,"QlikAlerting","toolbar=no,status=no,menubar=no,location=center,scrollbars=no,resizable=no,height=300,width=300");
   window.receiveDataFromPopup = function(data) {
      popupWindow.close()
      if (data === true) resolve({ type: 'SUCCESS' })
      else reject({ type: 'DISMISS' })
   };
   const interval = setInterval(() => {
      if (popupWindow?.closed) {
        if (resolve) resolve({ type: 'DISMISS' });
        clearInterval(interval);
        popupWindow.close()
      }
    }, 1000);
    const timeout = setTimeout(() => {
      if (!popupWindow?.closed) {
        if (resolve) resolve({ type: 'DISMISS' });
        clearInterval(timeout);
        popupWindow.close()
      }
    }, 180000);
  })
}


export const testSource = (input) => {
  return async (dispatch) => {
    const data = fromSourceForm(input);
    try {
      if (data.config.ssoEnabled === true) {
        const isValidURL = isValidHttpUrl(data.config.authenticationURI);
        if (!isValidURL || !data.config.authenticationURI.includes('content')) throw "Invalid SSO URL";
        else {
          const ssoResp = await verifySsoUrl(`${data.config.authenticationURI}?redirectType=verifySSO&linkingUri=${window.location}`)
          if (ssoResp.type == 'DISMISS') throw "Invalid SSO URL. Please contact your administrator."
        }
      }
      const result = await API.testSource(data);
      dispatch({
        type: ActionTypes.TEST_SOURCE,
        payload: { data, ...result },
      });
      if (result.success) {
        dispatch({
          type: ActionTypes.SNACK_SUCCESS,
          payload: {
            snackMessage: `Apps: ${result.apps}. Tasks: ${result.tasks}. Users: ${result.users}`,
          },
        });
      } else {
        dispatch({
          type: ActionTypes.SNACK_ERROR,
          payload: { snackMessage: result.errors },
        });
      }
    } catch (error) {
      dispatch({
        type: ActionTypes.TEST_SOURCE,
        payload: { data, result: null, success: false },
      });
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: {
          snackMessage:
            typeof error === "string" ? error : JSON.stringify(error),
        },
      });
    }
  };
};

export const editSource = (id, input) => {
  return async (dispatch) => {
    try {
      if (input.config.ssoEnabled === true) {
        const isValidURL = isValidHttpUrl(input.config.authenticationURI);
        if (!isValidURL) throw "Invalid SSO URL";
      }
      dispatch({
        type: ActionTypes.SAVE_BUTTON_DISABLED,
        payload: { saveIsDisabled: true },
      });
      const data = fromSourceForm(input);
      const result = id
        ? await API.updateSource(id, data)
        : API.createSource(data);
      dispatch({
        type: ActionTypes.EDIT_SOURCE,
        payload: { data, result },
      });
      dispatch(push(paths.sources));
      dispatch({
        type: ActionTypes.SNACK_SUCCESS,
        payload: { snackMessage: "Snackbar.SourcesDetails.editDataSource" },
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: {
          snackMessage:
            typeof error === "string" ? error : JSON.stringify(error),
        },
      });
    } finally {
      dispatch({
        type: ActionTypes.SAVE_BUTTON_DISABLED,
        payload: { saveIsDisabled: false },
      });
    }
  };
};

export const uploadSourceCerts = (key, content) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.UPLOAD_SOURCE_CERTS,
        payload: { key, content },
      });
      dispatch({
        type: ActionTypes.SNACK_SUCCESS,
        payload: { snackMessage: "Snackbar.SourcesDetails.uploadSourceCerts" },
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: {
          snackMessage:
            typeof error === "string" ? error : JSON.stringify(error),
        },
      });
    }
  };
};

export const clearSource = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.CLEAR_SOURCE,
      });
      dispatch({
        type: ActionTypes.SNACK_SUCCESS,
        payload: { snackMessage: "Snackbar.SourcesDetails.clearSource" },
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: {
          snackMessage:
            typeof error === "string" ? error : JSON.stringify(error),
        },
      });
    }
  };
};

export const addValueToSourcesDetails = (field, value) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.ADD_VALUE_TO_SOURCES_DETAILS,
      payload: { field, value },
    });
    if (field === "ssoEnabled") {
      dispatch({
        type: ActionTypes.SSO_INPUT_DISPLAY_UPDATE,
      });
    }
  };
};

export const ssoInputDisplayAction = () => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.SSO_INPUT_DISPLAY_UPDATE,
    });
  };
};

export const ssoFlagAction = (field, value) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.SSO_FLAG_UPDATE,
      payload: { field: (value === 'true') }
    })
  };
};

export const cancelEditSource = () => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.CANCEL_EDIT_SOURCE,
    });
    dispatch(push(paths.sources));
  };
};
