import React from "react";
import { MenuItem } from "@material-ui/core";
import paths from "../../constants/paths";
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router";
import useString from "../../i18n/hooks/useString";
import AvatarMenu from "../../components/AvatarMenu";

const UserMenu = (props) => {
  const { openModal, modals, handleLogout, name } = props;
  const getString = useString();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = !!anchorEl;

  const handleMenuItem = (modal) => {
    openModal(modal);
    handleClose();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AvatarMenu
      onClick={handleClick}
      open={isMenuOpen}
      avatarSrc={name[0]}
      anchorEl={anchorEl}
      onClose={handleClose}
      userName={name}
    >
      <MenuItem
        tid="menu_item_user_profile"
        onClick={() => handleMenuItem(modals.userProfile)}
      >
        <p>{getString("UserMenu.MenuItem.UserProfile")}</p>
      </MenuItem>
      <MenuItem
        tid="menu_item_devices"
        onClick={handleClose}
        component={NavLink}
        to={paths.devices}
      >
        <p>{getString("UserMenu.MenuItem.Devices")}</p>
      </MenuItem>
      <MenuItem
        tid="menu_item_suggestions"
        onClick={handleClose}
        component={NavLink}
        to={paths.suggestions}
      >
        <p>{getString("UserMenu.MenuItem.Suggestions")}</p>
      </MenuItem>
      <MenuItem
        tid="menu_item_about"
        onClick={() => handleMenuItem(modals.about)}
      >
        <p>{getString("UserMenu.MenuItem.About")}</p>
      </MenuItem>
      <MenuItem
        tid="menu_item_logout"
        onClick={handleLogout}
      >
        <p>{getString("UserMenu.MenuItem.Logout")}</p>
      </MenuItem>
    </AvatarMenu>

  );
};

export default withRouter(UserMenu);