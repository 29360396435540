import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Typography,
  Checkbox,
  Grid
} from "@material-ui/core";
import NavTitle from "../../NavTitle";
import Table from "../../Table";
import { searchBar } from "../../../utils/searchBar";
import useString from "../../../i18n/hooks/useString";
import TextField from "../../TextField";
import TableHeader from "../../TableHeader";
import SearchInput from "../../SearchInput";
import Selector from "../../Selector";
import Radio from "../../Radio";

const useStyles = makeStyles((theme) => ({
  inputWrapper: {
    maxWidth: '500px',
    '& > *': {
      marginBottom: theme.spacing(2),
    }
  },
  sectionSpacing: {
    marginTop: theme.spacing(1)
  },
  sectionSpacingBottom: {
    marginBottom: theme.spacing(2)
  }
}));

const System = props => {
  const { actions, alertsDetails, login } = props;
  const classes = useStyles();
  const getString = useString();
  const [searchInput, setSearchInput] = useState("");
  const [sortBy, setSortBy] = useState({ field: "name", order: "asc" })

  useEffect(() => {
    if(alertsDetails.systemTaskDefOp === "$all") actions.addValueToAlert("systemTaskDef", "")
  }, [alertsDetails.systemTaskDefOp])

  const sortField = (field, order) => setSortBy({ field, order });

  const sortData = (a, b) => sortBy.order === "asc"
    ? sortCriteria(a[sortBy.field], b[sortBy.field])
    : sortCriteria(b[sortBy.field], a[sortBy.field])

  const sortCriteria = (a, b) => {
    if (typeof a === "number") return a - b
    if (!b) return -1
    if (!a) return 1
    return a.localeCompare(b)
  }

  const systemCheckBoxes = [{
    label: "System.Checkbox.Success", name: "systemSuccess"
  }, {
    label: "System.Checkbox.Failed", name: "systemFailed"
  }, {
    label: "System.Checkbox.Aborted", name: "systemAborted"
  }, {
    label: "System.Checkbox.SystemAborted", name: "systemSystemAborted"
  }, {
    label: "System.Checkbox.InProgress", name: "systemInProgress"
  }];
  
  const handleCheck = check => (event, value) =>
    actions.addValueToAlert(check, value);

  const handleValue = field => value => {
    switch (field) {
      case "systemTaskSelection":
        actions.addValueToAlert(field, value === "true")
        break;
      case "systemTaskDef":
      default:
        actions.addValueToAlert(field, value)
        break
    }
  };

  const searchRuleBar = (data = [], searchInput = "", operator) => {
    let regex 
    switch (operator) {
      case "$sw":
        regex = new RegExp(`^${searchInput}`, "i")
        break
      case "$ew":
        regex = new RegExp(`${searchInput}$`, "i")
        break
      case "$in":
        regex = new RegExp(`${searchInput}`, "i")
        break
      case "$all":
      default:
        return data   
    }
    return data.filter(({name}) => name.match(regex))
  }

  const checkDbVersion = () => {
    if (!login.db) return false
    let [ major, minor ] = login.db.versionArray
    if (major >= 4)  return true
    else return (major === 4 && minor >= 2)
  }

  return (
    <Grid container tid="grid_parent" spacing={4}>
      <Grid container item tid="grid_body" xs={12} direction="column">
        <NavTitle tid="nav_title_data_source" title={getString("AlertsDetails.Label.General")} />
        <div className={classes.inputWrapper}>
          <TextField
            tid="text-field_alert_name"
            fullWidth={true}
            id="name"
            onChange={(event) => handleValue("name")(event.target.value)}
            value={alertsDetails.name}
            label={getString("AlertsDetails.Label.AlertTitle")}
          />
          <TextField
            tid="text_field_alert_description"
            fullWidth={true}
            id="description"
            onChange={(event) => handleValue("description")(event.target.value)}
            value={alertsDetails.description}
            label={getString("AlertsDetails.Label.AlertDescription")}
            labelDetails={getString("AlertsDetails.Label.Optional")}
          />
        </div>
        {checkDbVersion() ?
          <Radio
            tid="radio_system_task_selection"
            id="systemTaskSelection"
            value={JSON.stringify(alertsDetails.systemTaskSelection)}
            type="radio"
            options={[
              {
                name: getString("System.Label.ManualSelection"),
                value: "false"
              },
              {
                name: getString("System.Label.RuleSelection"),
                value: "true"
              }
            ]}
            onChange={handleValue("systemTaskSelection")}
          />
          : null}
        <Grid tid="grid_tables" container item xs direction="row" spacing={4} style={{ flexBasis: "auto" }}>
          <Grid tid="grid_selected_table" item xs className={classes.sectionSpacing}>
            {alertsDetails.systemTaskSelection ?
              <>
                <NavTitle tid="nav_title_event_type" subtitle={getString("AlertsDetails.Label.RuleSelection")} style={{display: 'inline'}} />
                <div className={classes.sectionSpacingBottom}></div>
                <Selector
                  tid="selector_application"
                  style={{ flexGrow: 12 }}
                  {...alertsDetails.selectors.systemTaskOperators}
                  label="Operator"
                  id="systemTaskDefOp"
                  value={alertsDetails.systemTaskDefOp}
                  onSelect={e => handleValue("systemTaskDefOp")(e?.value)}
                  className={classes.sectionSpacingBottom}
                />
                {alertsDetails.systemTaskDefOp !== '$all' ?
                <TextField
                  tid="text_field_system_alert_rules"
                  fullWidth={false}
                  id="rules"
                  onChange={(e) => handleValue("systemTaskDef")(e.target.value)}
                  value={alertsDetails.systemTaskDef}
                  label={getString("System.Label.Rules")}
                  className={classes.sectionSpacingBottom}
                /> : null }
                <Table
                  style={{borderTop:'1px solid lightgray'}}
                  tid="table_rules"
                  {...alertsDetails.tables.rules}
                  actions={{ sortField }}
                  data={searchRuleBar(alertsDetails.systemTasks, alertsDetails.systemTaskDef, alertsDetails.systemTaskDefOp)}
                />
              </>
              : <>
                <NavTitle tid="nav_title_task_selection" subtitle={getString("AlertsDetails.Label.TaskSelection")} style={{display: 'inline'}} />
                <div className={classes.sectionSpacingBottom}></div>
                <TableHeader
                  leftToolbar={
                    <>
                      <SearchInput
                        tid={'search_input_system'}
                        value={searchInput}
                        onApply={setSearchInput}
                      />
                      <Typography variant="body2">
                        {`${getString("AlertsDetails.Label.Selected")}: ${alertsDetails.taskIds.length}`}
                      </Typography>
                    </>
                  }
                />
                <Table
                  tid="table_tasks"
                  {...alertsDetails.tables.tasks}
                  selectedIds={alertsDetails.taskIds}
                  actions={{ selectTasks: handleValue("taskIds"), sortField }}
                  data={searchBar(alertsDetails.systemTasks, searchInput).sort(sortData)}
                />
              </>
            }
          </Grid>
          <Grid container tid="grid_bottom_container" item xs={3} direction={"column"}>
          <div className={classes.sectionSpacingBottom}></div>
            <NavTitle tid="nav_title_event_type" title={getString("AlertsDetails.Label.EventType")} />
            {systemCheckBoxes.map(({ name, label }, i) => (
              <Grid item tid={`grid_checkbox_system_primary_${i}`} key={name}>
                <Checkbox
                  tid={`checkbox_system_primary_${i}`}
                  name={name}
                  color="primary"
                  checked={alertsDetails[name]}
                  onChange={handleCheck(name)}
                />
                <span tid={`span_each_${i}`} >{getString(label)}</span>
              </Grid>
            ))}
            {alertsDetails.systemInProgress && (
              <Grid item tid="grid_text_field_in_progress">
                <TextField
                  tid="text_field_in_progress"
                  variant="outlined"
                  fullWidth={true}
                  label={"System.Textfield.InProgressForSeconds"}
                  type={"number"}
                  value={alertsDetails.systemInProgressFor}
                  onChange={e => handleValue("systemInProgressFor")(e.target.value)}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default System;
