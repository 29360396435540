import React from 'react';
import { makeStyles, fade } from '@material-ui/core/styles';
import {
  Grid,
  Avatar,
  Typography,
  Menu,
  withStyles,
  Divider,
  Button,
} from '@material-ui/core';
import ArrowDown from '@qlik-trial/sprout/icons/ArrowDown';

const useStyle = makeStyles((theme) => ({
  button: {
    padding: 0,
    minWidth: '40px',
    borderRadius: '20px',
    '&:hover': {
      backgroundColor: fade(theme.palette.common.black, 0.03),
      '& span': {
        '& div[type="avatar"]': {
          transform: 'scale(0.75)',
          marginRight: 4,
        },
      },
    },
  },
  activeFab: {
    backgroundColor: fade(theme.palette.common.black, 0.1),
    '& span': {
      '& div[type="avatar"]': {
        transform: 'scale(0.75)',
        marginRight: 4,
      },
    },
  },
  avatar: {
    height: 32,
    width: 32,
    margin: 0,
    transition: 'height 150ms, width 150ms, margin 150ms, transform 150ms',
  },
  avatarLarge: {
    height: 64,
    width: 64,
  },
  icon: {
    padding: '5px',
    marginRight: '4px',
  },
  avatarInfo: {
    margin: theme.spacing(3, 5),
  },
  arrow: {
    width: '15px',
    height: '15px',
    position: 'absolute',
    top: '120%',
    backgroundColor: theme.palette.common.white,
    transform: 'rotate(45deg)',
    boxShadow: `0 0 0 1px ${fade(
      theme.palette.common.black,
      0.05
    )}, 0 4px 10px 0 ${fade(theme.palette.common.black, 0.15)}`,
  },
  whiteLine: {
    width: '15px',
    height: '15px',
    position: 'absolute',
    backgroundColor: theme.palette.common.white,
    top: '120%',
    transform: 'rotate(45deg)',
    zIndex: 9999,
  },
}));

const StyledMenu = withStyles(
  (theme) => ({
    paper: {
      marginTop: theme.spacing(1),
      boxShadow: `0 0 0 1px ${fade(
        theme.palette.common.black,
        0.05
      )}, 0 4px 10px 0 ${fade(theme.palette.common.black, 0.15)}`,
    },
  }),
  { withTheme: true }
)((props) => (
  <Menu
    keepMounted
    getContentAnchorEl={null}
    transitionDuration={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const AvatarInfo = ({ alt, src, userName, userEmail }) => {
  const classes = useStyle();
  return (
    <div className={classes.avatarInfo}>
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <Avatar alt={alt} className={classes.avatarLarge}>
            {src}
          </Avatar>
        </Grid>
        <Grid item>
          <Typography color="textPrimary">{userName}</Typography>
        </Grid>
        {userEmail &&
          <Grid item>
            <Typography color="textSecondary">{userEmail}</Typography>
          </Grid>
        }
      </Grid>
    </div>
  );
};

const AvatarMenu = ({
  size,
  onClick,
  avatarSrc,
  open,
  anchorEl,
  onClose,
  children,
  userName,
  userEmail,
}) => {
  const classes = useStyle();

  return (
    <div>
      <Button
        tid={'button_menu'}
        size={size}
        onClick={onClick}
        className={`${classes.button} ${open ? classes.activeFab : ''}`}
      >
        <Avatar className={classes.avatar} type="avatar">
          {avatarSrc}
        </Avatar>
        <ArrowDown className={classes.icon} size="small" />
        {open ? <div className={classes.arrow} /> : ''}
        {open ? <div className={classes.whiteLine} /> : ''}
      </Button>

      <StyledMenu anchorEl={anchorEl} open={open} onClose={onClose}>
        <div>
          <AvatarInfo
            src={avatarSrc}
            alt="avatar info"
            userEmail={userEmail}
            userName={userName}
          />
          <Divider variant="middle" />
        </div>

        {children}
      </StyledMenu>
    </div>
  );
};

export default AvatarMenu;
