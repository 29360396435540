import React, { useState, useEffect, useRef } from "react";
import { bindActionCreators } from "redux";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";
import * as userDetailsActions from "./actions";
import BottomBar from "../../components/BottomBar";
import Breadcrumbs from "../../components/BreadcrumbsHeader";
import Form from "../../components/Form";
import Modal from "../../components/Modal";
import { useStyles } from "../../constants/styles";
import useNavBlocker from "../../components/NavigationBlocker/hooks/useNavBlocker";
import { push } from "connected-react-router";
import paths from "../../constants/paths";

const UsersDetails = () => {
  const { unblockNavigation, blockNavigation } = useNavBlocker();
  useEffect(() => {
    blockNavigation();
  }, []);

  const dispatch = useDispatch();
  const { current: actions } = useRef(
    bindActionCreators({ ...userDetailsActions }, dispatch)
  );
  const usersDetails = useSelector(
    ({ usersDetailsReducer }) => usersDetailsReducer
  );
  const login = useSelector(({ loginReducer }) => loginReducer);

  const [modalVisible, setModalVisible] = useState(false);
  const classes = useStyles();
  const { id } = useParams();

  useEffect(() => {
    if(login.isAdmin) {
      if (id) actions.getUserDetails(id)
      else dispatch(push(paths.users))
    }
    else dispatch(push(paths.alerts))
  }, []);

  const handleSave = async () => {
    await unblockNavigation();
    actions.editUsersDetails(id, usersDetails);
  }
  const handleCancel = () => actions.cancelEditUser();
  const handleSelect = field => value => {
    switch (field) {
      case "admin":
        actions.addValueToUsersDetails(field, value === "true");
        break;
      default:
        actions.addValueToUsersDetails(field, value.target.value);
        break;
    }
  };

  const fields = [...usersDetails.form.fields];
  if (!login.isAdmin) fields.pop();
  else fields[6].value = JSON.stringify(usersDetails.admin);

  return (
    <>
      <Container className={classes.formContainer}>
        <Grid container className={classes.contentArea} direction="column">
          <Grid>
            <Breadcrumbs crumbs={usersDetails.crumbs} />
          </Grid>
          <Form
            {...usersDetails.form}
            fields={fields}
            reducerState={usersDetails}
            handleSelect={handleSelect}
          />
        </Grid>
      </Container>
      <BottomBar
        actions={{ handleSave, handleCancel }}
        {...usersDetails.bottomBar}
        reducerState={usersDetails} 
      />
      <Modal
        actions={actions}
        {...usersDetails.modal}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
      />
    </>
  );
};

export default UsersDetails;
