import React, { useState } from "react";
import { 
  makeStyles,
  fade,
  Grid,
  Button,
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails,
  InputAdornment
 } from "@material-ui/core";
import WarningTriangleIcon from '@qlik-trial/sprout/icons/WarningTriangle';
import TickIcon from '@qlik-trial/sprout/icons/Tick';
import { ExpandMore } from "@material-ui/icons";
import ConditionPanels from "../../ConditionPanels";
import NavTitle from "../../NavTitle";
import Table from "../../Table";
import useString from "../../../i18n/hooks/useString";
import TextField from "../../TextField";
import { formatResult } from "../../../utils/formatResult";
import TableHeader from "../../TableHeader";
import { COLORS } from "../../../utils/sproutOverrides";

const useStyles = makeStyles(theme => ({
  ruleRow: {
    display: 'flex',
    flex: '1 1 auto',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(0.5)
    }
  },
  actionButton: {
    margin: `${theme.spacing()}px ${theme.spacing(0.5)}px ${theme.spacing()}px ${theme.spacing(0.5)}px`
  },
  gridBody: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  grid: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  errorIcon: {
    color: theme.palette.error.main,
  },
  successIcon: {
    color: theme.palette.primary.main,
  },
  deleteButton: {
    margin: theme.spacing(1, 2, 2, 2),
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.light,
    },
    '&:active': {
      backgroundColor: theme.palette.error.dark,
    },
    '&.sprout-focus-visible': {
      borderColor: theme.palette.error.dark,
      boxShadow: `0 0 0 3px ${fade(theme.palette.error.light, 0.5)}`,
    },
  },
  summaryContainer: {
    display: 'flex',
    flex: '1 1 auto',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  summaryTextWrapper: {
    alignSelf: 'flex-start',
    flexDirection: 'column'
  },
  summaryErrorIcon: {
    color: theme.palette.error.main,
    marginRight: '56px',
  },
  ruleInputField: {
    width: '200px',
  },
  panelBox: {
    maxWidth: '500px',
    margin: '10px 0px',
  },
  panelOpen: {
    padding: theme.spacing(2),
  },
  selectorGrid: {
    paddingTop: '0px',
  }
}));

const RulePanels = ({ alertsDetails, actions, rules }) => {
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles();
  const getString = useString();

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return rules.map((each, index) => (
    <div className={classes.panelBox} key={`rule_expansion_panel_wrapper_${index}`}>
      <ExpansionPanel
        tid={`rule_expansion_panel_${index}`}
        expanded={expanded === `panel${index}`}
        onChange={handleChange(`panel${index}`)}
      >
        <ExpansionPanelSummary
          tid={`expansion_panel_rules_summary_${index}`}
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className={classes.summaryContainer}>
            <div className={classes.summaryTextWrapper}>
              <Typography tid={`typography_index_${index}`} variant="h5">
                {getString("Condition.Label.Rule", [index + 1])}
              </Typography>
              <Typography tid={`typography_title_${index}`} variant="body2">
                {each}
              </Typography>
            </div>
            {alertsDetails.warnings[index] && (
                <WarningTriangleIcon tid={`rule_error_${index}`} size="large" className={classes.summaryErrorIcon} />
            )}
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails tid={`expansion_panel_details_${index}`} className={`${classes.selectorGrid} ${classes.panelOpen}`}>
          <TextField
            tid={`text_field_edit_rules_${index}`}
            key={"text" + index}
            className={classes.ruleInputField}
            label={getString("Condition.Label.CustomRule")}
            value={each}
            onChange={e => actions.editConditionRule(index, e.target.value)}
            error={!!alertsDetails.warnings[index]}
            helperTextColor={COLORS.GREEN}
            helperText={alertsDetails.warnings[index] ? alertsDetails.warnings[index] : getString("Condition.Label.OkSyntax")}
            endAdornment={
              <InputAdornment position="end">
                {alertsDetails.warnings[index] ? (
                  <WarningTriangleIcon tid={`rule_error_${index}`} size="small" className={classes.errorIcon} />
                ) : (
                  <TickIcon size="small" tid={`rule_success_${index}`} className={classes.successIcon} />
                )}
              </InputAdornment>
            }
          />
        </ExpansionPanelDetails>
        <Button tid={`button_delete_${index}`} className={classes.deleteButton} variant="outlined" onClick={() => actions.deleteConditionRule(index)}>
          {getString("General.Delete")}
        </Button>
      </ExpansionPanel>
    </div>
  ));
}
const Condition = props => {
  const classes = useStyles();
  const { actions, alertsDetails, alertId } = props;
  const getString = useString();

  const handleCheck = () => actions.checkConditions(alertsDetails, alertId);

  const data = formatResult(
    alertsDetails.conditionResult.positive.slice(0, 10),
    alertsDetails.measures,
    alertsDetails.dimension
  );

  return (
    <Grid tid="grid_parent" container spacing={8} direction="column" justify="space-between" alignItems="stretch">
      <Grid tid="grid_body" className={classes.gridBody} container item xs spacing={8} style={{flex: '1 1 auto'}}>
        <Grid tid="grid_conditions_title" className={classes.grid} item xs>
          <div tid="div_conditions_title">
            <NavTitle tid="nav_title_conditions" title={getString("Condition.Label.Conditions")} description={"Condition.Label.Conditions.Description"} paragraph={false}/>
          </div>
          <ConditionPanels tid="condition_panels" {...props} />
        </Grid>
        <Grid tid="grid_conditions_title2" className={classes.grid} item xs>
          <div tid="div_rules_title">
            <NavTitle 
              tid="nav_title_rules"
              title={getString("Condition.Label.Rules")}
              subtitle={getString("AlertsDetails.Label.Optional")}
              description={getString("Condition.Label.Rules.Description")}
              paragraph={false}
            />
          </div>
          <RulePanels 
            alertsDetails={alertsDetails}
            actions={actions}
            rules={alertsDetails.conditionLogic}
          />
          <Button
            tid="button_add_condition_rule"
            variant="outlined"
            onClick={actions.addConditionRule}
          >
          {getString("Condition.Label.CreateRule")}
          </Button>
        </Grid>
      </Grid>
      <Grid tid="grid_preview_parent" className={classes.grid} item xs>
        {!alertsDetails.resultData.length && (
          <p tid="p_invalid_data_table" style={{ color: "red" }}>
            {getString("Condition.Label.InvalidDataTable")}
          </p>
        )}
        <NavTitle title={getString("DataTable.Label.Preview")} subtitle={getString("AlertsDetails.Label.Preview.FirstTenRows")} paragraph={false}/>
          <Grid tid="grid_table_item" item style={{ flexBasis: 'auto' }}>
            <Button
              tid="button_validate"
              variant="outlined"
              className={classes.actionButton}
              onClick={handleCheck}
              disabled={
                !alertsDetails.resultData.length ||
                !alertsDetails.resultData[0].length
              }
            >
              {getString("Condition.Label.ValidateConditions")}
            </Button>
            {alertsDetails.conditionResult.count ? (
              <>
                <TableHeader
                  leftToolbar={
                    <p tid="p_count">
                      {`${getString("Condition.TableHeader.Positive")}: ${alertsDetails.conditionResult.count.positive}`} (
                      {parseFloat(
                      alertsDetails.conditionResult.percentage.positive * 100
                    ).toFixed(1) + "%"}
                      ) {`${getString("Condition.TableHeader.Negative")}: ${alertsDetails.conditionResult.count.negative}`} (
                      {parseFloat(
                      alertsDetails.conditionResult.percentage.negative * 100
                    ).toFixed(1) + "%"}
                      ) {`${getString("Condition.TableHeader.Total")}: ${alertsDetails.conditionResult.count.total}`}
                  </p>
                  }
                />
                <Table
                  tid="table"
                  data={data}
                  {...alertsDetails.conditionTable}
                  style={{ header: { color: "green" } }}
                />
              </>
            ) : null}
          </Grid>
        </Grid>
    </Grid>
  );
};

export default Condition;
