import ActionTypes from "../../constants/actionTypes";
import API from "../../lib/api";
import { toSourceTable } from "../Sources/dataHandler";

export const getSources = ({ sort, reverse } = {}) => {
  return async dispatch => {
    try {
      const result = await API.getSources({ sort, reverse });
      const data = await toSourceTable(result);
      dispatch({
        type: ActionTypes.GET_SOURCES,
        payload: {
          data
        }
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: { snackMessage: typeof error === "string" ? error : JSON.stringify(error) }
      });
    }
  };
};
