import React from "react";
import {
  makeStyles,
  Grid,
  Container,
  FormControl,
  FormLabel,
} from "@material-ui/core";
import AdminCard from "./Card";
import SearchInput from "../../SearchInput";
import useString from '../../../i18n/hooks/useString';

const useStyles = makeStyles((theme) => ({
  fullWidthControl: {
    width: "100%",
  },
  marginTop: {
    marginTop: theme.spacing(),
  }
}));

const SelectAdmin = (props) => {
  const { actions, register } = props;
  const classes = useStyles();
  const getString = useString();

  return (
    <Container tid="container" style={{ width: "50%" }}>
      <Grid container tid="grid_parent">
        <Grid
          tid="grid_form_control"
          item
          md={12}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            tid="div_form_control"
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <FormControl tid="form_control" className={classes.fullWidthControl}>
              <FormLabel tid="form_label">
                {getString("SelectAdmin.FormControl.SearchBy")}
              </FormLabel>
              <SearchInput
                tid="search_input_select_admin"
                className={classes.marginTop}
                onApply={actions.findRegistrationUser}
              />
            </FormControl>
          </div>
          <Grid item tid="grid_admin_card" md="auto"style={{ width: "100%" }}>
            {register.findUser && (
              <AdminCard
                tid="admin_card"
                {...props}
                adminData={register.findUser}
                actions={actions}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SelectAdmin;
