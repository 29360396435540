import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";
import CloseIcon from '@material-ui/icons/Close';
import {
  fade,
  Button,
  Dialog,
  DialogTitle,
  Typography,
  IconButton,
  DialogContent,
  DialogActions
} from '@material-ui/core'
import { makeStyles } from "@material-ui/core/styles";
import useString from '../../i18n/hooks/useString';

const useStyles = makeStyles((theme) => ({
  smallIcon: {
    height: '16px',
    width: '16px',
  },
  redButton: {
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.light,
    },
    '&:active': {
      backgroundColor: theme.palette.error.dark,
    },
    '&.sprout-focus-visible': {
      borderColor: theme.palette.error.light,
      boxShadow: `0 0 0 3px ${fade(theme.palette.error.light, 0.5)}`,
    },
  },
}));


const propTypes = {
  actions: PropTypes.object,
  buttons: PropTypes.array,
  contentElement: PropTypes.object,
  content: PropTypes.string,
  modalVisible: PropTypes.bool,
  options: PropTypes.object,
  setModalVisible: PropTypes.func,
};

const defaultProps = {
  actions: {},
  buttons: [],
  options: {},
};

const MyModal = ({ actions, modalVisible, setModalVisible, buttons, content, contentElement, options }) => {
    const classes = useStyles();
    const getString = useString();
    const history = useHistory();

  const handleClose = () => {
    setModalVisible(false)
    if (options.onClose) {
      if (actions[options.onClose]) actions[options.onClose]()
      else options.onClose()
    }
  }

    const makeButton = (button) => {
        const { label, key, onClick, color, disabled, nav, variant, style } = button;
        let className;
        let buttonVariant = variant ? variant : 'contained';
        if(buttonVariant === "error") {
          className = classes.redButton;
          buttonVariant = 'contained';
        }
        const handleClick = () => {
            if (onClick) {
                if (actions[onClick]) {
                  actions[onClick]();
                } else {
                  onClick();
                }
            }
            if (nav && typeof nav === 'function') {
              history.push(nav());
            }
            setModalVisible(false)
        }
        if (nav) {
            return (
              <Button tid="button_if_nav" style={style} className={className} key={key} onClick={handleClick} variant={buttonVariant} color={color} disabled={disabled}>{getString(label)}</Button>
            )
        }
        else {
            return (
              <Button tid={`button_else_nav_${label || ''}`}  style={style} className={className} variant={buttonVariant} key={key} onClick={handleClick} color={color} disabled={disabled}>{getString(label)}</Button>
            )
        }
      }

    return (
      <Dialog
        tid="dialog"
        open={modalVisible}
        onClose={handleClose}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle tid="dialog_title" id="confirm-dialog-title">
          <Typography tid="typography_title" variant="h5">{getString(options.title)}</Typography>
          <IconButton
            tid="icon_button_close"
            onClick={handleClose}
          >
            <CloseIcon tid="close_icon" className={classes.smallIcon}/>
          </IconButton>
        </DialogTitle>
        <DialogContent tid="dialog_content">
          {content ? (
              <Typography tid="typography_title" variant="body2">
                {getString(content)}
              </Typography>
            ) : contentElement || null
          }
        </DialogContent>
        <DialogActions tid="dialog_actions">
          {buttons.map(button => makeButton(button))}
        </DialogActions>
      </Dialog>
    )
}

MyModal.propTypes = propTypes;
MyModal.defaultProps = defaultProps
export default MyModal
