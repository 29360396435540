import paths from '../../constants/paths';

export const crumbs = [
    {label: "Breadcrumbs.Admin"},
    {label: "Breadcrumbs.Management"},
    {label: "Breadcrumbs.Groups", link: paths.usersGroups},
    {label: "Breadcrumbs.Details"}
  ]

export const bottomBar = {
    options: {},
    buttons: {
        right: [
            {
                key: 'cancel',
                type: 'button',
                label: "General.Cancel",
                onClick: 'handleCancel',
                display: () => true
            },
            {
              key: "save",
              label: "General.Save",
              onClick: "handleSave",
              color: "primary",
              variant: "contained",
              disabled: "isSaveDisabled",
              display: () => true
            },
        ]
    }
}


export const modal = {
    options: {
        title: "General.Cancel"
    },
    content: "UsersGroupsDetails.Modal.CancelContent",
    buttons: [
        {
            key: 'notLeave',
            label: "General.Cancel",
            onClick: null,
            variant: 'outlined',
        },
        {
            key: 'leave',
            label: "General.Confirm",
            onClick: 'cancelEditUserGroup',
            color: 'primary'
        }
    ]
}

export const modalAssignAll = {
    options: {
      title: "UsersGroups.Modal.AssignAll.Title"
    },
    content: "UsersGroups.Modal.AssignAll.Content",
    buttons: [
      {
        key: "notLeave",
        label: "General.Cancel",
        onClick: null,
        variant: 'outlined',
      },
      {
        key: "leave",
        label: "General.Confirm",
        onClick: 'fullAssignation',
        color: "primary"
      }
    ]
  };
  
  export const modalUnassignAll = {
    options: {
      title: "UsersGroups.Modal.UnAssignAll.Title"
    },
    content: "UsersGroups.Modal.UnAssignAll.Content",
    buttons: [
      {
        key: "notLeave",
        label: "General.Cancel",
        onClick: null,
        variant: 'outlined',
      },
      {
        key: "leave",
        label: "General.Confirm",
        onClick: 'fullAssignation',
        color: "primary"
      }
    ]
  };