import BinIcon from '@qlik-trial/sprout/icons/Bin';
import Notification from "../../components/Steps/Notification";
import Distribution from "../../components/Steps/Distribution";
import Schedule from "../../components/Steps/Schedule";
import Condition from "../../components/Steps/Condition";
import DataTable from "../../components/Steps/DataTable";
import System from "../../components/Steps/System";
import { COLORS } from '../../utils/sproutOverrides';

export const tables = {
  measures: {
    fields: [
      {
        label: "AlertsDetails.Label.Measure",
        id: 'measure',
        getValue: row => `${row.label}${row.def ? ` - ${row.def}` : ''}`,
      },
      {
        label: "AlertsDetails.Label.Format",
        id: 'format',
        cellStyle: { color: COLORS.TEXT_SECONDARY },
        getValue: row => row.format,
      },
      {
        label: "General.Delete",
        type: 'icon',
        id: 'deleteMeasure',
        Icon: BinIcon,
        cellStyle: { padding: '0', textAlign: 'center' },
        onClick: 'deleteMeasure'
      }
    ]
  },
  filters: {
    fields: [
      {
        label: "AlertsDetails.Label.Field",
        id: 'field',
        style: { flex: 3 },
        getValue: row => row.field,
      },
      {
        label: "AlertsDetails.Label.Values",
        id: 'values',
        style: { flex: 6 },
        cellStyle: { color: COLORS.TEXT_SECONDARY },
        getValue: row => row.values.map(each => each.qText || '').join(', '),
      },
      {
        label: "General.Delete",
        type: 'icon',
        id: 'deleteFilter',
        Icon: BinIcon,
        cellStyle: { padding: '0', textAlign: 'center' },
        onClick: 'deleteFilter'
      }
    ]
  },
  times: {
    fields: [
      {
        label: "AlertsDetails.Label.Time",
        id: 'time',
        style: { flex: 3 },
        getValue: row => row.value
      },
      {
        label: "General.Delete",
        type: 'icon',
        id: 'deleteScheduleTime',
        Icon: BinIcon,
        cellStyle: { padding: '0', textAlign: 'center' },
        onClick: 'deleteScheduleTime'
      }
    ]
  },
  tasks: {
    options: {
      header: true,
      select: true,
      onSelect: "selectTasks"
    },
    fields: [
      {
        label: "AlertsDetails.Label.Task",
        sortBy: "name",
        id: 'task',
        getValue: row => row.name
      },
      {
        label: "AlertsDetails.Label.ApplicationName",
        sortBy: "applicationName",
        id: 'appName',
        getValue: row => row.applicationName
      },
      {
        label: "AlertsDetails.Label.LastReload",
        sortBy: "lastReload",
        id: 'lastReload',
        getValue: row => row.lastReload
      },
      {
        label: "AlertsDetails.Label.Last",
        sortBy: "last",
        id: 'last',
        getValue: row => row.last
      },
      {
        label: "AlertsDetails.Label.Duration(seconds)",
        sortBy: "duration",
        id: 'duration',
        getValue: row => row.duration
      }
    ]
  },
  rules: {
    options: {
      header: true,
      select: false,
    },
    fields: [
      {
        label: "AlertsDetails.Label.Task",
        sortBy: "name",
        id: 'task',
        getValue: row => row.name
      },
      {
        label: "AlertsDetails.Label.ApplicationName",
        sortBy: "applicationName",
        id: 'appName',
        getValue: row => row.applicationName
      },
      {
        label: "AlertsDetails.Label.LastReload",
        sortBy: "lastReload",
        id: 'lastReload',
        getValue: row => row.lastReload
      },
      {
        label: "AlertsDetails.Label.Last",
        sortBy: "last",
        id: 'last',
        getValue: row => row.last
      },
      {
        label: "AlertsDetails.Label.Duration(seconds)",
        sortBy: "duration",
        id: 'duration',
        getValue: row => row.duration
      }
    ]
  }
}

export const bottomBar = {
  options: {},
  buttons: {
    right: [
      {
        key: "previous",
        type: "button",
        label: "General.Previous",
        onClick: "handlePrevious",
        display: "hasPrevious"
      },
      {
        key: "next",
        type: "button",
        label: "General.Next",
        onClick: "handleNext",
        color: "primary",
        variant: "contained",
        display: "hasNext"
      },
      {
        key: "save",
        type: "button",
        label: "General.Save",
        onClick: "handleSave",
        disabled: "saveDisabled",
        color: "primary",
        variant: "contained",
        display: "hasSave"
      }
    ]
  }
};

export const steps = {
  data: [
    { index: 0, 
      label: "AlertsDetails.Label.DataTable",
      key: "data", 
      component: DataTable 
    },
    { index: 1, 
      label: "AlertsDetails.Label.Condition",
      key: "condition", 
      component: Condition 
    },
    { index: 2, 
      label: "AlertsDetails.Label.Schedule",
      key: "schedule", 
      component: Schedule 
    },
    { index: 3, 
      label: "AlertsDetails.Label.Distribution",
      key: "distribution", 
      component: Distribution 
    },
    { index: 4, 
      label: "AlertsDetails.Label.Notification",
      key: "notification", 
      component: Notification 
    }
  ],
  system: [
    { 
      index: 0, 
      label: "AlertsDetails.Label.System",
      key: "system", 
      component: System 
    },
    {
      index: 1,
      label: "AlertsDetails.Label.Distribution",
      key: "distribution",
      component: Distribution
    }
  ],
  broadcast: [
    {
      index: 0,
      label: "AlertsDetails.Label.Notification",
      key: "notification",
      component: Notification
    },
    {
      index: 1,
      label: "AlertsDetails.Label.Distribution",
      key: "distribution",
      component: Distribution
    },
    { 
      index: 2, 
      label: "AlertsDetails.Label.Schedule",
      key: "schedule", 
      component: Schedule
     }
  ]
};

export const operators = [
  { name: "Condition.Label.Operator.GreaterThan", value: "gt" },
  {
    name: "Condition.Label.Operator.GreaterThanOrEqual",
    value: "gte"
  },
  { name: "Condition.Label.Operator.LessThan", value: "lt" },
  { name: "Condition.Label.Operator.LessThanOrEqual", value: "lte" },
  { name: "Condition.Label.Operator.Equal", value: "eq", text: true },
  { name: "Condition.Label.Operator.NotEqual", value: "ne", text: true },
  { name: "Condition.Label.Operator.Includes", value: "in", text: true },
  { name: "Condition.Label.Operator.StartsWith", value: "sw", text: true },
  { name: "Condition.Label.Operator.EndsWith", value: "ew", text: true }
]

export const selectors = {
  app: {
    name: "app",
    getOptionLabel: input => (input ? input.name : ""),
    getValue: (options, value) => options.find(one => one.id === value) || '',
    groupBy: options => options.stream,
  },
  sheet: {
    name: "sheet",
    getOptionLabel: input => (input ? input.name : ""),
    getValue: (options, value) => options.find(one => one.id === value) || '',
    groupBy: options => options.status
  },
  measure: {
    name: "measure",
    getOptionLabel: input => (input ? input.label : ""),
    getValue: (options, value) => value ? options.find(one => one.id === value.id) : ''
  },
  format: {
    name: "format",
    getOptionLabel: input => (input ? input.name : ""),
    getValue: (options, value) => options.find(one => one.value === value) || '',
    options: [
      { value: "Auto", name: "Auto" },
      { value: "1,000", name: "1,000" },
      { value: "1,000.0", name: "1,000.0" },
      { value: "1,000.12", name: "1,000.12" },
      { value: "12%", name: "12%" },
      { value: "12.3%", name: "12.3%" },
      { value: "12.34%", name: "12.34%" }
    ]
  },
  dimension: {
    name: "dimension",
    getOptionLabel: input => (input ? input.label || input.def : ""),
    getValue: (options, value) => value ? options.find(one => value.id && one.id ? (one.id === value.id) : (one.def === value.def)) : '',
    groupBy: options => options.type,
  },
  bookmark: {
    name: "bookmark",
    getOptionLabel: input => (input ? input.name : ""),
    getValue: (options, value) => options.find(one => one.id === value) || '',
  },
  field: {
    name: "field",
    getOptionLabel: input => (input ? `${input.def} (${input.distinctValues} distinct)` : ""),
    getValue: (options, value) => value ? options.find(one => one.def === value.def) : '',
  },
  fieldValues: {
    name: "fieldValues",
    multiple: true,
    getOptionLabel: input => (input?.qText ? input.qText : ""),
    getValue: (options, values) => options.filter(one => {
      if (one.qNum === 'NaN') return values.map(each => each.qText || '').includes(one.qText)
      else return values.map(each => each.qNum).includes(one.qNum)
    }) || ''
  },
  sortAscending: {
    name: "sortAscending",
    // label: "DataTable.Label.SortOrder",
    getOptionLabel: input => (input ? input.name || input : ""),
    getValue: (options, value) => options.find(one => one.value === value) || '',
    options: [
      { name: "DataTable.Label.Ascending", value: true },
      { name: "DataTable.Label.Descending", value: false }
    ]
  },
  column: {
    label: "Condition.Label.Column",
    getOptionLabel: input => (input ? input.label || input.def : ""),
    getValue: (options, value) => options[value] || ''
  },
  operator: {
    label: "Condition.Label.Operator",
    getOptionLabel: input => (input ? input.name || input : ""),
    getValue: (options, value) => options.find(one => one.value === value) || '',
    options: operators
  },
  conditionType: {
    label: "Condition.Label.Type",
    getOptionLabel: input => (input ? input.name || input : ""),
    getValue: (options, value) => options.find(one => one.value === value) || '',
    options: [
      { name: "Condition.Label.Type.ManualValue", value: "manual" },
      { name: "Condition.Label.Type.Measure", value: "measure" },
      { name: "Condition.Label.Type.PreviousScans", value: "history" },
      {
        name: "Condition.Label.Type.Set",
        value: "set"
      }
    ]
  },
  aggregation: {
    label: "Condition.Label.Aggregation",
    getOptionLabel: input => (input ? input.name || input : ""),
    getValue: (options, value) => options.find(one => one.value === value) || ''
  },
  scans: {
    label: "Condition.Label.Scans",
    getOptionLabel: input => "" + input,
    getValue: (options, value) => value || '',
    options: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  },
  dayOfWeek: {
    name: "dayOfWeek",
    label: "Schedule.Label.DaysOfTheWeek",
    multiple: true,
    getOptionLabel: input => (input ? input.name || input : ""),
    getValue: (options, values) => options.filter(one => values.includes(one.value)) || '',
    options: [
      { name: 'Schedule.Label.DaysOfTheWeek.Monday', value: 1 },
      { name: 'Schedule.Label.DaysOfTheWeek.Tuesday', value: 2 },
      { name: 'Schedule.Label.DaysOfTheWeek.Wednesday', value: 3 },
      { name: 'Schedule.Label.DaysOfTheWeek.Thursday', value: 4 },
      { name: 'Schedule.Label.DaysOfTheWeek.Friday', value: 5 },
      { name: 'Schedule.Label.DaysOfTheWeek.Saturday', value: 6 },
      { name: 'Schedule.Label.DaysOfTheWeek.Sunday', value: 0 }
    ]
  },
  dayOfMonth: {
    name: "dayOfMonth",
    label: "Schedule.Label.DaysOfTheMonth",
    multiple: true,
    getOptionLabel: input => "" + input,
    getValue: (options, values) => values || '',
    options: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]
  },
  channels: {
    name: "channels",
    label: "Distribution.Label.Channels",
    multiple: true,
    getOptionLabel: input => (input ? input.name || input : ""),
    getValue: (options, values) => options.filter(one => values.includes(one.value)) || '',
    options: [
      { name: "AlertsDetails.Label.Email", value: "email" },
      { name: "AlertsDetails.Label.Mobile", value: "mobile" }
    ]
  },
  frequency: {
    name: "frequency",
    label: "Distribution.Label.Frequency",
    getOptionLabel: input => (input ? input.name || input : ""),
    getValue: (options, value) => options.find(one => one.value === value) || '',
    options: [
      { name: "AlertsDetails.Label.Frequency.Every", value: "always" },
      { name: "AlertsDetails.Label.Frequency.Hour", value: "hour" },
      { name: "AlertsDetails.Label.Frequency.Day", value: "day" },
      { name: "AlertsDetails.Label.Frequency.Week", value: "week" },
      { name: "AlertsDetails.Label.Frequency.Month", value: "month" },
      { name: "AlertsDetails.Label.Frequency.Once", value: "once" }
    ]
  },
  systemTaskOperators: {
    label: "System.Label.Operator",
    getOptionLabel: input => (input ? input.name || input : ""),
    getValue: (options, value) => options.find(one => one.value === value) || '',
    options: [
      { name: "System.Label.Operator.all", value: "$all", text: true },
      { name: "System.Label.Operator.Includes", value: "$in", text: true },
      { name: "System.Label.Operator.StartsWith", value: "$sw", text: true },
      { name: "System.Label.Operator.EndsWith", value: "$ew", text: true }
    ]
  }
};

export const uploader = {
  options: {
    onUpload: "uploadTemplate",
    //onClear: "clearTemplate"
  },
  files: [
    {
      label: "AlertsDetails.Label.HtmlTemplate",
      key: "template",
      type: ["text/html","hjs"]
    }
  ]
};
