import ActionTypes from "../../constants/actionTypes";
import * as definitions from "./definitions";
import { shouldCleanup } from '../../utils/navigationCleanup';

const initialState = {
  ...definitions,
  data: {},
  dataIds: [],
  selectedIds: [],
  dialogOpen: false,
  dialogText: ""
};

export default function usersPrivilegesReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_USER_PRIVILEGES:
      return {
        ...state,
        data: action.payload.data,
        dataIds: action.payload.data.map(each => each.id)
      };
    case ActionTypes.LOCATION_CHANGE:
        return shouldCleanup(action.payload) ? {...initialState} : state;
    default:
      return state;
  }
}
