import React from "react";
import Radio from "../Radio";
import { makeStyles, Grid, FormControl, FormLabel, OutlinedInput } from "@material-ui/core";
import { CONSTANTS } from '../../constants/constant';
import useString from "../../i18n/hooks/useString";


const useStyles = makeStyles((theme) => ({
  contentArea: {
    maxHeight: '100%',
    flexWrap: 'nowrap',
  },
  fullWidthControl: {
    width: '100%',
  },
  marginTop: {
    marginTop: theme.spacing(),
  },
  helperText: {
    fontWeight: "normal",
    marginTop: "0.4em",
    marginLeft: "0.4em"
},
}));

const MyForm = props => {
  const { handleSelect, fields, reducerState, submitOnEnter } = props;
  const getString = useString();

  const keyPress = (e) => {
    if (submitOnEnter && e.keyCode === CONSTANTS.KEYS.ENTER) submitOnEnter()
  }

  const classes = useStyles();
  const getField = (field, i) => {
    try {
      const { name, type, label, helperText, display, ...rest } = field;
      const value = reducerState[name];
      switch (type) {
        case "radio":
          return (
            <>
              {label
                ? <FormLabel tid="form_label"> {getString(label)} </FormLabel>
                : null}
              <Radio
                tid="radio"
                key={name}
                label={getString(label)}
                {...rest}
                value={
                  typeof value === "boolean"
                    ? JSON.stringify(value) || "false"
                    : value
                }
                onChange={handleSelect(name)}
              />
            </>
          );
        default:
          const qaDisplay = typeof display === 'undefined' ? true : display
          return (
            <>
            { qaDisplay ? 
            <FormControl tid="form_control" className={classes.fullWidthControl} >
              <FormLabel tid="form_label"> {getString(label)} </FormLabel>
              <OutlinedInput
                tid="outlined_input"
                {...rest}
                value={value || ""}
                key={name}
                type={type}
                onKeyDown={i === fields.length - 1 ? keyPress : null}
                className={classes.marginTop}
                onChange={handleSelect(name)}
                fullWidth
                style={{ flexBasis: 'auto'}}
              />
              <FormLabel className={classes.helperText}>{getString(helperText)} </FormLabel>
            </FormControl>
            : null}
            </>
          );
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Grid container tid="grid_fields" className={classes.contentArea} direction="column">
      {fields.map((each, i) => (
        <Grid item tid={`grid_fields_${i}`} xs style={{ flexBasis: 'auto', marginBottom: "1.2em" }} key={i}>
          {getField(each, i)}
        </Grid>
      ))}
    </Grid>
  );
};

export default MyForm;
