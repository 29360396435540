import { makeStyles } from "@material-ui/core/styles";
import { COLORS } from "../../utils/sproutOverrides";

export const useStyles = makeStyles((theme) => ({
  aboutPageLine: {
    color: "rgba(255, 255, 255, 0.6)",
    marginTop: "1em",
    marginBottom: "1em",
  },
  cardDeckLayout: {
    display: "flex",
    justifyContent: "center",
  },
  conditionContainer: {
    paddingBottom: "20vh",
  },
  configForm: {
    width: "50vw",
    maxWidth: 500,
  },
  container404: {
    paddingTop: 120,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  container404button: {
    paddingBottom: 50,
  },
  containerConditions: {
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  contentArea: {
    maxHeight: "100%",
    flexWrap: "nowrap",
  },
  copyright: {
    marginTop: "2em",
  },
  dataTableAscending: {
    flexGrow: 1,
    marginLeft: "10px",
  },
  dataTableRefresh: {
    marginBottom: "25px",
    display: "flex",
    alignItems: "center",
  },
  fileUpload: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "10em",
  },
  forgotPassLink: {
    paddingTop: 15,
    paddingBottom: 15,
  },
  form: {
    backgroundColor: COLORS.WHITE,
  },
  formAndUpload: {
    display: "flex",
    alignItems: "flex-start",
    marginTop: "2em",
  },
  formContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "auto",
    flexDirection: "column",
    alignItems: "center",
    width: "70vw",
    marginTop: "5vh",
  },
  iconsBox: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  iconsPanel: {
    marginRight: "5px",
    fontSize: "1.3rem",
    color: "#757575",
  },
  inboxSystemContainer: {
    paddingTop: "20px",
    overflow: "auto",
  },
  logoTitle: {
    color: "#4F4F4F",
    paddingLeft: "1em",
    fontSize: "1.2em",
    textDecoration: "none",
  },
  mainButtons: {
    fontWeight: "bold",
  },
  menuButtons: {
    textDecoration: "none",
  },
  modalButtons: {
    paddingLeft: "0.5vw",
    textDecoration: "none",
  },
  modalButtonsDiv: {
    display: "flex",
    justifyContent: "center",
    padding: "1em",
  },
  modalContent: {
    padding: "1em",
    marginTop: "-40px",
    maxHeight: "40vh",
  },
  modalContentTitle: {
    padding: "1em",
    maxHeight: "40vh",
  },
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  modalHeaderClose: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: "-200px",
  },
  modalNoButton: {
    marginLeft: "0.5em",
  },
  modalParent: {
    position: "fixed",
    zIndex: "500",
    backgroundColor: "white",
    width: "50%",
    border: "1px solid #ccc",
    boxShadow: "1px 1px 1px black",
    padding: "16px",
    left: "25%",
    top: "30%",
    boxSizing: "border-box",
    transition: "all 0.3s ease-out",
    outline: "none",
  },
  modalTitle: {
    display: "flex",
    alignItems: "flex-end",
  },
  myForm: {
    backgroundColor: "red",
  },
  navButtons: {
    justifyContent: "flex-end",
  },
  navButtonDiv: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexGrow: 1,
    color: "white",
    paddingLeft: "1vw",
  },
  navIcon: {
    color: COLORS.GREYSCALE_40,
    margin: "0 0.3em",
  },
  navTopLinks: {
    textDecoration: "none",
    color: "#404040",
    lineHeight: "16px",
    fontSize: "15px",
    fontWeight: "bold",
  },
  notificationText: {
    paddingTop: "2vh",
    paddingBottom: "10vh",
    width: "80vw",
    height: "88vh",
    overflowY: "hidden",
  },
  refreshAppsButton: {
    flexGrow: 1,
    maxWidth: "35px",
  },
  refreshAppsGrid: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
  },
  simpleCardContainer: {
    borderWidth: "1px",
    borderColor: "gray",
  },
  searchTabs: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  sectionMobileRight: {
    display: "flex",
    alignItems: "center",
    marginRight: "1em",
  },
  scanTimeButton: {
    marginLeft: "2em",
  },
  stepsSystemCheckbox: {
    display: "flex",
    alignItems: "center",
  },
  stepsDataParent: {
    width: "90vw",
  },
  tableContainer: {
    flex: "1 1 auto",
    overflowY: "auto",
  },
  tablesScroll: {
    overflowX: "hidden",
    overflowY: "auto",
  },
  tab: {
    fontWeight: "bold",
    minWidth: "5em",
    color: COLORS.GREYSCALE_25,
  },
  adminTab: {
    color: COLORS.TEXT_PRIMARY,
    height: '24px',
    paddingBottom: '6px',
    '& .MuiTab-wrapper': {
      paddingBottom: '2px',
      direction: 'rtl',
    }
  },
  triggeredHistoryTitle: {
    margin: "20px",
  }
}));
