export const form = {
  options: {},
  fields: [
    {
      name: "oldPassword",
      id: "oldPassword",
      label: "ChangePassword.Label.OldPassword",
      type: "password",
    },
    {
      name: "newPassword",
      id: "newPassword",
      label: "ChangePassword.Label.NewPassword",
      type: "password"
    },
    {
      name: "confirmPassword",
      id: "confirmPassword",
      label: "ChangePassword.Label.ConfirmNewPassword",
      type: "password"
    }
  ]
};

export const bottomBar = {
  options: {},
  buttons: {
    right: [
      {
        key: "cancel",
        type: "button",
        label: "General.Cancel",
        onClick: "handleCancel",
        display: () => true
      },
      {
        key: "change",
        type: "button",
        label: "ChangePassword.Button.ChangePassword",
        onClick: "handleSave",
        variant: 'contained',
        color: 'primary',
        display: () => true
      },
    ]
  }
};

export const modal = {
  options: {
    title: 'General.Cancel'
  },
  content: "ChangePassword.Modal.CancelContent",
  buttons: [
    {
      key: "notLeave",
      label: 'General.Cancel',
      onClick: null,
      variant: 'outlined',
    },
    {
      key: "leave",
      label: 'General.Confirm',
      onClick: "cancelChangePassword",
      color: "primary"
    }
  ]
}
