import React, { useState } from 'react';
import { Button, GridList, GridListTile, Typography, Link } from '@material-ui/core';
import Logo from "../../Qlik-logo_80.png";
import { thirdPartySoftware } from '../../data/thirdPartySoftware';
import { useStyles } from '../../constants/styles';
import useString from '../../i18n/hooks/useString';


const About = () => {
  const classes = useStyles();
  const getString = useString();
  const [aboutContentVisible, setAboutContentVisible] = useState(true);

  const MainContent = () => {
    if (aboutContentVisible) {
      return (
        <>
          <Typography tid="p_company_details" className={classes.copyright} paragraph>
            {getString('About.Text.CompanyDetails')}
            <br/>
            <Link variant="body2" tid="a_qlik_text_link" href='https://www.qlik.com/' target="_blank" rel="noopener noreferrer">
              {getString('About.Link.Qlik')}
            </Link>  
          </Typography>
          <Typography tid="p_qlik_text_help">
            {getString('About.Text.Help')}
            <Link variant="body2" tid="a_qlik_text_help" href='https://help.qlik.com/en-US/alerting' target="_blank" rel="noopener noreferrer">
              {getString('About.Link.Help')}
            </Link>
          </Typography>
        </>
      )
    } else {
      return (
        <>
          <h3 tid="h3_third_party_title">{getString('About.Text.ThirdPartyTitle')}</h3>
          <p tid="p_text_thrid_party">{getString('About.Text.ThirdParty')}</p>
          <GridList tid="grid_list" cellHeight={30} cols={6}>
            {thirdPartySoftware.map(item => (
              <GridListTile tid="grid_list_tile" key={item.name} cols={thirdPartySoftware.length}>
                <a tid="a_item_name" target="_blank" rel="noopener noreferrer" href={item.url}>{item.name}</a>
              </GridListTile>
            ))}
          </GridList>
        </>
      )
    }
  }

  return (
    <>
      <div tid="div_about_logo">
        <img tid="img_about_logo" style={{ height: "1.7em" }} src={Logo} alt='' />
      </div>
      <hr tid="hr_about_page_line" className={classes.aboutPageLine} />
      {aboutContentVisible &&
        <>
          <Typography tid="h4_qlik_alerting_april" variant="h6">
            {getString("About.Text.QlikAlertingRelease")}
          </Typography>
          <Typography tid="p_about_version" variant="body2" paragraph>
            {getString("About.Text.Version")}
          </Typography>
        </>
      }
      <div tid="div_modal_title" className={classes.modalTitle}>
        <Button tid="button_about" variant="outlined" onClick={() => setAboutContentVisible(true)}>
          <div tid="div_about" id="modal-title">{getString('About.Button.About')}</div>
        </Button>
        <Button tid="button_third_party" style={{ marginLeft: '0.75em' }} variant="outlined" onClick={() => setAboutContentVisible(false)}>
          <div tid="div_third_party" id="modal-title">{getString('About.Button.ThirdParty')}</div>
        </Button>
        <Button tid="button_license_pdf" style={{ marginLeft: '0.75em' }} variant="outlined" href={process.env.PUBLIC_URL + "/QULA English.pdf"} target="_blank">
          <div tid="div_third_party" id="modal-title">{getString('About.Button.LicensePDF')}</div>
        </Button>
      </div>
      <MainContent />
    </>
  )
}

export default About;
