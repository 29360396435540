import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { COLORS } from "../../utils/sproutOverrides";
import { makeStyles } from "@material-ui/core/styles";
import Logo from "../../Qlik-logo_80.png";
import useString from "../../i18n/hooks/useString";

const useStyles = makeStyles(theme => ({
  unsubscribeGrid: {
    alignContent: "center",
    backgroundColor: "#F7F7F7",
    height: "100%",
    width: "100%",
  },
  unsubscribeCard: {
    display: "flex",
    flexFlow: "column",
    backgroundColor: COLORS.WHITE,
    boxSizing: "border-box",
    boxShadow: "0 1px 2px 0 rgba(40,40,40,0.16)",
    borderRadius: "3px",
    alignItems: "center",
    height: "auto",
    width: "350px",
    maxWidth: "350px",
    padding: theme.spacing(4, 6, 14),
    margin: `${theme.spacing(14)}px auto`,
  },
  unsubscribeHeader: {
    fontWeight: "bold",
    marginBottom: theme.spacing(2)
  },
  unsubscribeLogo: {
    marginBottom: theme.spacing(6)
  }
}));

const Unsubscribe = () => {
  const classes = useStyles();
  const getString = useString();

  return (
    <Grid tid="grid_unsubscribe" className={classes.unsubscribeGrid}>
      <div className={classes.unsubscribeCard}>
        <div tid="div_unsubscribe_logo" 
        className={classes.unsubscribeLogo}
        >
          <img
            tid="img_qlik_logo"
            src={Logo}
            style={{ width: 120 }}
            alt="Qlik logo"
          />
        </div>

        <Typography variant="h5" color="textSecondary"
          tid="h4_unsubscribe_confirmation"
          className={classes.unsubscribeHeader}
        >
          {getString("Unsubscribe.String.SuccessfullyUnsubscribed")}
        </Typography>
        <Typography variant="body2" color="textSecondary"
          tid="h5_unsubscribe_close_page"
        >
          {getString("Unsubscribe.String.ClosePage")}
        </Typography>
      </div>
    </Grid>
  );
};

export default Unsubscribe;
