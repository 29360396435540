import ActionTypes from "../../constants/actionTypes";
import API from "../../lib/api";
import { toUserManagement } from "./dataHandler";

export const getUserList = ({ offset = 0, size = 50, overwrite, filter, sort, reverse } = {}) => {
  return async dispatch => {
    try {
      const filterString = filter ? `name $so ${filter} $or firstName $so ${filter} $or lastName $so ${filter} $or domain $so ${filter} $or channels.email.connection $so ${filter} $or license $so ${filter}` : '';
      const result = await API.getUsers({ offset, size, filter: filterString, sort, reverse });
      const data = await toUserManagement(result);
      dispatch({
        type: ActionTypes.SHOW_MORE_USERS,
        payload: data.length < size
      });
      if (overwrite) {
        dispatch({
          type: ActionTypes.RESTART_USERS,
          payload: data
        })
      } else {
        dispatch({
          type: ActionTypes.GET_USER_LIST,
          payload: data
        });
      }
      if (offset) dispatch({
        type: ActionTypes.SNACK_SUCCESS,
        payload: { 
          snackMessage: "Snackbar.Users.showMore",
          replacementArray: [data.length]
         }
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: { snackMessage: typeof error === "string" ? error : JSON.stringify(error) }
      });
    }
  };
};

export const resetPassword = (selected, userId) => {
  return async dispatch => {
    try {
      if (selected.includes(userId)) {
        dispatch({
          type: ActionTypes.SNACK_WARNING,
          payload: { snackMessage: "Snackbar.Users.resetPasswordWarning" }
        });
      } else {
        const allSettled = promises => Promise.all(promises.map(p => p.then(result => ({ status: 'fulfilled', result })).catch(error => ({ status: 'rejected', error }))))
        const response = await allSettled(selected.map(each => API.resetPassword(each)))
        const unfulfilled = response.filter(i => i.status !== "fulfilled")
        if (unfulfilled.length) {
          dispatch({
            type: ActionTypes.SNACK_WARNING,
            payload: { snackMessage: "Snackbar.Users.resetPasswordError", replacementArray: [unfulfilled.length] }
          });
        } else {
          dispatch({
            type: ActionTypes.SNACK_SUCCESS,
            payload: { snackMessage: "Snackbar.Users.resetPassword" }
          });
        }
      }
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: { snackMessage: typeof error === "string" ? error : JSON.stringify(error) }
      });
    }
  };
};

export const deleteUsers = (selected, userId) => {
  return dispatch => {
    try {
      if (selected.includes(userId)) {
        dispatch({
          type: ActionTypes.SNACK_WARNING,
          payload: { snackMessage: "Snackbar.Users.deleteUsersWarning" }
        });
      } else {
        selected.forEach(each => API.deleteUser(each));
        dispatch({
          type: ActionTypes.DELETE_USERS,
          payload: selected
        });
        dispatch({
          type: ActionTypes.SNACK_SUCCESS,
          payload: { snackMessage: "Snackbar.Users.deleteUsers" }
        });
      }
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: { snackMessage: typeof error === "string" ? error : JSON.stringify(error) }
      });
    }

  };
};

export const toggleUser = (id, bool) => {
  return async dispatch => {
    try {
      await API.toggleUser(id, bool);
      dispatch({
        type: ActionTypes.TOGGLE_USER,
        payload: {
          id,
          bool
        }
      });
      dispatch({
        type: ActionTypes.SNACK_SUCCESS,
        payload: { snackMessage: bool
          ? "Snackbar.Users.enabled"
          : "Snackbar.Users.disabled"
       }
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: { snackMessage: typeof error === "string" ? error : JSON.stringify(error) }
      });
    }
  };
};

export const syncQlikUsers = () => {
  return async dispatch => {
    try {
      await API.syncLicense();
      await Promise.all([API.syncUsers(),API.syncAllocations()]);
      dispatch({
        type: ActionTypes.SNACK_SUCCESS,
        payload: { snackMessage: "Snackbar.Users.syncQlikUsers" }
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: { snackMessage: typeof error === "string" ? error : JSON.stringify(error) }
      });
    }
  };
};

export const hideRestPassword = () =>{
  return async dispatch =>{
    dispatch({
      type : ActionTypes.RESET_PASSWORD_BTN_HIDE
    })
  }
}
