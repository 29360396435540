import axios from "axios";
import config from "../constants/config";
import { store } from "../index";
import ActionTypes from "../constants/actionTypes";

const { host } = config();

const sendRequest = async (method, endpoint, params, data, inputHeaders) => {
  try {
    const url = host + endpoint;
    const { userId, token } = localStorage;
    const headers = {
      UserId: userId,
      Authorization: token,
      Pragma: 'no-cache',
      'Cache-Control': 'no-cache',
      ...inputHeaders,
    };
    store.dispatch({
      type: ActionTypes.SET_LOADING,
      payload: true
    });
    const response = await axios({
      method: method.toLowerCase(),
      url,
      data,
      params,
      headers
    });
    store.dispatch({
      type: ActionTypes.SET_LOADING,
      payload: false
    });

    return response.data;
  } catch (err) {
    if (err.message.includes("401")) {
      localStorage.clear();
      store.dispatch({
        type: ActionTypes.NAVIGATION_UNBLOCK
      });
      store.dispatch({
        type: ActionTypes.LOGOUT
      });
    }
    store.dispatch({
      type: ActionTypes.SET_LOADING,
      payload: false
    });
    if (err.response) throw err.response.data?.error ? err.response.data.error : err.response.data
    if (err.message) throw err.message
    throw err;
  }
};

export default sendRequest;
