import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  OutlinedInput,
  Select,
  Tooltip
 } from "@material-ui/core";

export const CONSTANTS = {
  KEYS: {
    ENTER: 13
  },
  VARIANT: {
    TEXTINPUT: 'textinput',
    SELECT: 'select',
  }
};

const propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  onApply: PropTypes.func.isRequired,
  onEnter: PropTypes.func,
};

const defaultProps = {
  value: '',
  variant: CONSTANTS.VARIANT.TEXTINPUT,
};

const BufferedInput = ({
  onApply, title, tooltipProps, value, variant, onEnter, ...rest
}) => {
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const handleBlur = () => {
    onApply(currentValue);
  };

  const handleChange = (event) => {
    setCurrentValue(event.target.value);
  }

  const handleKeyUp = (event) => {
    if (event.keyCode === CONSTANTS.KEYS.ENTER) {
      onApply(currentValue);
      if(onEnter) {
        onEnter();
      }
    }
  };

  const renderInput = () => {
    switch(variant) {
      case CONSTANTS.VARIANT.SELECT:
        const handleSelectChange = (event) => {
          handleChange(event);
          onApply(event.target.value);
        }
        return (
          <Select
            tid="select"
            id={`input_${variant}_select`}
            {...rest}
            onChange={handleSelectChange}
            value={currentValue}
          />
        )
      default:
      case CONSTANTS.VARIANT.TEXTINPUT:
          return (
            <OutlinedInput
              tid="oulined_input"
              id={`input_${variant}_text`}
              {...rest}
              onBlur={handleBlur}
              onChange={handleChange}
              onKeyUp={handleKeyUp}
              value={currentValue}
            />
          )
    }
  }

  return (
    title ? (
      <Tooltip title={title} {...tooltipProps} tid="WrappedTextField__Tooltip">
        {renderInput()}
      </Tooltip>
    ) : renderInput()
  );
};

BufferedInput.propTypes = propTypes;
BufferedInput.defaultProps = defaultProps;
export default BufferedInput;
