import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as suggestionsActions from "./actions";
import Table from "../../components/Table";
import BreadcrumbsHeader from "../../components/BreadcrumbsHeader";
import { Container, Button, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useString from "../../i18n/hooks/useString";
import TableHeader from "../../components/TableHeader";
import SearchInput from "../../components/SearchInput";

const useStyles = makeStyles((theme) => ({
  container: {
    overflowY: "hidden",
    flex: "1 1 auto",
  },
  tableWrapper: {
    overflowX: "hidden",
    overflowY: "auto",
    paddingBottom: 50,
  },
  contentArea: {
    marginTop: theme.spacing(4),
    maxHeight: "100%",
    flexWrap: "nowrap",
  },
  searchActionBar: {
    flex: "1 1 auto",
    display: "flex",
    justifyContent: "flex-end",
  },
  selectedLabel: {
    paddingLeft: theme.spacing(),
  },
}));

const Suggestions = () => {
  const dispatch = useDispatch();
  const { current: actions } = useRef(
    bindActionCreators({ ...suggestionsActions }, dispatch)
  );
  const suggestions = useSelector(
    ({ suggestionsReducer }) => suggestionsReducer
  );
  const classes = useStyles();
  const getString = useString();
  const [selected, setSelected] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    actions.getSuggestions({ filter: searchInput });
  }, [actions]);

  const showMore = () =>
    actions.getSuggestions({
      offset: suggestions.dataIds.length,
      filter: searchInput,
    });

  const sortField = (fieldName) => {
    actions.getSuggestions({
      filter: searchInput,
      sort: fieldName,
      overwrite: true,
    });
  };

  const handleSearchApply = (value) => {
    actions.getSuggestions({
      offset: 0,
      filter: value,
      overwrite: true,
    });
    setSearchInput(value);
  };

  const handleAccept = () => {
    actions.acceptSuggestion(selected);
    setSelected([]);
  };

  const handleReject = () => {
    actions.rejectSuggestion(selected);
    setSelected([]);
  };

  const leftTableActions = (
    <>
      <SearchInput tid={'search_input_suggestions'} onApply={handleSearchApply} />
      {!!selected.length && (
        <span className={classes.selectedLabel}>
          <Typography variant="body2">
            {`${getString("General.Selected")}: ${selected.length}`}
          </Typography>
        </span>
      )}
    </>
  );

  const rightTableActions = (
    <>
      <Button
        variant="outlined"
        size="medium"
        onClick={handleAccept}
        disabled={!selected.length}
      >
        {getString("General.Accept")}
      </Button>
      <Button
        variant="outlined"
        size="medium"
        onClick={handleReject}
        disabled={!selected.length}
      >
        {getString("General.Reject")}
      </Button>
    </>
  );

  return (
    <Container className={classes.container}>
      <Grid container className={classes.contentArea} direction="column">
        <BreadcrumbsHeader crumbs={suggestions.crumbs} />
      </Grid>
      <TableHeader
        leftToolbar={leftTableActions}
        rightToolbar={rightTableActions}
      />
      <Grid item className={classes.tableWrapper}>
        <Table
          selectedIds={selected}
          actions={{ ...actions, setSelected, sortField }}
          {...suggestions.table}
          data={suggestions.dataIds.map((each) => suggestions.data[each])}
        />
      </Grid>
      {!suggestions.showMoreHidden && (
        <Button variant="outlined" onClick={showMore}>
          {getString("Suggestions.Button.ShowMore")}
        </Button>
      )}
    </Container>
  );
};

export default Suggestions;
