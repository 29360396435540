import React from 'react'
import { useSelector } from "react-redux";
import Form from "../../Form";
import { Grid } from "@material-ui/core";

const EmailStep = props => {
    const { register, handleSelect } = props;
    const channelsDetails = useSelector(({ channelsDetailsReducer }) => channelsDetailsReducer);
    let fields = [...channelsDetails.form.fields]
    fields[3].value = register.connectionAuthSecurity
    fields[4].value = register.authenticationMethod
    
    if (register.authenticationMethod === 'anonymous') fields = fields.filter(e => e.name !== 'connectionAuthUser' && e.name !== 'connectionAuthPass')

    return (
        <Grid container tid="grid_parent">
            <Grid item tid="grid_form" xs={12}>
                <Form fields={fields}
                    tid="form"
                    reducerState={register}
                    handleSelect={handleSelect}
                 />
            </Grid>
        </Grid>
    )
}

export default EmailStep