import React, { useEffect, useState, useRef } from "react";
import { bindActionCreators } from "redux";
import { useSelector, useDispatch } from "react-redux";
import * as usersActions from "./actions";
import { push } from "connected-react-router";
import paths from "../../constants/paths";
import Table from "../../components/Table";
import Modal from "../../components/Modal";
import BreadcrumbsHeader from "../../components/BreadcrumbsHeader";
import { makeStyles } from "@material-ui/core/styles";
import useString from "../../i18n/hooks/useString";
import { Button, Container, Grid, Typography } from "@material-ui/core";
import SearchInput from "../../components/SearchInput";
import TableHeader from "../../components/TableHeader";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    overflowY: "hidden",
    flex: "1 1 auto",
  },
  tableWrapper: {
    overflowX: "hidden",
    overflowY: "auto",
    paddingBottom: theme.spacing(),
  },
  contentArea: {
    marginTop: theme.spacing(4),
    maxHeight: "100%",
    flexWrap: "nowrap",
  },
  searchActionBar: {
    flex: "1 1 auto",
    display: "flex",
    justifyContent: "flex-end",
  },
  selectedLabel: {
    paddingLeft: theme.spacing(),
  },
  tabs: {
    marginBottom: theme.spacing(3),
  },
}));

const UserDetails = () => {
  const login = useSelector(({ loginReducer }) => loginReducer);
  const users = useSelector(({ usersReducer }) => usersReducer);
  const getString = useString();
  const dispatch = useDispatch();
  const { current: actions } = useRef(
    bindActionCreators({ ...usersActions }, dispatch)
  );
  const classes = useStyles();

  const [selected, setSelected] = useState([]);
  const [modal, setModal] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [inlineId, setInlineId] = useState(null);

  const [sortField, setSortField] = useState("name");
  const [sortOrderReverse, setSortOrderReverse] = useState(false);

  useEffect(() => {
    if(login.ssoEnabled) {
        actions.hideRestPassword()
    }
    if (login.isAdmin) actions.getUserList();
    else dispatch(push(paths.alerts));
  }, []);

  const showMore = () =>
    actions.getUserList({
      offset: users.dataIds.length,
      filter: searchInput,
      sort: sortField,
      reverse: sortOrderReverse,
    });

  const customActions = {
    setSelected,
    handleEdit: (id) => {
      dispatch(push(paths.getUsersDetails(id)));
    },
    handleToggle: (row) => {
      actions.toggleUser(row.id, !row.isActive);
    },
    handleImportUsers: () => {
      setModal(users.modals.importUsers);
      setModalVisible(true);
    },
    handleResetPassword: (id) => {
      if (typeof id === "string") setInlineId(id);
      setModal(users.modals.resetPassword);
      setModalVisible(true);
    },
    handleDelete: (id) => {
      if (typeof id === "string") setInlineId(id);
      setModal(users.modals.delete);
      setModalVisible(true);
    },
    sendResetPassword: () => {
      actions.resetPassword(inlineId ? [inlineId] : selected, login.userId);
      setSelected([]);
      setInlineId();
    },
    sendDelete: () => {
      actions.deleteUsers(inlineId ? [inlineId] : selected, login.userId);
      setSelected([]);
      setInlineId();
    },
    selectAll: () => setSelected(users.dataIds),
    unselectAll: () => setSelected([]),
    sortField: (fieldName, order) => {
      setSortField(fieldName);
      setSortOrderReverse(order === "desc");
      actions.getUserList({
        sort: fieldName,
        filter: searchInput,
        reverse: order === "desc",
        overwrite: true,
      });
    },
  };

  const handleSearchApply = (value) => {
    actions.getUserList({
      offset: 0,
      filter: value,
      overwrite: true,
      sort: sortField,
      reverse: sortOrderReverse,
    });
    setSearchInput(value);
  };

  const leftTableActions = (
    <>
      <SearchInput tid={"search_input_users"} onApply={handleSearchApply} />
      {!!selected.length && (
        <span className={classes.selectedLabel}>
          <Typography variant="body2">
            {`${getString("General.Selected")}: ${selected.length}`}
          </Typography>
        </span>
      )}
    </>
  );

  const rightTableActions = (
    <>
      <Button
        variant="outlined"
        size="medium"
        onClick={customActions.handleDelete}
        disabled={!selected.length}
      >
        {getString("General.Delete")}
      </Button>
      {!login.ssoEnabled && (
        <Button
          variant="outlined"
          size="medium"
          onClick={customActions.handleResetPassword}
          disabled={!selected.length}
        >
          {getString("General.ResetPassword")}
        </Button>
      )}

      <Button
        variant="contained"
        color="primary"
        size="medium"
        onClick={customActions.handleImportUsers}
      >
        {getString("Users.Button.SyncWithQlik")}
      </Button>
    </>
  );

  return (
    <Container className={classes.container}>
      <Grid container className={classes.contentArea} direction="column">
        <Grid container>
          <BreadcrumbsHeader crumbs={users.crumbs} />
        </Grid>
        <TableHeader
          leftToolbar={leftTableActions}
          rightToolbar={rightTableActions}
        />
        <Grid item className={classes.tableWrapper}>
          <Table
            tid={"users_table"}
            actions={{ ...actions, ...customActions }}
            data={users.dataIds.map((each) => users.data[each])}
            {...users.table}
            selectedIds={selected}
          />
          {!users.showMoreHidden && (
            <Button variant="outlined" onClick={showMore}>
              {getString("General.ShowMore")}
            </Button>
          )}
        </Grid>
      </Grid>
      <Modal
        actions={{ ...actions, ...customActions }}
        {...modal}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
      />
    </Container>
  );
};

export default UserDetails;
