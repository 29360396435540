import paths from "../../constants/paths";

export const crumbs = [
    {label: "Breadcrumbs.Admin"},
    {label: "Breadcrumbs.Users", link: paths.users},
    {label: "Breadcrumbs.Details"}
  ]

export const form = {
    options: {},
    fields: [
        {
            id: 'firstNameEdit',
            name: 'firstName',
            label: "UsersDetails.Label.FirstName",
            placeholder: 'Enter user first name',
            autoComplete: "off",
            disabled: true
        },
        {
            id: 'lastNameEdit',
            name: 'lastName',
            label: "UsersDetails.Label.LastName",
            placeholder: 'Enter user last name',
            autoComplete: "off",
            disabled: true
        },
        {
            id: 'nameEdit',
            name: 'name',
            label: "UsersDetails.Label.UserName",
            placeholder: 'Enter user name',
            autoComplete: "off",
            disabled: true
        },
        {
            id: 'domainEdit',
            name: 'domain',
            label: "UsersDetails.Label.Domain",
            autoComplete: "off",
            disabled: true
        },
        {
            id: 'emailEdit',
            name: 'email',
            label: "UsersDetails.Label.EmailId",
            placeholder: 'Enter email',
            autoComplete: "off",
        },
        {
            id: 'hasPasswordEdit',
            name: 'isPasswordSet',
            label: "UsersDetails.Label.PasswordSet",            
            disabled: true
        },
        {
            id: 'adminEdit',
            name: 'admin',
            type: 'radio',
            value: 'false',
            options: [{ value: "false", name: "UsersDetails.Label.User" }, { value: "true", name: "UsersDetails.Label.Administrator" }]
          }
    ]
}

export const bottomBar = {
    options: {},
    buttons: {
      right: [
          {
              key: 'cancel',
              type: 'button',
              label: "General.Cancel",
              onClick: 'handleCancel',
              display: () => true
          },
          {
            key: 'save',
            type: 'button',
            label: "General.Save",
            variant: "contained",
            disabled: (s, l, state) => state.saveIsDisabled,
            color: "primary",
            onClick: 'handleSave',
            display: () => true
        },
      ]
    }
}

export const modal = {
    options: {
        title: "General.Cancel"
    },
    content: "UsersDetails.Modal.CancelContent",
    buttons: [
        {
            key: 'notLeave',
            label: "General.Cancel",
            onClick: null,
            variant: 'outlined',
        },
        {
            key: 'leave',
            label: "General.Confirm",
            onClick: 'cancelEditUser',
            color: 'primary'
        }
    ]
}
