import axios from 'axios';
import CONSTANTS from './constants';
import ActionTypes from '../constants/actionTypes';

export const setLanguage = (locale = CONSTANTS.DEFAULT_LANGUAGE) => async (dispatch) => {
  let translations;
  let selectedLocale = locale;
  let type = ActionTypes.SET_LANGUAGE_SUCCESS;

  try {
    const { data : strings } = await axios.get(`../translations/${selectedLocale}.json`);
    translations = strings;
  } catch (e) {
    const { data : strings } = await axios.get(`../translations/${CONSTANTS.DEFAULT_LANGUAGE}.json`);
    translations = strings;
    selectedLocale = CONSTANTS.DEFAULT_LANGUAGE;
    type = ActionTypes.SET_LANGUAGE_FAILURE;
  }

  dispatch({
    type,
    locale: selectedLocale,
    translations
  });

  return Promise.resolve();
};
