import { push } from "connected-react-router";
import ActionTypes from "../../constants/actionTypes";
import paths from "../../constants/paths";
import API from "../../lib/api";
import {
  filter,
  groupsHandler,
  usersHandler,
  previewHandler,
  previewResponse,
  transformAlert,
  buildQuery,
  handleObjects,
  prepareConditions,
  prepareConditionsNewAlert,
  tasksHandler,
} from "./dataHandler";

export const saveAlert = (type, body, id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.SAVE_BUTTON_DISABLED,
        payload: { saveIsDisabled: true },
      })
      const result = id
        ? (await API.updateAlert(type, id, body),
          dispatch({
            type: ActionTypes.SNACK_SUCCESS,
            payload: { snackMessage: "Snackbar.AlertsDetails.updateAlert" },
          }))
        : (await API.createAlert(type, body),
          dispatch({
            type: ActionTypes.SNACK_SUCCESS,
            payload: { snackMessage: "Snackbar.AlertsDetails.createAlert" },
          }))
      dispatch({
        type: ActionTypes.SAVE_ALERT,
        payload: result,
      });
      dispatch(push(paths.alerts));
    } catch (error) {
      console.log(error);
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: {
          snackMessage:
            typeof error === "string" ? error : JSON.stringify(error),
        },
      });
    } finally {
      dispatch({
        type: ActionTypes.SAVE_BUTTON_DISABLED,
        payload: { saveIsDisabled: false },
      })
    }
  };
};

export const getAlert = (type, id, timezoneOffset) => {
  return async (dispatch) => {
    try {
      const result = await API.getOneAlert(type, id);
      const data = transformAlert[type](result, timezoneOffset);
      dispatch({
        type: ActionTypes.GET_ALERT,
        payload: { type, id, data },
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.NAVIGATION_UNBLOCK,
      });
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: {
          snackMessage:
            typeof error === "string" ? error : JSON.stringify(error),
        },
      });
      dispatch(push(paths.alerts));
    }
  };
};

export const getAppSources = (skip=0,limit=200,qFilter='') => {
  return async (dispatch) => {
    try {
      const data = await API.getApps(skip,limit,qFilter);
      const apps = data.map((each) => ({
        id: each.id,
        name: each.name,
        stream: each.stream,
        lastReload: each.lastReload,
      }));
      const sorted = apps.sort((a, b) => {
        if (a.stream === b.stream) return a.name.localeCompare(b.name);
        else return a.stream.localeCompare(b.stream);
      });
      dispatch({
        type: ActionTypes.GET_APP_SOURCE,
        payload: sorted,
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: {
          snackMessage:
            typeof error === "string" ? error : JSON.stringify(error),
        },
      });
    }
  };
};

export const getAppSource = (id = '') => {
  return async (dispatch) => {
    try {
      const data = await API.getApp(id);
      const apps = data.map((each) => ({
        id: each.id,
        name: each.name,
        stream: each.stream,
        lastReload: each.lastReload,
      }));
      dispatch({
        type: ActionTypes.GET_APP_SOURCE,
        payload: apps,
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: {
          snackMessage:
            typeof error === "string" ? error : JSON.stringify(error),
        },
      });
    }
  };
};

export const getAppSourcesLazyLoading = (skip=0,limit=200,qFilter='') => {
  return async (dispatch) => {
    try {
      const filterString = qFilter ? ` name sw '${qFilter}' `  : ''
      const data = await API.getApps(skip,limit,filterString);
      dispatch({
        type: ActionTypes.GET_APP_SOURCE_LazyLoading,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: {
          snackMessage:
            typeof error === "string" ? error : JSON.stringify(error),
        },
      });
    }
  };
};

export const getObjects = (appId) => {
  return async (dispatch) => {
    try {
      if (appId) {
        const result = await API.getAppObjects(appId);
        const data = handleObjects(result);
        dispatch({
          type: ActionTypes.GET_OBJECTS,
          payload: data,
        });
      } else {
        dispatch({
          type: ActionTypes.GET_OBJECTS,
          payload: [],
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: { snackMessage: "Snackbar.AlertsDetails.getObjects" },
      });
    }
  };
};

export const getFiltersValues = (appId, def) => {
  return async (dispatch) => {
    try {
      if (def) {
        const body = filter(appId, def);
        const data = await API.getAppData(body);
        dispatch({
          type: ActionTypes.GET_FILTERS_VALUES,
          payload: data,
        });
      } else {
        dispatch({
          type: ActionTypes.GET_FILTERS_VALUES,
          payload: [],
        });
      }
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: {
          snackMessage:
            typeof error === "string" ? error : JSON.stringify(error),
        },
      });
    }
  };
};

export const refreshCache = (appId) => {
  return async (dispatch) => {
    try {
      await API.refreshCache();
      if (appId) await API.refreshCache(appId);
      try {
        // const data = await API.getApps();
        // const apps = data.map((each) => ({
        //   id: each.id,
        //   name: each.name,
        //   stream: each.stream,
        //   lastReload: each.lastReload,
        // }));
        // const sorted = apps.sort((a, b) => {
        //   if (a.stream === b.stream) return a.name.localeCompare(b.name);
        //   else return a.stream.localeCompare(b.stream);
        // });
        location.reload()
        dispatch({
          type: ActionTypes.GET_APP_SOURCE,
          payload: sorted,
        });
      } catch (error) {
        dispatch({
          type: ActionTypes.SNACK_ERROR,
          payload: {
            snackMessage:
              typeof error === "string" ? error : JSON.stringify(error),
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: {
          snackMessage:
            typeof error === "string" ? error : JSON.stringify(error),
        },
      });
    }
  }
};

export const refreshCacheAppList = () => {
  return async (dispatch) => {
    try {
      await API.refreshCache();
      const data = await API.getApps();
      dispatch({
        type: ActionTypes.GET_APP_SOURCE,
        payload: data,
      });
      dispatch({
        type: ActionTypes.SNACK_SUCCESS,
        payload: { snackMessage: "Snackbar.AlertsDetails.RefreshedApps" },
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: {
          snackMessage:
            typeof error === "string" ? error : JSON.stringify(error),
        },
      });
    }
  };
};

export const refreshCacheAppObjects = (appId) => {
  return async (dispatch) => {
    try {
      await API.refreshCache(appId);
      const data = await API.getAppObjects(appId);
      dispatch({
        type: ActionTypes.GET_OBJECTS,
        payload: data,
      });
      dispatch({
        type: ActionTypes.SNACK_SUCCESS,
        payload: { snackMessage: "Snackbar.AlertsDetails.RefreshedObjects" },
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: {
          snackMessage:
            typeof error === "string" ? error : JSON.stringify(error),
        },
      });
    }
  };
};

export const getDistributionGroups = (type, id, filter) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_DISTRIBUTION_GROUPS,
        payload: {
          assigned: [],
          unassigned: [],
        },
      });
      const unassignedGroups = id ? await API.getGroupsAlerts({
        type, id, includes: false,
        filter: `isActive $eq true${filter ? ` $and name $eqi ${filter}` : ""
          }`,
      }) : await API.getGroups({
        filter: `isActive $eq true${filter ? ` $and name $eqi ${filter}` : ""
          }`
      });
      const assignedGroups = id
        ? await API.getGroupsAlerts({
          type, id, includes: true,
          filter: `isActive $eq true${filter ? ` $and name $eqi ${filter}` : ""
            }`,
        })
        : [];
      dispatch({
        type: ActionTypes.GET_DISTRIBUTION_GROUPS,
        payload: {
          assigned: groupsHandler(assignedGroups),
          unassigned: groupsHandler(unassignedGroups),
        },
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: {
          snackMessage:
            typeof error === "string" ? error : JSON.stringify(error),
        },
      });
    }
  };
};

export const getDistributionRecipients = (
  type,
  id,
  filter,
  impersonate,
  owner
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_DISTRIBUTION_RECIPIENTS,
        payload: {
          assigned: [],
          unassigned: [],
        },
      });
      const filterValues = filter
        ? `(name $so ${filter} $or domain $so ${filter} $or firstName $so ${filter} $or lastName $so ${filter} $or license $so ${filter}) $and isActive $eq true`
        : "";
      const unassignedUsers = id
        ? await API.getUsersAlerts({
          type, id, includes: false,
          filter: `${filterValues}${impersonate ? ` $and license $exists true` : ""}`,
        })
        : await API.getUsers({
          filter: `${filterValues} $and _id $ne ${owner}${impersonate ? ` $and license $exists true` : ""
            }`,
        });
      const assignedUsers = id
        ? await API.getUsersAlerts({
          type, id, includes: true,
          filter: `${filterValues}${impersonate ? ` $and license $exists true` : ""
            }`,
        })
        : await API.getUsers({
          filter: `${filterValues} $and _id $eq ${owner}`,
        });
      dispatch({
        type: ActionTypes.GET_DISTRIBUTION_RECIPIENTS,
        payload: {
          assigned: usersHandler(assignedUsers),
          unassigned: usersHandler(unassignedUsers),
        },
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: {
          snackMessage:
            typeof error === "string" ? error : JSON.stringify(error),
        },
      });
    }
  };
};

export const previewSelected = (recipients, groups, unsubscribed) => {
  return async (dispatch) => {
    try {
      const body = await previewHandler(recipients, groups);
      const previewSelected = await API.queryMembers(body);
      const data = previewResponse(previewSelected, unsubscribed);
      dispatch({
        type: ActionTypes.GET_PREVIEW_SELECTED,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: {
          snackMessage:
            typeof error === "string" ? error : JSON.stringify(error),
        },
      });
    }
  };
};

export const toggleUnsubscribed = (type, alert, id, unsubscribed) => {
  return async (dispatch) => {
    // if(unsubscribed) await API.unsubscribeAlert(type, alert, id)
    // else await API.resubscribeAlert(type, alert, id)
    dispatch({
      type: ActionTypes.TOGGLE_UNSUBSCRIBED,
      payload: { id, unsubscribed },
    });
  };
};

export const uploadTemplate = (key, content) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.UPLOAD_TEMPLATE,
        payload: { key, content },
      });
      dispatch({
        type: ActionTypes.SNACK_SUCCESS,
        payload: { snackMessage: "Snackbar.AlertsDetails.uploadTemplate" },
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: {
          snackMessage:
            typeof error === "string" ? error : JSON.stringify(error),
        },
      });
    }
  };
};

export const clearTemplate = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.CLEAR_TEMPLATE,
        payload: null,
      });
      dispatch({
        type: ActionTypes.SNACK_SUCCESS,
        payload: { snackMessage: "Snackbar.AlertsDetails.clearFiles" },
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: {
          snackMessage:
            typeof error === "string" ? error : JSON.stringify(error),
        },
      });
    }
  };
};

export const addMeasure = (custom) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.ADD_MEASURE,
      payload: custom,
    });
  };
};

export const deleteMeasure = (index) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.DELETE_MEASURE,
      payload: index,
    });
  };
};

export const addFilter = () => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.ADD_FILTER,
      payload: null,
    });
  };
};

export const deleteAllFilters = () => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.DELETE_ALL_FILTERS,
    });
  };
};

export const deleteFilter = (index) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.DELETE_FILTER,
      payload: index,
    });
  };
};

export const getData = (values) => {
  return async (dispatch) => {
    try {
      if (!values.measures.length) {
        dispatch({
          type: ActionTypes.GET_DATA,
          payload: {
            result: [[]],
            count: 0,
          },
        });
      } else {
        const query = buildQuery(values);
        const response = await API.getAppData(query, true);
        if (response.warning) {
          dispatch({
            type: ActionTypes.SNACK_WARNING,
            payload: { snackMessage: response.warning },
          });
        }
        dispatch({
          type: ActionTypes.GET_DATA,
          payload: {
            result: response.data,
            count: response.count,
          },
        });
      }
    } catch (e) {
      dispatch({
        type: ActionTypes.GET_DATA,
        payload: {
          result: [[]],
          count: 0,
        },
      });
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: {
          snackMessage: e
            ? typeof e === "string"
              ? e
              : JSON.stringify(e)
            : "Snackbar.AlertsDetails.checkDataTable",
        },
      });
    }
  };
};

export const addScheduleTime = (value) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.ADD_SCHEDULE_TIME,
      payload: value,
    });
  };
};

export const deleteScheduleTime = (index) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.DELETE_SCHEDULE_TIME,
      payload: index,
    });
  };
};

export const addCondition = () => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.ADD_CONDITION,
      payload: null,
    });
  };
};

export const deleteCondition = (index) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.DELETE_CONDITION,
      payload: index,
    });
  };
};
export const addConditionRule = () => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.ADD_CONDITION_RULE,
      payload: null,
    });
  };
};

export const editConditionRule = (index, value) => {
  return (dispatch) => {
    let warning;
    const elements = value.match(/\w+/g);
    if (elements) {
      const array = elements.filter((x) => !x.match(/^([A-J]|and|or)$/));
      if (array.length)
        warning = `Forbidden elements in logic string: ${array.join(", ")}`;
      else {
        const condition = value
          .replace(/and/g, "&&")
          .replace(/or/g, "||")
          .replace(/[A-J]/g, () => "true");
        try {
          const result = eval(condition);
          if (typeof result !== "boolean") warning = "Invalid logic string";
        } catch (e) {
          // warning = `Could not evaluate string to boolean: ${value}`;
        }
      }
    } else warning = "Condition Rule cannot be empty";
    dispatch({
      type: ActionTypes.EDIT_CONDITION_RULE,
      payload: { index, value, warning },
    });
  };
};

export const deleteConditionRule = (index) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.DELETE_CONDITION_RULE,
      payload: index,
    });
  };
};

export const checkConditions = (values, alertId) => {
  return async (dispatch) => {
    try {
      const seemsValid = values.conditions.every(
        (each) => each.index > -1 && each.type && each.operator
      );
      if (seemsValid) {
        if (Object.values(values.warnings).some((each) => each)) {
          dispatch({
            type: ActionTypes.SNACK_WARNING,
            payload: {
              snackMessage: "Snackbar.AlertsDetails.checkRules",
            },
          });
        } else if (alertId) {
          const body = prepareConditions(values);
          body.alertId = alertId;
          const result = await API.checkCondition("data", body);
          dispatch({
            type: ActionTypes.CHECK_CONDITIONS,
            payload: result,
          });
        } else {
          const { body, warning, send } = prepareConditionsNewAlert(values);
          if (warning) {
            dispatch({
              type: ActionTypes.SNACK_WARNING,
              payload: {
                snackMessage: "Snackbar.AlertsDetails.noHistoryForNewAlerts",
              },
            });
          }
          if (send) {
            const result = await API.checkCondition("data", body);
            dispatch({
              type: ActionTypes.CHECK_CONDITIONS,
              payload: result,
            });
          }
        }
      }
    } catch (e) {
      console.log(e);
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: {
          snackMessage: "Snackbar.AlertsDetails.errorGettingPreview",
        },
      });
    }
  };
};

export const getSystemTasks = () => {
  return async (dispatch) => {
    const result = await API.getTasks();
    const tasks = tasksHandler(result);
    dispatch({
      type: ActionTypes.GET_SYSTEM_TASKS,
      payload: tasks,
    });
    if (!result.length) {
      dispatch({
        type: ActionTypes.SNACK_WARNING,
        payload: {
          snackMessage: "Snackbars.AlertsDetails.GetSystemTask",
        },
      });
    }
  };
};

export const addValueToAlert = (field, value) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.ADD_VALUE_TO_ALERT,
      payload: { field, value },
    });
  };
};

export const addValueToCondition = (index, field, value) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.ADD_VALUE_TO_CONDITION,
      payload: { index, field, value },
    });
  };
};

export const cancelCreateAlert = () => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.CANCEL_CREATE_ALERT,
    });
    dispatch(push(paths.alerts));
  };
};

export const publishBookmark = (app, bookmark) => {
  return async dispatch => {
    await API.publishBookmark(app, bookmark)
    dispatch({
      type: ActionTypes.PUBLISH_BOOKMARK,
      payload: bookmark,
    });
    dispatch({
      type: ActionTypes.SNACK_SUCCESS,
      payload: { snackMessage: "Snackbar.AlertsDetails.PublishedBookmark" },
    })
  };
};
