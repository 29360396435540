import ActionTypes from "../../constants/actionTypes";
import API from "../../lib/api";
import { push } from "connected-react-router";
import paths from "../../constants/paths";
import * as dataHandler from "./dataHandler";


export const getConfig = () => {
  return async dispatch => {
    try {
      const result = await API.getConfig();
      dispatch({
        type: ActionTypes.GET_CONFIG,
        payload: result
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: { snackMessage: typeof error === "string" ? error : JSON.stringify(error) }
      });
    }
  };
};

export const editPingSettings = values => {
  return async dispatch => {
    try {
      dispatch({
        type: ActionTypes.SAVE_BUTTON_DISABLED,
        payload: { saveIsDisabled: true },
      })
      const data = dataHandler.editConfig(values);
      const result = await API.upsertConfig(data);
      dispatch({
        type: ActionTypes.EDIT_PING_SETTINGS,
        payload: {
          data,
          result
        }
      });
      dispatch({
        type: ActionTypes.SNACK_SUCCESS,
        payload: { snackMessage: "Snackbar.Config.editPingSettings" }
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: { snackMessage: typeof error === "string" ? error : JSON.stringify(error) }
      });
    } finally {
      dispatch({
        type: ActionTypes.SAVE_BUTTON_DISABLED,
        payload: { saveIsDisabled: false },
      })
    }
  };
};

export const addValueToConfig = (field, value) => {
  return dispatch => {
    dispatch({
      type: ActionTypes.ADD_VALUE_TO_CONFIG,
      payload: { field, value }
    });
  };
};

export const cancelEditConfig = () => {
  return dispatch => {
    dispatch({
      type: ActionTypes.CANCEL_EDIT_CONFIG
    })
    dispatch(push(paths.alerts))
  }
};
