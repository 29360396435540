import {dateISO} from "../../utils/dateISO";

export const toSuggestions = (input) => {
    if(!Array.isArray(input)) input = [input]
    return input.map(each => {
        return {
            id: each._id,
            name: each.name,
            userName: each.sharedByInfo.name,
            fullName: `${each.sharedByInfo.firstName} ${each.sharedByInfo.lastName || ""}`,
            sharedAt: dateISO(each.sharedAt, true),
        }
    })
};