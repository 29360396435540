import React, { useEffect, useState, useRef } from "react";
import { bindActionCreators } from "redux";
import { useSelector, useDispatch } from "react-redux";
import { Container, Grid, Step, StepButton, Stepper } from "@material-ui/core";
import * as registerActions from "./actions";
import { push } from "connected-react-router";
import paths from "../../constants/paths";
import BottomBar from "../../components/BottomBar";
import Modal from "../../components/Modal";
import useString from "../../i18n/hooks/useString";
import ActionTypes from "../../constants/actionTypes";

let Register = () => {
  const dispatch = useDispatch();
  const { current: actions } = useRef(
    bindActionCreators({ ...registerActions }, dispatch)
  );
  const register = useSelector(({ registerReducer }) => registerReducer);
  const login = useSelector(({ loginReducer }) => loginReducer);
  const getString = useString();

  const { steps, activeStep } = register;

  useEffect(() => {
    if (!login.pending) dispatch(push(paths.login));
    else actions.registerInitialStep(steps, login.pending);
  }, []);

  useEffect(() => {
    if (
      login.pending &&
      steps[activeStep] &&
      !login.pending.includes(steps[activeStep].key)
    ) {
      dispatch({
        type: ActionTypes.REGISTER_COMPLETED_STEP,
        payload: activeStep,
      });
    }
  }, [activeStep]);

  const [modalVisible, setModalVisible] = useState(false);
  const [modal, setModal] = useState({});

  const handleCancel = () => {
    setModalVisible(true);
    setModal(register.modals.cancel);
  };

  const bottomBar = { ...register.bottomBar };

  if (activeStep === 3) bottomBar.buttons.right[1].disabled = true;
  if (activeStep === 4) {
    bottomBar.buttons.right[1].disabled = false;
    bottomBar.buttons.right[1].label = "General.Save";
  }

  const handlePrevious = () => {
    switch (activeStep) {
      case 1:
        dispatch({
          type: ActionTypes.UPDATE_LOGIN_PENDING,
          payload: "sources",
        });
        dispatch({
          type: ActionTypes.REGISTER_BACKWARD_STEP,
          payload: activeStep,
        });
        dispatch({
          type: ActionTypes.REGISTER_BACKWARD_STEP_SOURCE,
        });
        bottomBar.buttons.right[1].disabled = false;
        break;
      case 2:
        dispatch({
          type: ActionTypes.UPDATE_LOGIN_PENDING,
          payload: "config",
        });
        dispatch({
          type: ActionTypes.REGISTER_BACKWARD_STEP,
          payload: activeStep,
        });
        break;
      case 3:
        dispatch({
          type: ActionTypes.UPDATE_LOGIN_PENDING,
          payload: "channels",
        });
        dispatch({
          type: ActionTypes.REGISTER_BACKWARD_STEP,
          payload: activeStep,
        });
        break;
      default:
        break;
    }
  };

  const handleNext = (values) => {
    switch (activeStep) {
      case 0:
        actions.registerSource(values);
        break;
      case 1:
        actions.registerConfig(values);
        break;
      case 2:
        actions.registerChannel(values);
        break;
      case 4:
        handleEndForm();
        break;
      default:
        break;
    }
  };

  const handleEndForm = () => {
    setModalVisible(true);
    setModal(register.modals.redirect);
  };

  const handleTest = () => actions.testSource(register);

  const handleBack = () => handlePrevious();

  const handleSave = () => handleNext(register);

  const handleSelect = (field) => (value) => {
    switch (field) {
      case "connectionAuthSecurity":
      case "authenticationMethod":
        actions.addValueToRegister(field, value);
        break;
      case "ssoEnabled":
        actions.addValueToRegister(field, value === "true");
        break;
      default:
        actions.addValueToRegister(field, value.target.value);
        break;
    }
  };

  const getComponent = (index) => {
    const StepComponent = register.steps[Math.min(3, index)].component;
    return <StepComponent {...{ actions, register, handleSelect }} />;
  };

  return (
    <>
      <div
        style={{
          flex: "1 1 auto",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <Container
          maxWidth={"lg"}
          style={{ flex: "1 1 auto", overflow: "auto" }}
        >
          <Grid
            container
            direction="column"
            justify="space-between"
            alignItems="stretch"
            style={{ height: "100%", flexWrap: "nowrap" }}
          >
            <Grid
              container
              item
              tid="grid_register_stepper"
              justify="center"
              alignItems="flex-start"
            >
              <Stepper
                activeStep={activeStep}
                style={{
                  maxWidth: steps.length * 200 + "px",
                  minWidth:
                    (window.innerWidth > 900
                      ? steps.length * 160
                      : steps.length * 20) + "px",
                }}
              >
                {steps.map((step, index) => (
                  <Step key={step.key}>
                    <StepButton completed={index < activeStep}>
                      {window.innerWidth > 900 && getString(step.label)}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
            </Grid>
            <Grid
              container
              item
              tid="grid_active_step"
              xs
              md
              lg
              style={{ flex: "1 1 auto", paddingBottom: 50 }}
            >
              {getComponent(activeStep)}
            </Grid>
          </Grid>
          <Modal
            actions={actions}
            {...modal}
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
          />
        </Container>
      </div>
      <BottomBar
        actions={{
          ...actions,
          handleSave,
          handleCancel,
          handleTest,
          handleBack,
        }}
        {...bottomBar}
        reducerState={register}
      />
    </>
  );
};

export default Register;
