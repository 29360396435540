import ActionTypes from "../../constants/actionTypes";
import { toInboxSystem } from "./dataHandler";
import API from "../../lib/api";


export const getHistorySystemDetail = (alertId, taskId, getString) => async dispatch => {
  try {
    const response = await API.getHistorySystemDetail(alertId, taskId);
    const data = toInboxSystem(response, getString)
    dispatch({
      type: ActionTypes.GET_HISTORY_SYSTEM_DETAIL,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: ActionTypes.SNACK_ERROR,
      payload: { snackMessage: typeof error === "string" ? error : JSON.stringify(error) }
    });
  }
};
