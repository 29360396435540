import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Grid from "@material-ui/core/Grid";
import TableRow from "@material-ui/core/TableRow";
import useString from "../../i18n/hooks/useString";
import { COLORS } from '../../utils/sproutOverrides';

const UserProfile = ({ isAdmin, firstName, userName, license }) => {
  const getString = useString();

  return (
    <Grid tid="grid_parent">
      <Table tid="table" aria-label="spanning table" style={{borderTop: `solid 1px ${COLORS.GREYSCALE_85}`}}>
        <TableBody tid="table_body">
          <TableRow tid="table_row_name">
            <TableCell tid="table_cell_name">{getString("UserProfile.Name")}</TableCell>
            <TableCell tid="table_cell_first_name">{firstName}</TableCell>
          </TableRow>
          <TableRow tid="table_row_userName">
            <TableCell tid="table_cell_userName">{getString("UserProfile.UserName")}</TableCell>
            <TableCell tid="table_cell_userName">{userName}</TableCell>
          </TableRow>
          <TableRow tid="table_row_role">
            <TableCell tid="table_cell_role">{getString("UserProfile.Role")}</TableCell>
            <TableCell tid="table_cell_isAdmin">{isAdmin ? getString("UserProfile.TableCell.Administrator") : getString("UserProfile.TableCell.User")}</TableCell>
          </TableRow>
          <TableRow tid="table_row_license_type">
            <TableCell tid="table_cell_license_type">{getString("UserProfile.LicenseType")}</TableCell>
            <TableCell tid="table_cell_license">{license ? license.toUpperCase() : getString("UserProfile.TableCell.None")}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Grid>
  );
};

export default UserProfile;
