import ActionTypes from "../../constants/actionTypes";
import API from "../../lib/api";
import { fromForm, toChannelsDetails } from "./dataHandler";
import { push } from "connected-react-router";
import paths from "../../constants/paths";

export const testConnection = input => {
  return async dispatch => {
    const data = fromForm(input);
    try {
      await API.testChannel(data);
      dispatch({
        type: ActionTypes.TEST_CONNECTION,
        payload: {
          data,
          result: true
        }
      });
      dispatch({
        type: ActionTypes.SNACK_SUCCESS,
        payload: { snackMessage: "Snackbar.ChannelsDetails.testConnection" }
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.TEST_CONNECTION,
        payload: {
          data,
          result: false
        }
      });
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: { snackMessage: typeof error === "string" ? error : JSON.stringify(error) }
      });
    }
  };
};

export const editDeliveryChannel = (id, input) => {
  return async dispatch => {
    try {
      dispatch({
        type: ActionTypes.SAVE_BUTTON_DISABLED,
        payload: { saveIsDisabled: true },
      })
      const data = fromForm(input);
      const result = await API.updateChannel(id, data);
      if (data.result) {
        dispatch({
          type: ActionTypes.EDIT_DELIVERY_CHANNEL,
          payload: {
            data,
            result
          }
        });
        dispatch({
          type: ActionTypes.SNACK_SUCCESS,
          payload: { snackMessage: "Snackbar.ChangePassword.editDeliveryChannel" }
        });
      }
      dispatch(push(paths.channels));
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: { snackMessage: typeof error === "string" ? error : JSON.stringify(error) }
      });
    } finally {
      dispatch({
        type: ActionTypes.SAVE_BUTTON_DISABLED,
        payload: { saveIsDisabled: false },
      })
    }
  };
};

export const getChannelsDetails = id => {
  return async dispatch => {
    try {
      const result = await API.getOneChannel(id);
      const data = await toChannelsDetails(result);
      dispatch({
        type: ActionTypes.GET_CHANNELS_DETAILS,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: { snackMessage: typeof error === "string" ? error : JSON.stringify(error) }
      });
    }
  };
};

export const addValueToChannelsDetails = (field, value) => {
  return dispatch => {
    dispatch({
      type: ActionTypes.ADD_VALUE_TO_CHANNELS_DETAILS,
      payload: { field, value }
    });
  };
};

export const cancelEditChannels = () => {
  return dispatch => {
    dispatch({
      type: ActionTypes.CANCEL_EDIT_CHANNELS
    })
    dispatch(push(paths.channels));
  }
}
