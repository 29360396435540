import ActionTypes from "../../constants/actionTypes";
import API from "../../lib/api";
import { toChannels } from "./dataHandler";

export const getChannels = ({ sort, reverse } = {}) => {
  return async dispatch => {
    try {
      const result = await API.getChannels({ sort, reverse });
      const data = await toChannels(result);
      dispatch({
        type: ActionTypes.GET_CHANNELS,
        payload: {
          data
        }
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: { snackMessage: typeof error === "string" ? error : JSON.stringify(error) }
      });
    }
  };
};
