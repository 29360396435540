import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Button } from '@material-ui/core';
import { useStyles } from '../../constants/styles';
import useString from "../../i18n/hooks/useString";


export default function Page404() {
    const classes = useStyles();
    const getString = useString();

    return (
        <Container className={classes.container404}>
            <h1 className={classes.container404button}>
                {getString("Page404.Label.Contents")}
            </h1>
            <Button variant="outlined">
                <Link to="/">
                    {getString("Page404.Label.BackHome")}
                    </Link>
            </Button>
        </Container>
    )
}
