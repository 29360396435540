import React from "react";
import { Grid, Button, Tooltip } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import useString from "../../i18n/hooks/useString";


const Uploader = props => {
  const {
    actions = {},
    files = [],
    options = {},
    style = {},
    inserted = {},
    maxSize = 500000
  } = props;
  const getString = useString();

  const handleUpload = (key, content) => {
    if (actions[options.onUpload]) actions[options.onUpload](key, content);
    else if (options.onUpload) options.onUpload(key, content);
  };

  const handleChange = event => {
    const inserted = event.target.files;
    const array = [];
    for (let i = 0; i < inserted.length; i++) {
      if (inserted[i].size <= maxSize) array.push({ name: inserted[i].name, type: inserted[i].type ? inserted[i].type : inserted[i].name.match(/.+\.hjs$/) ? 'hjs' : '' });
    }
    const readFile = (key, file) => {
      const reader = new FileReader();
      reader.onload = () => handleUpload(key, reader.result);
      reader.readAsText(file);
      reader.onerror = error => console.log(error);
    };
    files.forEach(each => {
      const property = each.name ? "name" : each.type ? "type" : null;
      if (property) {
        let index = array.findIndex(
          entry => entry[property] === each[property] || each[property].includes(entry[property])
        );
        if (index > -1) readFile(each.key, inserted[index]);
      } else readFile(each.key, inserted[0]);
    });
  };

  const handleClear = () => {
    if (actions[options.onClear]) actions[options.onClear]();
    else options.onClear();
  };

  const required = files.filter(each => each.required);
  return (
    <Grid container tid="grid_parent" direction="column" justify="space-evenly" alignItems="center">
      <Grid container tid="grid_upload_file" item xs={12} justify="space-evenly" alignItems="center" style={{ margin: "20px", flex: "0 0 auto" }}>
        {options.title ? <p>{options.title}</p> : null}
        <input
          tid="input_insert_file"
          style={style}
          hidden
          onChange={handleChange}
          accept="*/*"
          id="button-insert-file"
          multiple={files.length > 1}
          type="file"
        />
        <Tooltip
          tid="tooltip_wrapper"
          title={
            options.uploadToolTipText
              ? options.uploadToolTipText
              : getString("Uploader.Label.UploadFiles")
          }
        >
          <label tid="label_button_upload_files" htmlFor="button-insert-file">
            <Button
              tid="button_upload_files"
              component="span"
              disabled={!files.length}
              variant="outlined"
            >
              {options.buttonLabel
                ? getString(options.buttonLabel)
                : getString("Uploader.Label.UploadFiles")
              }
            </Button>
          </label>
        </Tooltip>
      </Grid>
      {options.showInserted ? (
        <Grid item tid="grid_inserted_files" xs={12}>
          <Tooltip
            tid="tooltip_inserted_files"
            title={Object.values(inserted)
              .map(each => each.label)
              .join("\r\n")}
          >
            <p tid="p_inserted_files">
              {/* TODO: refactor standard component to accept string + variable into getString(); ref QIA-430 */}
              {getString("Uploader.Label.Inserted")}
              {Object.values(inserted).length}
              {getString("Uploader.Label.Files")}
            </p>
          </Tooltip>
        </Grid>
      ) : null}
      <Grid item tid="grid_tooltip_is_required" xs={12} container justify="center" alignItems="center" style={{ margin: "20px", flex: "0 0 auto" }}>
        {required.map(each => (
          <Tooltip
            tid="tooltip_is_required"
            key={each.key}
            title={
              inserted[each.key]
                ? getString("Uploader.Label.Inserted", [getString(each.label)])
                : getString("Uploader.Label.IsRequired", [getString(each.label)])
            }
          >
            {inserted[each.key] ? <CheckIcon tid="check_icon" /> : <RemoveCircleIcon tid="remove_circle_icon" />}
          </Tooltip>
        ))}
      </Grid>
      {options.onClear ? (
        <Grid item tid="grid_clear" xs={12}>
          <Button tid="button_clear" onClick={handleClear} variant="outlined">
            {options.clearLabel ? options.clearLabel : getString("Uploader.Label.ClearFiles")}
          </Button>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default Uploader;
