import ActionTypes from "../../constants/actionTypes";
import { toInboxData, toInboxDataDetails } from "./dataHandler";
import API from "../../lib/api";

export const getInboxScans = (alertId) => async (dispatch) => {
  try {
    const response = await API.getDataHistory(alertId);   
    const data = toInboxData(response);
    dispatch({
      type: ActionTypes.GET_INBOX_SCANS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ActionTypes.SNACK_ERROR,
      payload: {
        snackMessage: typeof error === "string" ? error : JSON.stringify(error),
      },
    });
  }
};

export const getInboxScanDetails = (alertId, scanId) => {
  return async (dispatch) => {
    try {
      const response = await API.getHistoryDataDetails(alertId, scanId);
      const dataDetails = toInboxDataDetails(response);
      dispatch({
        type: ActionTypes.GET_INBOX_SCAN_DETAILS,
        payload: dataDetails,
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: {
          snackMessage:
            typeof error === "string" ? error : JSON.stringify(error),
        },
      });
    }
  };
};

export const getCsvFile = (alertId, scanId) => {
  return async (dispatch) => {
    try {
      const response = await API.getHistoryDataCsv(alertId, scanId);
      dispatch({
        type: ActionTypes.GET_INBOX_SCAN_CSV,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: {
          snackMessage:
            typeof error === "string" ? error : JSON.stringify(error),
        },
      });
    }
  };
};
