const numberFormat = (input = '', format) => { // ADD TO QAW-COMMON
  try {
    if (typeof input === 'object') return input.qText
    if (isNaN(input)) return input.toString() // return NaN
    else if (!format) return input.toString()
    else {
      const matchingFormat = {
        "Auto": "0.00a",
        "1,000": "0,0",
        "1,000.0": "0,0.0",
        "1,000.12": "0,0.00",
        "12%": "0%",
        "12.3%": "0.0%",
        "12.34%": "0.00%"
      }
        const ranges = [
            { divider: 1e12, suffix: 'T' },
            { divider: 1e9, suffix: 'B' },
            { divider: 1e6, suffix: 'M' },
            { divider: 1e3, suffix: 'k' }
        ]
        let number = Number(input)
        let decimalFormat = matchingFormat[format].split('.')[1]
        let precisionCount = decimalFormat ? decimalFormat.match(/0/g).length : 0
        let range = ranges.find(obj => number >= obj.divider)
        if (matchingFormat[format].match(/%/)) return (number * 100).toFixed(precisionCount) + '%'
        else if (matchingFormat[format].includes(',')) return Number(number.toFixed(precisionCount)).toLocaleString()
        else if (range) return (number / range.divider).toFixed(precisionCount) + range.suffix
        else return (Math.round( ( number + Number.EPSILON ) * 100 ) / 100).toString()
    }
  } catch (e) {
    console.log('format', format, input, e)
  }
}

export const formatResult = (data, measures, dimension) => {
  try{
    const index = dimension ? 1 : 0
    return data.map(row => row.map((cell, i) => {
      if(i || !dimension) return numberFormat(cell, measures[i-index].format)
      return numberFormat(cell)
    })) 
  } catch (e) {
    console.log('formatResult', e)
  }
}