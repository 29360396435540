import React from "react";
import PropTypes from 'prop-types';
import { COLORS } from '../../utils/sproutOverrides';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container: {
      overflowY: 'hidden',
      flex: '0 0 auto',
      display: 'flex',
      flexWrap: 'wrap',
      padding: `0px ${theme.spacing(0.5)}px 0px ${theme.spacing(0.5)}px`,
      justifyContent: 'space-between',
      backgroundColor: COLORS.GREYSCALE_98,
      border: `solid 1px ${COLORS.GREYSCALE_85}`,
      borderWidth: ({ topBorderOnly }) => topBorderOnly ? '1px 0px 0px 0px' : '1px',
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      flex: '0 0 auto',
      '& > *': {
        margin: `${theme.spacing()}px ${theme.spacing(0.5)}px ${theme.spacing()}px ${theme.spacing(0.5)}px`,
      }
    },
}));

const propTypes = {
  leftToolbar: PropTypes.element,
  rightToolbar: PropTypes.element,
};

const TableHeader = ({ leftToolbar, rightToolbar, ...rest }) => {
  const classes = useStyles({ topBorderOnly: !leftToolbar && !rightToolbar });

  return (
    <div tid="parent_div" className={classes.container} {...rest}>
      <div tid="div_left_toolbar" className={classes.toolbar}>
        {leftToolbar}
      </div>
      <div tid="div_right_toolbar" className={classes.toolbar}>
       {rightToolbar}
      </div>
    </div>
  );
}

TableHeader.propTypes = propTypes;
export default TableHeader;
