import ActionTypes from "../../constants/actionTypes"

const initialState = {
  isNavigationBlocked: false,
}

export default function navigationBlocker(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.NAVIGATION_BLOCK:
      return {
        isNavigationBlocked: true
      };
    case ActionTypes.NAVIGATION_UNBLOCK:
      return {
        isNavigationBlocked: false
      };
    default:
      return state
  }
};
