import ActionTypes from "../../constants/actionTypes";
import API from "../../lib/api";
import { push } from "connected-react-router";
import paths from '../../constants/paths';

export const changePassword = values => {
  return async dispatch => {
    try {
      if (!values.oldPassword) throw 'Old password is required.';
      if (!values.confirmPassword) throw 'Password is required.';
      if (values.confirmPassword !== values.newPassword) throw 'Passwords don\'t match.';
      let body = {
        oldPassword: values.oldPassword,
        newPassword: values.confirmPassword
      };
      await API.changePassword(body);
      dispatch({
        type: ActionTypes.SNACK_SUCCESS,
        payload: { snackMessage: "Snackbar.ChangePassword.changePassword" }
      });
      dispatch(push(paths.alerts));
      dispatch({
        type: ActionTypes.CLEAR_CHANGE_PASSWORD,
        payload: null
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: {
          snackMessage: JSON.stringify(error)
        }
      });
    }
  };
};

export const recoverPassword = (values, token) => {
  return async dispatch => {
    try {
      if (!token) throw 'Token is required.';
      if (!values.confirmPassword) throw 'Password is required.';
      if (values.confirmPassword !== values.newPassword) throw ' Passwords don\'t match.'
      let body = {
        id: token,
        newPassword: values.confirmPassword
      };
      await API.recoverPassword(body);
      dispatch(push(paths.login));
      dispatch({
        type: ActionTypes.SNACK_SUCCESS,
        payload: {
          snackMessage: "Snackbar.ChangePassword.changePassword"
        }
      });
      dispatch({
        type: ActionTypes.CLEAR_CHANGE_PASSWORD,
        payload: null
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: {
          snackMessage: JSON.stringify(error)
        }
      });
    }
  };
};

export const addValueToChangePassword = (field, value) => {
  return dispatch => {
    dispatch({
      type: ActionTypes.ADD_VALUE_TO_CHANGE_PASSWORD,
      payload: { field, value }
    });
  };
};

export const cancelChangePassword = () => {
  return dispatch => {
    dispatch({
      type: ActionTypes.CANCEL_CHANGE_PASSWORD
    })
    dispatch(push(paths.alerts));
  }
};

export const registrationStatus = () => {
  return async dispatch => {
    try {
      const isRegistered = await API.getRegistrationStatus()
      if(!isRegistered.success) dispatch(push(paths.register));
      if (isRegistered.ssoEnabled) dispatch(push(paths.login));
      dispatch({
        type: ActionTypes.REGISTER_REQUIRED,
        payload: isRegistered
      })
    } catch (error) {
      console.log(error)
    }
  }
}
