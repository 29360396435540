import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as usersGroupsDetailsActions from "./actions";
import BreadcrumbsHeader from "../../components/BreadcrumbsHeader";
import {
  makeStyles,
  Container,
  Grid,
  FormControl,
  FormLabel,
  Button
} from "@material-ui/core";
import Assigner from '../../components/Assigner';
import BottomBar from '../../components/BottomBar';
import Modal from '../../components/Modal';
import SearchInput from '../../components/SearchInput';
import BufferedInput from '../../components/BufferedInput';
import { useParams } from "react-router-dom";
import useNavBlocker from "../../components/NavigationBlocker/hooks/useNavBlocker";
import useString from "../../i18n/hooks/useString";
import { push } from "connected-react-router";
import paths from "../../constants/paths";

const useStyles = makeStyles(theme => ({
  assignerContainer: {
    flex: '1 1 100%',
    paddingTop: theme.spacing(4),
    overflow: 'hidden'
  },
  searchWrapper: {
    marginTop: '20px',
    marginLeft: theme.spacing(),
  },
  assignerWrapper: {
    height: 'calc(100% - 92px)',
  },
  formLabel: {
    marginBottom: '8px'
  }
}))

const UsersGroupsDetails = () => {
  const { unblockNavigation, blockNavigation } = useNavBlocker();
  const [assigned, setAssigned] = useState([]);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const getString = useString();

  useEffect(() => {
    blockNavigation();
  }, []);

  const classes = useStyles();
  const dispatch = useDispatch();
  const { current: actions } = useRef(bindActionCreators({ ...usersGroupsDetailsActions }, dispatch));
  const usersGroupsDetails = useSelector(({ usersGroupsDetailsReducer }) => usersGroupsDetailsReducer);
  const login = useSelector(({ loginReducer }) => loginReducer);
  const [modalVisible, setModalVisible] = useState(false);
  const [allSelection, setAllSelection] = useState(false)
  const [modalFullAssign, setModalFullAssign] = useState(false)
  const [pendingUnassigned, setPendingUnassigned] = useState([]);
  const [pendingAssigned, setPendingAssigned] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    if (login.isAdmin) {
      actions.getUsersGroupsDetails(id)
      actions.filterUsersGroupsDetails(id)
    }
    else dispatch(push(paths.alerts))
  }, []);

  const handleSave = async () => {
    setSaveDisabled(true);
    await unblockNavigation();
    actions.editUsersGroupsDetails(id, usersGroupsDetails.name, assigned);
  }

  const isSaveDisabled = () => saveDisabled;
  const handleCancel = () => actions.cancelEditUserGroup();

  const handleSearchApply = (value) => {
    actions.filterUsersGroupsDetails(id, {
      offset: 0,
      filter: value,
      overwrite: true,
    });
  };

  const customActions = {
    fullAssignation: async () => {
      await unblockNavigation();
      actions.fullGroupsAssignation(id, allSelection, usersGroupsDetails.name)
    }
  }

  const allAssignment = (type) => {
    setAllSelection(type)
    setModalFullAssign(true)
  }

  return (
    <>
      <Container className={classes.assignerContainer}>
        <Grid>
          <BreadcrumbsHeader crumbs={usersGroupsDetails.crumbs} />
        </Grid>
        <Grid tid="grid_icon_buttons_groups" container item xs direction="row" alignItems="center" justify="space-between">
          <div>
            <FormControl>
              <FormLabel className={classes.formLabel}> {getString('UserGroupDetails.Label.GroupName')} </FormLabel>
              <BufferedInput
                fullWidth={true}
                name="name"
                id='input_group_name'
                value={usersGroupsDetails.name}
                onApply={actions.setGroupName}
              />
            </FormControl>
            <SearchInput tid={'search_input_user_group_details'} onApply={handleSearchApply} className={classes.searchWrapper} />
          </div>
          <Grid item>
            <Button tid="icon_button_assign_all_groups" onClick={() => allAssignment(true)} variant="outlined" disabled={usersGroupsDetails.filtered} style={{ margin: 3 }}>
              {getString("UsersGroups.Button.AssignAll")}
            </Button>
            <Button tid="icon_button_unassign_all_groups" onClick={() => allAssignment(false)} variant="outlined" disabled={usersGroupsDetails.filtered || !id} style={{ margin: 3 }}>
              {getString("UsersGroups.Button.UnAssignAll")}
            </Button>
          </Grid>
        </Grid>
        <div className={classes.assignerWrapper}>
          <Assigner
            unassignedData={usersGroupsDetails.unassignedData}
            assignedData={usersGroupsDetails.assignedData}
            assigned={usersGroupsDetails.assigned}
            pendingUnassigned={pendingUnassigned}
            setPendingUnassigned={setPendingUnassigned}
            pendingAssigned={pendingAssigned}
            setPendingAssigned={setPendingAssigned}
            onAssigned={setAssigned}
            setSaveDisabled={setSaveDisabled}
          />
        </div>
        <Modal
          actions={{ ...actions, ...customActions }}
          {...usersGroupsDetails[`modal${allSelection ? 'AssignAll' : 'UnassignAll'}`]}
          modalVisible={modalFullAssign}
          setModalVisible={setModalFullAssign} />
        <Modal
          actions={actions}
          {...usersGroupsDetails.modal}
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
        />
      </Container>
      <BottomBar
        actions={{ ...actions, handleCancel, handleSave, isSaveDisabled }}
        {...usersGroupsDetails.bottomBar}
        dataLength={usersGroupsDetails.length}
      />
    </>
  );
};

export default UsersGroupsDetails;
