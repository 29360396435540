import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import Assigner from "../Assigner/legacy";
import ModalCustom from "../ModalCustom";
import * as definition from "./definitions";
import SearchInput from "../../components/SearchInput";

const useStyles = makeStyles((theme) => ({
  assignerWrapper: {
    height: 'calc(100% - 92px)',
  },
}));

const Share = ({
  actions,
  open,
  setOpen,
  onShare,
  recipients,
  groups,
  onClose,
  selectedAlert,
  ...rest
}) => {
  const classes = useStyles();
  const [selectedRecipients, setSelectedRecipients] = useState([]);

  const [recipientData, setRecipientData] = useState()

  const handleSave = () => {
    onShare(selectedRecipients);
  }

  useEffect(() => {
    const newRecipientData = recipients.reduce((prev,cur) => {
      prev[cur.id] = cur
      return prev
    }, {});
    setRecipientData(newRecipientData);
  }, [recipients])

  const handleSearchApply = (value) => {
      actions.getShareAlertRG(value)
  };
  
  return (
    <ModalCustom
      actions={{ sendShare: handleSave, close: onClose }}
      modalVisible={open}
      setModalVisible={setOpen}
      fullWidth={true}
      {...definition.modal.share}
      {...rest}
    >
      <SearchInput tid={'search_input_share'}  onApply={handleSearchApply}/>
      <div className={classes.assignerWrapper}>
        <Assigner
          unassignedData={recipientData}
          assignedData={[]}
          assigned={[]}
          onAssigned={setSelectedRecipients}
          setSaveDisabled={() => {}}
          selectedAlert={selectedAlert}
        />
      </div>
    </ModalCustom>
  );
};

export default Share;