import { createThemeOptions } from '@qlik-trial/sprout/theme';

export const COLORS = {
  TEXT_PRIMARY: '#404040',
  TEXT_SECONDARY: 'rgba(0, 0, 0, 0.55)',
  WHITE: '#FFFFFF',
  GREEN: '#009845',
  GREYSCALE_0_15: 'rgba(0, 0, 0, 0.05)',
  GREYSCALE_25: '#404040',
  GREYSCALE_98: '#FAFAFA',
  GREYSCALE_95: '#F2F2F2',
  GREYSCALE_85: '#D9D9D9',
}

const sproutBase = createThemeOptions()

export const sproutOverrides = {
  ...sproutBase,
  overrides: {
    ...sproutBase.overrides,
    MuiAutocomplete: {
      ...sproutBase.overrides.MuiAutocomplete,
      groupLabel: {
        fontSize: "16px",
        fontWeight: 600,
        color: COLORS.TEXT_PRIMARY,
      },
      option: {
        fontSize: "14px",
        '&[data-focus="true"]': {
          backgroundColor: COLORS.GREYSCALE_98,
        },
        '&[aria-selected="true"]': {
          backgroundColor: COLORS.GREYSCALE_95,
        }
      }
    },
    MuiTypography: {
      gutterBottom: {
        marginBottom: "8px",
      }
    },
    MuiExpansionPanel: {
      ...sproutBase.overrides.MuiExpansionPanelSummary,
      root: {
        backgroundColor: COLORS.WHITE,
        border: `1px solid ${COLORS.GREYSCALE_0_15}`,
        borderRadius: '3px',
        boxShadow: 'none',
      },
      rounded: {
        '&:last-child': {
          borderBottomLeftRadius: 3,
          borderBottomRightRadius: 3,
          borderRadius: 3,
        },
        '&:first-child': {
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          borderRadius: 3,
        }
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        boxShadow: 'none',
        borderRadius: '3px',
        padding: '0px',
        '& > .MuiIconButton-edgeEnd': {
          marginRight: '0px',
        },
        '&.Mui-expanded': {
          minHeight: 'unset',
        }
      },
      content: {
        margin: '16px',
        '&.Mui-expanded': {
          margin: '16px',
        },
      },
      expandIcon: {
        position: 'absolute',
        right: 0,
        top: 0,
        height: '100%',
        padding: '0px 32px',
      }
    },
    MuiCollapse: {
      container: {
        backgroundColor: COLORS.WHITE,
      }
    },
  }
};