import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import { useSelector, useDispatch } from "react-redux";
import {
  makeStyles,
  Container,
  Grid,
  Step,
  StepButton,
  Stepper
} from "@material-ui/core";
import paths from "../../constants/paths";
import * as alertsDetailsActions from "./actions";
import BottomBar from "../../components/BottomBar";
import useString from "../../i18n/hooks/useString";
import useNavBlocker from "../../components/NavigationBlocker/hooks/useNavBlocker";
import { prepareAlert } from "./dataHandler";
import SaveWarningModal, { CONSTANTS as ModalType } from "../../components/SaveWarningModal";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    flex: '1 1 auto',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  stepper: {
    flex: '1 1 auto',
    marginBottom: theme.spacing(4),
  }
}));

let AlertsDetails = () => {
  const { unblockNavigation, blockNavigation } = useNavBlocker();
  useEffect(() => {
    blockNavigation();
  }, []);

  const classes = useStyles();
  const { type, id } = useParams();
  const dispatch = useDispatch();
  const { current: actions } = useRef(
    bindActionCreators({ ...alertsDetailsActions }, dispatch)
  );
  const login = useSelector(({ loginReducer }) => loginReducer);
  const alertsDetails = useSelector(
    ({ alertsDetailsReducer }) => alertsDetailsReducer
  );
  const getString = useString();
  const steps = alertsDetails.steps[type || "data"];
  
  // ALL
  useEffect(() => {
    if (id) actions.getAlert(type, id, login.timezoneOffset);
    else actions.addValueToAlert("recipients", [login.userId]);
    actions.addValueToAlert("timezoneOffset", login.timezoneOffset)
  }, []);
  
  useEffect(() => {
    if (type === "data" ) {
      if (alertsDetails.app) actions.getObjects(alertsDetails.app);
      if (alertsDetails.alertId && (alertsDetails.options.fields.length || alertsDetails.options.measures.length) && alertsDetails.app) actions.getData(alertsDetails);
    }
  }, [actions, alertsDetails.app, type]);

  useEffect(() => {
    if (
      type === "data" &&
      alertsDetails.app &&
      alertsDetails.options.sheets.length &&
      !alertsDetails.sheet
    )
      actions.addValueToAlert("sheet", alertsDetails.options.sheets[0].id);
  }, [actions, alertsDetails.app, alertsDetails.options, type]);

  useEffect(() => {
    if (type === "data")
      actions.getFiltersValues(
        alertsDetails.app,
        alertsDetails.field ? alertsDetails.field.def : null
      );
  }, [actions, alertsDetails.field, type]);
  useEffect(() => {
    if (type === "data" && (alertsDetails.options.fields.length || alertsDetails.options.measures.length) ) actions.getData(alertsDetails);
  }, [
    actions,
    alertsDetails.options.fields,
    alertsDetails.dimension,
    alertsDetails.measures,
    alertsDetails.bookmark,
    alertsDetails.filters,
    alertsDetails.sortBy,
    alertsDetails.sortAscending
  ]);
  // CONDITION
  useEffect(() => {
    if (type === "data") {
      if (alertsDetails.resultData.length)
        actions.checkConditions(alertsDetails, id);
      else actions.getData(alertsDetails);
    }
  }, [alertsDetails.resultData, type]);
  // SYSTEM
  useEffect(() => {
    if (type === "system") actions.getSystemTasks();
  }, [actions, type]);

  const [activeStep, setActiveStep] = useState(steps[0]);
  const [completed] = useState({});
  const alertBody = useRef();
  const [modalType, setModalType] = useState(ModalType.NOT_VISIBLE);

  const getComponent = step => {
    const StepComponent = step.component;
    return <StepComponent {...{ alertsDetails, actions, login }} type={type} alertId={id} />;
  };

  const handleCancel = () => {
    actions.cancelCreateAlert();
  };

  const handleDiscardExit = async () => {
    await unblockNavigation();
    dispatch(push(paths.alerts));
  }

  const handleSaveAndExit = async () => {
    await unblockNavigation();
    actions.saveAlert(type, alertBody.current, id);
  }

  const handleSave = async () => {
    try {
      if(type === 'data' || type === 'system' || type === 'broadcast'){
        alertsDetails.unsubscribed = alertsDetails.unsubscribed.filter(userId =>{
            if([...alertsDetails.recipients].includes(userId)) return userId
        })
      }
      alertBody.current = prepareAlert[type](alertsDetails);
      handleSaveAndExit();
    } catch(error) {
      setModalType(ModalType.INCOMPLETE_ALERT);
    }
  }

  const handleNext = () => {
    setActiveStep(steps[activeStep.index + 1]);
  }

  const handlePrevious = () => {
    setActiveStep(steps[activeStep.index - 1]);
  }

  const hasNext = () => activeStep.index < steps.length - 1;
  const hasPrevious = () => activeStep.index > 0;

  const saveDisabled = () => alertsDetails.saveIsDisabled
  
  return (
    <>
      <div
        className={classes.root}
      >
        <Container
          tid="container_parent"
          maxWidth={"lg"} 
        >
          <Grid
            tid="grid_body"
            container
            direction="column"
            justify="space-between"
            alignItems="stretch"
            style={{height:"100%", flexWrap: 'nowrap'}}
          >
            <Grid container item tid="grid_stepper" justify="center" alignItems="flex-start">
              <Stepper
                className={classes.stepper}
                tid="stepper"
                nonLinear
                activeStep={activeStep.index}
                // orientation={matches ? "horizontal" : "vertical"}
                orientation="horizontal"
                style={{
                  maxWidth: steps.length * 200 + "px",
                }}
              >
                {steps.map((step, index) => (
                  <Step tid={`step_${index}`} key={step.key}>
                    <StepButton
                      tid={`step_button-${index}`}
                      onClick={() => setActiveStep(step)}
                      completed={completed[index]}
                    >
                      {window.innerWidth > 900 && getString(step.label)}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
            </Grid>
            <Grid container item tid="grid_active_step" xs md lg style={{flex: '1 1 auto', paddingBottom: 50}}>
              {getComponent(activeStep)}
            </Grid>
          </Grid>
        </Container>
        <SaveWarningModal
          type={modalType}
          setModal={setModalType}
          onDiscard={handleDiscardExit}
        />
      </div>
      <BottomBar
        tid="bottom_bar"
        actions={{ ...actions, handleSave, handleCancel, handleNext, handlePrevious, hasNext, hasPrevious, hasSave: () => !hasNext(), saveDisabled }}
        {...alertsDetails.bottomBar}
      />
    </>
  );
};

export default AlertsDetails;
