import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
     root: {
      border: '1px solid #d2d2d270',
      borderRadius: 5,
      '& .MuiGrid-grid-xs-12': {
      padding: '0 50px'
      } 
    },
    main: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center'
    },
    card: {
        width: 300,
        height: 300,
        margin: 30,
        borderRadius: 25,
    },
    title: {
        fontSize: 14,
        paddingTop: 20
    },
    row: {
        display: 'flex',
        alignItems: 'center'
    },
    button: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 20
    } 
});

export default useStyles