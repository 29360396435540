import ActionTypes from "../../constants/actionTypes";
import * as definitions from "./definitions";
import { shouldCleanup } from '../../utils/navigationCleanup';

const initialState = {
  ...definitions,
  unassignedData: {},
  assignedData: {},
  assigned: [],
  name: ''
};

export default function usersGroupsDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_USERS_GROUPS_DETAILS:
      return {
        ...state,
        ...action.payload
      };
      case ActionTypes.FILTER_USER_GROUPS_DETAILS:
        return {
          ...state,
          filtered: action.payload.filtered,
          unassignedData: action.payload.unassignedData.reduce((prev, cur) => {
            prev[cur.id] = cur;
            return prev;
          }, {}),
          assignedData: action.payload.assignedData.reduce((prev, cur) => {
            prev[cur.id] = cur;
            return prev;
          }, {})
        };
    case ActionTypes.SET_GROUP_NAME:
      return {
        ...state,
        name: action.payload
      }
    case ActionTypes.LOCATION_CHANGE:
        return shouldCleanup(action.payload) ? {...initialState} : state;
    default:
      return state
  }
}; 
