import paths from '../../constants/paths';
import VisibilityIcon from '@material-ui/icons/Visibility';

export const tabs = [
    {
        label: "Alerts.Label.DataAlerts",
        value: 'data'
    },
    {
        label: "Alerts.Label.SystemAlerts",
        value: 'system'
    },
]

export const tables = {
    data: {
        options: {
            select: false,
            header: true
        },
        style: {
            header: {
                fontWeight: 'bold'
            }
        },
        fields: [
            {
                label: "Inbox.Label.AlertName",
                type: '',
                id: 'alertName',
                sortBy: 'name',
                style: { flex: 3 },
                getValue: row => row.alertName,
                onClick: null
            },
            {
                label: "Inbox.Label.Last10",
                type: '',
                id: 'last10Triggered',
                style: { flex: 3 },
                getValue: row => row.last10Triggered,
                onClick: null
            },
            {
                label: "Inbox.Label.Last100",
                type: '',
                id: 'last100Triggered',
                style: { flex: 3 },
                getValue: row => row.last100Triggered,
                onClick: null
            },
            {
                label: "Inbox.Label.LastScan",
                type: '',
                id: 'lastScan',
                sortBy: 'lastScan',
                style: { flex: 3 },
                getValue: row => row.lastScan,
                onClick: null
            },
            {
                label: "Inbox.Label.LastTriggered",
                type: '',
                id: 'lastTriggered',
                sortBy: 'lastTriggered',
                style: { flex: 3 },
                getValue: row => row.lastTriggered,
                onClick: null
            },
            {
                label: "Inbox.Label.View",
                type: 'navIcon',
                id: 'view',
                Icon: VisibilityIcon,
                style: { flex: 2 },
                nav: selection => paths.getInboxData(selection.link)
            },
        ]
    },
    system: {
        options: {
            select: false,
            header: true
        },
        style: {
            header: {
                fontWeight: 'bold'
            }
        },
        fields: [
            {
                label: "Inbox.Label.AlertName",
                type: '',
                id: 'alertName',
                sortBy: 'name',
                style: { flex: 3 },
                getValue: row => row.alertName,
                onClick: null
            },
            {
                label: "Inbox.Label.TaskName",
                type: '',
                id: 'taskName',
                //sortBy: 'taskName',
                style: { flex: 3 },
                getValue: row => row.taskName,
                onClick: null
            },
            {
                label: "Inbox.Label.ApplicationName",
                type: '',
                id: 'applicationName',
                //sortBy: 'applicationName',
                style: { flex: 3 },
                getValue: row => row.applicationName,
                onClick: null
            },
            {
                label: "Inbox.Label.TriggerType",
                type: '',
                id: 'triggerType',
                //sortBy: 'triggerType',
                style: { flex: 3 },
                getValue: row => row.triggerType,
                onClick: null
            },
            {
                label: "Inbox.Label.ExecutionStatus",
                type: '',
                id: 'executionStatus',
                style: { flex: 2 },
                getValue: row => row.executionStatus,
                onClick: null
            },
            {
                label: "Inbox.Label.View",
                type: 'navIcon',
                id: 'view',
                Icon: VisibilityIcon,
                style: { flex: 2 },
                nav: selection => paths.getInboxSystem(selection.link)
            },
        ]
    },
}
