import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";
import {
  Button,
  IconButton,
  Toolbar,
  AppBar,
  Typography
} from "@material-ui/core";
import CloseIcon from '@qlik-trial/sprout/icons/Close';
import PreviousIcon from '@qlik-trial/sprout/icons/Previous';
import paths from '../../../../constants/paths';
import useNavBlocker from "../../../NavigationBlocker/hooks/useNavBlocker";
import { prepareAlert, } from "../../../../containers/AlertsDetails/dataHandler";
import SaveWarningModal, { CONSTANTS as ModalType } from '../../../SaveWarningModal';
import * as alertsDetailsActions from "../../../../containers/AlertsDetails/actions";

const AlertCreationHeader = ({ title, alertId, alertType }) => {
  const { unblockNavigation } = useNavBlocker();
  const dispatch = useDispatch();
  const alertsDetails = useSelector(
    ({ alertsDetailsReducer }) => alertsDetailsReducer
  );

  const alertBody = useRef();
  const [modalType, setModalType] = useState(ModalType.NOT_VISIBLE);


  const handleDiscardExit = async () => {
    await unblockNavigation();
    dispatch(push(paths.alerts));
  }
  
  const handleSaveAndExit = async () => {
    await unblockNavigation();
    dispatch(alertsDetailsActions.saveAlert(alertType, alertBody.current, alertId));
    dispatch(push(paths.alerts));
  }

  const handleBackButtonClick = async () => {
    try {
      alertBody.current = prepareAlert[alertType](alertsDetails);
      handleSaveAndExit();
    } catch(error) {
      setModalType(ModalType.INCOMPLETE_ALERT);
    }
  }

  const handleClose = async () => {
    try {
      alertBody.current = prepareAlert[alertType](alertsDetails);
      setModalType(ModalType.UNSAVED_CHANGES);
    } catch(error) {
      setModalType(ModalType.INCOMPLETE_ALERT);
    }
  }

  return (
    <>
      <AppBar position="static" style={{flexBasis: 'auto'}}>
        <Toolbar>
            <Button 
              size="small"
              variant="outlined"
              style={{ minWidth: 'auto', height: 'auto', padding: '8px' }}
              onClick={handleBackButtonClick}
            >
              <PreviousIcon style={{ margin: '0px' }}/>
            </Button>
          <div style={{ paddingLeft: '32px' }}>
            <Typography variant="h3">{title}</Typography>
          </div>
          <IconButton 
            tid="close_alerts"
            size="small"
            variant="outlined"
            style={{ marginLeft: 'auto', height: 'auto', padding: '8px' }}
            onClick={handleClose}
          >
            <CloseIcon/>
          </IconButton>
        </Toolbar>
      </AppBar>
      <SaveWarningModal
        type={modalType}
        setModal={setModalType}
        onSave={handleSaveAndExit}
        onDiscard={handleDiscardExit}
      />
    </>
  );
}

export default AlertCreationHeader;