import ActionTypes from "../../constants/actionTypes";
import * as definitions from "./definitions";
import { shouldCleanup } from "../../utils/navigationCleanup";

const initialState = {
  ...definitions,
  title: "",
  scans: [],
  scanDetails: { data: [], measures: [], dimensions: [] },
  sortBy: 1,
  sortAscending: false,
  csvFile: "",
};

export default function inboxDataReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_INBOX_SCANS:
      return {
        ...state,
        title: action.payload[0].name,
        scans: action.payload,
      };
    case ActionTypes.GET_INBOX_SCAN_DETAILS:
      return {
        ...state,
        scanDetails: action.payload,
        reportedDataTable: {
          options: { header: true },
          fields: action.payload.headers.map((each, i) => ({
            label: each,
            id: each,
            getValue: (row) => row[i],
            type:
              action.payload.dimensions.length && i === 0 ? "navIcon" : null,
            getLink: (row) =>
              action.payload.dimensions.length
                ? `${action.payload.baseLink}/select/${action.payload.dimensions[0].def}/${row[0]}`
                : null,
          })),
        },
      };
    case ActionTypes.LOCATION_CHANGE:
      return shouldCleanup(action.payload) ? { ...initialState } : state;
    case ActionTypes.GET_INBOX_SCAN_CSV:
      return {
        ...state,
        csvFile: action.payload,
      };
    default:
      return state;
  }
}
