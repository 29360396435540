import ActionTypes from "../../constants/actionTypes";
import API from "../../lib/api";
import { isValidHttpUrl } from "../../utils/validateURL";
import { push } from "connected-react-router";
import paths from "../../constants/paths";
import {  channel, source, config } from "./dataHandler";

export const registerInitialStep = (steps, pending) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.REGISTER_INITIAL_STEP,
      payload: Math.min(...steps.filter(each => pending.includes(each.key)).map(e => e.index)) || 0
    })
  }
}

export const registerUploadSourceCerts = (key, content) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.REGISTER_UPLOAD_SOURCE_CERTS,
        payload: { key, content },
      });
      dispatch({
        type: ActionTypes.SNACK_SUCCESS,
        payload: { snackMessage: "Snackbar.Register.uploadSourceCerts" },
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: { snackMessage: typeof error === "string" ? error : JSON.stringify(error) },
      });
    }
  };
};

const verifySsoUrl = (url) =>{
  return new Promise((resolve,reject)=>{
   let popupWindow = window.open(url,"QlikAlerting","toolbar=no,status=no,menubar=no,location=center,scrollbars=no,resizable=no,height=300,width=300");
   window.receiveDataFromPopup = function(data) {
      popupWindow.close()
      if (data === true) resolve({ type: 'SUCCESS' })
      else reject({ type: 'DISMISS' })
   };
   const interval = setInterval(() => {
      if (popupWindow?.closed) {
        if (resolve) resolve({ type: 'DISMISS' });
        clearInterval(interval);
        popupWindow.close()
      }
    }, 1000);
    const timeout = setTimeout(() => {
      if (!popupWindow?.closed) {
        if (resolve) resolve({ type: 'DISMISS' });
        clearInterval(timeout);
        popupWindow.close()
      }
    }, 30000);
  })
}

export const testSource = input => {
  return async dispatch => {
    const data = source(input);
    try {
      if (data.config.ssoEnabled === true) {
        const isValidURL = isValidHttpUrl(data.config.authenticationURI);
        if (!isValidURL || !data.config.authenticationURI.includes('content')) throw "Invalid SSO URL";
        else {
          const ssoResp = await verifySsoUrl(`${data.config.authenticationURI}?redirectType=verifySSO&linkingUri=${window.location}`)
          if (ssoResp.type == 'DISMISS') throw "Invalid SSO URL. Please contact your administrator."
        }
      }
      const result = await API.registerSourceTest(data);
      if(result.success) {
      dispatch({
        type: ActionTypes.SNACK_SUCCESS,
        payload: { snackMessage: `Apps: ${result.apps}. Tasks: ${result.tasks}. Users: ${result.users}` }
      });
      dispatch({
        type: ActionTypes.REGISTER_SOURCE_TEST,
        payload: true,
      });
    } else {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: { snackMessage: result.errors }
      });
    }
    } catch (error) {
      dispatch({
        type: ActionTypes.TEST_SOURCE,
        payload: { data, result: null, success: false }
      });
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: { snackMessage: typeof error === "string" ? error : JSON.stringify(error) },
      });
    }
  }
}

export const registerSource = (input) => {
  return async (dispatch) => {
    try {
      const data = source(input);
      await API.registerSource(data);
      dispatch({
        type: ActionTypes.SNACK_SUCCESS,
        payload: { snackMessage: "Snackbar.Register.registerSource" },
      });
      dispatch({
        type: ActionTypes.REGISTER_SOURCE,
        payload: null,
      });
      dispatch({
        type: ActionTypes.SNACK_SUCCESS,
        payload: { snackMessage: "Snackbar.Register.syncQlikUsers" },
      });
      dispatch({
        type: ActionTypes.REGISTER_COMPLETED_STEP,
        payload: 0,
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: { snackMessage: typeof error === "string" ? error : JSON.stringify(error) },
      });
    }
  };
};

export const registerConfig = (input) => {
  return async (dispatch) => {
    try {
      const data = config(input);
      await API.registerConfig(data);
      dispatch({
        type: ActionTypes.REGISTER_CONFIG,
        payload: null,
      });
      dispatch({
        type: ActionTypes.SNACK_SUCCESS,
        payload: { snackMessage: "Snackbar.Register.editPingSettings" },
      });
      dispatch({
        type: ActionTypes.REGISTER_COMPLETED_STEP,
        payload: 1,
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: { snackMessage: typeof error === "string" ? error : JSON.stringify(error) },
      });
    }
  };
};

export const registerChannel = (input) => {
  return async (dispatch) => {
    try {
      const data = channel(input);
      await API.registerChannel(data);
      dispatch({
        type: ActionTypes.REGISTER_CHANNEL,
        payload: null,
      });
      dispatch({
        type: ActionTypes.SNACK_SUCCESS,
        payload: { snackMessage: "Snackbar.Register.channelSuccessfullyCreated" },
      });
      dispatch({
        type: ActionTypes.REGISTER_COMPLETED_STEP,
        payload: 2,
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: { snackMessage: typeof error === "string" ? error : JSON.stringify(error) },
      });
    }
  };
};

export const findRegistrationUser = (user) => {
  return async (dispatch) => {
    try {
      const result = await API.findRegistrationUser({ user });
      dispatch({
        type: ActionTypes.FIND_REGISTRATION_USER,
        payload: result,
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: { snackMessage: typeof error === "string" ? error : JSON.stringify(error) },
      });
    }
  };
};

export const registerAdmin = (userId, email) => {
  return async (dispatch) => {
    try {
      await API.registerAdmin(userId, { email });
      dispatch({
        type: ActionTypes.REGISTER_ADMIN,
        payload: null,
      });
      dispatch({
        type: ActionTypes.SNACK_SUCCESS,
        payload: { snackMessage: "Snackbar.Register.adminDataUpdated" },
      });
      dispatch({
        type: ActionTypes.REGISTER_COMPLETED_STEP,
        payload: 3,
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: { snackMessage: typeof error === "string" ? error : JSON.stringify(error) },
      });
    }
  };
};

export const addValueToRegister = (field, value) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.ADD_VALUE_TO_REGISTER,
      payload: { field, value },
    });
    if (field === 'ssoEnabled') {
      dispatch({
        type: ActionTypes.SSO_FLAG_UPDATE,
        payload: { [field]: (value === 'true' || value === true) }
      })
    }
  };
};

export const goToLogin = () => {
  return async (dispatch) => {
    try {
      dispatch(push(paths.login));
      dispatch({
        type: ActionTypes.REGISTER_CLEAR,
        payload: null,
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: { snackMessage: typeof error === "string" ? error : JSON.stringify(error) },
      });
    }
  };
};

export const registerClear = () => {
  return async (dispatch) => {
    try {
      await API.clearRegistration();
      dispatch(push(paths.login));
      dispatch({
        type: ActionTypes.REGISTER_CLEAR,
        payload: null,
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: { snackMessage: typeof error === "string" ? error : JSON.stringify(error) },
      });
    }
  };
};
