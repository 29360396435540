import Actions from "../../constants/actionTypes";
import paths from "../../constants/paths";
import ConfigStep from '../../components/StepsRegister/Config'
import EmailStep from '../../components/StepsRegister/Email'
import QlikStep from '../../components/StepsRegister/Qlik'
import SelectAdmin from '../../components/StepsRegister/SelectAdmin'

export const steps = [
  { index: 0, label: "Register.Label.QlikConnection", key: "sources", component: QlikStep },
  { index: 1, label: "Register.Label.Config", key: "config", component: ConfigStep },
  { index: 2, label: "Register.Label.Email", key: "channels", component: EmailStep },
  { index: 3, label: "Register.Label.SelectAdmin", key: "admins", component: SelectAdmin },
];

export const modals = {
  cancel: {
    options: {
      title: "General.Cancel"
    },
    content: "Register.Modal.Content",
    buttons: [
      {
        key: "notLeave",
        label: "General.Cancel",
        onClick: null,
        variant: "outlined",
      },
      {
        key: "leave",
        label: "General.Confirm",
        onClick: Actions.REGISTER_CLEAR,
        color: "primary"
      }
    ]
  },
  redirect: {
    options: {
      title: "Register.Completion.Title"
    },
    content: "Register.Completion.Content",
    buttons: [
      {
        key: "trigger",
        label: "Register.Completion.GoToLogin",
        nav: paths.login,
        onClick: 'goToLogin',
        color: "primary"
      }
    ]
  }
};

export const bottomBar = {
  options: {},
  buttons: {
    right: [
      {
        key: "cancel",
        type: "button",
        label: "General.Cancel",
        onClick: "handleCancel",
        display: () => true
      },
      {
        key: "test",
        type: "button",
        label: "General.Test",
        onClick: "handleTest",
        display: (s,l,state) => state.activeStep === 0
      },
      {
        key: "back",
        type: "button",
        label: "General.Back",
        onClick: "handleBack",
        display: (s,l,state) => state.activeStep !== 0 && state.activeStep !== 4,
      },
      {
        key: "save",
        type: "button",
        label: "General.Save",
        variant: "contained",
        color: "primary",
        onClick: "handleSave",
        disabled: (s,l,state) => state.activeStep === 0 && !state.tested,
        display: () => true
      }
    ]
  }
};

export const uploaderCerts = {
  options: {
    onUpload: "registerUploadSourceCerts",
    buttonLabel: "Register.Button.SelectCertificates"
  },
  files: [
    {
      name: "client.pem",
      label: "Register.Label.ClientFile",
      key: "client",
      required: true
    },
    {
      name: "client_key.pem",
      label: "Register.Label.ClientKeyFile",
      key: "client_key",
      required: true
    }
  ]
};
