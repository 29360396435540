import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "connected-react-router";
import * as channelsActions from "./actions";
import Table from "../../components/Table";
import BreadcrumbsHeader from "../../components/BreadcrumbsHeader";
import { Container, Grid } from "@material-ui/core";
import TableHeader from "../../components/TableHeader";
import { makeStyles } from "@material-ui/core/styles";
import paths from "../../constants/paths";


const useStyles = makeStyles((theme) => ({
  container: {
    overflowY: "hidden",
    flex: "1 1 auto",
  },
  tableWrapper: {
    overflowX: "hidden",
    overflowY: "auto",
  },
  contentArea: {
    marginTop: theme.spacing(4),
    maxHeight: "100%",
    flexWrap: "nowrap",
  },
  searchActionBar: {
    flex: "1 1 auto",
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const Channels = () => {
  const dispatch = useDispatch();
  const { current: actions } = useRef(
    bindActionCreators({ ...channelsActions }, dispatch)
  );
  const channels = useSelector(({ channelsReducer }) => channelsReducer);
  const login = useSelector(({ loginReducer }) => loginReducer);

  const classes = useStyles();

  useEffect(() => {
    if(login.isAdmin) actions.getChannels()
    else dispatch(push(paths.alerts))
  }, []);

  const handleEdit = (id) => {
      dispatch(push(paths.getChannelsDetails(id)));
  };

  return (
    <Container className={classes.container}>
      <Grid container className={classes.contentArea} direction="column">
        <Grid container>
          <BreadcrumbsHeader crumbs={channels.crumbs} />
        </Grid>
        <TableHeader />
        <Grid item className={classes.tableWrapper}>
          <Table
            actions={{ handleEdit }}
            data={Object.values(channels.data)}
            dataIds={channels.dataIds}
            {...channels.table}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Channels;
