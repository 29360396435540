import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { Button, Toolbar, AppBar, Typography } from "@material-ui/core";
import PreviousIcon from "@qlik-trial/sprout/icons/Previous";
import paths from "../../../../constants/paths";

const InboxDataHeader = () => {
  const dispatch = useDispatch();
  const inboxData = useSelector(({ inboxDataReducer }) => inboxDataReducer);
  const handleBackButtonClick = async () => dispatch(push(paths.inbox));

  return (
    <AppBar position="sticky" style={{ flexBasis: "auto" }}>
      <Toolbar>
        <Button
          tid="back_button"
          size="small"
          variant="outlined"
          style={{ minWidth: "auto", height: "auto", padding: "8px" }}
          onClick={handleBackButtonClick}
        >
          <PreviousIcon style={{ margin: '0px' }}/>
        </Button>
        <div style={{ paddingLeft: "32px" }}>
          <Typography variant="h3">{inboxData.title}</Typography>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default InboxDataHeader;
