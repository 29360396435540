import React from "react";
import {
  makeStyles,
  FormControl,
  FormLabel,
  OutlinedInput,
  Tooltip,
} from "@material-ui/core";
import useString from "../../i18n/hooks/useString";

const useStyles = makeStyles((theme) => ({
  fullWidthControl: {
    width: "100%",
  },
  marginBottom: {
    marginBottom: theme.spacing(),
  },
  helperText: {
    fontWeight: "normal",
    marginTop: "0.4em",
    marginLeft: "0.4em",
    color: ({ helperTextColor }) => helperTextColor ? helperTextColor : theme.palette.text.primary,
    '&.Mui-focused:not(.Mui-error)': {
      color: ({ helperTextColor }) => helperTextColor ? helperTextColor : theme.palette.text.primary,
    },
    '&.Mui-error': {
      color: theme.palette.error.main,
    }
  },
  secondaryLabel: {
    color: theme.palette.text.secondary,
  }
}));

const WrappedTextField = ({
  title,
  tooltipProps,
  helperText,
  helperTextColor,
  label,
  labelDetails,
  error,
  ...rest
}) => {
  const getString = useString();
  const classes = useStyles({ helperTextColor });

  const renderTextField = () => (
    <FormControl error={error} tid="form_control" className={classes.fullWidthControl}>
      <FormLabel className={classes.marginBottom}> {getString(label)} <span className={classes.secondaryLabel}>{labelDetails}</span></FormLabel>
      <OutlinedInput
        tid="text_field"
        variant="outlined"
        fullWidth
        {...rest}
      />
      {helperText && <FormLabel tid="form_label" className={classes.helperText}>{helperText} </FormLabel>}
    </FormControl>
  );
  return title ? (
    <Tooltip
      tid="WrappedTextField__Tooltip"
      title={getString(title)}
      {...tooltipProps}
    >
      {renderTextField()}
    </Tooltip>
  ) : (
    renderTextField()
  );
};

export default WrappedTextField;
