import React, { useState } from 'react'
import { useHistory, Prompt } from 'react-router-dom'
import Modal from '../Modal';
import useNavBlocker from './hooks/useNavBlocker';
import useString from '../../i18n/hooks/useString';

const NavigationBlocker = () => {
  const { unblockNavigation, isNavigationBlocked } = useNavBlocker();
  const getString = useString();
  const history = useHistory();
  const [modalVisible, setModalVisible] = useState(false);
  const [requestedLocation, setRequestedLocation] = useState(null);

  const handleBlockedNavigation = (nextLocation) => {
    if (isNavigationBlocked){
        setRequestedLocation(nextLocation);
        setModalVisible(true)
        return false
    }
    return true
  };

  const handleConfirmNavigationClick = async () =>{
    await unblockNavigation();
    history.push(requestedLocation.pathname);
  };

  return (
    <>
      <Prompt
        tid="prompt"
        when={isNavigationBlocked}
        message={handleBlockedNavigation}
      />
      <Modal
        tid="modal"
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        content={getString('NavigationBlocker.Warning.Content')}
        options={{title: 'NavigationBlocker.Warning.Title'}}
        buttons={[
            {
              key: "cancel",
              label: "General.Cancel",
              onClick: null,
              variant: "outlined"
            },
            {
              key: "confirm",
              label: "General.Confirm",
              onClick: handleConfirmNavigationClick,
              color: "primary"
            }
          ]}
        />
    </>
  )
}

export default NavigationBlocker;