import ActionTypes from "../../constants/actionTypes";
import API from "../../lib/api";
import { getDevices } from "./dataHandler";

export const generateQrToken = () => {
  return async dispatch => {
    try {
      const data = await API.generateQRToken();
      dispatch({
        type: ActionTypes.GET_QR_TOKEN,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: { snackMessage: typeof error === "string" ? error : JSON.stringify(error) }
      });
    }
  };
};

export const removeDevice = id => {
  return async dispatch => {
    try {
      await API.deleteDevice(id);
      dispatch({
        type: ActionTypes.REMOVE_DEVICE,
        payload: id
      });
      dispatch({
        type: ActionTypes.SNACK_SUCCESS,
        payload: { snackMessage: "Snackbar.Devices.removeDevice" }
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: { snackMessage: typeof error === "string" ? error : JSON.stringify(error) }
      });
    }
  };
};

export const loadDevices = () => {
  return async dispatch => {
    try {
      const result = await API.getDevices();
      const data = getDevices(result);
      dispatch({
        type: ActionTypes.LOAD_DEVICES,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: { snackMessage: typeof error === "string" ? error : JSON.stringify(error) }
      });
    }
  };
};

export const toggleDevice = (id, isActive) => {
  return async dispatch => {
    try {
      await API.toggleDevice(id, isActive);
      dispatch({
        type: ActionTypes.TOGGLE_DEVICE,
        payload: { id, isActive }
      });
      dispatch({
        type: ActionTypes.SNACK_SUCCESS,
        payload: { snackMessage: isActive
          ? "Snackbar.Devices.disabled"
          : "Snackbar.Devices.enabled"
         }
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: { snackMessage: typeof error === "string" ? error : JSON.stringify(error) }
      });
    }
  };
};
