import ActionTypes from "../../constants/actionTypes";
import * as definitions from "./definitions";
import { shouldCleanup } from '../../utils/navigationCleanup';

const initialState = {
  ...definitions,
  unassignedData: {},
  assignedData: {},
  assigned: []
};

export default function usersPrivilegesDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_USERS_PRIVILEGES_DETAILS:
      return {
        ...state,
        ...action.payload
      };
    case ActionTypes.FILTER_USERS_PRIVILEGES_DETAILS:
      return {
        ...state,
        filtered: action.payload.filtered,
        unassignedData: action.payload.unassignedData.reduce((prev, cur) => {
          prev[cur.id] = cur;
          return prev;
        }, {}),
        assignedData: action.payload.assignedData.reduce((prev, cur) => {
          prev[cur.id] = cur;
          return prev;
        }, {}),
        assigned: action.payload.assignedData.map(({ id }) => id)
      };
    case action.CANCEL_EDIT_PRIVILEGES:
      return state
    case ActionTypes.LOCATION_CHANGE:
        return shouldCleanup(action.payload) ? {...initialState} : state;
    default:
      return state
  }
};
