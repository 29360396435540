import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { bindActionCreators } from "redux";
import * as devicesActions from "./actions";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Fab,
  Typography,
  Container,
  Grid,
  Switch
} from "@material-ui/core";
import { Add, Delete } from "@material-ui/icons";
import useStyles from "./styles";
import QRCode from "qrcode.react";
import CardDeck from "../../components/CardDeck";
import useString from "../../i18n/hooks/useString";


const Devices = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const getString = useString();
  const { current: actions } = useRef(bindActionCreators({ ...devicesActions }, dispatch));
  const devices = useSelector(({ devicesReducer }) => devicesReducer);

  useEffect(() => {
    actions.loadDevices();
  }, []);

  const [qrVisible, setQrVisible] = useState(false);
  const handleDelete = id => actions.removeDevice(id);
  const handleSwitch = (id, boolean) => actions.toggleDevice(id, boolean);
  const handleGetQr = () => {
    actions.generateQrToken();
    setQrVisible(true);
  };

  const handleRefresh = () => {
    actions.loadDevices();
    setQrVisible(false);
  };

  const handleCancel = () => setQrVisible(false);

  const EmptyCard = index => {
    return (
      <Card
        className={classes.card}
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        key={index}
      >
        <CardContent>
          <Typography
            variant="h6"
            component="h2"
            style={{ marginTop: 20, fontSize: 16, color: "grey" }}
          >
            {getString("Devices.Card.EmptyCard")}
          </Typography>
        </CardContent>
      </Card>
    );
  };

  const FilledCard = data => {
    return (
      <Card className={classes.card} key={data.id}>
        <CardContent>
          <div className={classes.row} style={{ paddingBottom: 20 }}>
            <Typography variant="h5" component="h2">
              {getString("Devices.Card.FilledCard")}
            </Typography>
            <Typography variant="h5" component="h2" style={{ paddingLeft: 10 }}>
              {data.name}
            </Typography>
          </div>
          <div className={classes.row}>
            <Typography
              variant="body1"
              component="h2"
              style={{ paddingRight: 20 }}
            >
              {getString("Devices.Card.DeviceStatus")}
            </Typography>
            <Switch
              id="status"
              checked={data.isActive}
              color="primary"
              onClick={e => handleSwitch(data.id, e.target.checked)}
            />
          </div>
          <div className={classes.row}>
            <Typography variant="body1" component="h2">
            {getString("Devices.Card.DeviceType")}
            </Typography>
            <Typography
              variant="body1"
              component="h2"
              style={{ paddingLeft: 10 }}
            >
              {data.type}
            </Typography>
          </div>
          <div className={classes.row}>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              {getString("Devices.Card.Since")}
            </Typography>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              style={{ paddingLeft: 10 }}
            >
              {data.time}
            </Typography>
          </div>
        </CardContent>
        <CardActions className={classes.button}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => handleDelete(data.id)}
            aria-label="delete"
            startIcon={<Delete />}
          >
            {getString("Devices.Card.Remove")}
          </Button>
        </CardActions>
      </Card>
    );
  };

  const QRCard = () => {
    return (
      <div className={classes.main}>
        <Card className={classes.card}>
          <div>
            {qrVisible ? (
              <>
                <CardContent
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                  }}
                >
                  <QRCode value={devices.qrCode} size={150} />
                  <Typography
                    variant="h6"
                    component="h2"
                    style={{ marginTop: 20, fontSize: 16 }}
                  >
                    {getString("Devices.Card.QrCode")}
                  </Typography>
                </CardContent>
                <CardActions
                  className={classes.button}
                  style={{ marginTop: -20 }}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    component="span"
                    onClick={handleRefresh}
                  >
                    {getString("Devices.Card.Refresh")}
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    component="span"
                    onClick={handleCancel}
                  >
                    {getString("General.Cancel")}

                  </Button>
                </CardActions>
              </>
            ) : (
                <>
                  <CardActions className={classes.button}>
                    <Fab color="primary" aria-label="add" onClick={handleGetQr}>
                      <Add />
                    </Fab>
                  </CardActions>
                  <CardContent
                    style={{ alignItems: "center", alignContent: "center" }}
                  >
                    <Typography
                      variant="h6"
                      component="h2"
                      style={{ marginTop: 20, fontSize: 16 }}
                    >
                      {getString("Devices.Card.AddDevice")}
                      <br /> <br />
                      {getString("Devices.Card.DownloadApp")}
                    </Typography>
                  </CardContent>
                </>
              )}
          </div>
        </Card>
      </div>
    );
  };

  return (
    <Container>
      <Grid container>
        <Grid item md={12}>
          <h1 style={{ marginLeft: 20 }}>{getString("Devices.Card.MobileDeviceList")}</h1>
          <CardDeck
            size={5}
            data={Object.values(devices.items)}
            filled={FilledCard}
            firstEmpty={QRCard}
            empty={EmptyCard}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Devices;
