import {dateISO} from "../../utils/dateISO";

export const fromAlerts = {
    data: (input) => {
        if(!Array.isArray(input)) input = [input]
        return input.map(each => {
            return {
                id: each._id,
                name: each.name,
                description: each.description || '',
                updatedAt: dateISO(each.updatedAt || each.createdAt, true),
                isActive: each.isActive,
                appName:  each.app?.name || '',
                owner: each.ownerInfo?.name || ''
            }
        })
    },
    system: (input) => {
      if(!Array.isArray(input)) input = [input]
      return input.map(each => {
          return {
              id: each._id,
              name: each.name,
              description: each.description || '',
              updatedAt: dateISO(each.updatedAt || each.createdAt, true),
              owner: each.ownerInfo?.name,
              isActive: each.isActive
          }
      })
    },
    broadcast: (input) => {
        if(!Array.isArray(input)) input = [input]
        return input.map(each => {
            return {
                id: each._id,
                name: each.name,
                description: each.description || '',
                updatedAt: dateISO(each.updatedAt || each.createdAt, true),
                owner: each.ownerInfo?.name,
                isActive: each.isActive
            }
        })
    }
}

export const groupsHandler = value => {
    if (!Array.isArray(value)) value = [value];
    return value.map(each => {
      return {
        id: each._id,
        name: each.name,
        count: each.count
      };
    });
  };
  
export const usersHandler = (input) => {
  if(!Array.isArray(input)) input = [input]
    return input.map(each => {
        return {
            id: each._id,
            name: each.name,
            firstName: each.firstName || '',
            lastName: each.lastName || '',
            domain: each.domain
        }
    })
};
