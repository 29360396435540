import React from "react";
import { NavLink } from "react-router-dom";
import { AppBar, Button, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useString from "../../i18n/hooks/useString";

const useStyles = makeStyles(theme => ({
  appBar: {
    top: 'auto',
    bottom: 0,
    boxShadow: 'inset 0 1px 0 0 rgba(0, 0, 0, 0.15)',
  },
  actionButtons: {
    margin: '5px'
  },
  navLink: {
    textDecoration: 'none'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0px 5px'
  },
  noWrap: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  selectedLabel: {
    fontWeight: 'bold',
    fontSize: '14px',
    paddingRight: '20px',
    paddingLeft: '20px',
  },
}));



const BottomBar = props => {
  const { actions, selected = [], buttons = {}, dataLength = 0, reducerState } = props;
  const { left = [], right = [], back = [] } = buttons;
  const classes = useStyles();
  const getString = useString();

  const getButton = (button) => {
    const { key, type, onClick, display, Icon, nav, label, disabled, variant, color } = button;
    const onClickHandler = () => {
      if (onClick) {
        if (actions[onClick]) actions[onClick](selected, reducerState);
        else onClick(selected, reducerState);
      }
    };

    let displayFunction;
    if (typeof display === "function") {
      displayFunction = display;
    } else if (actions[display]) {
      displayFunction = actions[display];
    }

    if (display === true || (displayFunction && displayFunction(selected, dataLength, reducerState))) {
      let disabledValue = false
      if(typeof disabled === "boolean") disabledValue = disabled;
      else if (typeof disabled === "function") disabledValue = disabled(selected, dataLength, reducerState);
      else if (actions[disabled]) disabledValue = actions[disabled]();
      if (nav && !disabledValue) {
        return (
          <NavLink tid="nav_link_wrapper" className={classes.navLink}to={typeof nav === "function" ? nav(selected) : nav}>
            {type === "icon" ? (
              <IconButton tid="icon_button_nav_link" component={Icon} />
            ) : (
              <Button tid="button_nav_link" className={classes.actionButtons} color={color} variant={variant || "outlined"}>{getString(label) || key}</Button>
            )}
          </NavLink>
        );
      } else {
        return type === "icon" ? (
          <IconButton tid="icon_button_disabled" disabled={disabledValue} onClick={onClickHandler} component={Icon}/>
        ) : (
          <Button tid={`button_${key}`} className={classes.actionButtons} color={color} variant={variant || "outlined"} disabled={disabledValue} onClick={onClickHandler}>
            {getString(label) || key}
          </Button>
        );
      }
    }
  };

  return (
    <AppBar tid="app_bar" position="relative" className={classes.appBar}>
        <div tid="app_bar_container" className={classes.container}>
          <div tid="div_app_bar_back">
            {back.map((each, i) => (
              <React.Fragment key={each.key}>
                {getButton(each)}
              </React.Fragment>
            ))}
          </div>
          <div tid="div_app_bar_left">
            {left.map((each, i) => (
              <React.Fragment key={each.key}>
                {getButton(each)}
              </React.Fragment>
            ))}
          </div>
          <div tid="div_app_bar_right">
            {!!selected.length &&
              <span tid="span_selected" className={classes.selectedLabel}>
                 {`Selected ${selected.length}`}
              </span>
            }
            {right.map((each, i) => (
              <React.Fragment key={each.key}>
                {getButton(each)}
              </React.Fragment>
            ))}
          </div>
        </div>
    </AppBar>
  );
};

export default BottomBar;
