import ActionTypes from "../../constants/actionTypes";
import API from "../../lib/api";
import { toUsersGroupsDetails, fromForm } from "./dataHandler";
import { push } from 'connected-react-router';
import paths from '../../constants/paths';

export const getUsersGroupsDetails = id => {
  return async dispatch => {
    try {
      const groupsResult = id ? await API.getOneGroup(id) : {};
      dispatch({
        type: ActionTypes.GET_USERS_GROUPS_DETAILS,
        payload: {
          name: groupsResult.name || '',
          assigned: groupsResult.ids || []
        }
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: { snackMessage: typeof error === "string" ? error : JSON.stringify(error) }
      });
    }
  };
};

export const setGroupName = value => dispatch => {
  dispatch({
    type: ActionTypes.SET_GROUP_NAME,
    payload: value
  })
}

export const filterUsersGroupsDetails = (id, { filter, offset = 0, size = 50 } = {}) => {
  return async dispatch => {
    try {
      const filterString = filter ? `name $so ${filter} $or firstName $so ${filter} $or lastName $so ${filter} $or domain $so ${filter} $or channels.email.connection $so ${filter}` : '';
      const unassigned = id
        ? await API.getGroupsUsers({ id, includes: false, offset, size, filter: `( ${filterString} ) $and isActive $eq true` })
        : await API.getUsers({ offset, size, filter: `(${filterString}) $and isActive $eq true` })
      const assigned = id ? await API.getGroupsUsers({ id, includes: true, offset, size, filter: `( ${filterString} ) $and isActive $eq true` }) : []
      const unassignedData = toUsersGroupsDetails(unassigned);
      const assignedData = toUsersGroupsDetails(assigned);
      dispatch({
        type: ActionTypes.FILTER_USER_GROUPS_DETAILS,
        payload: {
          filtered: !!filter,
          unassignedData,
          assignedData
        }
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: { snackMessage: typeof error === "string" ? error : JSON.stringify(error) }
      });
    }
  };
};

export const editUsersGroupsDetails = (id, name, newAssigned) => {
  return async dispatch => {
    try {
      if (newAssigned.length > 1000) {
        dispatch({
          type: ActionTypes.SNACK_WARNING,
          payload: { snackMessage: "Snackbar.UsersGroupsDetails.limit1000" }
        });
      } else {
        const data = fromForm(name, newAssigned);
        const result = id
          ? await API.updateGroup(id, data)
          : await API.createGroup(data);
        dispatch({
          type: ActionTypes.EDIT_USERS_GROUPS_DETAILS,
          payload: {
            data,
            result
          }
        });
        dispatch({
          type: ActionTypes.SNACK_SUCCESS,
          payload: { snackMessage: "Snackbar.UsersGroupsDetails.editUsersGroupsDetails" }
        });
        dispatch(push(paths.usersGroups))
      }
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: { snackMessage: typeof error === "string" ? error : JSON.stringify(error) }
      });
    }
  };
};

export const cancelEditUserGroup = () => {
  return dispatch => {
    dispatch({
      type: ActionTypes.CANCEL_EDIT_USERGROUP
    })
    dispatch(push(paths.usersGroups));
  }
};

export const fullGroupsAssignation = (id, type, name) => {
  return async dispatch => {
    try {
      if (!id) {
        if(!name) throw "Snackbar.UsersGroupsDetails.ErrorNameRequired"
        const data = fromForm(name, [])
        const { id: newGroupId } = await API.createGroup(data)
        await API.fullAssignToGroup(newGroupId, type)
      } else {
        await API.fullAssignToGroup(id, type)
      }
      dispatch({
        type: ActionTypes.SNACK_SUCCESS,
        payload: { snackMessage: "Snackbar.UsersGroupsDetails.editUsersGroupsDetails"}
      });
      dispatch(push(paths.usersGroups))
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: { snackMessage: typeof error === "string" ? error : JSON.stringify(error) }
      });
    }
  };
};
