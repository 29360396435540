import { useCallback } from 'react';
import { useSelector } from 'react-redux';

export const replacePlaceholders = (template, replacementArray) => {
  const regex = /({([^{}]*)})/gm;
  const replaceFn = (fullMatch, firstGroup, secondGroup) => {
    return replacementArray[secondGroup];
  }
  return template.replace(regex, replaceFn);
};

const useString = () => {
  const translations = useSelector(state => state.translationsReducer?.translations);
  const translate = useCallback((stringId, replacementArray) => {
    const template = translations?.[stringId]?.value || stringId;
    if(replacementArray) {
      return replacePlaceholders(template, replacementArray);
    }
    return template;
  }, [translations]);
  return translate;
};

export default useString;