export const mainTable = {
  options: {
    header: true,
    select: true,
    onSelect: "setSelected",
    hideSelections: true,
  },
  fields: [
    {
      label: "InboxData.MainTable.Title",
      type: "",
      id: "title",
      getValue: (row) => row.title,
      action: null,
    },
    {
      label: "InboxData.MainTable.TriggeredData",
      type: "",
      id: "scanTimeStamp",
      getValue: (row) => row.scanTimeStamp,
      action: null,
    },
    {
      label: "InboxData.MainTable.Notified",
      type: "",
      id: "notified",
      getValue: (row) => row.notified,
      action: null,
    },
  ],
};

export const filterTable = {
  options: {
    header: true,
  },
  fields: [
    {
      label: "InboxData.FilterTable.Field",
      id: "field",
      style: { flex: 3 },
      getValue: (row) => row.field,
    },
    {
      label: "InboxData.FilterTable.Selections",
      id: "values",
      style: { flex: 6 },
      getValue: row => row.values.map(each => each.qText || each).join(', '),
    },
  ],
};

export const simpleCard = {
  fields: [
    {
      label: "InboxData.SimpleCard.Title",
      key: "title",
    },
    {
      label: "InboxData.SimpleCard.AppName",
      key: "appName",
      link: true,
    },
    {
      label: "InboxData.SimpleCard.Conditions",
      key: "conditions",
    },
    {
      label: "InboxData.SimpleCard.Rules",
      key: "rules",
    },
    {
      label: "InboxData.SimpleCard.DistributionFrequency",
      key: "distributionFrequency",
    },
  ],
};
