import paths from "../../constants/paths";


export const crumbs = [
  {label: "Breadcrumbs.Admin"},
  {label: "Breadcrumbs.Sources", link: paths.sources},
  {label: "Breadcrumbs.Details"}
]

export const form = {
  options: {},
  fields: [
    {
      id: "hostname",
      name: "hostname",
      label: "SourcesDetails.Label.HostName",
      helperText: "SourcesDetails.HelperText.HostName"
    },
    {
      id: "httpsPort",
      name: "httpsPort",
      label: "SourcesDetails.Label.ListenPort",
      helperText: "SourcesDetails.HelperText.HttpsPort"
    },
    {
      id: "qpsPort",
      name: "qpsPort",
      label: "SourcesDetails.Label.RestApiListenPort",
      helperText: "SourcesDetails.HelperText.QpsPort"
    },
    {
      id: "qrsPort",
      name: "qrsPort",
      label: "SourcesDetails.Label.RepoServiceApiListenPort",
      helperText: "SourcesDetails.HelperText.QrsPort"
    },
    {
      id: "userDirectory",
      name: "userDirectory",
      label: "SourcesDetails.Label.UserDirectory",
      helperText: ""
    },
    {
      id: "userId",
      name: "userId",
      label: "SourcesDetails.Label.ConnectAsUsername",
      helperText: ""
    },
    {
      id: "filter",
      name: "filter",
      label: "Filter for user fetch",
      helperText: "(optional) e.g. \"userDirectory eq 'DIR'\" "
    },
    {
      id: "virtualProxy",
      name: "virtualProxy",
      label: "SourcesDetails.Label.VirtualProxyPrefix",
      helperText: "SourcesDetails.HelperText.VirtualProxy"
    },
    {
      id: "sessionCookieName",
      name: "sessionCookieName",
      label: "SourcesDetails.Label.SessionCookieHeaderName",
      helperText: "SourcesDetails.HelperText.SessionCookieName"
    },
    {
      id: "url",
      name: "url",
      label: "SourcesDetails.Label.AliasHostName",
      helperText: "SourcesDetails.HelperText.AliasHostName"
    },
    {
      id: "proxyUrl",
      name: "proxyUrl",
      label: "Reverse proxy URL redirection",
      helperText: "URL from a reverse proxy, e.g redirectionurl.rdlund.qliktech.com"
    },
    {
      id: 'ssoEnabled',
      name: 'ssoEnabled',
      type: 'radio',
      label: "Authentication",
      value: 'false',
      options: [{ value: "false", name: "Basic" }, { value: "true", name: "SSO" }]
    },
    {
      id: "authenticationURI",
      name: "authenticationURI",
      label: "Authentication URL",
      display: false,
      helperText: "URL for SSO, e.g https://qliksenseserver/content_url_path"
    },
  ]
};

export const bottomBar = {
  options: {},
  buttons: {
    right: [
      {
        key: "cancel",
        type: "button",
        label: "General.Cancel",
        onClick: "handleCancel",
        display: () => true
      },
      {
        key: "test",
        type: "button",
        label: "General.Test",
        onClick: "handleTest",
        display: () => true
      },
      {
        key: "save",
        type: "button",
        label: "General.Save",
        onClick: "handleSave",
        variant: "contained",
        color: "primary",
        disabled: (s, l, state) => !state.tested || state.saveIsDisabled,
        display: () => true
      },
    ]
  }
};

export const uploader = {
  options: {
    onUpload: "uploadSourceCerts",
    //onClear: "clearSource",
    buttonLabel: "SourcesDetails.Button.SelectCertificates"
  },
  files: [
    {
      name: "client.pem",
      label: "SourcesDetails.Tooltip.ClientFile",
      key: "client",
      required: true
    },
    {
      name: "client_key.pem",
      label: "SourcesDetails.Tooltip.ClientKeyFile",
      key: "client_key",
      required: true
    }
  ]
};

export const modal = {
  options: {
    title: "General.Cancel"
  },
  content: "SourcesDetails.Modal.CancelContent",
  buttons: [
    {
      key: "notLeave",
      label: "General.Cancel",
      onClick: null,
      variant: "outlined",
    },
    {
      key: "leave",
      label: "General.Confirm",
      onClick: 'cancelEditSource',
      color: "primary"
    }
  ]
};
