import ActionTypes from "../../constants/actionTypes";
import * as definitions from "./definitions";
import { shouldCleanup } from '../../utils/navigationCleanup';

const initialState = {
  ...definitions,
  data: {},
  dataIds: [],
  showMoreHidden: false
};

export default function mySuggestionsReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_SUGGESTIONS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload.reduce((prev, cur) => {
            prev[cur.id] = cur;
            return prev;
          }, {})
        },
        dataIds: [...new Set([...state.dataIds, ...action.payload.map(each => each.id)])]
      };
    case ActionTypes.RESTART_SUGGESTIONS:
      return {
        ...state,
        data: action.payload.reduce((prev, cur) => {
          prev[cur.id] = cur;
          return prev;
        }, {}),
        dataIds: action.payload.map(each => each.id),
      }
    case ActionTypes.ACCEPT_SUGGESTION:
    case ActionTypes.REJECT_SUGGESTION:
      action.payload.forEach(each => delete state.data[each]);
      return {
        ...state,
        dataIds: state.dataIds.filter(each => !action.payload.includes(each))
      };
    case ActionTypes.SHOW_MORE_SUGGESTIONS:
      return {
        ...state,
        showMoreHidden: action.payload
      }
    case ActionTypes.LOCATION_CHANGE:
        return shouldCleanup(action.payload) ? {...initialState} : state;
    default:
      return state;
  }
}