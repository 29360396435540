import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as alertsActions from "../Alerts/actions";
import { useHistory } from "react-router-dom";
import { push } from "connected-react-router";
import useString from "../../i18n/hooks/useString";
import paths from "../../constants/paths";
import Modal from "../../components/Modal";
import Table from "../../components/Table";
import BufferedInput from "../../components/BufferedInput";
import Share from "../../components/Share";
import { makeStyles } from "@material-ui/core/styles";
import {
  Tab,
  Tabs,
  Container,
  Grid,
  Button,
  MenuItem,
  FormControl,
  Typography,
} from "@material-ui/core";
import SearchInput from "../../components/SearchInput";
import TableHeader from "../../components/TableHeader";
import ActionTypes from "../../constants/actionTypes";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    overflowY: "hidden",
    flex: "1 1 auto",
    paddingBottom: theme.spacing(3),
  },
  tableWrapper: {
    overflowX: "hidden",
    overflowY: "auto",
    paddingBottom: theme.spacing(1),
  },
  contentArea: {
    paddingTop: theme.spacing(4),
    flexWrap: "nowrap",
  },
  searchActionBar: {
    flex: "1 1 auto",
    display: "flex",
    justifyContent: "flex-end",
  },
  selectedLabel: {
    paddingLeft: theme.spacing(),
  },
  itemWrapper: {
    display: "flex",
    marginBottom: "auto",
    alignItems: "center",
  },
  tabs: {
    marginBottom: theme.spacing(3),
  },
}));

const Alerts = () => {
  const classes = useStyles();
  const history = useHistory();
  const getString = useString();
  const dispatch = useDispatch();
  const { current: actions } = useRef(
    bindActionCreators({ ...alertsActions }, dispatch)
  );
  const login = useSelector(({ loginReducer }) => loginReducer);
  const alerts = useSelector(({ alertsReducer }) => alertsReducer);

  const [modal, setModal] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [shareModalVisible, setShareModalVisible] = useState(false);  
  const [selected, setSelected] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [inlineId, setInlineId] = useState(null);
  const [sortField, setSortField] = useState("updatedAt");
  const [sortOrderReverse, setSortOrderReverse] = useState(true);

  const { isAdmin, permissions, license } = login;

  useEffect(() => {
    setSortField('updatedAt')
    setSortOrderReverse(true)
  }, [alerts.tab])

  useEffect(() => {
    actions.getAlerts({
      tab: alerts.tab,
      allAlerts: alerts.allAlerts,
      filter: searchInput,
      sort: sortField,
      reverse: sortOrderReverse,
    });
  }, [actions, alerts.tab, alerts.allAlerts]);

  useEffect(() => {
    if(!login.isAdmin && !login.permissions.includes('share')) alerts.tables.data.menu = alerts.tables.data.menu.filter(each => each.id !== "share")
  }, [login, alerts.tables.data]);
  
  const showMore = () =>
    actions.getAlerts({
      tab: alerts.tab,
      allAlerts: alerts.allAlerts,
      offset: alerts[alerts.tabName + "Ids"].length,
      filter: searchInput,
      sort: sortField,
      reverse: sortOrderReverse,
    });

  const handleChange = (event, newValue) => {
    actions.switchTabs(newValue);
    setSelected([]);
  };

  const handleSearchApply = (value) => {
    actions.getAlerts({
      tab: alerts.tab,
      allAlerts: alerts.allAlerts,
      offset: 0,
      filter: value,
      overwrite: true,
      sort: sortField,
      reverse: sortOrderReverse
    });
    setSearchInput(value);
  };

  const customActions = {
    setSelected: setSelected,
    handleToggle: (row) => {
      actions.toggleAlert(alerts.tab, row.id, !row.isActive, alerts.allAlerts);
      setSelected([]);
    },
    handleTrigger: (id) => {
      if (typeof id === "string") setInlineId(id);
      setModal(alerts.modals.trigger);
      setModalVisible(true);
    },
    handleDelete: (id) => {
      if (typeof id === "string") setInlineId(id);
      setModal(alerts.modals.delete);
      setModalVisible(true);
    },
    handleDuplicate: (id) => {
      if (typeof id === "string") setInlineId(id);
      setModal(alerts.modals.duplicate)
      setModalVisible(true);
    },
    handleShare: (id) => {
      if (typeof id === "string") setInlineId(id);
      actions.getShareAlertRG();
      setShareModalVisible(true);
    },
    handleEdit: (id) => {
      if (alerts.tab === 'data' && !license) {
        dispatch({
          type: ActionTypes.SNACK_WARNING,
          payload: { snackMessage: "Snackbar.Alerts.NotLicensed" },
        })
      } else dispatch(push(paths.getAlertsDetails(alerts.tab, id)));
    },
    handleCreate: () => {
      if (alerts.tab === 'data' && !license) {
        dispatch({
          type: ActionTypes.SNACK_WARNING,
          payload: { snackMessage: "Snackbar.Alerts.NotLicensed" },
        })
      } else history.push(paths.getAlertsDetails(alerts.tab))
    },
    sendDelete: () => {
      actions.deleteAlert(
        alerts.tab,
        inlineId ? [inlineId] : selected,
        alerts.allAlerts
      );
      setSelected([]);
      setInlineId();
    },
    sendDuplicate: () => {
      const selectedForDuplicate = inlineId ? [inlineId] : selected;
      const size = selectedForDuplicate.length + alerts[alerts.tabName + "Ids"].length;
      actions.duplicateAlert({
        selected: selectedForDuplicate, 
        tab: alerts.tab,
        allAlerts: alerts.allAlerts,
        filter: searchInput,
        offset: 0,
        size,
        sort: sortField,
        reverse: sortOrderReverse,
      });
      setSelected([]);
      setInlineId();
    },
    sendShare: (recipients, groups) => {
      actions.shareAlerts(
        alerts.tab,
        inlineId ? [inlineId] : selected,
        recipients,
        groups,
      );
      setSelected([]);
      setInlineId();
    },
    sendTrigger: () => {
      actions.triggerAlert(
        alerts.tab,
        inlineId ? [inlineId] : selected,
        alerts.allAlerts,
        alerts
      );
      setSelected([]);
      setInlineId();
    },
    selectAll: () => setSelected(alerts[alerts.tab + "Ids"]),
    unselectAll: () => setSelected([]),
    sortField: (fieldName, order) => {
      setSortField(fieldName);
      setSortOrderReverse(order === "desc");
      actions.getAlerts({
        tab: alerts.tab,
        allAlerts: alerts.allAlerts,
        filter: searchInput,
        sort: fieldName,
        reverse: order === "desc",
        overwrite: true,
      });
    },
  };  

  const leftTableActions = (
    <>
    {isAdmin ? 
      <FormControl variant="outlined" className={classes.formControl}>
        <BufferedInput
          variant="select"
          onApply={actions.switchAlertFilter}
          value={alerts.allAlerts}
          displayEmpty
        >
          <MenuItem value={false}>
            {getString("Alerts.Dropdown.MyAlerts")}
          </MenuItem>
          <MenuItem value={true}>
            {getString("Alerts.Dropdown.AllAlerts")}
          </MenuItem>
        </BufferedInput>
      </FormControl>
    : null}
      <SearchInput tid={'search_input_alerts_table'} onApply={handleSearchApply} />
      {!!selected.length && (
        <span className={classes.selectedLabel}>
          <Typography variant="body2">
            {`${getString("General.Selected")}: ${selected.length}`}
          </Typography>
        </span>
      )}
    </>
  );

  const rightTableActions = (
    <>
      <Button
        variant="outlined"
        size="medium"
        tid="deleteAlerts"
        onClick={customActions.handleDelete}
        disabled={!selected.length}
      >
        {getString("General.Delete")}
      </Button>
      <Button
          variant="outlined"
          size="medium"
          tid="duplicateAlerts"
          onClick={customActions.handleDuplicate}
          disabled={!selected.length}
        >
          {getString("General.Duplicate")}
        </Button>
      {alerts.tab === "data" && (login.isAdmin || login.permissions.includes("share")) ? 
        <Button
          variant="outlined"
          size="medium"
          tid="shareAlerts"
          onClick={customActions.handleShare}
          disabled={!selected.length}
        >
          {getString("General.Share")}
        </Button>
      : null}
      {alerts.tab === "data" ? (
        <Button
          variant="outlined"
          size="medium"
          tid="triggerAlerts"
          onClick={customActions.handleTrigger}
          disabled={!selected.length}
        >
          {getString("General.Trigger")}
        </Button>
      ) : null}
      <Button
        variant="contained"
        tid="button_create_alert"
        color="primary"
        size="medium"
        onClick={customActions.handleCreate}
      >
        {getString("Alerts.Button.Create")}
      </Button>
    </>
  );

  const tabs = (
    alerts.tabs.map((each) =>
              each.value === "data" ||
              isAdmin ||
              permissions.includes(each.value) ? (
                <Tab
                  key={each.value}
                  value={each.value}
                  label={getString(each.label)}
                  tid={each.tid}
                />
              ) : null
            )
  )

  return (
    <Container className={classes.container}>
      <Grid container className={classes.contentArea} direction="column">
        <Grid item>
          <Tabs
            value={alerts.tab}
            onChange={handleChange}
            className={classes.tabs}
          >
            {tabs}
          </Tabs>
        </Grid>
        <TableHeader
          leftToolbar={leftTableActions}
          rightToolbar={rightTableActions}
        />
        <Grid item className={classes.tableWrapper}>
          <Table
            tid="alerts_table"
            actions={{ ...actions, ...customActions }}
            data={alerts[alerts.tabName + "Ids"].map(
              (each) => alerts[alerts.tabName][each]
            )}
            {...alerts.tables[alerts.tab]}
            selectedIds={selected}
          />
        </Grid>

        {!alerts.showMoreHidden && (
          <Button variant="outlined" onClick={showMore}>
            {getString("General.ShowMore")}
          </Button>
        )}
      </Grid>
      <Modal
        actions={{ ...actions, ...customActions }}
        {...modal}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
      />
      {shareModalVisible &&
        <Share
          actions={actions}
          open={true}
          setOpen={setShareModalVisible}
          onClose={actions.clearShareAlertRG}
          onShare={customActions.sendShare}
          recipients={alerts.recipientList}   
          selectedAlert={inlineId ? [inlineId] : selected}  
          groups={alerts.groupList}
        />
      }
    </Container>
  );
};

export default Alerts;
