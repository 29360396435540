export const toChannelsDetails = (input) => {
    return {
        name:input.name,
        type:input.type,
        connectionHost: input.connection.host,
        connectionPort: input.connection.port,
        connectionAuthUser: input.connection.auth.user,
        connectionAuthSecurity: input.connection.auth.security,
        authenticationMethod: input.connection.authenticationMethod,
        connectionFrom: input.connection.from,
        connectionCC:  input.connection.cc,
        connectionBCC:  input.connection.bcc,
        connectionTest: input.connection.test
    }
}

export const fromForm = values => {
    return {
        name: values.name,
        type: 'email',
        connection: {
            host: values.connectionHost,
            port: values.connectionPort,
            from: values.connectionFrom,
            CC: values.connectionCC,
            BCC: values.connectionBCC,
            test: values.connectionTest,
            authenticationMethod: values.authenticationMethod,
            auth: {
                user: values.connectionAuthUser,
                pass: btoa(unescape(encodeURIComponent(values.connectionAuthPass))),
                security: values.connectionAuthSecurity
            }
        }
    }
}
