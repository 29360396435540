import React from "react";
import { Grid, Menu, MenuItem, IconButton } from "@material-ui/core";
import paths from "../../constants/paths";
import ArrowRight from "@qlik-trial/sprout/icons/ArrowRight";
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router";
import useString from "../../i18n/hooks/useString";

const UserManagementMenu = (props) => {
  const getString = useString();
  const { handleCloseAdmin } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    handleCloseAdmin();
  };

  return (
    <div>
      <Grid style={{ display: "flex", width: "100%" }} onClick={handleClick}>
        {getString("Admin.MenuItem.UserManagement")}
        <IconButton aria-controls="simple-menu" aria-haspopup="true">
          <ArrowRight
            tid="keyboard_arrow_right"
            style={{ color: "gray" }}
            size="small"
          />
        </IconButton>
      </Grid>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          tid="menu_item_users"
          component={NavLink}
          onClick={handleClose}
          to={paths.users}
        >
          <p>{getString("UserManagement.MenuItem.Users")}</p>
        </MenuItem>
        <MenuItem
          tid="menu_item_user_groups"
          component={NavLink}
          onClick={handleClose}
          to={paths.usersGroups}
        >
          <p>{getString("UserManagement.MenuItem.UserGroups")}</p>
        </MenuItem>
        <MenuItem
          tid="menu_item_user_privileges"
          component={NavLink}
          onClick={handleClose}
          to={paths.usersPrivileges}
        >
          <p>{getString("UserManagement.MenuItem.UserPrivileges")}</p>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default withRouter(UserManagementMenu);
