import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  InputAdornment,
} from "@material-ui/core";
import SearchIcon from "@qlik-trial/sprout/icons/Search";
import useString from "../../i18n/hooks/useString";
import BufferedInput from "../BufferedInput";

const propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onApply: PropTypes.func.isRequired
};

const defaultProps = {
  value: '',
};

const SearchInput = ({
  onApply, value, ...rest
}) => {
  const getString = useString();
  const [previousValue, setPreviousValue] = useState(value);

  const handleApply = (value) => {
    if(value !== previousValue) {
      onApply(value);
      setPreviousValue(value);
    }
  };

  return (
    <BufferedInput
      tid="buffered_input"
      {...rest}
      onApply={handleApply}
      placeholder={getString("General.Search")}
      value={value}
      startAdornment={
        <InputAdornment tid="input_adornment" position="start">
          <SearchIcon tid="search_icon" />
        </InputAdornment>
      }
    />
  );
};

SearchInput.propTypes = propTypes;
SearchInput.defaultProps = defaultProps;
export default SearchInput;
