export const toUsersGroupsDetails = (result) => {
    return result.map(each => {
        return {
            id: each._id,
            name: each.name,
            firstName: each.firstName || '',
            lastName: each.lastName || '',
            domain: each.domain
        }
    })
};

export const fromForm = (name, ids) => {
    return {
        name,
        ids
    }
}

