import ActionTypes from '../../constants/actionTypes';
import API from '../../lib/api';
import { toUserPrivilegesDetails } from './dataHandler';
import { push } from 'connected-react-router';
import paths from '../../constants/paths';

export const getUsersPrivileges = (id) => { 
  return async dispatch => {
    try {
      const usersPrivileges = await API.getOneUserPermissionsType(id)
      dispatch({
        type: ActionTypes.GET_USERS_PRIVILEGES_DETAILS,
        payload: {
          type: usersPrivileges.type,
          key: usersPrivileges.key
        }
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: { snackMessage: typeof error === "string" ? error : JSON.stringify(error) }
      });
    }
  };
};

export const filterUsersPrivilegesDetails = (key, { filter, offset = 0, size = 50 } = {}) => {
  return async dispatch => {
    try {
      const filterString = filter ? `name $so ${filter} $or firstName $so ${filter} $or lastName $so ${filter} $or domain $so ${filter} $or channels.email.connection $so ${filter}` : '';
      const [unassigned, assigned] = await Promise.all([
        API.getUsers({ offset, size, filter: `(${filterString}) $and (permissions $exists false $or permissions $ne ${key}) $and license $eq professional $and $admin $ne true $and isActive $eq true` }),
        API.getUsers({ offset, size, filter: `(${filterString}) $and permissions $eq ${key} $and license $eq professional $and $admin $ne true $and isActive $eq true` })
      ]);
      const unassignedData = toUserPrivilegesDetails(unassigned);
      const assignedData = toUserPrivilegesDetails(assigned);
      dispatch({
        type: ActionTypes.FILTER_USERS_PRIVILEGES_DETAILS,
        payload: {
          filtered: !!filter,
          unassignedData,
          assignedData
        }
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: { snackMessage: typeof error === "string" ? error : JSON.stringify(error) }
      });
    }
  };
};

export const editUsersPrivilegesDetails = (id, toAdd, toRemove) => {
  return async dispatch => {
   try {
    const result = await API.updateUserPermissions(id, { toAdd, toRemove })
    dispatch({
      type: ActionTypes.EDIT_USERS_PRIVILEGES_DETAILS,
      payload: {
        result
      }
    })
    dispatch({
      type: ActionTypes.SNACK_SUCCESS,
      payload: { snackMessage: "Snackbar.UsersPrivilegesDetails.editUsersPrivilegesDetails" }
    });
    dispatch(push(paths.usersPrivileges))
   } catch (error) {
    dispatch({
      type: ActionTypes.SNACK_ERROR,
      payload: { snackMessage: typeof error === "string" ? error : JSON.stringify(error) }
    });
  }
 }
}

export const cancelEditPrivileges = () => {
  return dispatch => {
    dispatch({
      type: ActionTypes.CANCEL_EDIT_PRIVILEGES
    })
    dispatch(push(paths.usersPrivileges));
  }
};


export const fullPermissionsAssignation = (id, type) => { 
  return async dispatch => {
    try {
      await API.fullAssignPermissions(id, type)
      dispatch({
        type: ActionTypes.SNACK_SUCCESS,
        payload: { snackMessage: "Snackbar.UsersPrivilegesDetails.editUsersPrivilegesDetails" }
      });
      dispatch(push(paths.usersPrivileges))
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: { snackMessage: typeof error === "string" ? error : JSON.stringify(error) }
      });
    }
  };
};
