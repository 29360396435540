import React, { useState, useEffect, useRef } from "react";
import { bindActionCreators } from "redux";
import { useSelector, useDispatch } from "react-redux";
import { Container, Grid } from "@material-ui/core";
import * as channelsDetailsActions from "./actions";
import { useParams } from "react-router-dom";
import Form from "../../components/Form";
import BottomBar from "../../components/BottomBar";
import Modal from "../../components/Modal";
import BreadcrumbsHeader from '../../components/BreadcrumbsHeader';
import { useStyles } from "../../constants/styles";
import useNavBlocker from "../../components/NavigationBlocker/hooks/useNavBlocker";
import { push } from "connected-react-router";
import paths from "../../constants/paths";

const ChannelsDetails = () => {
  const { unblockNavigation, blockNavigation } = useNavBlocker();
  useEffect(() => {
    blockNavigation();
  }, []);

  const channelsDetails = useSelector(({ channelsDetailsReducer }) => channelsDetailsReducer);
  const login = useSelector(({ loginReducer }) => loginReducer);
  const dispatch = useDispatch();
  const { current: actions } = useRef(bindActionCreators({...channelsDetailsActions}, dispatch));
  const [modalVisible, setModalVisible] = useState(false);

  const classes = useStyles();
  const { id } = useParams();
  
  useEffect(() => {
    if(login.isAdmin) {
      if (id) actions.getChannelsDetails(id)
      else dispatch(push(paths.channels))
    }
    else dispatch(push(paths.alerts))
  }, []);

  const handleTest = () => actions.testConnection(channelsDetails);
  const handleSave = async () => {
    await unblockNavigation();
    actions.editDeliveryChannel(id, channelsDetails);
  }
  const handleCancel = () => actions.cancelEditChannels();
  const handleSelect = field => value => {
    switch (field) {
      case "connectionAuthSecurity":
      case "authenticationMethod":
        actions.addValueToChannelsDetails(field, value);
        break;
      default:
        actions.addValueToChannelsDetails(field, value.target.value);
        break;
    }
  };

  let fields = [...channelsDetails.form.fields]
  if (channelsDetails.authenticationMethod === 'anonymous') fields = fields.filter(e => e.name !== 'connectionAuthUser' && e.name !== 'connectionAuthPass')

  return (
    <>
      <Container className={classes.formContainer}>
        <Grid>
          <BreadcrumbsHeader crumbs={channelsDetails.crumbs} />
        </Grid>
        <Grid className={classes.configForm}>
          <Form fields={fields} 
            reducerState={channelsDetails}
            handleSelect={handleSelect}
          />
        </Grid>
      </Container>
      <BottomBar
        actions={{ handleSave, handleCancel, handleTest }}
        {...channelsDetails.bottomBar}
        reducerState={channelsDetails}
      />
      <Modal
        actions={actions}
        {...channelsDetails.modal}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
      />
    </>
  );
};

export default ChannelsDetails;
