export const getDevices = result => {
  let data = result.map(input => {
    return {
      name: input.name,
      type: input.type.toUpperCase(),
      id: input._id,
      isActive: input.isActive,
      time: new Date(input.createdAt).toLocaleString()
    };
  });
  return data;
};
