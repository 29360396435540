import {dateISO} from "../../utils/dateISO";

export const toUserGroups = (result) => {
    return result.map(each => {
        return {
            id: each._id,
            name: each.name,
            users: each.count,
            createdAt: dateISO(each.createdAt),
            isActive: each.isActive
        }
    })
};
