import 'core-js/stable';
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { createMuiTheme } from '@material-ui/core/styles';
import { sproutOverrides } from './utils/sproutOverrides';
import App from "./containers/App";
import * as serviceWorker from "./serviceWorker";
import configureStore, { history } from "./configureStore";
import { ThemeProvider } from '@material-ui/styles';
import * as i18nActions from './i18n/actions';
import I18N_CONSTANTS from './i18n/constants';
import { startListening } from './utils/historyTracker';

export const store = configureStore();
startListening(history);


const sproutTheme = createMuiTheme(sproutOverrides);

store.dispatch(i18nActions.setLanguage(I18N_CONSTANTS.DEFAULT_LANGUAGE)).finally(() => {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
      <ThemeProvider theme={sproutTheme}>
        <App />
      </ThemeProvider>
      </ConnectedRouter>
    </Provider>,
  
    document.getElementById("root")
  );
  
  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
})
