import actionTypes from "../../constants/actionTypes"
import * as definitions from './definitions'
import { shouldCleanup } from '../../utils/navigationCleanup';

const initialState = {
  ...definitions,
  loading: false,
  service: true,
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
  ssoEnabled:false
}

export default function changePasswordReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.ADD_VALUE_TO_CHANGE_PASSWORD:
      return {
        ...state,
        [action.payload.field]: action.payload.value
      };
    case actionTypes.CANCEL_CHANGE_PASSWORD:
    case actionTypes.CLEAR_CHANGE_PASSWORD:
      return {
        ...initialState
      };
    case actionTypes.LOCATION_CHANGE:
      return shouldCleanup(action.payload) ? { ...initialState } : state;
    case actionTypes.REGISTER_REQUIRED:
      console.log(action.payload)
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
};
