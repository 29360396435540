import React from "react";
import TextField from "../TextField";

const DateTimePicker = ({ label, name, ...custom }) => {
  return (
    <TextField
      tid="text_field_date"
      fullWidth={true}
      id={name}
      label={label}
      name={name}
      type="datetime-local"
      placeholder="YYYY-MM-DDThh:mm"
      {...custom}
    />
  );
};

export default DateTimePicker;
