import ActionTypes from "../../constants/actionTypes";
import * as definitions from "./definitions";
import { shouldCleanup } from '../../utils/navigationCleanup';

const initialState = {
  ...definitions,
  saveIsDisabled: false
  // default values, if any (included hidden fields)
};

export default function userDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_USER_DETAILS:
      return {
        ...state,
        ...action.payload
      };
    case ActionTypes.EDIT_USERS_DETAILS:
      return {
        ...state,
        result: action.payload.result,
        ...action.payload.data
      };
    case ActionTypes.SAVE_BUTTON_DISABLED:
      return {
        ...state,
        saveIsDisabled: action.payload.saveIsDisabled
      };
    case ActionTypes.CANCEL_EDIT_USER:
      return state
    case ActionTypes.ADD_VALUE_TO_USERS_DETAILS:
      return {
        ...state,
        [action.payload.field]: action.payload.value
      };
    case ActionTypes.LOCATION_CHANGE:
      return shouldCleanup(action.payload) ? {...initialState} : state;
    default:
      return state;
  }
}
