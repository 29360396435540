export const crumbs = [
  {label: "Breadcrumbs.Admin"},
  {label: "Breadcrumbs.Sources"}
]

export const table = {
  options: {
    header: true
  },
  style: {
    header: {
      fontWeight: "bold"
    }
  },
  menu: [
    { id: "edit", action: "handleEdit", label: "General.Edit" }
  ],
  fields: [
    {
      label: "Sources.Label.Source",
      type: "",
      id: "sourceName",
      style: { flex: 2 },
      getValue: row => row.sourceName,
      action: null
    },
    {
      label: "Sources.Label.DataSourceHost",
      type: "",
      id: "sourceHost",
      style: { flex: 2 },
      getValue: row => row.sourceHost,
      action: null
    }
  ]
};
