import actionTypes from "../../constants/actionTypes";
import * as definitions from "./definitions";
import { shouldCleanup } from "../../utils/navigationCleanup";

const initialState = {
  ...definitions,
  name: "Qlik",
  type: "qlik",
  hostname: "localhost",
  httpsPort: "433",
  qpsPort: "4243",
  qrsPort: "4242",
  userDirectory: "",
  userId: "",
  filter: "",
  virtualProxy: "",
  sessionCookieName: "",
  url: "localhost",
  proxyUrl: "",
  inserted: {},
  tested: false,
  saveIsDisabled: false,
};

export default function sourcesDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_SOURCE:
      state.form.fields.map((i, idx) => {
        if (i.id == "authenticationURI") {
          state.form.fields[idx].display = action.payload['ssoEnabled'];
        }
      });
      return {
        ...state,
        ...action.payload,
        inserted: {},
      };
    case actionTypes.EDIT_SOURCE:
      return {
        ...state,
        result: action.payload.result,
        ...action.payload.data,
        inserted: {},
      };
    case actionTypes.UPLOAD_SOURCE_CERTS:
      const { key, content } = action.payload;
      return {
        ...state,
        inserted: {
          ...state.inserted,
          [key]: content,
        },
      };
    case actionTypes.TEST_SOURCE:
      return {
        ...state,
        ...action.payload.data,
        testResult: action.payload.result,
        tested: action.payload.success,
      };
    case actionTypes.SAVE_BUTTON_DISABLED:
      return {
        ...state,
        saveIsDisabled: action.payload.saveIsDisabled,
      };
    case actionTypes.CLEAR_SOURCE:
      return {
        ...state,
        inserted: {},
      };
    case actionTypes.CANCEL_EDIT_SOURCE:
      return state;
    case actionTypes.ADD_VALUE_TO_SOURCES_DETAILS:
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    case actionTypes.LOCATION_CHANGE:
      return shouldCleanup(action.payload) ? { ...initialState } : state;
    case actionTypes.SSO_INPUT_DISPLAY_UPDATE:
      const fields = state.form.fields;
      fields.map((i, idx) => {
        if (i.id == "authenticationURI") {
          state.form.fields[idx].display = state['ssoEnabled'];
        }
      });
      return { ...state };
    case actionTypes.SSO_FLAG_UPDATE:
      const copyFields = state.form.fields;
      copyFields.map((i, idx) => {
        if (i.id == "authenticationURI") {
          state.form.fields[idx].display = action.payload['ssoEnabled'];
        }
      });
      return { 
        ...state,
        ...action.payload,
       };
    default:
      return state;
  }
}
