import prodConfig from './prodConfig'
import devConfig from './devConfig'

const config = () => {
    if(process.env.NODE_ENV === 'production') {
        return prodConfig
    } else {
        return devConfig
    }
}

export default config