export const tabs = [
  {
    label: "Alerts.Label.DataAlerts",
    value: "data",
    type: "",
    id: "updatedAt",
    tid: "data"
  },
  {
    label: "Alerts.Label.SystemAlerts",
    value: "system",
    tid: "system"
  },
  {
    label: "Alerts.Label.BroadcastNotification",
    value: "broadcast",
    tid: "broadcast"
  },
];

export const tables = {
  data: {
    options: {
      select: true,
      header: true,
      onSelect: "setSelected",
    },
    style: {
      header: {
        fontWeight: "bold",
      },
    },
    menu: [
      {
        id: "edit",
        action: "handleEdit",
        label: "General.Edit",
      },
      { id: "trigger", action: "handleTrigger", label: "General.Trigger" },
      { id: "duplicate", action: "handleDuplicate", label: "General.Duplicate"},
      { id: "share", action: "handleShare", label: "General.Share"},
      { id: "delete", action: "handleDelete", label: "General.Delete" },
    ],
    fields: [
      {
        label: "Alerts.Label.Name",
        type: "",
        id: "name",
        sortBy: "name",
        style: { flex: 3 },
        getValue: (row) => row.name,
        onClick: null,
      },
      {
        label: "Alerts.Label.Description",
        type: "",
        id: "description",
        sortBy: "description",
        style: { flex: 6 },
        getValue: (row) => row.description || ""
      },
      {
        label: "Alerts.Label.Application",
        type: "",
        id: "appName",
        sortBy: "app.name",
        style: { flex: 3 },
        getValue: (row) => row.appName,
      },
      {
        label: "Alerts.Label.UpdatedAt",
        type: "",
        id: "updatedAt",
        sortBy: "updatedAt",
        style: { flex: 3 },
        getValue: (row) => row.updatedAt,
      },
      {
        label: "Alerts.Label.Owner",
        type: "",
        id: "owner",
        sortBy: "ownerInfo.name",
        style: { flex: 3 },
        getValue: (row) => row.owner,
      },
      {
        label: "Alerts.Label.Enabled",
        type: "switch",
        id: "isActive",
        sortBy: "isActive",
        style: { flex: 2 },
        getValue: (row) => row.isActive,
        onClick: "handleToggle",
      },
    ],
  },
  system: {
    options: {
      select: true,
      header: true,
      onSelect: "setSelected",
    },
    style: {
      header: {
        fontWeight: "bold",
      },
    },
    menu: [
      {
        id: "edit",
        action: "handleEdit",
        label: "General.Edit",
      },
      { id: "duplicate", action: "handleDuplicate", label: "General.Duplicate"},
      { id: "delete", action: "handleDelete", label: "General.Delete" },
    ],
    fields: [
      {
        label: "Alerts.Label.Name",
        type: "",
        id: "name",
        sortBy: "name",
        style: { flex: 3 },
        getValue: (row) => row.name,
        onClick: null,
      },
      {
        label: "Alerts.Label.Description",
        type: "",
        id: "description",
        sortBy: "description",
        style: { flex: 6 },
        getValue: (row) => row.description || "",
      },
      {
        label: "Alerts.Label.UpdatedAt",
        type: "",
        id: "updatedAt",
        sortBy: "updatedAt",
        style: { flex: 3 },
        getValue: (row) => row.updatedAt,
      },
      {
        label: "Alerts.Label.Owner",
        type: "",
        id: "owner",
        sortBy: "ownerInfo.name",
        style: { flex: 3 },
        getValue: (row) => row.owner,
      },
      {
        label: "Alerts.Label.Enabled",
        type: "switch",
        id: "isActive",
        sortBy: "isActive",
        style: { flex: 2 },
        getValue: (row) => row.isActive,
        onClick: "handleToggle",
      },
    ],
  },
  broadcast: {
    options: {
      select: true,
      header: true,
      onSelect: "setSelected",
    },
    style: {
      header: {
        fontWeight: "bold",
      },
    },
    menu: [
      {
        id: "edit",
        action: "handleEdit",
        label: "General.Edit",
      },
      { id: "trigger", action: "handleTrigger", label: "General.Trigger" },
      { id: "duplicate", action: "handleDuplicate", label: "General.Duplicate"},
      { id: "delete", action: "handleDelete", label: "General.Delete" }
    ],
    fields: [
      {
        label: "Alerts.Label.Name",
        type: "",
        id: "name",
        sortBy: "name",
        style: { flex: 3 },
        getValue: (row) => row.name,
        onClick: null,
      },
      {
        label: "Alerts.Label.Description",
        type: "",
        id: "description",
        sortBy: "description",
        style: { flex: 6 },
        getValue: (row) => row.description || "",
      },
      {
        label: "Alerts.Label.UpdatedAt",
        type: "",
        id: "updatedAt",
        sortBy: "updatedAt",
        style: { flex: 3 },
        getValue: (row) => row.updatedAt,
      },
      {
        label: "Alerts.Label.Owner",
        type: "",
        id: "owner",
        sortBy: "ownerInfo.name",
        style: { flex: 3 },
        getValue: (row) => row.owner,
      },
      {
        label: "Alerts.Label.Enabled",
        type: "switch",
        id: "isActive",
        sortBy: "isActive",
        style: { flex: 2 },
        getValue: (row) => row.isActive,
        onClick: "handleToggle",
      },
    ],
  },
};

export const modals = {
  trigger: {
    options: {
      title: "Trigger",
    },
    content: "Alerts.Modal.TriggerContent",
    buttons: [
      {
        key: "notTrigger",
        label: "General.Cancel",
        onClick: null,
        variant: "outlined",
      },
      {
        key: "trigger",
        label: "General.Confirm",
        onClick: "sendTrigger",
        color: "primary",
      },
    ],
  },
  delete: {
    options: {
      title: "Delete",
    },
    content: "Alerts.Modal.DeleteContent",
    buttons: [
      {
        key: "notTrigger",
        label: "General.Cancel",
        onClick: null,
        variant: "outlined",
      },
      {
        key: "trigger",
        label: "General.Confirm",
        onClick: "sendDelete",
        color: "primary",
      },
    ],
  },
  duplicate: {
    options: {
      title: "Duplicate",
    },
    content: "Alerts.Modal.DuplicateContent",
    buttons: [
      {
        key: "notTrigger",
        label: "General.Cancel",
        onClick: null,
        variant: "outlined",
      },
      {
        key: "trigger",
        label: "General.Confirm",
        onClick: "sendDuplicate",
        color: "primary",
      },
    ],
  },
};
