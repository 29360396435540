export const toUserPrivileges = (input) => {
    if(!Array.isArray(input)) input = [input]
    return input.map(each => {
        return {
            id: each.key,
            name: each.type,
            users: each.count
        }
    })
};
