import ActionTypes from "../../constants/actionTypes";
import API from "../../lib/api";
import { toUserGroups } from "./dataHandler";

export const getUsersGroups = ({ offset, size = 50, filter, overwrite, sort, reverse } = {}) => {
  return async dispatch => {
    try {
      const filterString = filter ? `name $so ${filter}` : '';
      const result = await API.getGroups({ offset, size, filter: filterString, sort, reverse });
      const data = await toUserGroups(result);
      dispatch({
        type: ActionTypes.SHOW_MORE_GROUPS,
        payload: data.length < size
      });
      if (overwrite) {
        dispatch({
          type: ActionTypes.RESTART_USERS_GROUPS,
          payload: data
        })
      } else {
        dispatch({
          type: ActionTypes.GET_USER_GROUPS,
          payload: data

        });
      }
      if (offset) dispatch({
        type: ActionTypes.SNACK_SUCCESS,
        payload: { 
          snackMessage: "Snackbar.UsersGroups.showMore",
          replacementArray: [data.length]
        }
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: { snackMessage: typeof error === "string" ? error : JSON.stringify(error) }
      });
    }
  };
};

export const deleteUsersGroups = selected => {
  return dispatch => {
    selected.forEach(each => API.deleteGroup(each));
    dispatch({
      type: ActionTypes.DELETE_USERS_GROUPS,
      payload: selected
    });
    dispatch({
      type: ActionTypes.SNACK_SUCCESS,
      payload: { snackMessage: "Snackbar.UsersGroups.deleteUsersGroups" }
    });
  };
};

export const toggleGroup = (id, bool) => {
  return async dispatch => {
    try {
      await API.togglegroup(id, bool);
      dispatch({
        type: ActionTypes.TOGGLE_GROUP,
        payload: {
          id,
          bool
        }
      });
      dispatch({
        type: ActionTypes.SNACK_SUCCESS,
        payload: { snackMessage: bool
          ?  "Snackbar.UsersGroups.enabled" 
          : "Snackbar.UsersGroups.disabled"          
         }
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: { snackMessage: typeof error === "string" ? error : JSON.stringify(error) }
      });
    }
  };
};
