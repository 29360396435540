import React from "react";
import {
  Typography
} from "@material-ui/core";
import Modal from '../Modal';
import useString from "../../i18n/hooks/useString";

export const CONSTANTS = {
  UNSAVED_CHANGES: 'unsavedChanges',
  INCOMPLETE_ALERT: 'incompleteAlert',
  NOT_VISIBLE: false,
};

const getIncompleteAlertModal = (discardAndExit, content) => ({
  options: {
    title: "Navbar.AlertCreation.IncompleteAlert.Title"
  },
  style: {},
  contentElement: content,
  buttons: [
    {
      key: "confirm",
      label: "Navbar.AlertCreation.IncompleteAlert.ExitDiscard",
      onClick: discardAndExit,
      variant: "error",
      style: {
        marginRight: 'auto'
      }
    },
    {
      key: "cancel",
      label: "General.Cancel",
      onClick: null,
      variant: "outlined"
    }
  ]
});

const getUnsavedChangesModal = (discardAndExit, saveAndExit, content) => ({
  options: {
    title: "Navbar.AlertCreation.UnsavedChanges.Title"
  },
  style: {},
  content: content,
  buttons: [
    {
      key: "cancel",
      label: "Navbar.AlertCreation.UnsavedChanges.Discard",
      onClick: discardAndExit,
      variant: "outlined"
    },
    {
      key: "save",
      label: "General.Save",
      onClick: saveAndExit,
      color: "primary",
    },
  ]
});

const SaveWarningModal = ({ type, setModal, onSave, onDiscard }) => {
  const getString = useString();

  const getIncompleteAlertModalContent = () => (
      <Typography variant="body2"> {getString("Navbar.AlertCreation.IncompleteAlert.Content")} </Typography>
  );
  const unsavedChangesModalContent = getString("Navbar.AlertCreation.UnsavedChanges.Content");
  const modal = type === CONSTANTS.INCOMPLETE_ALERT
    ? getIncompleteAlertModal(onDiscard, getIncompleteAlertModalContent())
    : getUnsavedChangesModal(onDiscard, onSave, unsavedChangesModalContent);

  return (
    type && <Modal
      tid="saveWarningModal"
      {...modal}
      modalVisible={!!type}
      setModalVisible={setModal}
    />
  );
}

export default SaveWarningModal;