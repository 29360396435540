export const crumbs = [
  { label: "Breadcrumbs.Admin" },
  { label: "Breadcrumbs.Management" },
  { label: "Breadcrumbs.Users" }
]

export const table = {
  options: {
    header: true,
    select: true,
    onSelect: "setSelected"
  },
  style: {
    header: {
      fontWeight: "bold"
    }
  },
  menu: [
    { id: "edit", action: "handleEdit", label: "General.Edit" },
    { id: "resetPassword", action: "handleResetPassword", label: "General.ResetPassword" }, 
    { id: "delete", action: "handleDelete", label: "General.Delete" }
  ],
  fields: [
    {
      label: "Users.Label.FirstName",
      type: "",
      id: "firstName",
      sortBy: "firstName",
      style: { flex: 2 },
      getValue: row => row.firstName,
      action: null
    },
    {
      label: "Users.Label.LastName",
      type: "",
      id: "lastName",
      sortBy: "lastName",
      style: { flex: 2 },
      getValue: row => row.lastName,
      action: null
    },
    {
      label: "Users.Label.UserName",
      type: "",
      id: "name",
      sortBy: "name",
      style: { flex: 2 },
      getValue: row => row.name,
      action: null
    },
    {
      label: "Users.Label.Role",
      type: "",
      id: "admin",
      sortBy: "admin",
      style: { flex: 2 },
      getValue: row => row.role,
      action: null
    },
    {
      label: "Users.Label.Domain",
      type: "",
      id: "domain",
      sortBy: "domain",
      style: { flex: 2 },
      getValue: row => row.domain,
      action: null
    },
    {
      label: "Users.Label.Email",
      type: "",
      sortBy: "channels.email.connection",
      id: "email",
      style: { flex: 2 },
      getValue: row => row.email,
      action: null
    },
    {
      label: "Users.Label.License",
      type: "",
      sortBy: "license",
      id: "license",
      style: { flex: 2 },
      getValue: row => row.license,
      action: null
    },
    {
      label: "Users.Label.Enabled",
      type: "switch",
      id: "isActive",
      sortBy: "isActive",
      style: { flex: 2 },
      getValue: row => row.isActive,
      onClick: "handleToggle"
    }
  ]
};

export const modals = {
  delete: {
    options: {
      title: "General.Delete"
    },
    content: "Users.Modal.ContentDelete",
    buttons: [
      {
        key: "notDelete",
        label: "General.Cancel",
        onClick: null,
        variant: "outlined"
      },
      {
        key: "delete",
        label: "General.Confirm",
        onClick: "sendDelete",
        color: "primary"
      }
    ]
  },
  resetPassword: {
    options: {
      title: "General.ResetPassword"
    },
    content: "Users.Modal.ContentResetPassword",
    buttons: [
      {
        key: "notReset",
        label: "General.Cancel",
        onClick: null,
        variant: "outlined"
      },
      {
        key: "reset",
        label: "General.Confirm",
        onClick: "sendResetPassword",
        color: "primary"
      }
    ]
  },
  importUsers: {
    options: {
      title: "Users.Modal.ImportUsers"
    },
    content: "Users.Modal.ContentImport",
    buttons: [
      {
        key: "notImportUsers",
        label: "General.Cancel",
        onClick: null,
        variant: "outlined"
      },
      {
        key: "importUsers",
        label: "General.Confirm",
        onClick: "syncQlikUsers",
        color: "primary"
      }
    ]
  }
};
