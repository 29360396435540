import ActionTypes from '../constants/actionTypes';

const initialState = null;

export default function translationsReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_LANGUAGE_FAILURE:
        console.log('Failed to detect locale - reverted to default')
        // fallthrough
    case ActionTypes.SET_LANGUAGE_SUCCESS:
        return {
          ...state,
          translations: action.translations,
          locale: action.locale,
        };
    default:
      return state;
  }
}