import ActionTypes from "../../constants/actionTypes";
import * as definitions from "./definitions";
import { shouldCleanup } from '../../utils/navigationCleanup';

const initialState = {
  ...definitions,
  data: {},
  dataIds: [],
  dialogOpen: false,
  dialogText: ""
};

export default function sourcesReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_SOURCES:
      return {
        ...state,
        data: action.payload.data.reduce((prev, cur) => {
          prev[cur.id] = cur;
          return prev;
        }, {}),
        dataIds: action.payload.data.map(each => each.id)
      };
    case ActionTypes.LOCATION_CHANGE:
        return shouldCleanup(action.payload) ? {...initialState} : state;
    default:
      return state;
  }
}
