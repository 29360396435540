
export const modal = {
  share: {
    options: {
      title: "General.Share",
      onClose: "close"
    },
    buttons: [
      {
        key: "notShare",
        label: "General.Cancel",
        onClick: null,
        variant: "outlined",
      },
      {
        key: "share",
        label: "General.Share",
        onClick: "sendShare",
        color: "primary",
      },
    ],
  },
};

export const tables = {
  list: {
    recipients: {
      options: {
        select: true,
        onSelect: "setSelected",
      },
      fields: [
        {
          id: "firstName",
          label: "Share.Groups.TableHeader.FirstName",
          getValue: (row) => row.firstName,
        },
        {
          id: "lastName",
          label: "Share.Groups.TableHeader.LastName",
          getValue: (row) => row.lastName,
        },
        {
          id: "domainUser",
          label: "Share.Groups.TableHeader.DomainUser",
          getValue: (row) => `${row.domain}/${row.name}`,
        },
      ],
    },
    groups: {
      options: {
        select: true,
        onSelect: "setSelected",
      },
      fields: [
        {
          id: "name",
          label: "Share.Groups.TableHeader.Name",
          getValue: (row) => row?.name,
        },
        {
          id: "length",
          label: "Share.Groups.TableHeader.Recipients",
          getValue: (row) => row?.count
        },
      ],
    }
  },
};