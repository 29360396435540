export const crumbs = [
  {label: "Breadcrumbs.Admin"},
  {label: "Breadcrumbs.Management"},
  {label: "Breadcrumbs.Groups"}
]

export const table = {
  options: {
    header: true,
    select: true,
    onSelect: "setSelected"
  },
  style: {
    header: {
      fontWeight: "bold"
    }
  },
  menu: [
    { id: "edit", action: "handleEdit", label: "General.Edit" },
    { id: "delete", action: "handleDelete", label: "General.Delete" }
  ],
  fields: [
    {
      label: "UsersGroups.Label.GroupName",
      type: "",
      id: "name",
      sortBy: "name",
      style: { flex: 2 },
      getValue: row => row.name,
      action: null
    },
    {
      label: "UsersGroups.Label.Users",
      type: "",
      id: "assigned",
      style: { flex: 2 },
      getValue: row => row.users,
      action: null
    },
    {
      label: "UsersGroups.Label.CreatedAt",
      type: "",
      id: "createdAt",
      sortBy: "createdAt",
      style: { flex: 2 },
      getValue: row => row.createdAt,
      action: null
    },
    {
      label: "UsersGroups.Label.Enabled",
      type: "switch",
      id: "isActive",
      sortBy: "isActive",
      style: { flex: 2 },
      getValue: row => row.isActive,
      onClick: "handleToggle"
    }
  ]
};

export const modals = {
  delete: {
    options: {
      title: "General.Delete"
    },
    content: "General.DeleteContent",
    buttons: [
      {
        key: "notDelete",
        label: "General.Cancel",
        onClick: null,
        variant: "outlined"
      },
      {
        key: "delete",
        label: "General.Confirm",
        onClick: "sendDelete",
        color: "primary"
      }
    ]
  }
};
