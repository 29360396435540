import React, { useState, useEffect, useRef } from "react";
import { NavLink, useLocation, matchPath } from "react-router-dom";
import { bindActionCreators } from "redux";
import { useSelector, useDispatch } from "react-redux";
import * as loginActions from "../../containers/Login/actions";
import { Toolbar, AppBar, Tab, Tabs } from "@material-ui/core";
import Modal from "../Modal";
import { push } from "connected-react-router";
import paths from "../../constants/paths";
import Logo from "../../Qlik-logo_80.png";
import { useStyles } from "../../constants/styles";
import About from "../About";
import UserProfile from "../UserProfile";
import AdminDropdown from "./AdminDropdown";
import DropdownArrow from "@qlik-trial/sprout/icons/DropdownArrow";
import UserMenu from "./UserMenu";
import useNavBlocker from "../NavigationBlocker/hooks/useNavBlocker";
import useString from "../../i18n/hooks/useString";
import AlertCreationHeader from "./components/AlertCreationHeader";
import InboxDataHeader from "./components/InboxDataHeader";

const tabSelectLookup = {
  [paths.alerts]: "alerts",
  [paths.alertsDetails]: "alerts",
  [paths.inbox]: "inbox",
  [paths.inboxSystem]: "inbox",
  [paths.channels]: "admin",
  [paths.channelsDetails]: "admin",
  [paths.config]: "admin",
  [paths.sources]: "admin",
  [paths.sourcesDetails]: "admin",
  [paths.users]: "admin",
  [paths.usersDetails]: "admin",
  [paths.usersGroups]: "admin",
  [paths.usersGroupsDetails]: "admin",
  [paths.usersPrivileges]: "admin",
  [paths.usersPrivilegesDetails]: "admin",
};

const getPreventLogoutModal = (onConfirmation, content) => ({
  options: {
    title: "NavigationBlocker.Warning.Title",
  },
  style: {},
  content: content,
  buttons: [
    {
      key: "cancel",
      label: "General.Cancel",
      onClick: null,
      variant: "outlined",
    },
    {
      key: "confirm",
      label: "General.Confirm",
      onClick: onConfirmation,
      color: "primary",
    },
  ],
});

const NavBar = () => {
  const classes = useStyles();
  const getString = useString();

  const dispatch = useDispatch();
  const { current: actions } = useRef(
    bindActionCreators({ ...loginActions }, dispatch)
  );
  const login = useSelector(({ loginReducer }) => loginReducer);

  const location = useLocation();
  const { unblockNavigation, isNavigationBlocked } = useNavBlocker();
  let variantTitle = null;
  let alertId = null;
  let alertType = null;
  const alertDetailsPathObject = matchPath(location.pathname, {
    path: paths.alertsDetails,
  });
  const inboxDataPathObject = matchPath(location.pathname, {
    path: paths.inboxData,
  });
  if (alertDetailsPathObject && login.token) {
    // Only show this variant when the user is logged in and in alertDetails
    alertType = alertDetailsPathObject.params.type;
    alertId = alertDetailsPathObject.params.id;
    variantTitle = getString("Navbar.AlertCreation.Title", [
      `${alertType[0].toUpperCase()}${alertType.slice(1)}`,
    ]);
  }

  const [modal, setModal] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [tab, setTab] = useState("alerts");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const buttonList = [
    {
      key: "password",
      label: "Navbar.Button.ChangePassword",
      color: "primary",
      nav: () => paths.getChangePassword(),
    },
    {
      key: "close",
      variant: "outlined",
      label: "General.Close",
    },
  ]
  const modals = {
    about: {
      options: {},
      style: {},
      contentElement: <About />,
      buttons: [],
    },
    userProfile: {
      options: {
        title: "UserProfile.Modal.UserProfile",
      },
      style: {},
      contentElement: (
        <UserProfile
          isAdmin={login.isAdmin}
          firstName={login.firstName}
          license={login.license}
          userName={login.userName}
        />
      ),
      buttons: buttonList.filter(item => {
         if (login.ssoEnabled) {
            if (item.key != 'password') return item      
          } else {
            return item
          }
      }),
    },
  };

  const openModal = (content) => {
    setModal(content);
    setModalVisible(true);
  };

  const handleLogout = () => {
    if (isNavigationBlocked) {
      const preventLogoutModal = getPreventLogoutModal(async () => {
        await unblockNavigation();
        actions.logout();
      }, getString("NavigationBlocker.Warning.Content"));
      openModal(preventLogoutModal);
    } else {
      actions.logout();
    }
  };

  const handleChange = (event, newValue) => {
    if (newValue === "inbox") {
      dispatch(push(paths.inbox));
    } else if (newValue === "alerts") {
      dispatch(push(paths.alerts));
    }
  };

  useEffect(() => {
    let selectedTab = false;
    for (let key of Object.keys(tabSelectLookup)) {
      const matched = matchPath(location.pathname, {
        path: key,
      });
      if (matched?.isExact) {
        selectedTab = tabSelectLookup[key];
        break;
      }
    }
    setTab(selectedTab);
  }, [location.pathname]);

  return (
    <>
      {variantTitle && (
        <AlertCreationHeader
          title={variantTitle}
          alertId={alertId}
          alertType={alertType}
        />
      )}
      {inboxDataPathObject && <InboxDataHeader />}

      {!(variantTitle || inboxDataPathObject) && (
        <div className={classes.grow}>
          <AppBar position="static">
            <Toolbar variant="dense" style={{ minHeight: "64px" }}>
              <NavLink tid="nav_link_qlik_logo" to={paths.app}>
                <img
                  tid="img_qlik_logo"
                  src={Logo}
                  style={{ width: "88px", marginLeft: "0.5em" }}
                  alt="Qlik logo"
                />
              </NavLink>
              <p tid="p_alerting" className={classes.logoTitle}>
                {getString("Navbar.Header.Alerting")}
              </p>
              {login.token && (
                <>
                  <section
                    tid="div_nav_top_links"
                    className={classes.navButtonDiv}
                  >
                    <Tabs value={tab} onChange={handleChange}>
                      <Tab
                        tid="nav_link_alerts"
                        className={classes.tab}
                        label={getString("Navbar.Header.Alerts")}
                        value="alerts"
                      />
                      <Tab
                        tid="nav_link_inbox"
                        className={classes.tab}
                        label={getString("Navbar.Header.Inbox")}
                        value="inbox"
                      />
                      {login.isAdmin && (
                        <Tab
                          tid="nav_admin_dropdown"
                          className={classes.adminTab}
                          label={getString("Admin.Dropdown.Admin")}
                          onClick={handleClick}
                          value="admin"
                          icon={<DropdownArrow size="medium" />}
                        />
                      )}
                    </Tabs>
                  </section>
                  <section className={classes.sectionMobileRight}>
                    <UserMenu
                      tid="user_menu"
                      classes={classes}
                      openModal={openModal}
                      modals={modals}
                      handleLogout={handleLogout}
                      name={login?.firstName || ""}
                    />
                  </section>
                </>
              )}
            </Toolbar>
            <AdminDropdown
              tid="admin_dropdown"
              classes={classes}
              openModal={openModal}
              modals={modals}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
            />
            <Modal
              tid="modal"
              actions={actions}
              {...modal}
              modalVisible={modalVisible}
              setModalVisible={setModalVisible}
            />
          </AppBar>
        </div>
      )}
    </>
  );
};

export default NavBar;
