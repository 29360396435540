import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import { useSelector, useDispatch } from "react-redux";
import { Container, Grid } from "@material-ui/core";
import * as changePasswordActions from "./actions";
import Form from "../../components/Form";
import BottomBar from "../../components/BottomBar";
import { push } from "connected-react-router";
import { store } from "../../index";
import paths from "../../constants/paths";
import Modal from "../../components/Modal";
import useString from "../../i18n/hooks/useString";

const ChangePassword = () => {
  const changePassword = useSelector(({ changePasswordReducer }) => changePasswordReducer);
  const login = useSelector(({ loginReducer }) => loginReducer);
  const getString = useString();

  const dispatch = useDispatch();
  const { current: actions } = useRef(bindActionCreators({ ...changePasswordActions }, dispatch));
  const { token } = useParams();

  useEffect(() => {
    if (!login.token && !token) store.dispatch(push(paths.login))
  }, [login.token, token])

  useEffect(() =>{
    actions.registrationStatus()
  },[])

  const [modalVisible, setModalVisible] = useState(false);

  const handleSave = () => {
    if (token) actions.recoverPassword(changePassword, token);
    else actions.changePassword(changePassword);
  };

  const handleCancel = () => setModalVisible(true);

  const handleSelect = field => value => actions.addValueToChangePassword(field, value.target.value);

  const fields = [...changePassword.form.fields]
  if (token) fields.shift()


  return (
      <>
        <Container maxWidth="md" style={{ flex: '1 1 auto', overflow: 'auto' }}>
          <Grid item xs={12} justify="left" container>
            <div>
              <h3>
                {token
                  ? getString("ChangePassword.Generate")
                  : getString("ChangePassword.Change")
                }
                {getString("ChangePassword.YourPassword")}
              </h3>
            </div>
          </Grid>
          <Grid container item xs={12} justify="center" spacing={1}>
            <Grid item xs={12} sm={12}>
              <Form
                fields={fields}
                reducerState={changePassword}
                handleSelect={handleSelect}
              />
            </Grid>
          </Grid>
          <Modal
            actions={actions}
            {...changePassword.modal}
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
          />
        </Container>
        <BottomBar
          actions={{ handleSave, handleCancel }}
          {...changePassword.bottomBar}
        />
      </>
  );
};


export default ChangePassword;
