import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as usersPrivilegesDetailsActions from "./actions";
import {
  makeStyles,
  Container,
  Grid,
  FormControl,
  FormLabel,
  Button
} from "@material-ui/core";
import Assigner from '../../components/Assigner';
import BottomBar from '../../components/BottomBar';
import Modal from '../../components/Modal';
import SearchInput from '../../components/SearchInput';
import BreadcrumbsHeader from '../../components/BreadcrumbsHeader';
import { useParams } from "react-router-dom";
import useNavBlocker from "../../components/NavigationBlocker/hooks/useNavBlocker";
import { push } from "connected-react-router";
import paths from "../../constants/paths";
import useString from '../../i18n/hooks/useString';

const useStyles = makeStyles(theme => ({
  assignerContainer: {
    flex: '1 1 100%',
    paddingTop: theme.spacing(4),
    overflow: 'hidden'
  },
  assignerWrapper: {
    height: 'calc(100% - 92px)',
  },
  formLabel: {
    marginBottom: '8px'
  }
}))

const UserPrivilegesDetails = () => {
  const { unblockNavigation, blockNavigation } = useNavBlocker();
  const [assigned, setAssigned] = useState([]);
  const [saveDisabled, setSaveDisabled] = useState(true);

  useEffect(() => {
    blockNavigation();
  }, []);

  const classes = useStyles();
  const dispatch = useDispatch();
  const { current: actions } = useRef(bindActionCreators({ ...usersPrivilegesDetailsActions }, dispatch));
  const usersPrivilegesDetails = useSelector(({ usersPrivilegesDetailsReducer }) => usersPrivilegesDetailsReducer);
  const login = useSelector(({ loginReducer }) => loginReducer);
  const [modalVisible, setModalVisible] = useState(false)
  const [allSelection, setAllSelection] = useState(false)
  const [modalFullAssign, setModalFullAssign] = useState(false)
  const [pendingUnassigned, setPendingUnassigned] = useState([]);
  const [pendingAssigned, setPendingAssigned] = useState([]);
  const { id } = useParams()
  const getString = useString();

  useEffect(() => {
    if (login.isAdmin) {
      if (id) actions.getUsersPrivileges(id)
      else dispatch(push(paths.usersPrivileges))
    }
    else dispatch(push(paths.alerts))
  }, []);

  useEffect(() => {
    if (usersPrivilegesDetails.key) actions.filterUsersPrivilegesDetails(usersPrivilegesDetails.key)
  }, [actions, usersPrivilegesDetails.key])

  const handleSave = async () => {
    setSaveDisabled(true);
    await unblockNavigation();
    actions.editUsersPrivilegesDetails(id, pendingAssigned, pendingUnassigned );
  }

  const isSaveDisabled = () => saveDisabled;

  const handleCancel = () => actions.cancelEditPrivileges();

  const handleSearchApply = (value) => {
    actions.filterUsersPrivilegesDetails(usersPrivilegesDetails.key, {
      offset: 0,
      filter: value,
      overwrite: true,
    });
  };

  const customActions = {
    fullAssignation: async () => {
      await unblockNavigation();
      actions.fullPermissionsAssignation(id, allSelection)
    }
  }

  const allAssignment = (type) => {
    setAllSelection(type)
    setModalFullAssign(true)
  }

  return (
    <>
      <Container className={classes.assignerContainer}>
        <Grid>
          <BreadcrumbsHeader crumbs={usersPrivilegesDetails.crumbs} />
        </Grid>
        <Grid tid="grid_icon_buttons" container item xs direction="row" alignItems="center" justify="space-between" //style={{ alignItems: 'center' }}
        >
          <FormControl>
            <FormLabel className={classes.formLabel}> {`Privilege: ${usersPrivilegesDetails.type}`} </FormLabel>
            <SearchInput tid={'search_input_user_privileges'} onApply={handleSearchApply} />
          </FormControl>
          <Grid item>
            <Button tid="icon_button_assign_all" onClick={() => allAssignment(true)} variant="outlined" disabled={usersPrivilegesDetails.filtered} style={{ margin: 3 }}>
              {getString("UsersPrivilegesDetails.Button.AssignAll")}
            </Button>
            <Button tid="icon_button_unassign_all" onClick={() => allAssignment(false)} variant="outlined" disabled={usersPrivilegesDetails.filtered} style={{ margin: 3 }}>
              {getString("UsersPrivilegesDetails.Button.UnAssignAll")}
            </Button>
          </Grid>        
        </Grid>
        <div className={classes.assignerWrapper}>
          <Assigner
            unassignedData={usersPrivilegesDetails.unassignedData}
            assignedData={usersPrivilegesDetails.assignedData}
            assigned={usersPrivilegesDetails.assigned}
            pendingUnassigned={pendingUnassigned}
            setPendingUnassigned={setPendingUnassigned}
            pendingAssigned={pendingAssigned}
            setPendingAssigned={setPendingAssigned}
            onAssigned={setAssigned}
            setSaveDisabled={setSaveDisabled}
          />
        </div>
        <Modal
          actions={{ ...actions, ...customActions }}
          {...usersPrivilegesDetails[`modal${allSelection ? 'AssignAll' : 'UnassignAll'}`]}
          modalVisible={modalFullAssign}
          setModalVisible={setModalFullAssign} />
        <Modal
          actions={actions}
          {...usersPrivilegesDetails.modal}
          modalVisible={modalVisible}
          setModalVisible={setModalVisible} />
      </Container>
      <BottomBar
        actions={{ ...actions, handleCancel, handleSave, isSaveDisabled }}
        {...usersPrivilegesDetails.bottomBar}
        dataLength={usersPrivilegesDetails.length}
      />
    </>
  )
}

export default UserPrivilegesDetails;
