import React, { useEffect, useState, useRef } from "react";
import { useLocation, Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { useSelector, useDispatch } from 'react-redux'
import * as loginSSOActions from "./actions";
import useString from "../../i18n/hooks/useString";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import CircularIndeterminate from "../../components/Spinner";

const useStyles = makeStyles(theme => ({
  backdrop: {
    alignContent: 'center',
    backgroundColor: '#F7F7F7',
    height: '100%',
    width: '100%',
  },
  loginCard: {
    display: 'flex',
    flexFlow: 'column',
    backgroundColor: theme.palette.background.paper,
    boxSizing: 'border-box',
    boxShadow: '0 1px 2px 0 rgba(40,40,40,0.16)',
    borderRadius: '3px',
    alignContent: 'center',
    height: 'auto',
    width: '500px',
    maxWidth: '500px',
    padding: theme.spacing(4, 4),
    margin: `${theme.spacing(14)}px auto`,
    [theme.breakpoints.down('xs')]: {
      width: `calc(100% - ${theme.spacing()}px)`,
      padding: theme.spacing(4, 5),
    },
  },
  cardHeader: {
    marginBottom: theme.spacing(1),
    color: theme.palette.text.primary,
  },
  link: {
    cursor: 'pointer',
  },
  linkContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(1, 0),
  },
  title: {
    lineHeight: '36px',
    marginBottom: '10px'
  },
  subtitle: {
    color: theme.palette.text.secondary,
  }
}));

export default function LoginQXSession() {
  const classes = useStyles();
  const getString = useString();
  const search = useLocation().search;
  const uniqueId = new URLSearchParams(search).get('uniqueId');
  const type = new URLSearchParams(search).get('type');
  const id = new URLSearchParams(search).get('id');
  const redirectType = new URLSearchParams(search).get('redirectType');
  const dispatch = useDispatch();
  const [ssoStatus, setSsoStatus] = React.useState(true);
  const { current: actions } = useRef(bindActionCreators({ ...loginSSOActions }, dispatch));

  console.log("search",search)
  React.useEffect(() => {
    setTimeout(() => {
      if(redirectType === 'verifySSO'){
        window.opener.receiveDataFromPopup(true);
      }else{
        actions.loginSSO(uniqueId, type, id).then((res) => {
          setSsoStatus(res);
        })
      }
      
    }, 500);
  }, []);

  return (
    <div className={classes.backdrop}>
      <div className={classes.loginCard}>
        <div className={classes.cardHeader}>
          {
            ssoStatus ? <React.Fragment>
              <Typography variant="h4" className={classes.title}>
                {getString('SSO.Label.Login')}
              </Typography>
            </React.Fragment> : <React.Fragment>
              <Typography variant="h4" className={classes.title}>
                {getString('SSO.Label.Error')}
              </Typography>
              <Typography variant="body1" className={classes.subtitle}>
                {getString("SSO.Label.PleaseClick")}
                <Link to="/" className={classes.link}>
                  {getString("SSO.Link.Login")}
                </Link>
              </Typography>
            </React.Fragment>
          }
          <CircularIndeterminate loading={ssoStatus} />
        </div>
      </div>
    </div>
  )
}
