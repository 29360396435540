import {dateISO} from "../../utils/dateISO";

export const toInbox = {
    data: (input) => {
        if (!Array.isArray(input)) input = [input]
        return input.map(each => {
            return {
                id: each._id,
                alertName: each.name,
                activeLink: each.lastScan ? true : false,
                last10Triggered: each.last10 + '/10',
                last100Triggered: each.last100 + '/100',
                link: each._id,
                lastScan: each.lastScan ? dateISO(each.lastScan, true) : "Inbox.DataHandler.NeverScanned",
                lastTriggered: each.lastTriggered ? dateISO(each.lastTriggered, true) : "Inbox.DataHandler.NotTriggered"
            }
        })
    },
    system: (input) => {
        if (!Array.isArray(input)) input = [input]
        return input.map(each => {
            return {
                id: each.alertId,
                alertName: each.alertName,
                taskName: each.taskName,
                applicationName: each.applicationName,
                triggerType: each.triggerType,
                view: each.view,
                link: each.link,
                executionStatus: each.executionStatus
            }
        })
    }
}
