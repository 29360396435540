export const crumbs = [
  { label: "Breadcrumbs.Admin" },
  { label: "Breadcrumbs.Settings" }
]

export const form = {
  options: {},
  fields: [
    {
      name: "hostname",
      id: "hostname",
      label: "Config.Label.HostServer",
      type: "input",
      placeholder: "Please type here...",
      autoComplete: "off",
      helperText: "Config.Helper.HostServer",
    },
    {
      name: "httpPort",
      id: "http",
      label: "Config.Label.HttpPort",
      placeholder: "Please type here...",
      helperText: "Config.Helper.HttpPort",
    },
    {
      name: "httpsPort",
      id: "https",
      label: "Config.Label.HttpsPort",
      placeholder: "Please type here...",
      helperText: "Config.Helper.HttpsPort",
    }
  ]
};

export const bottomBar = {
  options: {},
  buttons: {
    right: [
      {
        key: "cancel",
        type: "button",
        label: "General.Cancel",
        onClick: "handleCancel",
        display: () => true
      },
      {
        key: "save",
        type: "button",
        label: "General.Save",
        onClick: "handleSave",
        variant: "contained",
        color: "primary",
        disabled: (s, l, state) => state.saveIsDisabled,
        display: () => true
      },
    ]
  }
};

export const modal = {
  options: {
    title: "General.Cancel"
  },
  content: "Config.Modal.CancelContent",
  buttons: [
    {
      key: "notLeave",
      label: "General.Cancel",
      onClick: null,
      variant: "outlined",
    },
    {
      key: "leave",
      label: "General.Confirm",
      onClick: 'cancelEditConfig',
      color: "primary"
    }
  ]
};
