import ActionTypes from "../../constants/actionTypes";
import API from "../../lib/api";
import { fromAlerts, usersHandler } from "./dataHandler";

export const getAlerts = ({
  tab,
  allAlerts,
  offset,
  filter,
  sort,
  reverse,
  overwrite,
  size = 50,
}) => {
  return async (dispatch) => {
    try {
      const filterString = filter
        ? `name $so ${filter} $or description $so ${filter} $or app.name $so ${filter} $or ownerInfo.name $so ${filter}`
        : "";
      const result = await API.getAlerts({
        type: tab,
        offset,
        size,
        filter: filterString,
        allAlerts,
        sort,
        reverse,
      });
      const data = fromAlerts[tab](result);
      dispatch({
        type: ActionTypes.SHOW_MORE_ALERTS,
        payload: data.length < size,
      });
      if (overwrite) {
        dispatch({
          type: ActionTypes.RESTART_ALERTS,
          payload: { tab: allAlerts ? tab + "All" : tab, data },
        });
      } else {
        dispatch({
          type: ActionTypes.GET_ALERTS,
          payload: { tab: allAlerts ? tab + "All" : tab, data },
        });
      }
      if (offset && !overwrite)
        dispatch({
          type: ActionTypes.SNACK_SUCCESS,
          payload: {
            snackMessage: "Snackbar.Alerts.showMore",
            replacementArray: [data.length],
          },
        });
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: {
          snackMessage:
            typeof error === "string" ? error : JSON.stringify(error),
        },
      });
    }
  };
};

export const toggleAlert = (tab, id, bool, allAlerts) => {
  return async (dispatch) => {
    try {
      await API.toggleAlert(tab, id, bool);
      let tabName = tab;
      if (allAlerts) tabName += "All";
      dispatch({
        type: ActionTypes.TOGGLE_ALERT,
        payload: { tab: tabName, id, bool },
      });
      dispatch({
        type: ActionTypes.SNACK_SUCCESS,
        payload: {
          snackMessage: bool
            ? "Snackbar.Alerts.EnableAlert"
            : "Snackbar.Alerts.DisableAlert",
        },
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: {
          snackMessage:
            typeof error === "string" ? error : JSON.stringify(error),
        },
      });
    }
  };
};

export const triggerAlert = (tab, selected, allAlerts, state) => {
  return (dispatch) => {
    let tabName = tab;
    if (allAlerts) tabName += "All";
    const data = state[tabName];
    selected.forEach(async (each) => {
      try {
        if (data[each].isActive) {
          await API.triggerAlert(tab, each);
          dispatch({
            type: ActionTypes.SNACK_SUCCESS,
            payload: { 
              snackMessage: "Snackbar.Alerts.triggerAlert",
              replacementArray: [data[each].name],

            },
          });
        } else {
          dispatch({
            type: ActionTypes.SNACK_WARNING,
            payload: {
              snackMessage: "Snackbar.Alerts.triggerAlertWarn",
              replacementArray: [data[each].name],
            },
          });
        }
      } catch (error) {
        dispatch({
          type: ActionTypes.SNACK_ERROR,
          payload: {
            snackMessage:
              typeof error === "string" ? error : JSON.stringify(error),
          },
        });
      }
    });
  };
};

export const deleteAlert = (tab, selected, allAlerts) => {
  return (dispatch) => {
    let tabName = tab;
    if (allAlerts) tabName += "All";
    dispatch({
      type: ActionTypes.DELETE_ALERT,
      payload: { tab: tabName, selected },
    });
    selected.forEach(async (each) => {
      try {
        await API.deleteAlert(tab, each);
        dispatch({
          type: ActionTypes.SNACK_SUCCESS,
          payload: {
            snackMessage: "Snackbar.Alerts.deleteAlert",
            replacementArray: [tab],
          },
        });
      } catch (e) {
        dispatch({
          type: ActionTypes.SNACK_ERROR,
          payload: {
            snackMessage: "Snackbar.Alerts.deleteAlertFailed",
            replacementArray: [tab],
          },
        });
      }
    });
  };
};

export const getShareAlertRG = (value) => {
  return async (dispatch) => {
    const filter = value
      ? `( name $so ${value} $or domain $so ${value} $or firstName $so ${value} $or lastName $so ${value} ) $and `
      : "";
    const users = await API.getUsers({
      filter:
        filter +
        "isActive $eq true $and ( admin $eq true $or license $exists true )",
    });
    const recipientList = usersHandler(users);
    dispatch({
      type: ActionTypes.GET_SHARE_ALERT_RG,
      payload: { recipientList },
    });
  };
};

export const clearShareAlertRG = () => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.CLEAR_SHARE_ALERT_RG,
    });
  };
};

export const shareAlerts = (type, alerts, users) => {
  return async (dispatch) => {
    try {
      const result = await API.shareAlerts(type, { alerts, users });
      dispatch({
        type: ActionTypes.SNACK_SUCCESS,
        payload: { snackMessage: result.message },
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: { snackMessage: JSON.stringify(error) },
      });
    }
  };
};

export const validateShareUser = (type, alertId,userId) => {
  return async (dispatch) => {
    try {
      const result = await API.validateShareAlertUser(type, { alertId, userId });
      dispatch({
        type: ActionTypes.SNACK_SUCCESS,
        payload: { snackMessage: result.message },
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: { snackMessage: JSON.stringify(error) },
      });
    }
  };
};

export const switchTabs = (selected) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.SWITCH_TABS,
      payload: selected,
    });
  };
};

export const switchAlertFilter = (value) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.SWITCH_ALERT_FILTER,
      payload: value,
    });
  };
};

export const duplicateAlert = ({
  selected,
  tab,
  allAlerts,
  filter,
  offset,
  size = 50,
  reverse,
  sort,
  }) => {
  return async dispatch => {
    try {
      const allSettled = promises => Promise.all(promises.map((p) => p.then(result => ({ status: 'fulfilled', result })).catch(error => ({ status: 'rejected', error }))))
      const response = await allSettled(
        selected.map((id) => API.duplicateAlert(tab, id))
      )
      const fulfilled = response.filter(i => i.status === "fulfilled");
      const unfulfilled = response.length - fulfilled.length

      const getAlertRequest = {
        tab,
        offset,
        allAlerts,
        filter,
        overwrite: true,
        size,
      }
      if(sort) {
        getAlertRequest.sort = sort;
        getAlertRequest.reverse = reverse;
      }
      await dispatch(getAlerts(getAlertRequest));

      if(fulfilled.length === response.length) {
        dispatch({
          type: ActionTypes.SNACK_SUCCESS,
          payload: { snackMessage: "Snackbar.Alerts.duplicateAlert" },
        });
      }
      if (response.length !== fulfilled.length) {
        dispatch({
          type: ActionTypes.SNACK_WARNING,
          payload: {
            snackMessage: "Snackbar.Alerts.duplicateAlertError",
            replacementArray: [fulfilled.length, unfulfilled],
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: {
          snackMessage:
            typeof error === "string" ? error : JSON.stringify(error),
        },
      });
    }
  };
};
