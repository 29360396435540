import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Actions from '../actions';

const useNavBlocker = () => {
  const dispatch = useDispatch();
  const isNavigationBlocked = useSelector(({ navigationBlockerReducer }) => navigationBlockerReducer.isNavigationBlocked);

  const blockNavigation = useCallback(async () => {
    await dispatch(Actions.blockNavigation());
  }, [dispatch]);

  const unblockNavigation = useCallback(async () => {
    await dispatch(Actions.unblockNavigation());
  }, [dispatch]);

  return {blockNavigation, unblockNavigation, isNavigationBlocked};
};

export default useNavBlocker;
