import alerts from "../containers/Alerts/reducers";
import alertsDetails from '../containers/AlertsDetails/reducers';
import app from '../containers/App/reducer';
import changePassword from "../containers/ChangePassword/reducers";
import channels from "../containers/Channels/reducers";
import channelsDetails from "../containers/ChannelsDetails/reducers";
import config from "../containers/Config/reducers";
import devices from '../containers/Devices/reducers';
import inbox from "../containers/Inbox/reducers";
import inboxSystem from '../containers/InboxSystem/reducers';
import inboxData from '../containers/InboxData/reducers';
import login from "../containers/Login/reducers";
import register from "../containers/Register/reducers";
import sources from '../containers/Sources/reducers';
import sourcesDetails from '../containers/SourcesDetails/reducers';
import suggestions from '../containers/Suggestions/reducers';
import translations from '../i18n/reducers';
import users from '../containers/Users/reducers';
import usersDetails from '../containers/UsersDetails/reducers';
import usersGroups from '../containers/UsersGroups/reducers';
import usersGroupsDetails from '../containers/UsersGroupsDetails/reducers';
import usersPrivileges from '../containers/UsersPrivileges/reducers';
import usersPrivilegesDetails from '../containers/UsersPrivilegesDetails/reducers';
import NavigationBlocker from '../components/NavigationBlocker/reducers';

export default {
  appReducer: app,
  alertsReducer: alerts,
  alertsDetailsReducer: alertsDetails,
  changePasswordReducer: changePassword,
  channelsReducer: channels,
  channelsDetailsReducer: channelsDetails,
  configReducer: config,
  devicesReducer: devices,
  inboxReducer: inbox,
  inboxSystemReducer: inboxSystem,
  inboxDataReducer: inboxData,
  navigationBlockerReducer: NavigationBlocker,
  loginReducer: login,
  registerReducer: register,
  sourcesReducer: sources,
  sourcesDetailsReducer: sourcesDetails,
  suggestionsReducer: suggestions,
  translationsReducer: translations,
  usersReducer: users,
  usersDetailsReducer: usersDetails,
  usersGroupsReducer: usersGroups,
  usersGroupsDetailsReducer: usersGroupsDetails,
  usersPrivilegesReducer: usersPrivileges,
  usersPrivilegesDetailsReducer: usersPrivilegesDetails
};
