import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import useString from "../../i18n/hooks/useString";

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(2),
        marginTop: 8,
        marginBottom: 8,
        display: 'flex',
        flexDirection: 'row'
    },
    singleRadio: {
        marginRight: '2em'
    }
}));

const MyRadio = (props) => {
    const { value, name, options = [], onChange, ...rest } = props
    const getString = useString();
    const classes = useStyles();

    const handleChange = (event, newValue) => onChange(newValue);

    return (
        <div tid="div_parent">
            <FormControl tid="form_control" className={classes.formControl} >
                {options.map((option, index) => (
                    <RadioGroup {...rest}
                        tid={`radio_group_${index}`}
                        id={`${props.tid}_${index}` ||`radio_${index}`}
                        key={index}
                        aria-label={name}
                        name={name}
                        value={value || "false"}
                        onChange={handleChange}
                    >
                        <FormControlLabel
                            tid={`form_control_label_${index}`}
                            className={classes.singleRadio}
                            value={option.value}
                            control={<Radio />}
                            label={ getString(option.name) }
                        />
                    </RadioGroup>
                ))}
            </FormControl>
        </div>
    );
}

export default MyRadio;
