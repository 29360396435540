export default {
  IS_LOGGED: "IS_LOGGED",
  SSO_INPUT_DISPLAY_UPDATE: "ssoInputDisplayUpdate",
  SSO_FLAG_UPDATE: "ssoFlagUpdate",
  LOGOUT: "LOGOUT",
  ACCEPT_SUGGESTION: "acceptSuggestion",
  ADD_SCHEDULE_TIME: "addScheduleTime",
  ADD_CONDITION: "addCondition",
  ADD_CONDITION_RULE: "addConditionRule",
  ADD_VALUE_TO_ALERT: "addValueToAlert",
  ADD_VALUE_TO_CONDITION: "addValueToCondition",
  ADD_VALUE_TO_CHANNELS_DETAILS: "addValueToChannelsDetails",
  ADD_VALUE_TO_CHANGE_PASSWORD: "addValueToChangePassword",
  ADD_VALUE_TO_CONFIG: "addValueToConfig",
  ADD_VALUE_TO_FORGOT_PASSWORD: "addValueToForgotPassword",
  ADD_VALUE_TO_LOGIN: "addValueToLogin",
  ADD_VALUE_TO_REGISTER: "addValueToRegister",
  ADD_VALUE_TO_SOURCES_DETAILS: "addValueToSourcesDetails",
  ADD_VALUE_TO_USERS_DETAILS: "addValueToUsersDetails",
  ADD_FILTER: "addFilter",
  ADD_MEASURE: "addMeasure",
  CANCEL_CHANGE_PASSWORD: "cancelChangePassword",
  CANCEL_CREATE_ALERT: "cancelCreateAlert",
  CANCEL_EDIT_CHANNELS: "cancelEditChannels",
  CANCEL_EDIT_CONFIG: "cancelEditConfig",
  CANCEL_EDIT_PRIVILEGES: "cancelEditPrivileges",
  CANCEL_EDIT_SOURCE: "cancelEditSource",
  CANCEL_EDIT_USER: "cancelEditUser",
  CANCEL_EDIT_USERGROUP: "cancelEditUserGroup",
  CHECK_CONDITIONS: "checkConditions",
  CLEAR_CHANGE_PASSWORD: "clearChangePassword",
  CLEAR_SOURCE: "clearSource",
  CLEAR_TEMPLATE: "clearTemplate",
  CLOSE_SNACK_BAR: "closeSnackBar",
  DELETE_ALERT: "deleteAlert",
  DELETE_CONDITION: "deleteCondition",
  DELETE_CONDITION_RULE: "deleteConditionRule",
  DELETE_SCHEDULE_TIME: "deleteScheduleTime",
  DELETE_ALL_FILTERS: "deleteAllFilters",
  DELETE_FILTER: "deleteFilter",
  DELETE_SUGGESTION: "deleteSuggestion",
  DELETE_MEASURE: "deleteMeasure",
  DELETE_USERS: "deleteUsers",
  DELETE_USERS_GROUPS: "deleteUsersGroups",
  DONT_SHOW_MORE: "DONT_SHOW_MORE",
  EDIT_ALERT: "editAlert",
  EDIT_CHANNEL: "editChannel",
  EDIT_CONDITION_RULE: "editConditionRule",
  EDIT_DELIVERY_CHANNEL: "editDeliveryChannel",
  EDIT_FILE_DATA: "editFile",
  EDIT_LAYER: "editLayer",
  EDIT_PING_SETTINGS: "editPingSettings",
  EDIT_USERS_DETAILS: "editUsersDetails",
  EDIT_USERS_GROUPS_DETAILS: "editUsersGroupsDetails",
  EDIT_USERS_PRIVILEGES_DETAILS: "editUsersPrivilegesDetails",
  EDIT_SOURCE: "editDataSource",
  FILTER_USER_GROUPS_DETAILS: "filterUsersGroupsDetails",
  FILTER_USERS_PRIVILEGES_DETAILS: "filterUsersPrivilegesDetails",
  GET_ALERT: "getAlert",
  GET_ALERTS: "getAlerts",
  GET_APP_SOURCE: "getAppSources",
  GET_APP_SOURCE_LazyLoading : "getAppSourcesLazyLoading",
  GET_APP_SOURCE_LazyLoading_EmptyApps:"GET_APP_SOURCE_LazyLoading_EmptyApps",
  GET_CHANNELS: "getChannels",
  GET_CHANNELS_DETAILS: "getChannelsDetails",
  GET_CONFIG: "getConfig",
  GET_DATA: "getData",
  GET_DISTRIBUTION_GROUPS: "getDistributionGroups",
  GET_DISTRIBUTION_RECIPIENTS: "getDistributionRecipients",
  GET_FILTERS_VALUES: "getFiltersValues",
  GET_GROUPS_SELECTED: "saveGroups",
  GET_HISTORY_SYSTEM_DETAIL: 'getHistorySystemDetail', 
  GET_INBOX: "getInbox",
  GET_INBOX_SCANS: 'getInboxScans',
  GET_INBOX_SCAN_DETAILS: 'getInboxScanDetails',
  GET_INBOX_SCAN_CSV: 'getCsvFile',
  GET_OBJECTS: "getObjects",
  GET_PREVIEW_SELECTED: "previewSelected",
  GET_QR_TOKEN: "generateQrToken",
  GET_RECIPENTS_SELECTED: "saveRecipients",
  GET_SHARE_ALERT_RG: 'getShareAlertsRG',
  GET_SOURCE: "getSource",
  GET_SOURCES: "getSources",
  GET_SUGGESTIONS: "GET_SUGGESTIONS",
  GET_SYSTEM_TASKS: "getSystemTasks",
  GET_USER_LIST: "getUserList",
  GET_USERS: "getUsers",
  GET_USER_DETAILS: "GET_USER_DETAILS",
  GET_USER_GROUPS: "GET_USER_GROUPS",
  GET_USERS_GROUPS_DETAILS: "getUsersGroupsDetails",
  GET_USER_PRIVILEGES: "getPermissions",
  GET_USERS_PRIVILEGES_DETAILS: "getUsersPrivilegesDetails",
  CLEAR_SHARE_ALERT_RG: 'clearShareAlertsRG',
  LOAD_DEVICES: "loadDevices",
  LOCATION_CHANGE: "@@router/LOCATION_CHANGE",
  NAVIGATION_BLOCK: "navigationBlock",
  NAVIGATION_UNBLOCK: "navigationUnblock",
  PUBLISH_BOOKMARK: 'publishBookmark',
  REGISTER_REQUIRED: "registrationStatus",
  REGISTER_DEVICE: "registerDevice",
  REGISTER_CHANNEL: 'registerChannel',
  REGISTER_SOURCE_TEST: 'registerSourceTest',
  REGISTER_SOURCE: 'registerSource',
  REGISTER_STATUS_LOADING: 'registerStatusLoading',
  REGISTER_CONFIG: 'registerConfig',
  FIND_REGISTRATION_USER: 'findRegistrationUser',
  REGISTER_INITIAL_STEP: "registerInitialStep",
  REGISTER_ADMIN:"registerAdmin" ,
  REGISTER_UPLOAD_SOURCE_CERTS: "registerUploadSourceCerts",
  REGISTER_COMPLETED_STEP: "registerCompletedStep",
  REGISTER_BACKWARD_STEP_SOURCE: "registerBackwardStepSource",
  REGISTER_BACKWARD_STEP: "registerBackwardStep",
  UPDATE_LOGIN_PENDING: "updateLoginPending",
  REGISTER_CLEAR: "registerClear",
  REJECT_SUGGESTION: "rejectSuggestion",
  REMOVE_DEVICE: "removeDevice",
  RESTART_ALERTS: "RESTART_ALERTS",
  RESTART_INBOX: "RESTART_INBOX",
  RESTART_SUGGESTIONS: "RESTART_SUGGESTIONS",
  RESTART_USERS: "RESTART_USERS",
  RESTART_USERS_GROUPS: "RESTART_USERS_GROUPS",
  SAVE_ALERT: "saveAlert",
  SAVE_BUTTON_DISABLED: "SAVE_BUTTON_DISABLED",
  SET_LANGUAGE_SUCCESS: "setLanguageSuccess",
  SET_LANGUAGE_FAILURE: "setLanguageFailure",
  SET_LOADING: "setLoading",
  SET_GROUP_NAME: "setGroupName",
  SET_RECOVER_PASSWORD_TOKEN: "setToken",
  SHARE_ALERTS: "shareAlerts",
  SHOW_FORGOT_PASSWORD: "showForgotPassword",
  SHOW_MORE_ALERTS: "SHOW_MORE_ALERTS",
  SHOW_MORE_INBOX: "SHOW_MORE_INBOX",
  SHOW_MORE_GROUPS: "SHOW_MORE_GROUPS",
  SHOW_MORE_SUGGESTIONS: "SHOW_MORE_SUGGESTIONS",
  SHOW_MORE_USERS: "SHOW_MORE_USERS",
  SNACK_ERROR: "snackError",
  SNACK_SUCCESS: "snackSuccess",
  SNACK_WARNING: "snackWarning",
  SORT_ALERTS: "SORT_ALERTS",
  SWITCH_INBOX_TABS: "switchInboxTabs",
  SWITCH_ALERT_FILTER: "switchAlertFilter",
  SWITCH_TABS: "switchTabs",
  TEST_CONNECTION: "testConnection",
  TEST_SOURCE: "testSource",
  TOGGLE_ALERT: "toggleAlert",
  TOGGLE_DEVICE: "toggleDevice",
  TOGGLE_UNSUBSCRIBED: "toggleUnsubscribed",
  TOGGLE_USER: "toggleUser",
  TOGGLE_GROUP: "toggleGroup",
  TRIGGER_ALERT: "triggerAlert",
  UPLOAD_SOURCE_CERTS: "uploadSourceCerts",
  UPLOAD_TEMPLATE: "uploadTemplate",
  RESET_PASSWORD_BTN_HIDE : "resetPasswordButtonHide"
};
