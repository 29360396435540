export const toUserPrivilegesDetails = (input) => {
    if(!Array.isArray(input)) input = [input]
    return input.map(each => {
        return {
            id: each._id,
            name: each.name,
            firstName: each.firstName || '',
            lastName: each.lastName || '',
            domain: each.domain
        }
    })
};

export const fromForm = ids => {
    return {
        users: ids
    }
}