import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { bindActionCreators } from "redux";
import { push } from "connected-react-router";
import * as userGroupsActions from "./actions";
import Table from "../../components/Table";
import Modal from "../../components/Modal";
import useString from "../../i18n/hooks/useString";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Button, Grid, Typography } from "@material-ui/core";
import BreadcrumbsHeader from '../../components/BreadcrumbsHeader';
import { useHistory } from "react-router-dom";
import paths from '../../constants/paths'
import SearchInput from "../../components/SearchInput";
import TableHeader from "../../components/TableHeader";

const useStyles = makeStyles((theme) => ({
  container: {
    overflowY: 'hidden',
    flex: '1 1 auto',
  },
  tableWrapper: {
    overflowX: "hidden",
    overflowY: 'auto',
    paddingBottom: 50
  },
  contentArea: {
    marginTop: theme.spacing(4),
    maxHeight: '100%',
    flexWrap: 'nowrap',
  },
  searchActionBar: {
    flex: '1 1 auto',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  selectedLabel: {
    paddingLeft: theme.spacing(),
  },
}));

const UserGroups = () => {
  const usersGroups = useSelector(({ usersGroupsReducer }) => usersGroupsReducer);
  const login = useSelector(({ loginReducer }) => loginReducer);
  const dispatch = useDispatch();
  const { current: actions } = useRef(bindActionCreators({ ...userGroupsActions }, dispatch));
  const classes = useStyles();
  const history = useHistory();
  const getString = useString();

  const [selected, setSelected] = useState([]);
  const [modal, setModal] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [inlineId, setInlineId] = useState(null);

  useEffect(() => {
    if(login.isAdmin) actions.getUsersGroups()
    else dispatch(push(paths.alerts))
  }, []);

  const showMore = () => actions.getUsersGroups({ offset: usersGroups.dataIds.length, filter: searchInput });

  const handleSearchApply = (value) => {
    setSearchInput(value)
    actions.getUsersGroups({
      offset: 0,
      filter: value,
      overwrite: true
    });
  }

  const customActions = {
    setSelected: setSelected,
    handleToggle: row => {
      actions.toggleGroup(row.id, !row.isActive);
    },
    handleDelete: (id) => {
      if (typeof id === "string") setInlineId(id);
      setModal(usersGroups.modals.delete);
      setModalVisible(true);
    },
    handleEdit: (id) => {
      dispatch(push(paths.getUsersGroupsDetails(id)))
    },
    handleCreate: () => {
      history.push(paths.getUsersGroupsDetails());
    },
    sendDelete: () => {
      actions.deleteUsersGroups(inlineId ? [inlineId] : selected);
      setSelected([]);
      setInlineId()
    },
    selectAll: () => setSelected(usersGroups.dataIds),
    unselectAll: () => setSelected([]),
    sortField: (fieldName, order) => {
      actions.getUsersGroups({
        filter: searchInput,
        sort: fieldName,
        reverse: order === 'asc',
        overwrite: true
      });
    },
  };

  const leftTableActions = (
    <>
      <SearchInput
        tid={'search_input_user_groups'}
        onApply={handleSearchApply}
      />
      {!!selected.length &&
        <span className={classes.selectedLabel}>
          <Typography variant="body2">
            {`${getString('General.Selected')}: ${selected.length}`}
          </Typography>
        </span>
      }
    </>
  );

  const rightTableActions = (
    <>
      <Button tid='deleteAllGroups' variant="outlined" size="medium" onClick={customActions.handleDelete} disabled={!selected.length}>
        {getString('General.Delete')}
      </Button>
      <Button tid='createGroup' variant="contained" color="primary" size="medium" onClick={customActions.handleCreate}>
        {getString('Alerts.Button.Create')}
      </Button>
    </>
  );

  return (
      <Container className={classes.container}>
        <Grid container className={classes.contentArea} direction="column">
          <Grid>
            <BreadcrumbsHeader crumbs={usersGroups.crumbs} />
          </Grid>
          <TableHeader
            leftToolbar={leftTableActions}
            rightToolbar={rightTableActions}
          />
          <Grid item className={classes.tableWrapper}>
            <Table
              selectedIds={selected}
              actions={{ ...actions, ...customActions }}
              data={usersGroups.dataIds.map(each => usersGroups.data[each])}
              dataIds={usersGroups.dataIds}
              {...usersGroups.table}
            />
          </Grid>
            {!usersGroups.showMoreHidden && <Button variant="outlined" onClick={showMore}>{getString('UsersGroups.Button.ShowMore')}</Button>}
          <Modal
            actions={{ ...actions, ...customActions }}
            {...modal}
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
          />
        </Grid>
      </Container>
  );
};

export default UserGroups;
