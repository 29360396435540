import ActionTypes from "../../constants/actionTypes";
import { shouldCleanup } from '../../utils/navigationCleanup';

const initialState = {
  items: {},
  showQr: false,
  qrCode: ""
};

export default function deviceReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.LOAD_DEVICES:
      const devices = action.payload.reduce((prev, cur) => {
        prev[cur.id] = cur;
        return prev;
      }, {});
      return {
        ...state,
        items: devices
      };
    case ActionTypes.REMOVE_DEVICE:
      const id = action.payload;
      const items = { ...state.items };
      delete items[id];
      return {
        ...state,
        items
      };
    case ActionTypes.GET_QR_TOKEN:
      return {
        ...state,
        qrCode: JSON.stringify(action.payload)
      };
    case ActionTypes.TOGGLE_DEVICE:
      const toggleDevices = { ...state.items };
      toggleDevices[action.payload.id].isActive = action.payload.isActive;
      return {
        ...state,
        items: toggleDevices
      };
    case ActionTypes.LOCATION_CHANGE:
        return shouldCleanup(action.payload) ? {...initialState} : state;
    default:
      return state;
  }
}
