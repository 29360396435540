import React from 'react'
import { useSelector } from "react-redux";
import Form from "../../Form";
import { Grid } from "@material-ui/core";

const ConfigStep = props => {

    const { register, handleSelect } = props;
    const config = useSelector(({ configReducer }) => configReducer);

    return (
        <Grid container tid="grid_parent">
            <Form {...config.form}
                tid="form"
                reducerState={register}
                handleSelect={handleSelect}
            />
        </Grid>
    )
}

export default ConfigStep
