import { Edit } from "@material-ui/icons";
import paths from "../../constants/paths";

export const crumbs = [
  {label: "Breadcrumbs.Admin"},
  {label: "Breadcrumbs.Management"},
  {label: "Breadcrumbs.Privileges"}
]

export const table = {
  options: {
    header: true,
    select: true,
    onSelect: null
  },
  style: {
    header: {
      fontWeight: "bold"
    }
  },
  fields: [
    {
      label: "Privileges.Label.Privilege",
      type: "",
      id: "privilege",
      style: { flex: 2 },
      getValue: row => row.name,
      action: null
    },
    {
      label: "Privileges.Label.AssignedUsers",
      type: "",
      id: "assignedUsers",
      style: { flex: 2 },
      getValue: row => row.users,
      action: null
    },
    {
      label: "Privileges.Label.Edit",
      type: "navIcon",
      id: "edit",
      Icon: Edit,
      style: { flex: 2 },
      nav: selection => paths.getUsersPrivilegesDetails(selection.id)
    }
  ]
};
