import React from "react";
import { useSelector } from 'react-redux'
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from '@material-ui/lab/Alert';
import useString from '../../i18n/hooks/useString';

function Alert(props) {
  return <MuiAlert tid="mui_alert" elevation={6} variant="filled" {...props} />;
}

const SnackBar = ({ actions }) => {
  const { showSnackBar, snackMessage, replacementArray, snackType } = useSelector(({ appReducer }) => appReducer); 
  const getString = useString();

  const handleClose = reason => {
    if (reason === "clickaway") {
      return;
    }
    actions.closeSnackBar();
  };

  return (
    <Snackbar
      tid="snackbar"
      open={showSnackBar}
      style={{ marginBottom: '3em' , zIndex : 99999 }}
      label={snackMessage}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      autoHideDuration={ null }//duration || (snackType !== 'error') ? 1500 : null }
      onClose={handleClose}
      ContentProps={{
        "aria-describedby": "message-id"
      }}
    >
      <Alert tid="alert" severity={snackType}>
        {getString(snackMessage, replacementArray)}
      </Alert>

    </Snackbar>
  );
}

export default SnackBar;
