import React, { useEffect, useState, useRef } from "react";
import { bindActionCreators } from "redux";
import { useSelector, useDispatch } from 'react-redux'
import * as inboxActions from "./actions";
import { Container, Grid, Button } from "@material-ui/core";
import Table from "../../components/Table";
import useString from "../../i18n/hooks/useString";
import { Tab, Tabs } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SearchInput from "../../components/SearchInput";
import TableHeader from "../../components/TableHeader";

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    overflowY: 'hidden',
    flex: '1 1 auto'
  },
  tableWrapper: {
    overflowX: "hidden",
    overflowY: 'auto',
    paddingBottom: theme.spacing()
  },
  contentArea: {
    marginTop: theme.spacing(4),
    maxHeight: '100%',
    flexWrap: 'nowrap'
  },
  searchActionBar: {
    flex: '1 1 auto',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  tabs: {
    marginBottom: theme.spacing(3),
  }
}));

const Inbox = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const getString = useString();
  const login = useSelector(({ loginReducer }) => loginReducer);
  const inbox = useSelector(({ inboxReducer }) => inboxReducer);
  const { current: actions } = useRef(bindActionCreators({ ...inboxActions }, dispatch));
  const [searchInput, setSearchInput] = useState('');
  const [searchApplied, setSearchApplied] = useState(false);
  const { isAdmin, permissions } = login;

  useEffect(() => {
    let searchObj = { tab: inbox.tab, filter: searchInput };
    if (searchApplied == true) searchObj.overwrite = true;
    actions.getHistoryData({ ...searchObj });
    setSearchApplied(false);
  }, [actions, searchInput, inbox.tab]);

  const showMore = () =>
    actions.getHistoryData({
      tab: inbox.tab,
      offset: inbox.dataIds.length,
      filter: searchInput,
    });

  const handleChange = (event, newValue) => actions.switchInboxTabs(newValue);

  const handleSearchApply = (value) => {
    setSearchInput(value);
    setSearchApplied(true);
  }

  const sortField = (fieldName, order) => {
    actions.getHistoryData({
      tab: inbox.tab,
      filter: searchInput,
      sort: fieldName,
      reverse: order === "asc",
      overwrite: true
    });
  };

  const leftTableActions = (
    <>
      {/* <FormControl variant="outlined" className={classes.formControl}>
        <BufferedInput
          variant="select"
          onApply={actions.switchInboxTabs}
          value={false}
          displayEmpty
        >
          <MenuItem value={false}>
            {getString('Alerts.Dropdown.MyAlerts')}
          </MenuItem>
          <MenuItem value={true}>
            {getString('Alerts.Dropdown.AllAlerts')}
          </MenuItem>
        </BufferedInput>
      </FormControl> */}
      <SearchInput
        onApply={handleSearchApply}
      />
    </>
  );

  return (
      <Container className={classes.container} >
        <Grid container className={classes.contentArea} direction="column">
          <Grid item className={classes.tabs}>
            <Tabs value={inbox.tab} onChange={handleChange}>
              {inbox.tabs.map(each => (
                each.value === "data" || isAdmin || permissions.includes(each.value)
                  ? <Tab key={each.value} tid={each.value} value={each.value} label={getString(each.label)} />
                  : null
              ))}
            </Tabs>
          </Grid>
          <TableHeader leftToolbar={leftTableActions} />
          <Grid className={classes.tableWrapper}>
            <Table
              tid={'inbox_table'}
              actions={{ ...actions, sortField }}
              data={inbox[inbox.tab + "Ids"].map(each => inbox[inbox.tab][each])}
              {...inbox.tables[inbox.tab]}
            />
          </Grid>

          {!inbox.showMoreHidden && <Button variant="outlined" onClick={showMore}>Show more</Button>}
        </Grid>
      </Container>
  );
};

export default Inbox;
