export const toSourceTable = (input) => {
    if(!Array.isArray(input)) input = [input]
    return input.map(each => {
        return {
            id: each._id,
            sourceName: each.name,
            sourceType: each.type,
            sourceHost: each.config.url
        }
    })
};
