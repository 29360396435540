import actionTypes from "../../constants/actionTypes";
import * as definitions from "./definitions";
import { shouldCleanup } from '../../utils/navigationCleanup';

const initialState = {
  ...definitions,
  hostname: "localhost",
  scanInterval: 1,
  httpPort: "4551",
  httpsPort: "4552",
  sessionLimit: 30,
  workerSlaves: 10,
  saveIsDisabled: false
};

export default function pingSettingsEditReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.EDIT_PING_SETTINGS:
      return {
        ...state,
        result: action.payload.result,
        ...action.payload.data
      };
    case actionTypes.GET_CONFIG:
      return {
        ...state,
        ...action.payload
      };
    case actionTypes.CANCEL_EDIT_CONFIG:
      return state
    case actionTypes.ADD_VALUE_TO_CONFIG:
      return {
        ...state,
        [action.payload.field]: action.payload.value
      };
    case actionTypes.SAVE_BUTTON_DISABLED:
      return {
        ...state,
        saveIsDisabled: action.payload.saveIsDisabled
      };
    case actionTypes.LOCATION_CHANGE:
        return shouldCleanup(action.payload) ? {...initialState} : state;
    default:
      return state;
  }
}
