import ActionTypes from "../../constants/actionTypes";
import * as definitions from "./definitions";
import { shouldCleanup } from '../../utils/navigationCleanup';

const initialState = {
  ...definitions,
  data: {},
  dataIds: [],
  dataAll: {},
  dataAllIds: [],
  system: {},
  systemIds: [],
  systemAll: {},
  systemAllIds: [],
  broadcast: {},
  broadcastIds: [],
  broadcastAll: {},
  broadcastAllIds: [],
  selectedIds: [],
  tab: "data",
  dialogOpen: false,
  dialogText: "",
  allAlerts: false,
  tabName: "data",
  showMoreHidden: false,
  recipientList: [],
  sortBy: '',
  sortAscending: true
};

export default function alertsReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_ALERTS:
      return {
        ...state,
        [action.payload.tab]: {
          ...state[action.payload.tab],
          ...action.payload.data.reduce((prev, cur) => {
            prev[cur.id] = cur;
            return prev;
          }, {})
        },
        [action.payload.tab + "Ids"]: [
          ...new Set([
            ...state[action.payload.tab + "Ids"],
            ...action.payload.data.map(each => each.id)
          ])
        ]
      };
    case ActionTypes.RESTART_ALERTS:
      return {
        ...state,
        [action.payload.tab]: action.payload.data.reduce((prev, cur) => {
          prev[cur.id] = cur;
          return prev;
        }, {}),
        [action.payload.tab + "Ids"]: action.payload.data.map(each => each.id)
      };
    case ActionTypes.TOGGLE_ALERT:
      if (state[action.payload.tab][action.payload.id]) state[action.payload.tab][action.payload.id].isActive = action.payload.bool;
      return {
        ...state
      };
    case ActionTypes.SWITCH_TABS:
      return {
        ...state,
        tab: action.payload,
        tabName: state.allAlerts ? action.payload + 'All' : action.payload,
        selectedIds: [],
        showMoreHidden: false
      };
    case ActionTypes.SWITCH_ALERT_FILTER:
      return {
        ...state,
        allAlerts: action.payload,
        tabName: action.payload ? state.tab + 'All' : state.tab,
        selectedIds: [],
        showMoreHidden: false
      };
    case ActionTypes.GET_SHARE_ALERT_RG:
      return {
        ...state,
        ...action.payload
      };
    case ActionTypes.CLEAR_SHARE_ALERT_RG: 
      return {
        ...state,
        recipientList: []
      };
    case ActionTypes.DELETE_ALERT:
      action.payload.selected.forEach(each => delete state[action.payload.tab][each]);
      return {
        ...state,
        [action.payload.tab + "Ids"]: state[action.payload.tab + "Ids"].filter(each => !action.payload.selected.includes(each))
      };
    case ActionTypes.SHOW_MORE_ALERTS:
      return {
        ...state,
        showMoreHidden: action.payload
      }
    case ActionTypes.SORT_ALERTS:
      return {
        ...state,
        sortBy: action.payload.sort,
        sortAscending: !action.payload.reverse
      }
    case ActionTypes.LOCATION_CHANGE:
        return shouldCleanup(action.payload) 
          ? { ...initialState, tab: state.tab, tabName: state.tabName, allAlerts: state.allAlerts } 
          : state;
    default:
      return state;
  }
}
