import ActionTypes from "../../constants/actionTypes";
import API from "../../lib/api";
import { toSuggestions } from "./dataHandler";

export const getSuggestions = ({ offset, filter, overwrite, size = 50 } = {}) => {
  return async dispatch => {
    try {
      const filterString = filter ? `name $so ${filter} $or sharedByInfo.name $so ${filter} $or sharedByInfo.firstName $so ${filter} $or sharedByInfo.lastName $so ${filter}` : '';
      const result = await API.getInvitations(offset, size, filterString);
      const data = await toSuggestions(result);
      dispatch({
        type: ActionTypes.SHOW_MORE_SUGGESTIONS,
        payload: data.length < size
      });
      if (overwrite) {
        dispatch({
          type: ActionTypes.RESTART_SUGGESTIONS,
          payload: data
        })
      } else {
        dispatch({
          type: ActionTypes.GET_SUGGESTIONS,
          payload: data
        });
      }
      if (offset)
        dispatch({
          type: ActionTypes.SNACK_SUCCESS,
          payload: { 
            snackMessage: "Snackbar.Suggestions.showMore",
            replacementArray: [data.length]
          }
        });
    } catch (error) {
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: { snackMessage: typeof error === "string" ? error : JSON.stringify(error) }
      });
    }
  };
};

export const acceptSuggestion = selected => {
  return async dispatch => {
    const allSettled = promises => Promise.all(promises.map((p, i) => p.then(result => ({ status: 'fulfilled', result, i })).catch(error => ({ status: 'rejected', error, i }))))
      const response = await allSettled(
        selected.map((each) => API.acceptInvitation(each))
      )
      const fulfilled = response.filter(i => i.status === "fulfilled");
      const unfulfilled = response.length - fulfilled.length     
      dispatch({
        type: ActionTypes.ACCEPT_SUGGESTION,
        payload: fulfilled.map(each => selected[each.i])
      });  
      if(fulfilled.length === response.length) {
        dispatch({
          type: ActionTypes.SNACK_SUCCESS,
          payload: { snackMessage: "Snackbar.Suggestions.acceptSuggestion" }
        });
      }
      if (response.length !== fulfilled.length) {
        dispatch({
          type: ActionTypes.SNACK_WARNING,
          payload: {
            snackMessage: "Snackbar.Alerts.acceptSuggestionError",
            replacementArray: [fulfilled.length, unfulfilled],
          },
        });
      }
  };
};

export const rejectSuggestion = selected => {
  return async dispatch => {
    const allSettled = promises => Promise.all(promises.map((p, i) => p.then(result => ({ status: 'fulfilled', result, i })).catch(error => ({ status: 'rejected', error, i }))))
      const response = await allSettled(
        selected.map((each) => API.rejectInvitation(each))
      )
      const fulfilled = response.filter(i => i.status === "fulfilled");
      const unfulfilled = response.length - fulfilled.length  
      dispatch({
        type: ActionTypes.REJECT_SUGGESTION,
        payload: fulfilled.map(each => selected[each.i])
      });  
      if(fulfilled.length === response.length) {
        dispatch({
          type: ActionTypes.SNACK_SUCCESS,
          payload: { snackMessage: "Snackbar.Suggestions.rejectSuggestion" }
        });
      }
      if (response.length !== fulfilled.length) {
        dispatch({
          type: ActionTypes.SNACK_WARNING,
          payload: {
            snackMessage: "Snackbar.Alerts.rejectSuggestionError",
            replacementArray: [fulfilled.length, unfulfilled],
          },
        });
      }
  };
};
