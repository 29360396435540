import ActionTypes from "../../constants/actionTypes";
import API from "../../lib/api";
import { toInbox } from "./dataHandler";


export const getHistoryData = ({ tab, offset, filter, overwrite, size = 50, sort, reverse }) => {
  return async dispatch => {
    try {
      const filterString = filter ? `name $so ${filter}` : '';
      const result = await API.getHistory({ type: tab, offset, filter: filterString, overwrite, sort, reverse })
      const data = toInbox[tab](result);
      dispatch({
        type: ActionTypes.SHOW_MORE_INBOX,
        payload: data.length < size
      });
      if (overwrite) {
        dispatch({
          type: ActionTypes.RESTART_INBOX,
          payload: { tab, data }
        })
      } else {
        dispatch({
          type: ActionTypes.GET_INBOX,
          payload: { tab, data }
        });
      }
      if (offset) dispatch({
        type: ActionTypes.SNACK_SUCCESS,
        payload: { 
          snackMessage: "Snackbar.Inbox.showMore",
          replacementArray: [data.length]
        }
      });
    } catch (error) {
      console.log(error)
      dispatch({
        type: ActionTypes.SNACK_ERROR,
        payload: { snackMessage: typeof error === "string" ? error : JSON.stringify(error) }
      });
    }
  };
};

export const switchInboxTabs = selected => {
  return dispatch => {
    dispatch({
      type: ActionTypes.SWITCH_INBOX_TABS,
      payload: selected
    });
  };
};
