import React, { useEffect, useState } from "react";
import { makeStyles, Button, Grid, Switch, FormControlLabel, Typography } from "@material-ui/core";
import Selector from "../../Selector";
import Radio from "../../Radio";
import SearchInput from "../../SearchInput";
import Assigner from "../../Assigner";
import Modal from "../../ModalCustom";
import Table from "../../Table";
import * as def from "./definitions";
import useString from "../../../i18n/hooks/useString";
import NavTitle from "../../NavTitle";
import { COLORS } from '../../../utils/sproutOverrides';

const useStyles = makeStyles((theme) => ({
  inputWrapper: {
    maxWidth: '500px',
    '& > *': {
      marginBottom: theme.spacing(1),
    }
  },
  sectionSpacing: {
    marginBottom: theme.spacing(1)
  },
  itemSpacing: {
    marginTop: theme.spacing()
  },
  previewListButton: {
    marginTop: theme.spacing(4)
  },
  assignerWrapper: {
    height: 'calc(100% - 92px)',
  },
  tableWrapper: {
    minWidth: '600px',
    borderTop: `solid 1px ${COLORS.GREYSCALE_85}`
  },
  publishBookmark: {
    display: 'inherit', 
    alignItems: 'center'
  }
}));

const Distribution = props => {
  const { actions, alertsDetails, login, type, alertId } = props;
  const classes = useStyles();
  const {
    advancedDistribution,
    recipients,
    groups,
    unsubscribed,
    previewSelected,
    managedAlert,
    app,
    bookmark,
    options
  } = alertsDetails;
  const getString = useString();
  const allowedDistribution = login.isAdmin || login.permissions.includes("distribution");
  const [selected, setSelected] = useState([]);
  const [buttons, setButtons] = useState([]);
  const [title, setTitle] = useState("");
  const [preview, setPreview] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [pendingUnassigned, setPendingUnassigned] = useState([]);
  const [pendingAssigned, setPendingAssigned] = useState([]);

  const handleSelect = field => value => {
    switch (field) {
      case "frequency":
        actions.addValueToAlert(field, value ? value.value || "" : "");
        break;
      case "channels":
        actions.addValueToAlert(field, value.map(each => each.value) || []);
        break;
      case "advancedDistribution":
        actions.addValueToAlert(field, value.target.checked);
        break;
      case "recipients":
      case "groups":
        actions.addValueToAlert(field, value || []);
        break;
      case "managedAlert":
        actions.addValueToAlert(field, value === "true");
        break;
      default:
        break;
    }
  };

  const handleUnsubscribed = (row) => {
    actions.toggleUnsubscribed(type, alertId, row.id, !row.unsubscribed);
  }

  // R&G
  const handleClick = listName => {
    if (listName === "recipients") {
      actions.getDistributionRecipients(type, alertId, null, alertsDetails.managedAlert, login.userId)
    } else if (listName === "groups") {
      actions.getDistributionGroups(type, alertId)
    }
    setButtons([{ key: "save", label: getString('General.Save'), onClick: "handleSave", color: "primary", variant: "contained" }]);
    setSelected(alertsDetails[listName]);
    setTitle(listName);
    setModalVisible(true);
  };

  const handlePublish = () => actions.publishBookmark(app,bookmark)

  const handlePreview = () => {
    setButtons([]);
    setSelected([]);
    actions.previewSelected(recipients, groups, unsubscribed);
    setTitle("PREVIEW");
    setModalVisible(true);
  };
  const handleClose = () => {
    setSelected([])
    setPendingAssigned([])
    setPendingUnassigned([])
  };
  const handleSave = () => {
    handleSelect(title)(selected)
    setPendingAssigned([])
    setPendingUnassigned([])
  };

  useEffect(() => {
    setPreview(previewSelected);
  }, [previewSelected]);

  const handleSearchApply = (value) => {
    if (title === "recipients") {
      actions.getDistributionRecipients(type, alertId, value, alertsDetails.managedAlert, login.userId)
    } else if (title === "groups") {
      actions.getDistributionGroups(type, alertId, value)
    }
  };

  const publishedBookmark = (options.bookmarks.find(each => each.id === bookmark) || {}).published

  return (
    <Grid container item tid="grid_container_parent" direction="column">
      <NavTitle
        title={getString("AlertsDetails.Label.Distribution")}
        description={getString("AlertsDetails.Distribution.Description")}
      />
      <Grid
        item
        className={classes.inputWrapper}
        tid="grid_channels_frequency"
        xs
        style={{ flexBasis: 'auto' }}
      >
        <Selector
          tid="selector_channels"
          {...alertsDetails.selectors.channels}
          label={("Distribution.Label.Channels")}
          id={"channels"}
          value={alertsDetails.channels}
          onSelect={handleSelect("channels")}
        />
        {type !== "broadcast" && <>
          <Selector
            tid="selector_frequency"
            {...alertsDetails.selectors.frequency}
            label={("Distribution.Label.Frequency")}
            id={"frequency"}
            value={alertsDetails.frequency}
            onSelect={handleSelect("frequency")}
          />
        </>}
        {type !== "broadcast" && allowedDistribution &&
          <FormControlLabel
            control={
              <Switch
                tid="switch_advanced_distribution"
                id="advancedDistribution"
                checked={advancedDistribution}
                color="primary"

                onClick={handleSelect("advancedDistribution")}
              />
            }
            label={getString("Distribution.Label.Advanced")}
            labelPlacement="start"
          />
        }
      </Grid>
      {advancedDistribution || type === "broadcast" ? (
        <>
          <Grid
            item
            container
            tid="grid_managed_alert_radio"
            xs
            direction="column"
            style={{ flexBasis: 'auto' }}
            className={classes.sectionSpacing}
          >
            {type === "data" &&
              <>
                <Radio
                  tid="radio_managed_alert"
                  id="managedAlert"
                  label={getString("Distribution.Label.ManagedAlert")}
                  value={JSON.stringify(alertsDetails.managedAlert)}
                  type="radio"
                  options={[{ name: getString("Distribution.Label.BroadcastAlert"), value: "false" }, { name: getString("Distribution.Label.ManagedAlert"), value: "true" }]}
                  onChange={handleSelect("managedAlert")}
                />
                {managedAlert && bookmark && !publishedBookmark &&
                  <Grid className={classes.publishBookmark}>
                    <Typography tid="typography_publish_bookmark" variant="body2" color="textSecondary">{getString("AlertsDetails.Distribution.PublishBookmark")}</Typography>
                    <Button style={{marginLeft: '10px'}} tid="button_publish_bookmark" variant="outlined" onClick={() => handlePublish()}>{getString("Distribution.Label.PublishBookmark")}</Button>
                  </Grid>
                }
              </>
            }
          </Grid>
          <Grid container item tid="grid_recipients_and_groups" xs direction="column" style={{ flexBasis: 'auto' }}>

            <Grid
              item
              container
              tid="grid_parent"
              direction="column"
              style={{ flexBasis: 'auto' }}
              xs
            >
              <Grid
                tid="grid_recipients_groups_body"
                container
                direction="row"
                spacing={2}
              >
                <Grid tid="grid_recipients" item>
                  <Button tid="button_recipients" variant="outlined"
                    onClick={() => handleClick("recipients")}>
                    {getString("Distribution.Label.SelectRecipients")}
                  </Button>
                  {alertsDetails.recipients.length > 0 &&
                    <Typography tid="typography_recipients" variant="body2" color="textSecondary" className={classes.itemSpacing}>
                      {getString("Distribution.Label.SelectedRecipients")}{alertsDetails.recipients.length}
                    </Typography>
                  }
                </Grid>
                <Grid tid="grid_groups" item>
                  <Button tid="button_groups" variant="outlined"
                    onClick={() => handleClick("groups")}>
                    {getString("Distribution.Label.SelectGroups")}
                  </Button>
                  {alertsDetails.groups.length > 0 &&
                    <Typography tid="typography_groups" variant="body2" color="textSecondary" className={classes.itemSpacing}>
                      {getString("Distribution.Label.SelectedGroups")}{alertsDetails.groups.length}
                    </Typography>
                  }
                </Grid>
              </Grid>
              <Grid item tid="grid_preview_list">
                <Button tid="button_preview_list" variant="outlined" onClick={() => handlePreview()} className={classes.previewListButton}>
                  {getString("Distribution.Label.PreviewList")}
                </Button>
              </Grid>
            </Grid>
            {!!title && <Modal
              tid="modal"
              actions={{ ...actions, handleClose, handleSave }}
              options={{ title: title.toUpperCase(), onClose: "handleClose" }}
              buttons={buttons}
              content={
                title !== "PREVIEW" ?
                  <>
                    <SearchInput tid={'search_input_distribution'} onApply={handleSearchApply} />
                    <div className={classes.assignerWrapper}>
                      <Assigner
                        unassignedData={alertsDetails.unassigned[title]}
                        assignedData={alertsDetails.assigned[title]}
                        assigned={alertsDetails[title]}
                        fields={title === "groups" && def.groupFields}
                        onAssigned={setSelected}
                        setSaveDisabled={() => { }}
                        pendingUnassigned={pendingUnassigned}
                        setPendingUnassigned={setPendingUnassigned}
                        pendingAssigned={pendingAssigned}
                        setPendingAssigned={setPendingAssigned}
                      />
                    </div>
                  </>
                  :
                  <>
                    <div className={classes.tableWrapper}>
                      <Table
                        tid="table_preview"
                        actions={{ handleUnsubscribed }}
                        data={preview}
                        {...def.tables.preview}
                      />
                    </div>
                  </>
              }
              modalVisible={modalVisible}
              setModalVisible={setModalVisible}
            />}
          </Grid>
        </>
      ) : null}

    </Grid>
  );
};

export default Distribution;
