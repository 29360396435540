import React, { useEffect, useRef, useCallback } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SnackBar from "../../components/SnackBar";
import Spinner from "../../components/Spinner";
import NavigationBlocker from "../../components/NavigationBlocker";

// ACTIONS
import * as app from "../App/actions";
import * as login from "../Login/actions";

import ActionTypes from "../../constants/actionTypes";

import paths from "../../constants/paths";

// CONTAINERS
import Alerts from "../Alerts";
import AlertsDetails from "../AlertsDetails";
import ChangePassword from "../ChangePassword";
import Channels from "../Channels";
import ChannelsDetails from "../ChannelsDetails";
import Config from "../Config";
import Devices from "../Devices";
import Page404 from '../Page404';
import Inbox from "../Inbox";
import InboxSystem from "../InboxSystem";
import InboxData from "../InboxData";
import Login from "../Login";
import NavBar from "../../components/NavBar";
import Register from "../Register";
import Sources from "../Sources";
import SourcesDetails from "../SourcesDetails";
import Unsubscribe from "../Unsubscribe";
import Users from "../Users";
import UsersDetails from "../UsersDetails";
import UsersGroups from "../UsersGroups";
import UsersGroupsDetails from "../UsersGroupsDetails";
import UsersPrivileges from "../UsersPrivileges";
import UsersPrivilegesDetails from "../UsersPrivilegesDetails";
import LoginQXSession from "../LoginQXSession";

import Suggestions from "../Suggestions";
import { push } from "connected-react-router";
import { store } from "../../index";
import useString from "../../i18n/hooks/useString";
import useNavBlocker from "../../components/NavigationBlocker/hooks/useNavBlocker";

const App = props => {
  const { actions, login, app } = props;
  const location = useLocation();
  const { unblockNavigation } = useNavBlocker();
  const logoutTimer = useRef();
  const expiryWarningTimer = useRef();
  const getString = useString();

  const showExpiryNotice = useCallback(() =>
    store.dispatch({
      type: ActionTypes.SNACK_WARNING,
      payload: { snackMessage: getString("App.ExpiryNotice")}
    }),
  [getString]);

  useEffect(() => {
    const currentTime = Date.now();
    const BUFFER = 5000;
    if (login.expiresOn && login.expiresOn > currentTime) {
      const delta = (login.expiresOn - currentTime) + BUFFER;
      clearInterval(logoutTimer.current);
      clearInterval(expiryWarningTimer.current);

      logoutTimer.current = setTimeout(() => {
        actions.isLogged();
      }, delta);

      // Notify the user when 15% session time is remaining
      expiryWarningTimer.current = setTimeout(showExpiryNotice, delta * 0.85); 
    }
  }, [actions, login.expiresOn, showExpiryNotice]);

  useEffect(() => {
    const { token, userId } = localStorage;
    const validUnauth = [
      /^\/register$/,
      /^\/unsubscribe$/,
      /^\/loginQXSession$/,
      /^\/change-password\/[a-zA-Z0-9-]{24}([a-zA-Z0-9]{12})?\/*$/
    ];
    if(!validUnauth.some(e => location.pathname.match(e))){
      console.log("location.pathname",location.pathname)
      if (token && userId) {
        actions.isLogged();
      } else {
        unblockNavigation().then(() => {
          store.dispatch(push(paths.login))
        });
      }
    }
  }, [actions, login.token, location.pathname, unblockNavigation]);

  return (
    <>
      <NavBar />
      <NavigationBlocker />
      <SnackBar actions={props.actions} />
      <Spinner loading={app.loading} />
      {!login.token ? (
        <Switch>
          <Route path={paths.login} render={() => <Login />} />
    
          <Route
            path={paths.changePassword}
            render={() => <ChangePassword />}
          />
          <Route exact path={paths.register} render={() => <Register />} />
          <Route path={paths.unsubscribe} render={() => <Unsubscribe />} />
          <Route path={paths.loginQXSession} render={() => <LoginQXSession />} />
        </Switch>
      ) : ( 
        <Switch>
          <Route exact path={paths.login} render={() => <Login />} />
          <Route exact path={paths.register} render={() => <Register />} />
          <Route exact path={paths.app} render={() => <Alerts />} />
          <Route path={paths.alertsDetails} render={() => <AlertsDetails />} />
          <Route path={paths.changePassword} render={() => <ChangePassword />} />
          <Route exact path={paths.channels} render={() => <Channels />} />
          <Route path={paths.channelsDetails} render={() => <ChannelsDetails />} />
          <Route path={paths.config} render={() => <Config />} />
          
          <Route path={paths.devices} render={() => <Devices />} />
          <Route exact path={paths.inbox} render={() => <Inbox />} />
          <Route path={paths.inboxSystem} render={() => <InboxSystem />} />
          <Route path={paths.inboxData} render={() => <InboxData />} />
          <Route path={paths.suggestions} render={() => <Suggestions />} />
          <Route path={paths.register} render={() => <Register />} />
          <Route exact path={paths.sources} render={() => <Sources />} />
          <Route path={paths.sourcesDetails} render={() => <SourcesDetails />} />
          <Route path={paths.unsubscribe} render={() => <Unsubscribe />} />
          <Route exact path={paths.users} render={() => <Users />} />
          <Route path={paths.usersDetails} render={() => <UsersDetails />} />
          <Route exact path={paths.usersGroups} render={() => <UsersGroups />} />
          <Route path={paths.usersGroupsDetails} render={() => <UsersGroupsDetails />} />
          <Route exact path={paths.usersPrivileges} render={() => <UsersPrivileges />} />
          <Route exact path={paths.usersPrivilegesDetails} render={() => <UsersPrivilegesDetails />} />
          <Route path="*" component={ Page404 } />
        </Switch>
      )} 
    </>
  );
};

const mapStateToProps = state => ({
  app: state.appReducer,
  login: state.loginReducer,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...app,
      ...login,
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
