let previousLocations = [null, null];

const startListening = (history) => {
  previousLocations.fill(history.location);
  return history.listen((location) => {
    previousLocations.unshift(location);
    previousLocations.pop();
  })
};

export {
  previousLocations,
  startListening
};