import actionTypes from "../../constants/actionTypes";
import * as definitions from "./definitions";
import { shouldCleanup } from '../../utils/navigationCleanup';

const initialState = {
  ...definitions,

  inserted: {},
  type: '',
  options: {
    apps: [],
    sheets: [],
    measures: [],
    dimensions: [],
    bookmarks: [],
    fields: [],
    fieldValues: []
  },

  name: "",
  description: "",
  digest: false,

  sheet: "",
  app: "",
  measures: [],
  filters: [],
  dimension: '',
  bookmark: "",
  filterSelection: "",
  conditionLogic: ["A"],
  warnings: {},
  conditions: [{}],
  measure: '',
  format: 'Auto',
  field: '',
  fieldValues: [],
  sortBy: 1,
  sortAscending: false,

  assigned: {
    recipients: {},
    groups: {}
  },
  unassigned: {
    recipients: {},
    groups: {}
  },
  recipientsBuffer: {},
  recipients: [],
  groups: [],
  previewSelected: [],
  unsubscribed: [],
  channels: ['email'],
  frequency: "always",
  advancedDistribution: false,
  managedAlert: false,
  publishedBookmark: true,
  timeZone: "",
  dayOfWeek: [],
  dayOfMonth: [],
  onSchedule: false,
  intervalTime: false,
  minHour: "08:00",
  maxHour: "18:59",
  intervalHours: 1,
  intervalMinutes: 0,
  times: [],
  since: "",
  until: "",
  notificationCustom: false,
  subject: '',
  body: '',

  queryTable: {},
  queryData: [],
  resultData: [],
  conditionTable: {},
  conditionResult: {
    positive: [],
    negative: []
  },

  systemTasks: [],
  taskIds: [],
  systemSuccess: false,
  systemFailed: false,
  systemAborted: false,
  systemSystemAborted: false,
  systemInProgress: false,
  systemInProgressFor: 180,
  systemTaskDef: "",
  systemTaskDefOp: "$all",

  systemTaskSelection: false,
  saveIsDisabled: false
};

export default function alertsDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_ALERT:
      return {
        ...state,
        ...action.payload.data,
        alertType: action.payload.type,
        alertId: action.payload.id
      };
    case actionTypes.GET_APP_SOURCE:
      return {
        ...state,
        options: {
          ...state.options,
          apps: action.payload
        }
      };
    case actionTypes.GET_APP_SOURCE_LazyLoading:
      
      return {
        ...state,
        options: {
          ...state.options,
          apps: [...state.options.apps,...action.payload]
                .filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i)
                .sort((a, b) => {
                  if (a.stream === b.stream) return a.name.localeCompare(b.name);
                  else return a.stream.localeCompare(b.stream);
                })
        }
      };
      case actionTypes.GET_APP_SOURCE_LazyLoading_EmptyApps:   
        return {
          ...state,
          options: {
            ...state.options,
            apps: []
          }
        };
    case actionTypes.GET_OBJECTS:
      return {
        ...state,
        measure: '',
        format: 'Auto',
        field: '',
        fieldValues: [],
        options: {
          ...state.options,
          ...action.payload
        }
      };
    case actionTypes.GET_FILTERS_VALUES:
      return {
        ...state,
        options: {
          ...state.options,
          fieldValues: action.payload.map(each => each[0])
        }
      };
    case actionTypes.ADD_MEASURE:
      if (action.payload)
        return {
          ...state,
          measures: [
            ...state.measures,
            {
              ...action.payload,
              format: state.format
            }
          ],
          measure: '',
          format: 'Auto'
        };
      else
        return {
          ...state,
          measures: [
            ...state.measures,
            {
              ...state.measure,
              format: state.format
            }
          ],
          measure: '',
          format: 'Auto'
        };
    case actionTypes.DELETE_MEASURE:
      const measures = [...state.measures];
      measures.splice(action.payload, 1);
      return {
        ...state,
        measures
      };
    case actionTypes.ADD_FILTER:
      return {
        ...state,
        filters: [
          ...state.filters,
          {
            field: state.field.def,
            values: state.fieldValues,
            isNum: state.field.isNum,
            isDate: state.field.isDate
          }
        ],
        field: '',
        fieldValues: [],
        options: {
          ...state.options,
          fieldValues: []
        }
      };
    case actionTypes.DELETE_ALL_FILTERS:
      return {
        ...state,
        filters: [],
      };
    case actionTypes.DELETE_FILTER:
      const filters = [...state.filters];
      filters.splice(action.payload, 1);
      return {
        ...state,
        filters
      };
    case actionTypes.GET_DATA:
      const { count, result } = action.payload
      const resultData = [...result];
      const headers = result
        .shift()
        .map((each, i) => ({ label: each, id: each, getValue: row => typeof row[i] === 'object' ? row[i].qText : row[i] }));
      return {
        ...state,
        resultData,
        queryCount: count,
        queryData: result,
        queryTable: {
          options: { header: true },
          fields: headers
        }
      };
    case actionTypes.ADD_CONDITION:
      return {
        ...state,
        conditions: [...state.conditions, {}]
      };
    case actionTypes.DELETE_CONDITION:
      const conditions = [...state.conditions];
      conditions.splice(action.payload, 1);
      return {
        ...state,
        conditions
      };
    case actionTypes.ADD_CONDITION_RULE:
      return {
        ...state,
        conditionLogic: [...state.conditionLogic, "A"]
      };
    case actionTypes.EDIT_CONDITION_RULE:
      const temp = [...state.conditionLogic];
      temp[action.payload.index] = action.payload.value;
      const warnings = { ...state.warnings, [action.payload.index]: action.payload.warning }
      return {
        ...state,
        conditionLogic: temp,
        warnings
      };
    case actionTypes.DELETE_CONDITION_RULE:
      const conditionLogic = [...state.conditionLogic];
      const newWarnings = {};

      for (let [keyString, value] of Object.entries(state.warnings)) {
        const keyToDelete = action.payload;
        const key = Number(keyString);
        if (key > keyToDelete) {
          newWarnings[key - 1] = value;
        } else if (key < keyToDelete) {
          newWarnings[key] = value;
        }
      }

      conditionLogic.splice(action.payload, 1);

      return {
        ...state,
        warnings: newWarnings,
        conditionLogic
      };
    case actionTypes.CHECK_CONDITIONS:
      return {
        ...state,
        conditionResult: action.payload,
        conditionTable: {
          options: { header: true },
          fields: action.payload.headers.map((each, i) => ({
            label: each,
            id: each,
            getValue: row => typeof row[i] === 'object' ? row[i].qText : row[i]
          }))
        },
        conditions: action.payload.list
      };
    case actionTypes.GET_DISTRIBUTION_GROUPS:
      const assignedGroups = {}
      const unassignedGroups = {}
      const callbackGroups = (each) => {
        if (state.groups.includes(each.id)) assignedGroups[each.id] = each
        else unassignedGroups[each.id] = each
      }
      action.payload.assigned.forEach(callbackGroups)
      action.payload.unassigned.forEach(callbackGroups)
      return {
        ...state,
        assigned: {
          ...state.assigned,
          groups: assignedGroups
        },
        unassigned: {
          ...state.unassigned,
          groups: unassignedGroups
        },
      };
    case actionTypes.GET_DISTRIBUTION_RECIPIENTS:
      const assignedUsers = {}
      const unassignedUsers = {}
      const buffer = { ...state.recipientsBuffer }
      const callbackRecipients = (each) => {
        if (state.recipients.includes(each.id)) {
          assignedUsers[each.id] = each
          buffer[each.id] = each
        }
        else {
          unassignedUsers[each.id] = each
          buffer[each.id] = each
        }
      }
      action.payload.assigned.forEach(callbackRecipients)
      action.payload.unassigned.forEach(callbackRecipients)
      state.recipients.forEach(each => {
        if (!assignedUsers[each] && buffer[each]) assignedUsers[each] = buffer[each]
      })
      return {
        ...state,
        recipientsBuffer: buffer,
        assigned: {
          ...state.assigned,
          recipients: assignedUsers
        },
        unassigned: {
          ...state.unassigned,
          recipients: unassignedUsers
        },
      };
    case actionTypes.UPLOAD_TEMPLATE:
      const { key, content } = action.payload;
      return {
        ...state,
        inserted: {
          [key]: content
        },
        body: content
      };
    case actionTypes.CLEAR_TEMPLATE:
      return {
        ...state,
        inserted: {},
        body: ''
      };
    case actionTypes.ADD_SCHEDULE_TIME:
      return {
        ...state,
        times: [...state.times, action.payload]
      };
    case actionTypes.DELETE_SCHEDULE_TIME:
      const times = [...state.times];
      times.splice(action.payload, 1);
      return {
        ...state,
        times
      };
    case actionTypes.GET_RECIPENTS_SELECTED:
      return {
        ...state,
        savedRecipients: action.payload.savedRecipients
      };
    case actionTypes.GET_GROUPS_SELECTED:
      return {
        ...state,
        savedGroups: action.payload.savedGroups
      };
    case actionTypes.GET_PREVIEW_SELECTED:
      return {
        ...state,
        previewSelected: action.payload
      };
    case actionTypes.TOGGLE_UNSUBSCRIBED:
      const unsubscribed = [...state.unsubscribed]
      if (action.payload.unsubscribed) unsubscribed.push(action.payload.id)
      else unsubscribed.splice(unsubscribed.indexOf(action.payload.id), 1);
      const changingIndex = state.previewSelected.findIndex(each => each.id === action.payload.id)
      const previewSelected = [...state.previewSelected]
      previewSelected[changingIndex].unsubscribed = action.payload.unsubscribed
      return {
        ...state,
        unsubscribed,
        previewSelected
      };
    case actionTypes.GET_SYSTEM_TASKS:
      return {
        ...state,
        systemTasks: action.payload
      }
    case actionTypes.ADD_VALUE_TO_ALERT:
      return {
        ...state,
        [action.payload.field]: action.payload.value
      };
    case actionTypes.ADD_VALUE_TO_CONDITION:
      const tempConditions = [...state.conditions];
      tempConditions[action.payload.index] = {
        ...tempConditions[action.payload.index],
        [action.payload.field]: action.payload.value
      };
      return {
        ...state,
        conditions: tempConditions
      };
    case actionTypes.SAVE_ALERT:
      return state
    case actionTypes.SAVE_BUTTON_DISABLED:
      return {
        ...state,
        saveIsDisabled: action.payload.saveIsDisabled
      };
    case actionTypes.CANCEL_CREATE_ALERT:
      return state
    case actionTypes.LOCATION_CHANGE:
      return shouldCleanup(action.payload) ? { ...initialState } : state;
    case actionTypes.PUBLISH_BOOKMARK:
      const bookmarks = state.options.bookmarks.map(each => each.id === action.payload ? ({ ...each, published: true }) : each)
      return {
        ...state,
        options: {
          ...state.options,
          bookmarks
        }
      };
    default:
      return state;
  }
}
