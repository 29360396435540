/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles, Chip, FormControl, FormLabel, TextField } from "@material-ui/core";
import useString from "../../i18n/hooks/useString";

const useStyles = makeStyles((theme) => ({
  fullWidthControl: {
    width: "100%",
  },
  marginBottom: {
    marginBottom: theme.spacing(),
  },
  helperText: {
    fontWeight: "normal",
    marginTop: "0.4em",
    marginLeft: "0.4em",
    color: ({ helperTextColor }) => helperTextColor ? helperTextColor : theme.palette.text.primary,
    '&.Mui-focused:not(.Mui-error)': {
      color: ({ helperTextColor }) => helperTextColor ? helperTextColor : theme.palette.text.primary,
    },
    '&.Mui-error': {
      color: theme.palette.error.main,
    }
  },
  secondaryLabel: {
    color: theme.palette.text.secondary,
  }
}));

const Selector = props => {
  const {
    name,
    label,
    multiple,
    options = [],
    getValue,
    value,
    onSelect,
    helperText,
    groupBy,
    inputfilter,
    ...rest
  } = props;
  const [bufferedValue, setBufferedValue] = useState(getValue(options, value));
  const classes = useStyles();
  const getString = useString();
  const getOptionLabel = input => {
    return getString(rest.getOptionLabel(input))
  }

  useEffect(() => {
    setBufferedValue(getValue(options, value) || '');
  }, [value, getValue, options]);

  const handleChange = (event, newValue) => {
    return onSelect(newValue)
  };

  


  return (
    <Autocomplete
      tid="autocomplete"
      name={name}
      options={options}
      multiple={multiple}
      disableCloseOnSelect={multiple}
      includeInputInList
      groupBy={groupBy && (item => getString(groupBy(item)))}
      {...rest}
      getOptionLabel={getOptionLabel}
      value={bufferedValue}
      onChange={handleChange}
      onInputChange ={inputfilter}
      renderTags={(tagValue, getTagProps) => (
        tagValue.map((option, index) => {
          const chipText = option.qText || option.name || option;
          return (
            <Chip
            key={`chip${index}-${chipText }`}
            tid={`chip${index}`}
            variant="outlined"
            size="small"
            label={getString(chipText)}
            {...getTagProps({ index })}
          />
          )
      }))}
      renderInput={({error, labelDetails, ...rest}) => (
        <FormControl error={error} tid="form_control" className={classes.fullWidthControl}>
          <FormLabel className={classes.marginBottom}> {getString(label)} <span className={classes.secondaryLabel}>{labelDetails}</span></FormLabel>
          <TextField
            tid="text_field"
            variant="outlined"
            fullWidth
           inputProps={{ autoComplete: 'disabled'}}
            {...rest}
          />
          {helperText && <FormLabel tid="form_label" className={classes.helperText}>{getString(helperText)} </FormLabel>}
        </FormControl>
      )}
    />
  );
};

export default Selector;
