import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";
import CloseIcon from '@material-ui/icons/Close';
import {
  Button,
  Dialog,
  DialogTitle,
  Typography,
  IconButton,
  DialogContent,
  DialogActions
} from '@material-ui/core'
import { makeStyles } from "@material-ui/core/styles";
import useString from '../../i18n/hooks/useString';

const useStyles = makeStyles((theme) => ({
  smallIcon: {
    height: '16px',
    width: '16px',
  }
}));

const propTypes = {
  actions: PropTypes.object,
  buttons: PropTypes.array,
  //content: PropTypes.string,
  modalVisible: PropTypes.bool,
  options: PropTypes.object,
  setModalVisible: PropTypes.func,
};

const defaultProps = {
  actions: {},
  buttons: [],
  options: {},
};

const MyModal = ({ actions, modalVisible, setModalVisible, buttons, children, content, options, maxWidth, ...rest }) => {
  const classes = useStyles();
  const getString = useString({ maxWidth });
  const history = useHistory();

  const handleClose = () => {
    setModalVisible(false)
    if (options.onClose) {
      if (actions[options.onClose]) actions[options.onClose]()
      else options.onClose()
    }
  }

  const makeButton = (button) => {
    const { label, key, onClick, color, disabled, nav, variant } = button;
    const buttonVariant = variant ? variant : 'contained';
    let disabledValue = disabled;
    if (actions[disabled]) disabledValue = actions[disabled]();
    const handleClick = () => {
      if (onClick) {
        if (actions[onClick]) actions[onClick]()
        else onClick()
      }
      if (nav) {
        history.push(nav());
      }
      setModalVisible(false)
    }
    if (nav) {
      return (
        <Button tid="button_if_nav" key={key} onClick={handleClick} variant={buttonVariant} color={color} disabled={disabledValue}>{getString(label)}</Button>
      )
    }
    else {
      return (
        <Button tid={`button_${key === 'share' ? 'save' : key}`} variant={buttonVariant} key={key} onClick={handleClick} color={color} disabled={disabledValue}>{getString(label)}</Button>
      )
    }
  }

  return (
    <Dialog
      tid="dialog"
      open={modalVisible}
      onClose={handleClose}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
      fullScreen={true}
      scroll='paper'
      {...rest}
    >
      <DialogTitle tid="dialog_title" id="confirm-dialog-title">
        <Typography tid="typography_title" variant="h5">{getString(options.title)}</Typography>
        <IconButton
          tid="icon_button_close"
          onClick={handleClose}
        >
          <CloseIcon tid="close_icon" className={classes.smallIcon} />
        </IconButton>
      </DialogTitle>
      <DialogContent tid="dialog_content">
        {children ? (
          children
        ) : (
          getString(content)
        )}
      </DialogContent>
      <DialogActions tid="dialog_actions">
        {buttons.map(button => makeButton(button))}
      </DialogActions>
    </Dialog>
  )
}

MyModal.propTypes = propTypes;
MyModal.defaultProps = defaultProps
export default MyModal
