export const crumbs = [
  {label: "User Profile"},
  {label: "Suggestions"},
]

export const table = {
  options: {
    header: true,
    select: true,
    onSelect: "setSelected"
  },
  style: {
    header: {
      fontWeight: "bold"
    }
  },
  fields: [
    {
      label: "Suggestions.Label.AlertNames",
      type: "",
      id: "name",
      // sortBy: "name",
      style: { flex: 2 },
      getValue: row => row.name,
      action: null
    },
    {
      label: "Suggestions.Label.SharedBy",
      type: "",
      id: "sharedBy",
      // sortBy: "sharedByInfo.name",
      style: { flex: 2 },
      getValue: row => row.userName,
      action: null
    },
    {
      label: "Suggestions.Label.FullName",
      type: "",
      id: "fullName",
      // sortBy: "sharedByInfo.firstName",
      style: { flex: 2 },
      getValue: row => row.fullName,
      action: null
    },
    {
      label: "Suggestions.Label.SharedAt",
      type: "",
      id: "sharedAt",
      //sortBy: "sharedAt",
      style: { flex: 2 },
      getValue: row => row.sharedAt,
      action: null
    }
  ]
};
