import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  Container,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useStyles } from "../../constants/styles";
import useString from "../../i18n/hooks/useString";

const SimpleCard = (props) => {
  const { fields, data, title, outline, baseLink } = props;
  const getString = useString();
  const classes = useStyles();

  const borderOutline = (style) => (outline ? style : { border: "none" });

  return (
    <Container
      tid="container"
      className={outline ? classes.simpleCardContainer : null}
      disableGutters={true}
    >
      <Table
        style={borderOutline(null)}
        tid="table"
        aria-label={getString("SimpleCard.Label.SpanningTable")}
      >
        <TableHead tid="table_head">
          <TableRow tid="table_row">
            <TableCell
              tid="table_cell"
              style={borderOutline({ backgroundColor: "#f2f2f2" })}
              align="left"
              colSpan={4}
            >
              {title}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody tid="table_body">
          {fields.map((item, i) => (
            <TableRow tid={`table_row_${i}`} key={item.key}>
              <TableCell
                style={borderOutline(null)}
                tid={`table_cell_label_${i}`}
              >
                {getString(item.label)}
              </TableCell>

              {item.link ? (
                <TableCell style={borderOutline(null)}>
                  <a
                    href={baseLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      textDecoration: "underline",
                      fontWeight: "bold",
                    }}
                  >
                    {getString(data[item.key])}
                  </a>
                </TableCell>
              ) : (
                <TableCell
                  style={borderOutline(null)}
                  tid={`table_cell_data_${i}`}
                >
                  {getString(data[item.key])}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Container>
  );
};

export default SimpleCard;
