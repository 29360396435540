import { dateISO } from "../../utils/dateISO";

export const toInboxData = (input) =>
  input.map((each) => ({
    scanTimeStamp: dateISO(each.timestamp, true),
    title: each.title,
    notified: each.notified ? "InboxData.DataHandler.Yes" : "InboxData.DataHandler.No",
    id: each.scanId,
    name: each.name,
  }));

export const toInboxDataDetails = (input) => {
  const format = {
    "0.00a": 'Auto',
    "0,0": "1,000",
    "0,0.0": "1,000.0",
    "0,0.00": "1,000.12",
    "0%": "12%",
    "0.0%": "12.3%",
    "0.00%": "12.34%"
  };
  const frequencyLabels = {
    always: "InboxData.DataHandler.Label.Every",
    hour: "InboxData.DataHandler.Label.FirstHour",
    day: "InboxData.DataHandler.Label.FirstDay",
    week: "InboxData.DataHandler.Label.FirstWeek",
    month: "InboxData.DataHandler.Label.FirstMonth",
    once: "InboxData.DataHandler.Label.Once",
  };
  return {
    title: input.title,
    appName: input.appName,
    conditions: input.conditions,
    rules: input.rules,
    filters: input.query.filters?.length
    ? input.query.filters
    : [
        {
          field: input.query.bookmark
            ? "InboxData.DataHandler.BookmarkField"
            : "InboxData.DataHandler.NoFiltersField",
          values: [],
        },
      ],
    headers: input.headers,
    data: input.data,
    dimensions: input.query.dimensions || [],
    measures: input.query.measures.map((each) => ({
      ...each,
      format: (each.format ? format[each.format] : format[input.query.format]) || 'Auto'
    })),
    distributionFrequency: frequencyLabels[input.notificationFrequency],
    timestamp: input.timestamp,
    countPositive: input.count.positive,
    countTotal: input.count.total,
    scanId: input.scanId,
    baseLink: input.baseLink,
  };
};
