import React, { useState } from "react";
import { Button, Card, CardContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "../../TextField";
import useString from "../../../i18n/hooks/useString";


const useStyles = makeStyles({
  containerCard: {
    width: "100%",
    marginTop: "15px"
  },
  cardContent: {
    textAlign: " center"
  },
  emailContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  emailField: {
    width: "40%"
  },
  buttonAdmin: {
    marginTop: "30px"
  }
});

const AdminCard = props => {
  const { actions, register } = props;
  const classes = useStyles();
  const [value, setValue] = useState("");
  const getString = useString();

  const handleClick = () => actions.registerAdmin(register.findUser._id, value);

  return (
    <Card tid="card_parent" className={classes.containerCard}>
      <CardContent tid="card_content" className={classes.cardContent}>
        <div tid="div_username">
          <h4 tid="h4_username"> {getString("Register.Card.UserName")} </h4> {register.findUser.name}
        </div>
        <div tid="div_first_name">
          <h4 tid="h4_first_name"> {getString("Register.Card.FirstName")} </h4> {register.findUser.firstName}
        </div>
        <div tid="div_last_name">
          <h4 tid="h4_last_name"> {getString("Register.Card.LastName")} </h4> {register.findUser.lastName}
        </div>
        <div tid="div_email" className={classes.emailContainer}>
          <h4 tid="h4_email">{getString("Register.Card.Email")} </h4>
          {register.findUser.email ? (
            register.findUser.email
          ) : (
            <span tid="span_email" className={classes.emailField}>
              <TextField
                tid="text_field_email"
                fullWidth={true}
                style={{ width: "100%" }}
                value={value}
                onChange={e => setValue(e.target.value)}
                placeholder={getString("Register.Card.ConfirmEmail")}
              />
            </span>
          )}
        </div>
        <Button
          tid="button_user_admin"
          color="primary"
          variant="contained"
          onClick={handleClick}
          className={classes.buttonAdmin}
        >
          {getString("Register.Card.SelectThisUserAsAdmin")}
        </Button>
      </CardContent>
    </Card>
  );
};

export default AdminCard;
