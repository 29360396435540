import React, { useState, useEffect } from "react";
import { Grid, IconButton, Typography } from "@material-ui/core";
import { SkipNext, SkipPrevious } from "@material-ui/icons";
import Table from "../Table";
import { makeStyles } from "@material-ui/core/styles";
import useString from "../../i18n/hooks/useString";
import NavTitle from "../NavTitle";
import TableHeader from "../TableHeader";

const useStyles = makeStyles(theme => ({
  assignerScroll: {
    maxHeight: '100%',
    overflowY: 'auto',
  },
  container: {
    height: '100%',
    flexWrap: 'nowrap',
    '& > div': {
      marginTop: theme.spacing(),
    }
  },
  tableWrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%'
  },
  quadrant: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  gridParent: {
    flex: '1 1 auto',
    flexWrap: 'nowrap',
    maxHeight: '100%',
    paddingBottom: theme.spacing(),
  },
  gridPendingParent: {
    flex: '1 1 auto'
  }
}))

const Assigner = (props) => {
  const classes = useStyles();
  const getString = useString();
  const {
    assigned = [],
    unassignedData = {},
    assignedData = {},
    onAssigned,
    fields,
    setSaveDisabled,
    pendingUnassigned,
    setPendingUnassigned,
    pendingAssigned,
    setPendingAssigned
  } = props;

  const [selectUnassigned, setSelectUnassigned] = useState([]);
  const [selectAssigned, setSelectAssigned] = useState([]);
  const [selectPendingUnassigned, setSelectPendingUnassigned] = useState([]);
  const [selectPendingAssigned, setSelectPendingAssigned] = useState([]);

  const [localUnassigned, setLocalUnassigned] = useState(unassignedData)
  const [localAssigned, setLocalAssigned] = useState(assignedData)
  
  const [buffer, setBuffer] = useState({})

  useEffect(() => {
    setLocalAssigned(assignedData)
    setSelectAssigned([])
  }, [ assignedData ])

  useEffect(() => {
    setLocalUnassigned(unassignedData)
    setSelectUnassigned([])
  }, [ unassignedData ])
  
  const handleAssign = () => {
    const tempList = [...selectUnassigned, ...selectPendingUnassigned]
    const tempUnassigned = {...localUnassigned}
    const tempAssigned  = {...localAssigned}
    const tempBuffer = {...buffer}
    const tempPendingAssigned = [...pendingAssigned]
    const pendingUnassignedData = pendingUnassigned.filter(each => !tempList.includes(each))
    setPendingUnassigned(pendingUnassignedData)
    tempList.forEach(each => {
      if(!assigned.includes(each)) {
        tempBuffer[each] = tempUnassigned[each]
        if (tempBuffer[each]) tempPendingAssigned.push(each)
        delete tempUnassigned[each]
      }
      else if (tempBuffer[each]) {
        tempAssigned[each] = tempBuffer[each]
        delete tempBuffer[each]
      }
    })
    setLocalUnassigned(tempUnassigned)
    setLocalAssigned(tempAssigned)
    setBuffer(tempBuffer)
    setPendingAssigned(tempPendingAssigned)
    setSelectUnassigned([]);
    setSelectPendingUnassigned([]);
    const pending = [...assigned.filter(each => !pendingUnassignedData.includes(each)), ...tempPendingAssigned]
    onAssigned(pending);
    setSaveDisabled((tempPendingAssigned.length + pendingUnassigned.length) === 0);
  };

  const handleUnassign = () => {
    const tempList = [...selectAssigned, ...selectPendingAssigned]
    const tempAssigned = {...localAssigned}
    const tempUnassigned  = {...localUnassigned}
    const tempBuffer = {...buffer}
    const tempPendingUnassigned = [...pendingUnassigned]
    setPendingAssigned(pendingAssigned.filter(each => !tempList.includes(each)))
    tempList.forEach(each => {
      if(assigned.includes(each)) {
        tempBuffer[each] = tempAssigned[each]
        if (tempBuffer[each]) tempPendingUnassigned.push(each)
        delete tempAssigned[each]
      }
      else if (tempBuffer[each]) {
        tempUnassigned[each] = tempBuffer[each]
        delete tempBuffer[each]
      }
    })
    setLocalAssigned(tempAssigned)
    setLocalUnassigned(tempUnassigned)
    setBuffer(tempBuffer)
    setPendingUnassigned(tempPendingUnassigned)
    setSelectAssigned([]);
    setSelectPendingAssigned([]);
    const pending = [...assigned.filter(each => !tempPendingUnassigned.includes(each)), ...pendingAssigned.filter(each => !tempList.includes(each))]
    onAssigned(pending);
    setSaveDisabled((pendingAssigned.length + tempPendingUnassigned.length) === 0);
  };

  const tableFields = fields || [
    { id: "firstName", label: "Users.Label.FirstName", getValue: (row) => row.firstName },
    { id: "lastName", label: "Users.Label.LastName", getValue: (row) => row.lastName },
    { id: 'name', label: 'Users.Label.UserName', getValue: row => row.name },
    { id: "domain", label: "Users.Label.Domain", getValue: (row) => row.domain }
  ];

  return (
    <Grid container direction="column" className={classes.container}>
      <Grid container tid="grid_parent" spacing={1} className={classes.gridParent}>
        <Grid item tid="grid_unassigned" xs className={classes.tableWrapper}>
          <Grid item tid="grid_table_unassigned" className={classes.quadrant}>
            <TableHeader
              leftToolbar={
                <NavTitle title={"Assigner.Label.Unassigned"} subtitle={"Assigner.Label.FirstFiftyRows"} underlineWidth="auto" noUnderline/>
              }
            />
            <Grid item className={classes.assignerScroll}>
              <Table
                tid="table_unassigned"
                data={Object.values(localUnassigned).filter(each => !assigned.includes(each.id) && !pendingAssigned.includes(each.id)).sort((a,b) => a.name.localeCompare(b.name))}
                fields={tableFields}
                options={{ header: true, select: true, onSelect: setSelectUnassigned }}
                selectedIds={selectUnassigned}
              />
            </Grid>
          </Grid>
          <Grid item tid="grid_pending_unassigned" xs className={classes.quadrant} style={{maxHeight: '50%', flex: '99 0 auto'}}>
            <TableHeader
              leftToolbar={
                <NavTitle title={"Assigner.Label.Pending"} underlineWidth="auto" noUnderline/>
              }
            />
           
            {pendingUnassigned.length > 0 ? (
              <Grid item className={classes.assignerScroll}>              
                <Table
                  tid="table_pending_unassigned"
                  data={pendingUnassigned.map(each => buffer[each]).sort((a,b) => a.name.localeCompare(b.name))}
                  fields={tableFields}
                  options={{ header: true, select: true, onSelect: setSelectPendingUnassigned }}
                  selectedIds={selectPendingUnassigned}
                />
              </Grid>
            ) : (
              <TableHeader 
                leftToolbar= {
                  <Typography color="textSecondary">{getString('Assigner.Label.NoPendingItems')}</Typography>
                }
                style={{borderTop: 'none'}}
              />
            )}
          </Grid>
        </Grid>
        <Grid tid="grid_icon_buttons" container item xs direction="column" justify="center" style={{maxWidth: '50px'}}>
          <IconButton tid="table_unassigned_button" disabled={!(selectUnassigned.length + selectPendingUnassigned.length)} onClick={handleAssign}>
            <SkipNext tid="skip_next_assign"/>
          </IconButton>
          <IconButton tid="table_assigned_button" disabled={!(selectAssigned.length + selectPendingAssigned.length)} onClick={handleUnassign}>
          <SkipPrevious tid="skip_previous_unassign" />
          </IconButton>
        </Grid>
        <Grid tid="grid_assigned" item xs className={classes.tableWrapper}>
          <Grid tid="grid_table_assigned" item className={classes.quadrant}>
            <TableHeader
              leftToolbar={
                <NavTitle title={"Assigner.Label.Assigned"} subtitle={"Assigner.Label.FirstFiftyRows"} underlineWidth="auto" noUnderline/>
              }
            />
            <Grid item className={classes.assignerScroll}>
              <Table
                tid="table_assigned"
                data={Object.values(localAssigned).filter(each => !pendingUnassigned.includes(each.id)).sort((a,b) => a.name.localeCompare(b.name))}
                fields={tableFields}
                options={{ header: true, select: true, onSelect: setSelectAssigned }}
                selectedIds={selectAssigned}
              />
            </Grid>
          </Grid>
          <Grid item tid="grid_pending_assigned" xs className={classes.quadrant} style={{maxHeight: '50%', flex: '99 0 auto'}}>
            <TableHeader
              leftToolbar={
                <NavTitle title={"Assigner.Label.Pending"} underlineWidth="auto" noUnderline/>
              }
            />
            {pendingAssigned.length > 0 ? (
              <Grid item className={classes.assignerScroll}>
                <Table
                  tid="table_pending_assigned"
                  data={pendingAssigned.map(each => buffer[each]).sort((a,b) => a.name.localeCompare(b.name))}
                  fields={tableFields}
                  options={{ header: true, select: true, onSelect: setSelectPendingAssigned }}
                  selectedIds={selectPendingAssigned}
                  />
              </Grid>
            ) : (
              <TableHeader 
                leftToolbar= {
                  <Typography color="textSecondary">{getString('Assigner.Label.NoPendingItems')}</Typography>
                }
                style={{borderTop: 'none'}}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(Assigner)
