import React from 'react'
import { Container, Grid } from '@material-ui/core';
import { useStyles } from '../../constants/styles';

const CardDeck = (props) => {
    const classes = useStyles();
    const { size = 5, data = [], filled, firstEmpty, empty } = props
    if (size > data.length && firstEmpty) 
    return (
        <>
            <Container tid="container" >
                <Grid container tid="grid_parent" className= { classes.cardDeckLayout }>
                    {data.map(filled)}
                    {size > data.length && firstEmpty ? firstEmpty() : null}
                    {Array(size - data.length - (firstEmpty ? 1 : 0)).fill(null).map((each, index) => empty(index))}
                </Grid>
            </Container>
        </>
    );
}

export default CardDeck
