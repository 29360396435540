import send from './requests'

const endpoints = {
  // LOGIN
  login: ({ user, password }) => send("POST", "/login", null, null, { Authorization: btoa(unescape(encodeURIComponent(`${user}:${password}`)))}),
  isLogged: () => send("POST", "/logged"),
  logout: () => send("POST", "/logout"),
  // ALERTS
  getAlerts: ({
    type,
    offset = 0,
    size = 50,
    allAlerts,
    filter,
    sort,
    reverse
  } = {}) =>
    send("GET", `/alerts/${type}${allAlerts ? "/all" : ""}`, {
      offset,
      size,
      expand: true,
      filter,
      sort,
      reverse
    }),
  getOneAlert: (type, id) => send("GET", `/alerts/${type}/${id}`),
  createAlert: (type, body) => send("POST", `/alerts/${type}`, null, body),
  duplicateAlert: (type, alertId) => send("POST", `/alerts/${type}/${alertId}/clone`),
  updateAlert: (type, id, body) =>
    send("PUT", `/alerts/${type}/${id}`, null, body),
  deleteAlert: (type, id) => send("DELETE", `/alerts/${type}/${id}`),
  toggleAlert: (type, id, onOff) =>
    send("PUT", `/alerts/${type}/${id}/${onOff}`),
  triggerAlert: (type, id) => send("POST", `/alerts/${type}/${id}/trigger`),
  unsubscribeAlert: (type, id, userId) =>
    send("PUT", `/alerts/${type}/${id}/unsubscribe/${userId}`),
  resubscribeAlert: (type, id, userId) =>
    send("PUT", `/alerts/${type}/${id}/resubscribe/${userId}`),
  calculateSchedule: (type, body) =>
    send("POST", `/alerts/${type}/schedule/calculate`, null, body),
  shareAlerts: (type, body) =>
    send("POST", `/alerts/${type}/share`, null, body), // DATA
  validateShareAlertUser: (type, body) =>
    send("POST", `/alerts/${type}/share/validateUser`, null, body),
  checkCondition: (type, body) =>
    send("POST", `/alerts/${type}/condition`, null, body), // DATA
  // CHANNELS
  getChannels: ({ sort, reverse } = {}) =>
    send("GET", `/channels`, { expand: true, sort, reverse }),
  getOneChannel: id => send("GET", `/channels/${id}`),
  updateChannel: (id, body) => send("PUT", `/channels/${id}`, null, body),
  testChannel: body => send("POST", `/channels/test`, null, body),
  toggleChannel: (id, onOff) => send("PUT", `/channels/${id}/${onOff}`),
  // CONFIG
  getConfig: () => send("GET", "/config"),
  upsertConfig: body => send("POST", "/config", null, body),
  // GROUPS
  getGroups: ({ offset = 0, size = 50, filter, sort, reverse, join, on, outer } = {}) =>
    send("GET", `/groups/users`, {
      offset,
      size,
      expand: true,
      filter,
      sort,
      reverse,
      join,
      on,
      outer
    }),
  getGroupsAlerts: ({ type, id, includes, offset = 0, size = 50, filter, sort, reverse } = {}) =>
    send("GET", `/groups/users/alerts/${type}/${id}/${includes}`, { offset, size, filter, sort, reverse }),
  getGroupsUsers: ({ id, includes, offset = 0, size = 50, filter, sort, reverse } = {}) =>
    send("GET", `/groups/users/${id}/users/${includes}`, { offset, size, filter, sort, reverse }),
  getOneGroup: id => send("GET", `/groups/users/${id}`),
  createGroup: body => send("POST", `/groups/users`, null, body),
  updateGroup: (id, body) => send("PUT", `/groups/users/${id}`, null, body),
  deleteGroup: id => send("DELETE", `/groups/users/${id}`),
  togglegroup: (id, onOff) => send("PUT", `/groups/users/${id}/${onOff}`),
  fullAssignToGroup: (id, type) => send("PUT", `/groups/users/${id}/all/${type}`),
  // HISTORY
  getHistory: ({ type, offset = 0, size = 50, filter, sort, reverse } = {}) =>
    send("GET", `/history/${type}`, { offset, size, filter, sort, reverse }),
  getHistorySystemDetail: (alertId, taskId) =>
    send("GET", `/history/system/${alertId}/${taskId}`),

    // INBOX DATA
    getDataHistory: (alertId) =>
    send("GET", `/history/data/${alertId}`), 
    getHistoryDataDetails: (alertId, scanId) =>
    send("GET", `/history/data/${alertId}/${scanId}`),
        getHistoryDataCsv: (alertId, scanId) =>
    send("GET", `/history/data/${alertId}/${scanId}/csv`),
  // INVITATIONS
  getInvitations: (offset = 0, size = 50, filter) =>
    send("GET", "/invitations", { offset, size, filter }),
  getSentInvitations: (offset = 0, size = 50) =>
    send("GET", "/invitations/sent", { offset, size }),
  acceptInvitation: id => send("POST", `/invitations/accept/${id}`),
  rejectInvitation: id => send("POST", `/invitations/reject/${id}`),
  deleteInvitation: id => send("DELETE", `/invitations/${id}`),
  // REGISTRATION
  getRegistrationStatus: () => send("GET", "/registration/status"),
  registerConfig: body => send("POST", "/registration/config", null, body),
  registerChannel: body => send("POST", "/registration/channel", null, body),
  registerSourceTest: body => send("POST", "/registration/source/test", null, body),
  registerSource: body => send("POST", "/registration/source", null, body),
  findRegistrationUser: body => send("POST", "/registration/user", null, body),
  registerAdmin: (userId, body) =>
    send("POST", `/registration/admin/${userId}`, null, body),
  clearRegistration: () => send("DELETE", `/registration/clear`),
  // SOURCES
  getSources: ({ sort, reverse } = {}) =>
    send("GET", `/sources`, { expand: true, sort, reverse }),
  getOneSource: id => send("GET", `/sources/${id}`),
  updateSource: (id, body) => send("PUT", `/sources/${id}`, null, body),
  testSource: body => send("POST", `/sources/test`, null, body),
  toggleSource: (id, onOff) => send("PUT", `/sources/${id}/${onOff}`),
  getApps: (skip=0,limit=200,qsFilter='') => send("POST", `/sources/qlik/apps?skip=${skip}&limit=${limit}`,null,{qsFilter:`${qsFilter}`}),
  getApp: (id) => send("GET", `/sources/qlik/app?id=${id}`),
  getAppsCount: (skip=0,limit=200,qsFilter='') => send("GET", `/sources/qlik/apps/count?skip=${skip}&limit=${limit}&qsFilter=${qsFilter}`),
  getTasks: () => send("GET", `/sources/qlik/tasks`),
  getAppObjects: (appId) =>
    send("GET", `/sources/qlik/${appId}/objects`),
  getAppData: (body, envelope) =>
    send("POST", `/sources/qlik/data`, {envelope}, body),
  refreshCache: (appId) =>
    send("DELETE", `/sources/qlik/cache/${appId || ""}`),
  syncAllocations: () => send("POST", `/sources/qlik/allocations`),
  syncLicense: () => send("POST", `/sources/qlik/license`),
  syncUsers: () => send("POST", `/sources/qlik/users`),
  publishBookmark: (app, bookmark) => send("POST", `/sources/qlik/bookmark/publish/${app}/${bookmark}`),
    // USERS
  getUsers: ({ id = '' , offset = 0, size = 50, filter, sort, reverse, join, on, outer } = {}) =>
    send("GET", `/users/${id}`, { offset, size, filter, sort, reverse, join, on, outer }),
  getUsersAlerts: ({ type, id, includes, offset = 0, size = 50, filter, sort, reverse } = {}) =>
    send("GET", `/users/alerts/${type}/${id}/${includes}`, { offset, size, filter, sort, reverse }),
  getOneUser: id => send("GET", `/users/${id}`),
  updateUser: (id, body) => send("PUT", `/users/${id}`, null, body),
  deleteUser: id => send("DELETE", `/users/${id}`),
  toggleUser: (id, onOff) => send("PUT", `/users/${id}/${onOff}`),
  queryMembers: body => send("POST", `/users/members`, null, body),
  // PASSWORDS
  forgotPassword: body => send("POST", `/users/password/forgot`, null, body),
  changePassword: body => send("PUT", `/users/password/change`, null, body),
  recoverPassword: body => send("POST", `/users/password/recover`, null, body),
  resetPassword: id => send("POST", `/users/password/${id}`),
  // DEVICES
  getDevices: () => send("GET", `/users/devices`),
  generateQRToken: () => send("POST", `/users/devices`),
  deleteDevice: id => send("DELETE", `/users/devices/${id}`),
  toggleDevice: (id, onOff) => send("PUT", `/users/devices/${id}/${onOff}`),
  // PERMISSIONS
  getUserPermissions: ({ sort, reverse } = {}) =>
    send("GET", `/users/permissions`, { sort, reverse }),
  getOneUserPermissionsType: id => send("GET", `/users/permissions/${id}`),
  updateUserPermissions: (id, body) =>
    send("PUT", `/users/permissions/${id}`, null, body),
  fullAssignPermissions: (id, type) => send("PUT", `/users/permissions/${id}/all/${type}`),
  loginSSO : (uniqueId) => send("GET",`/users/loginsso?uniqueId=${uniqueId}`)
};

export default endpoints
