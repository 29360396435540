export const config = (input) => {
  return {
    hostname: input.hostname,
    httpPort: input.httpPort,
    httpsPort: input.httpsPort,
    sessionLimit: 30, //TODO: remove sessionLimit and scanInterval
    scanInterval: 1
  }
}

export const channel = values => {
  return {
    name: values.channelName || 'Email',
    type: 'email',
    connection: {
      host: values.connectionHost,
      port: values.connectionPort,
      from: values.connectionFrom,
      test: values.connectionTest,
      authenticationMethod: values.authenticationMethod,
      auth: {
        user: values.connectionAuthUser,
        pass: btoa(unescape(encodeURIComponent(values.connectionAuthPass))),
        security: values.connectionAuthSecurity
      }
    }
  }
};

export const source = values => {
  const object = {
    name: values.sourceName || 'Qlik',
    type: 'qlik',
    config: {
      hostname: values.hostname,
      url: values.url,
      httpsPort: values.httpsPort,
      qpsPort: values.qpsPort,
      qrsPort: values.qrsPort,
      userDirectory: values.userDirectory,
      userId: values.userId,
      filter: values.filter,
      virtualProxy: values.virtualProxy,
      sessionCookieName: values.sessionCookieName,
      proxyUrl: values.proxyUrl,
      ssoEnabled: values.ssoEnabled ? values.ssoEnabled : false,
      authenticationURI: values.authenticationURI ? values.authenticationURI : null
    }
  }
  if (values.insertedCert.client && values.insertedCert.client_key) {
    object.certs = {
      client: values.insertedCert.client,
      client_key: values.insertedCert.client_key
    }
  }
  return object;
};
