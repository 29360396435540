export const crumbs = [
  {label: "Breadcrumbs.Admin"},
  {label: "Breadcrumbs.Channels"}
]

export const table = {
  options: {
    header: true
  },
  style: {
    header: {
      fontWeight: "bold"
    }
  },
  menu: [
    { id: "edit", action: "handleEdit", label: "General.Edit" }
  ],
  fields: [
    {
      label: "Channels.Label.Channel",
      type: "",
      id: "name",
      style: { flex: 2 },
      getValue: row => row.name,
      action: null
    },
    {
      label: "Channels.Label.Address",
      type: "",
      id: "address",
      style: { flex: 2 },
      getValue: row => row.address,
      action: null
    },
    {
      label: "Channels.Label.PortNo",
      type: "",
      id: "portNo",
      style: { flex: 2 },
      getValue: row => row.portNo,
      action: null
    },
    {
      label: "Channels.Label.SecurityLayer",
      type: "",
      id: "securityLayer",
      style: { flex: 2 },
      getValue: row => row.securityLayer,
      action: null
    },
    {
      label: "Channels.Label.Email",
      type: "",
      id: "email",
      style: { flex: 2 },
      getValue: row => row.email,
      action: null
    }
  ]
};
